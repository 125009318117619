import { TypeProperty, VisibilityProperty } from './enums';
import { PaginatedQuerySearch } from './pagingResult';
import { TopExport } from './topExport';

export interface ILogEntry {
    id: string;
    date: Date;
    message: string;

    // User
    userId: string;
    userUsername: string;
    userDisplayName: string;
    userEmail: string;

    // Original User
    originalUserId: string;
    originalUserUsername: string;
    originalUserDisplayName: string;
    originalUserEmail: string;


    // Values
    oldValue: string;
    newValue: string;

    // Property
    propertyId: string;
    propertyName: string;
    propertyDisplayName: String;
    propertyType: TypeProperty;
    propertyVisibility: VisibilityProperty;

    // Treenodes
    treeNodeId: string;
    treeNodeName: string;
    treeNodeCode: string;
}

export class HistorySearch extends PaginatedQuerySearch {
    userId: string;
    treeNodeId: string;

    startDate: string;
    endDate: string;
}

export class HistoryExportModel {
  topExports: TopExport[];
  from: string;
  to: string;
}

