import { ImportType } from './enums';
export class GenericImportParameter {
    allowFrozenOverride: boolean;
    validationRequired: boolean;
    ignoreUpdatedate: boolean;
}

export class Import {
    public genericImportsList: ImportImportType[] = [
        {name: 'Properties', type: ImportType.Properties},
        {name: 'Financials', type: ImportType.Financials},
        {name: 'Flags', type: ImportType.Flags},
        {name: 'Kpis', type: ImportType.Kpis},
        {name: 'Change Requests', type: ImportType.ChangeRequests}
    ];

    public genericImports(): ImportImportType[] {
        return this.genericImportsList;
    }
}

export class ImportImportType {
    name: string;
    type: number;
}
