import { JobStatus } from './enums';
import { PaginatedQuerySearch } from './pagingResult';

export interface IJob {
  jobId: string;
  type: string;
  status: JobStatus;
  creationDate: Date;
  endJobDate: Date;
  userId: string;
  username: string;
  displayName: string;
  message: string;
  typeImport: string;
}

export class JobSearch extends PaginatedQuerySearch {
  type: string;
  status: JobStatus;
}

export class JobType {
  name: string;
  description: string;
}


