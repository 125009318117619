import { Injectable } from '@angular/core';
import {
    Router,
    ActivatedRouteSnapshot,
    CanActivate,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router';

// Rxjs
import { Observable, of } from 'rxjs';

// Services
import { AuthService } from '../services';
import { ValidationService } from 'src/app/mytrend/workspace/views/update-manager/services';

@Injectable({
    providedIn: 'root',
})
export class UpdateManagerGuard implements CanActivate {
    /**
     * Creates an instance of update manager guard.
     * @param router
     * @param authService
     * @param validationService
     */
    constructor(
        private router: Router,
        private readonly authService: AuthService,
        private readonly validationService: ValidationService
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        return new Observable((observer) => {
            const validations$ = this.authService.actualUser.isAdministrator
                ? of(true)
                : this.validationService.getValidations();

            validations$.subscribe((permission: boolean) => {
                if (permission) {
                    observer.next(true);
                } else {
                    this.router.navigate([`error/403`]);
                }
            });
        });
    }
}
