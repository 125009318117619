import { Component, OnInit } from '@angular/core';

import { JobSearch, IJob, JobType } from '../../../../../shared/models/job';
import { JobService } from '../../../../../shared/services/repositories/job.service';
import { Subject } from 'rxjs';
import { BaseComponent } from '../../../../shared/components/base/base.component';
import { fadeInOut } from 'src/app/shared/animations/fade';
import { _ } from '../../../../../shared/services/translation.service';
import { Router } from '@angular/router';
import { WorkflowService } from '../../../../shared/services/workflow.service';
import { JobStatus } from 'src/app/shared/models/enums';
@Component({
    selector: 'app-jobs',
    templateUrl: './jobs.component.html',
    styleUrls: ['./jobs.component.scss'],
    animations: [fadeInOut],
})
export class JobsComponent extends BaseComponent implements OnInit {
    public dtTrigger: Subject<any> = new Subject();

    public jobSearch: JobSearch = new JobSearch();
    public jobs: IJob[] = [];
    public page: Number = 1;
    public totalPages: Number = 1;

    public jobTypes: JobType[] = [];

    public JobStatus = JobStatus;
    public jobStatusKeys;

    constructor(
        private jobService: JobService,
        public router: Router,
        protected workflowService: WorkflowService
    ) {
        super(router, workflowService);
        this.generateBreadCrumb();

        this.dtOptions = {
            ordering: false,
            searching: false,
            paging: false,
            info: false,
        };

        this.jobSearch.max = 20;

        this.jobStatusKeys = Object.keys(this.JobStatus);
        this.jobStatusKeys = this.jobStatusKeys.slice(0, this.jobStatusKeys.length / 2);
    }

    ngOnInit() {
        this.getJobs();
        this.getJobTypes();
    }

    public pageChanged(event: any): void {
        this.jobSearch.offset = (event.page - 1) * this.jobSearch.max;
        this.getJobs();
    }

    public getJobs() {
        this.loading = true;
        this.jobService.getJobs(this.jobSearch).subscribe((res) => {
            this.totalPages = res.total;
            this.page = +res.offset / this.jobSearch.max + 1;
            this.jobs = res.results;
            this.refreshTable('#table', this.dtTrigger);
        });
    }

    public getJobTypes() {
        this.jobService.getJobTypes().subscribe((res) => {
            this.jobTypes = res;
        });
    }

    public JobTypeDescription(jobType: string): string {
        const jobtypeDesc = this.jobTypes.find((x) => {
            return x.name === jobType;
        });
        return jobtypeDesc ? jobtypeDesc.description : '';
    }

    public checkShowJobDetails(job: IJob): boolean {
        return job.type === 'Import' && job.status === JobStatus.Success;
    }

    private generateBreadCrumb() {
        this.dynamicBreadCrumb('Monitoring');
    }
}
