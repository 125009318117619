import { Component, OnInit, TemplateRef } from '@angular/core';
import { Subject } from 'rxjs';
import { MessageService } from 'primeng/api';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { BaseComponent } from '../../../../shared/components/base/base.component';
import { PropertyService } from '../../../../../shared/services/repositories/property.service';
import { fadeInOut } from 'src/app/shared/animations/fade';
import { Section } from '../../../../../shared/models/property';
import { ConfirmationDialogService } from '../../../../../shared/components/utils/confirmation/ConfirmationDialog.service';
import { Router } from '@angular/router';
import { WorkflowService } from '../../../../shared/services/workflow.service';
import { _ } from '../../../../../shared/services/translation.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-sections',
    templateUrl: './sections.component.html',
    styleUrls: ['./sections.component.scss'],
    animations: [fadeInOut],
})
export class SectionsComponent extends BaseComponent implements OnInit {
    public addModalRef: BsModalRef;
    public editModalRef: BsModalRef;

    public dtTrigger: Subject<any> = new Subject();

    public sections: Section[] = [];
    public section: Section = new Section();

    constructor(
        private propertyService: PropertyService,
        private confirmationService: ConfirmationDialogService,
        private messageService: MessageService,
        private modalService: BsModalService,
        public router: Router,
        protected workflowService: WorkflowService,
        private translate: TranslateService
    ) {
        super(router, workflowService);
        // disable order in specific columns
        this.dtOptions.columnDefs = [{ orderable: false, targets: 3 }];
        this.generateBreadCrumb();
    }

    ngOnInit() {
        this.getSections();
    }

    getSections() {
        this.loading = true;
        this.propertyService.getSections().subscribe((res) => {
            this.sections = res;
            this.sections.forEach((x) => {
                if (x.parentSectionId) {
                    const sectionParent = this.sections.find((y) => {
                        return y.id === x.parentSectionId;
                    });
                    x.parentSectionName = sectionParent ? sectionParent.name : '';
                }
            });

            this.refreshTable('#table', this.dtTrigger);
        });
    }

    //#region remove
    delete(section: Section) {
        this.confirmationService
            .confirm(`Do you want to delete Section: ${section.name}?`)
            .subscribe((result) => {
                if (result) {
                    this.propertyService.deleteSection(section.id).subscribe(
                        () => {
                            this.messageService.add({
                                severity: 'success',
                                summary: this.translate.instant(_('Success!')),
                                detail: this.translate.instant(_('Section removed with success!')),
                            });
                        },
                        (error) => {
                            return console.log('Error: ', error);
                        },
                        () => {
                            return this.getSections();
                        }
                    );
                }
            });
    }

    //#region add
    addSectionModal(modal: TemplateRef<any>) {
        this.section = new Section();
        this.addModalRef = this.modalService.show(modal, this.modalConfig);
    }

    addSection() {
        this.propertyService.setSection(this.section).subscribe(
            () => {
                this.messageService.add({
                    severity: 'success',
                    summary: this.translate.instant(_('Success!')),
                    detail: this.translate.instant(_('Section added with success!')),
                });
            },
            (error) => {
                return console.log('Error: ', error);
            },
            () => {
                return this.getSections();
            }
        );
        this.addModalRef.hide();
    }

    //#region edit
    editSectionModal(section: Section, modal: TemplateRef<any>) {
        this.section = new Section();
        this.section.id = section.id;
        this.section.name = section.name;
        this.section.parentSectionId = section.parentSectionId;
        this.section.order = section.order;
        this.section.system = section.system;
        this.editModalRef = this.modalService.show(modal, this.modalConfig);
    }

    editSection() {
        this.propertyService.updateSection(this.section.id, this.section).subscribe(
            () => {
                this.messageService.add({
                    severity: 'success',
                    summary: this.translate.instant(_('Success!')),
                    detail: this.translate.instant(_('Section updated with success!')),
                });
            },
            (error) => {
                return console.log('Error: ', error);
            },
            () => {
                return this.getSections();
            }
        );
        this.editModalRef.hide();
    }
    private generateBreadCrumb() {
        this.dynamicBreadCrumb('Node Configuration');
    }
}
