import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';

import { IEmailScheduleView } from '../../../../shared/models/email';
import { EmailService } from '../../../../../shared/services/repositories/email.service';
import { ConfirmationDialogService } from '../../../../../shared/components/utils/confirmation/ConfirmationDialog.service';
import { BaseComponent } from '../../../../shared/components/base/base.component';
import { fadeInOut } from 'src/app/shared/animations/fade';
import { _ } from '../../../../../shared/services/translation.service';
import { Router } from '@angular/router';
import { WorkflowService } from '../../../../shared/services/workflow.service';

@Component({
    selector: 'app-generated-emails',
    templateUrl: './generated-emails.component.html',
    styleUrls: ['./generated-emails.component.scss'],
    animations: [fadeInOut],
})
export class GeneratedEmailsComponent extends BaseComponent implements OnInit {
    public dtTrigger: Subject<any> = new Subject();

    public generatedEmails: IEmailScheduleView[] = [];

    constructor(
        public emailsService: EmailService,
        private confirmationService: ConfirmationDialogService,
        private messageService: MessageService,
        public router: Router,
        protected workflowService: WorkflowService,
        private translate: TranslateService
    ) {
        super(router, workflowService);
        this.generateBreadCrumb();
        this.dtOptions = {
            ordering: false,
            searching: false,
            lengthChange: false,
        };
    }

    ngOnInit() {
        this.getEmailSchedules();
    }

    getEmailSchedules() {
        this.loading = true;
        this.emailsService.getEmailSchedules().subscribe((res) => {
            this.generatedEmails = res;
            this.generatedEmails.sort((a, b) => {
                return b.date > a.date ? 1 : a.date > b.date ? -1 : 0;
            });
            this.refreshTable('#table', this.dtTrigger);
        });
    }

    sendEmail(generatedEmail: IEmailScheduleView) {
        const nbOfDays = Date.now().valueOf() - (generatedEmail.endJobDate || Date.now()).valueOf();
        if (nbOfDays > 0) {
            this.confirmationService
                .confirm(
                    this.translate.instant(
                        _(
                            `Data was generated ${nbOfDays} days(s) ago, data may be aoutdated, do you wish you send the mail(s)?`
                        )
                    )
                )
                .subscribe((result) => {
                    if (result) {
                        this.emailsService.sendEmailSchedules(generatedEmail.id).subscribe(
                            () => {
                                this.messageService.add({
                                    severity: 'success',
                                    summary: this.translate.instant(_('Success!')),
                                    detail: this.translate.instant(_('Send Email Scheduled!')),
                                });
                            },
                            (error) => {
                                return console.log('Error: ', error);
                            },
                            () => {
                                return this.getEmailSchedules();
                            }
                        );
                    }
                });
        } else {
            this.emailsService.sendEmailSchedules(generatedEmail.id).subscribe(
                () => {
                    this.messageService.add({
                        severity: 'success',
                        summary: this.translate.instant(_('Success!')),
                        detail: this.translate.instant(_('Send Email Scheduled!')),
                    });
                },
                (error) => {
                    return console.log('Error: ', error);
                },
                () => {
                    return this.getEmailSchedules();
                }
            );
        }
    }

    reloadEmail(generatedEmail: IEmailScheduleView) {
        this.emailsService.reloadEmailSchedules(generatedEmail.id).subscribe(
            () => {
                this.messageService.add({
                    severity: 'success',
                    summary: this.translate.instant(_('Success!')),
                    detail: this.translate.instant(_('Mail reload!')),
                });
            },
            (error) => {
                return console.log('Error: ', error);
            },
            () => {
                return this.getEmailSchedules();
            }
        );
    }

    cancelEmail(generatedEmail: IEmailScheduleView) {
        this.emailsService.cancelEmailSchedules(generatedEmail.id).subscribe(
            () => {
                this.messageService.add({
                    severity: 'success',
                    summary: this.translate.instant(_('Success!')),
                    detail: this.translate.instant(_('Mail cancel!')),
                });
            },
            (error) => {
                return console.log('Error: ', error);
            },
            () => {
                return this.getEmailSchedules();
            }
        );
    }

    private generateBreadCrumb() {
        this.dynamicBreadCrumb('Communication');
    }
}
