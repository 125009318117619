import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpExtendedInstanceService } from '../http-extended/http-extended-instance.service';

@Injectable({
  providedIn: 'root'
})
export class SourceAgreementService {

  constructor(private readonly httpInstance: HttpExtendedInstanceService,) { }

  getSourceAgreement(topId: string): Observable<HttpResponse<Blob>> {
    return this.httpInstance.get<HttpResponse<Blob>>(
      `top/${topId}/sourceagreement`,
      this.httpInstance.blobOptions()
    );
  }
}
