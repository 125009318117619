import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpExtendedInstanceService } from 'src/app/shared/services';

@Injectable({
    providedIn: 'root',
})
export class AdminService {
    constructor(private http: HttpExtendedInstanceService) {}

    public ReIndexNodes() {
        return this.http.post(`admin/reindexation/Nodes`, null);
    }

    public ReIndexHistory() {
        return this.http.post(`admin/reindexation/History`, null);
    }

    public ApiInfo(): Observable<string> {
        return this.http.get('info');
    }
}
