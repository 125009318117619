import { Instance } from './instance';
import { PaginatedQuerySearch } from './pagingResult';

export class CompactUser {
    id: string;
    username: string;
    displayName: string;
    email: string;
}

export class User extends CompactUser {
    isAdministrator: boolean;
    requireNewPasswordAtLogon: boolean;
    isBlocked: boolean;
    isDisabled: boolean;
    apiKey: string;
    isActive: boolean;

    // view helper
    isExpanded: boolean;
    isAssignationsExpanded: boolean;
}

//#region isntance User ( actual user )
export class InstanceUser extends CompactUser {
    instances: Instance[];
    isAdministrator: boolean;
    // Helper
    logedAsRealId: string;
    logedAsRealDisplayName: string;
}

export class AuthenticatedUser extends InstanceUser {
    isLocalAdministrator: boolean;
    adminRights: string[];
    sharedUsers: CompactUser[];
}

export class AdmniRights {
    id: string;
    name: string;
    displayName: string;
    description: string;
    permission: string;
}

export class UserSearch extends PaginatedQuerySearch {
    isBlocked = false;
    isDisabled = false;
    isAdmin = false;

    // helper
    all = true;
}

export class UserModel {
    username: string;
    displayName: string;
    email: string;
    password?: string;
    sendMail?: boolean;
    requireNewPasswordAtLogon?: boolean;

    // helper
    id?: string;
    confirmPassword?: string;
}

//#region groups
export interface IGroup {
    id: string;
    name: string;
    system: boolean;

    // view helper
    isExpanded: boolean;
    isPropertiesExpanded: boolean;
}

export interface IGroupView extends IGroup {
    numberOfUsers: number;
    numberOfNodesAssigned: number;
    numberOfPropertiesAssigned: number;
}

export class GroupSearch extends PaginatedQuerySearch {
    includeSystem: boolean;
}
