import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpExtendedInstanceService } from 'src/app/shared/services';

import { ExchangeRate } from '../../models/exchangerate';

@Injectable({
    providedIn: 'root',
})
export class ExchangeRateService {
    constructor(private http: HttpExtendedInstanceService) {}

    //#region Exchange Rates
    public getExchangeRates(year: number): Observable<ExchangeRate[]> {
        return this.http.get<ExchangeRate[]>(`exchangerates/${year}`);
    }

    public getExchangeRatesCurrencies(): Observable<{ [isoCode: string]: string }> {
        return this.http.get<{ [isoCode: string]: string }>('currencies');
    }

    public setExchangeRate(exchangeRate: ExchangeRate): Observable<ExchangeRate> {
        return this.http.post<ExchangeRate>('exchangerates', exchangeRate);
    }

    public updateExchangeRate(exchangeRate: ExchangeRate): Observable<ExchangeRate> {
        return this.http.put<ExchangeRate>('exchangerates', exchangeRate);
    }

    public deleteExchangeRate(id: string): Observable<ExchangeRate> {
        return this.http.delete(`exchangerates/${id}`);
    }
}
