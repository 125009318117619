import { Component, OnInit, TemplateRef } from '@angular/core';
import { MessageService } from 'primeng/api';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

import { ChecklistService } from '../../../../../shared/services/repositories/checklist.service';
import { ChecklistView, DeliverableModel } from '../../../../../shared/models/checklist';
import { ConfirmationDialogService } from '../../../../../shared/components/utils/confirmation/ConfirmationDialog.service';
import { BaseComponent } from '../../../../shared/components/base/base.component';
import { fadeInOut } from 'src/app/shared/animations/fade';
import { _ } from '../../../../../shared/services/translation.service';
import { WorkflowService } from '../../../../shared/services/workflow.service';
import { Router } from '@angular/router';
import { Property } from '../../../../../shared/models/property';
import { AuthService } from '../../../../../shared/services/auth.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-checklists',
    templateUrl: './checklists.component.html',
    styleUrls: ['./checklists.component.scss'],
    animations: [fadeInOut],
})
export class ChecklistsComponent extends BaseComponent implements OnInit {
    public addModalRef: BsModalRef;
    public editModalRef: BsModalRef;
    public dtTrigger: Subject<any> = new Subject();

    public checklists: ChecklistView[] = [];

    public newChecklistName = '';
    public edtChecklist: ChecklistView;

    // manage deliverables
    public newDeliverable: DeliverableModel;
    public deliverableProperties: Property[] = [];

    constructor(
        private checklistService: ChecklistService,
        private confirmationService: ConfirmationDialogService,
        private messageService: MessageService,
        private modalService: BsModalService,
        protected workflowService: WorkflowService,
        public router: Router,
        public authService: AuthService,
        private translate: TranslateService
    ) {
        super(router, workflowService);
        this.generateBreadCrumb();
    }

    ngOnInit() {
        this.getChecklists();
        this.getDeliverableProperties();
    }

    private getChecklists() {
        this.loading = true;
        this.checklistService.getChecklists().subscribe((res) => {
            this.checklists = res;
            this.refreshTable('#table', this.dtTrigger);
        });
    }

    getDeliverableProperties() {
        this.checklistService.getDeliverableProperties().subscribe((res) => {
            return (this.deliverableProperties = res);
        });
    }

    //#region remove
    removeChecklist(checklist: ChecklistView) {
        this.confirmationService
            .confirm(
                this.translate.instant(_('Do you want to delete ')) +
                    checklist.name +
                    this.translate.instant(_(' checklist?'))
            )
            .subscribe((result) => {
                if (result) {
                    this.checklistService.deleteChecklist(checklist.id).subscribe(
                        () => {
                            this.messageService.add({
                                severity: 'success',
                                summary: this.translate.instant(_('Success!')),
                                detail: this.translate.instant(
                                    _('Checklist removed with success!')
                                ),
                            });
                        },
                        (error) => {
                            return console.log('Error: ', error);
                        },
                        () => {
                            return this.getChecklists();
                        }
                    );
                }
            });
    }

    //#region add
    addChecklistModal(modal: TemplateRef<any>) {
        this.newChecklistName = '';
        this.addModalRef = this.modalService.show(modal, this.modalConfig);
    }

    addChecklist() {
        // null or white spaces
        if (this.newChecklistName !== null && this.newChecklistName.match(/^ *$/) === null) {
            this.checklistService.setChecklist(this.newChecklistName).subscribe(
                () => {
                    this.messageService.add({
                        severity: 'success',
                        summary: this.translate.instant(_('Success!')),
                        detail: this.translate.instant(_('Checklist added with success!')),
                    });
                },
                (error) => {
                    return console.log('Error: ', error);
                },
                () => {
                    return this.getChecklists();
                }
            );
            this.addModalRef.hide();
        }
    }

    //#region edit
    editChecklistModal(checklist: ChecklistView, modal: TemplateRef<any>) {
        this.edtChecklist = {
            id: checklist.id,
            name: checklist.name,
            numberOfDeliverables: checklist.numberOfDeliverables,
            isExpanded: false,
        };
        this.editModalRef = this.modalService.show(modal, this.modalConfig);
    }

    editChecklist() {
        // null or white spaces
        if (this.edtChecklist.name !== null && this.edtChecklist.name.match(/^ *$/) === null) {
            this.checklistService
                .updateChecklist(this.edtChecklist.id, this.edtChecklist.name)
                .subscribe(
                    () => {
                        this.messageService.add({
                            severity: 'success',
                            summary: this.translate.instant(_('Success!')),
                            detail: this.translate.instant(_('Checklist edited with success!')),
                        });
                    },
                    (error) => {
                        return console.log('Error: ', error);
                    },
                    () => {
                        return this.getChecklists();
                    }
                );
            this.editModalRef.hide();
        }
    }

    //#region sub items  (Deliverables)
    expandCheckListDelivarables(checklist: ChecklistView) {
        if (checklist.isExpanded) {
            checklist.isExpanded = false;
            return;
        }

        for (let index = 0; index < this.checklists.length; index++) {
            this.checklists[index].isExpanded = false;
        }

        checklist.isExpanded = true;
    }

    // Manage deliverables

    //#region add
    addDeliverableModal(modal: TemplateRef<any>) {
        this.newDeliverable = new DeliverableModel();
        this.addModalRef = this.modalService.show(modal, this.modalConfig);
    }

    addDeliverable() {
        // null or white spaces
        if (this.newDeliverable.name !== null && this.newDeliverable.name.match(/^ *$/) === null) {
            this.checklistService.setDeliverable(this.newDeliverable).subscribe(
                () => {
                    this.messageService.add({
                        severity: 'success',
                        summary: this.translate.instant(_('Success!')),
                        detail: this.translate.instant(_('Deliverable added with success!')),
                    });
                },
                (error) => {
                    return console.log('Error: ', error);
                }
            );
            this.addModalRef.hide();
        }
    }

    private generateBreadCrumb() {
        this.dynamicBreadCrumb('Maturity');
    }
}
