import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';

import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject, forkJoin } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

import { BaseComponent } from 'src/app/admin/shared/components/base/base.component';
import { fadeInOut } from './../../../../../shared/animations/fade';
import { WorkflowService } from 'src/app/admin/shared/services/workflow.service';
import { PropertySet, GpspPropertySetDisplay } from 'src/app/shared/models/property';
import { PropertyService } from 'src/app/shared/services/repositories/property.service';
import { Rights } from '../../../models/rights';
import { _ } from './../../../../../shared/services/translation.service';

@Component({
    selector: 'app-property-set-displays',
    templateUrl: './property-set-displays.component.html',
    styleUrls: ['./property-set-displays.component.scss'],
    animations: [fadeInOut],
})
export class PropertySetDisplaysComponent extends BaseComponent implements OnInit {
    private propertySetId: string;
    public editMode: boolean;
    public propertySet: PropertySet = new PropertySet();
    public propertySetDisplaysPropertySet: GpspPropertySetDisplay[];
    public addModalRef: BsModalRef;
    public editModalRef: BsModalRef;
    public dtTrigger: Subject<any> = new Subject();
    constructor(
        private propertyService: PropertyService,
        private route: ActivatedRoute,
        private messageService: MessageService,
        private translate: TranslateService,
        public router: Router,
        protected workflowService: WorkflowService
    ) {
        super(router, workflowService);
        this.generateBreadCrumb();
        this.dtOptions = {
            searching: true,
            lengthChange: false,
            paging: false,
            order: [],
        };

        this.dtOptions.columnDefs = [{ orderable: false, targets: 1 }];
    }
    ngOnInit() {
        this.route.paramMap.subscribe((params) => {
            const id = params.get('id');
            this.editMode = true;
            this.propertySetId = id;
            this.getPropertySet(this.propertySetId);
        });
    }
    getPropertySet(propertySetId: string) {
        this.loading = true;
        forkJoin(
            this.propertyService.getPropertySet(propertySetId),
            this.propertyService.getPropertySetDisplaysSetId(propertySetId)
        ).subscribe((res) => {
            this.propertySet = res[0];
            this.propertySetDisplaysPropertySet = res[1];
            this.refreshTable('#table', this.dtTrigger);
        });
    }
    // //#region remove
    removePropertySetDisplay(propertySetDisplay: GpspPropertySetDisplay) {
        this.propertyService.removePropertySetDisplay(propertySetDisplay.id).subscribe(
            () => {
                this.messageService.add({
                    severity: 'success',
                    summary: this.translate.instant(_('Success!')),
                    detail: this.translate.instant(_('Property display removed with success!')),
                });
            },
            (error) => {
                return console.log('Error: ', error);
            },
            () => {
                return this.getPropertySet(this.propertySetId);
            }
        );
    }
    private generateBreadCrumb() {
        this.dynamicBreadCrumb('Node Configuration', 'Properties set', [
            {
                name: 'Display',
                link: '',
                isActive: false,
                subElements: [],
                permissions: [Rights.PropertyAndSet],
            },
        ]);
    }
}
