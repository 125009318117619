import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';

import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';
import { Constants } from './../../../../../../../shared/models/constants';
import { WorkflowService } from './../../../../../../shared/services/workflow.service';
import { fadeInOut } from './../../../../../../../shared/animations/fade';
import { _ } from './../../../../../../../shared/services/translation.service';
import { BaseComponent } from 'src/app/admin/shared/components/base/base.component';
import { ImportService } from 'src/app/shared/services/repositories/import.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-import-duplication',
    templateUrl: './import-duplication.component.html',
    styleUrls: ['./import-duplication.component.scss'],
    animations: [fadeInOut],
})
export class ImportDuplicationComponent extends BaseComponent implements OnInit {
    file: FormData;
    fileName: string;

    constructor(
        public router: Router,
        protected workflowService: WorkflowService,
        private importService: ImportService,
        private translate: TranslateService,
        private messageService: MessageService
    ) {
        super(router, workflowService);
        this.dynamicBreadCrumb('Structure Management');
    }

    ngOnInit() {
        this.file = new FormData();
    }

    public onFileDrop(files: NgxFileDropEntry[]) {
        if (this.fileName) {
            this.messageService.add({
                severity: 'warning',
                summary: this.translate.instant(_('Warning!')),
                detail: this.translate.instant(_('Groups import only accept 1 file at the time')),
            });
            return;
        }
        files.forEach((file) => {
            if (file.fileEntry.isFile) {
                const fileEntry = file.fileEntry as FileSystemFileEntry;
                fileEntry.file((uploadFile: File) => {
                    this.file.append(file.fileEntry.name, uploadFile, file.fileEntry.name);
                    this.fileName = file.fileEntry.name;
                });
            }
        });
    }

    removeFile() {
        this.file = new FormData();
        this.fileName = undefined;
    }

    uploadFile() {
        this.importService
            .import(
                Constants.IMPORT_DUPLICATION,
                '00000000-0000-0000-0000-000000000000',
                this.file,
                null
            )
            .subscribe(
                () => {},
                (err) => {
                    return console.log(err, err.Message);
                },
                () => {
                    this.messageService.add({
                        severity: 'success',
                        summary: this.translate.instant(_('Success!')),
                        detail: this.translate.instant(_('Import job created')),
                    });
                    this.fileName = undefined;
                    this.file = new FormData();
                }
            );
    }
}
