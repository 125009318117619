import { Injectable } from '@angular/core';

// Rxjs
import { Observable } from 'rxjs';

// Services
import { HttpExtendedGlobalService } from '.';

@Injectable({
    providedIn: 'root',
})
export class VersionService {
    /**
     * Creates an instance of profile faqservice.
     * @param http
     */
    constructor(private readonly http: HttpExtendedGlobalService) {}

    /**
     * Gets app version
     * @returns app version
     */
    public getAppVersion(): Observable<string> {
        return this.http.get<string>(`version`);
    }
}
