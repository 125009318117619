import { Injectable } from '@angular/core';

// Rxjs
import { Observable } from 'rxjs';

enum ExtensionEnum {
    PNG = 'png',
    JPG = 'jpg',
    JPEG = 'jpeg',
    PDF = 'pdf',
    CSV = 'csv',
    XLS = 'xls',
    XLSX = 'xlsx',
    DOC = 'doc',
    DOCX = 'docx',
    TXT = 'txt',
    PPT = 'ppt',
    PPTX = 'pptx',
}

@Injectable({
    providedIn: 'root',
})
export class FileService {
    /**
     * Gets file extension
     * @param fileName
     * @returns file extension
     */
    public getFileExtension(fileName: string): string {
        return fileName.split('.').pop();
    }

    /**
     * Formats bytes
     * @param bytes
     * @param [decimals]
     * @returns
     */
    public formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    /**
     * Gets file icon by extension
     * @param fileExtension
     * @returns file icon by extension
     */
    public getFileIconByExtension(fileExtension: string): string {
        let fileIcon;

        switch (fileExtension) {
            case ExtensionEnum.PNG:
            case ExtensionEnum.JPG:
            case ExtensionEnum.JPEG:
                fileIcon = 'far fa-file-image';
                break;
            case ExtensionEnum.PDF:
                fileIcon = 'far fa-file-pdf';
                break;
            case ExtensionEnum.CSV:
            case ExtensionEnum.XLS:
            case ExtensionEnum.XLSX:
                fileIcon = 'far fa-file-excel';
                break;
            case ExtensionEnum.DOC:
            case ExtensionEnum.DOCX:
            case ExtensionEnum.TXT:
                fileIcon = 'far fa-file-word';
                break;
            case ExtensionEnum.PPT:
            case ExtensionEnum.PPTX:
                fileIcon = 'far fa-file-power-point';
                break;
            default:
                fileIcon = 'far fa-file';
        }

        return fileIcon;
    }

    /**
     * Converts file to byte array
     * @param file
     * @returns file to byte array
     */
    public convertFileToByteArray(file: File): Observable<Array<number>> {
        return new Observable((observer) => {
            const fileReader = new FileReader();

            fileReader.readAsArrayBuffer(file);
            fileReader.onload = () => {
                const arrayBuffer: any = fileReader.result;
                const fileByteArray = [];

                new Uint8Array(arrayBuffer).forEach((buffer: number) => {
                    return fileByteArray.push(buffer);
                });

                observer.next(fileByteArray);
                observer.complete();
            };

            fileReader.onerror = (error) => {
                return observer.error(error);
            };
        });
    }
}
