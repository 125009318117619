import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

// Services
import { TreenodeService } from 'src/app/shared/services/repositories';

// Rxjs
import { forkJoin, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class NodeDisplayResolver implements Resolve<any> {
    /**
     * Creates an instance of node display resolver.
     * @param treenodeService
     */
    constructor(private readonly treenodeService: TreenodeService) {}

    /**
     * Resolves workspace layout resolver
     * @param route
     * @returns resolve
     */
    public resolve(route: ActivatedRouteSnapshot): Observable<any> {
        const treenodeId = route.paramMap.get('treenodeId');

        const treenode$ = this.treenodeService.getTreeNode(treenodeId);
        const parents$ = this.treenodeService.getTreeNodeParents(treenodeId);

        return forkJoin([treenode$, parents$]);
    }
}
