import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { saveAs } from 'file-saver';

import { HistoryService } from '../../../../../shared/services/repositories/history.service';
import {
    HistoryExportModel,
    HistorySearch,
    ILogEntry,
} from '../../../../../shared/models/logEntry';
import { BaseComponent } from '../../../../shared/components/base/base.component';
import { fadeInOut } from 'src/app/shared/animations/fade';
import { _ } from '../../../../../shared/services/translation.service';
import { Router } from '@angular/router';
import { WorkflowService } from '../../../../shared/services/workflow.service';
import { MyTrendConfig } from '../../../../../shared/services/myTrendConfig.service';
import { AuthService } from '../../../../../shared/services/auth.service';

@Component({
    selector: 'app-history',
    templateUrl: './history.component.html',
    styleUrls: ['./history.component.scss'],
    animations: [fadeInOut],
})
export class HistoryComponent extends BaseComponent implements OnInit {
    public dtTrigger: Subject<any> = new Subject();
    public pproplitoIntanceUrl = '';

    public historySearch: HistorySearch = new HistorySearch();
    public startDate: Date;
    public endDate: Date;
    public logEntries: ILogEntry[] = [];
    public page = 1;
    public totalPages = 1;
    public downloadLoading = false;

    constructor(
        private historyService: HistoryService,
        public router: Router,
        protected workflowService: WorkflowService,
        private authService: AuthService
    ) {
        super(router, workflowService);

        this.pproplitoIntanceUrl = `${MyTrendConfig.myTrendSettings.myTrendUrl}/${authService.selectedInstance.name}/Instance#/treenode/`;

        this.dtOptions = {
            ordering: false,
            searching: false,
            paging: false,
            info: false,
        };
        this.historySearch.max = 20;
        this.endDate = new Date();
        this.startDate = new Date();
        this.startDate.setDate(-1);
        this.generateBreadCrumb();
    }

    ngOnInit() {
        this.getHistoryEntries();
    }

    public pageChanged(event: any): void {
        this.historySearch.offset = (event.page - 1) * this.historySearch.max;
        this.getHistoryEntries();
    }

    public getHistoryEntries() {
        this.loading = true;

        this.historySearch.startDate = this.startDate
            ? new Date(this.startDate).toDateString()
            : '';
        this.historySearch.endDate = this.endDate ? new Date(this.endDate).toDateString() : '';

        this.historyService.getHistoryEntries(this.historySearch).subscribe((res) => {
            this.totalPages = res.total;
            this.page = +res.offset / this.historySearch.max + 1;
            this.logEntries = res.results;
            this.refreshTable('#table', this.dtTrigger);
        });
    }

    public downloadHistoryEntries() {
        this.downloadLoading = true;
        const model = new HistoryExportModel();
        model.from = this.startDate ? new Date(this.startDate).toCustomString() : null;
        model.to = this.endDate ? new Date(this.endDate).toCustomString() : null;
        this.historyService.getHistory(model).subscribe(
            (data) => {
                this.downloadLoading = false;
                const filename = data.headers
                    .get('Content-Disposition')
                    .split(';')[1]
                    .trim()
                    .split('=')[1]
                    .replace(/"/g, '');
                saveAs(data.body, filename);
            },
            (err) => {
                console.log(err, err.Message);
                this.downloadLoading = false;
            }
        );
    }

    private generateBreadCrumb() {
        this.dynamicBreadCrumb('Monitoring');
    }
}
