import { Injectable } from '@angular/core';

// Models
import {
    IScorecardView,
    ScorecardDisplay,
    ScorecardEffectDisplay,
    VentilationAxis,
    EffectVentilation,
    Scorecard,
} from '../../models/scorecard';
import { FinancialState } from '../../models/financialState';

// Rxjs
import { Observable } from 'rxjs';

// Services
import { HttpExtendedInstanceService } from 'src/app/shared/services';
import { Effect } from '../../models/effect';

@Injectable({
    providedIn: 'root',
})
export class ScorecardService {
    constructor(private http: HttpExtendedInstanceService) {}

    /**
     * Gets treenode scorecard display
     * @param treeNodeId
     * @returns treenode scorecard display
     */
    public getTreenodeScorecardDisplay(treeNodeId: string): Observable<ScorecardDisplay> {
        return this.http.get<ScorecardDisplay>(`treenodes/${treeNodeId}/scorecarddisplays`);
    }

    /**
     * Gets treenode financialstates available
     * @param treeNodeId
     * @returns treenode financialstates available
     */
    public getTreenodeFinancialstatesAvailable(treeNodeId: string): Observable<FinancialState[]> {
        return this.http.get<FinancialState[]>(`treenodes/${treeNodeId}/financialstates/available`);
    }

    public getScorecardsView(): Observable<IScorecardView[]> {
        return this.http.get('scorecardsview');
    }

    public getScorecard(id: string): Observable<Scorecard> {
        return this.http.get(`scorecards/${id}`);
    }

    public getDefaultScorecardDisplay(scorecardId: string): Observable<ScorecardDisplay> {
        return this.http.get(`scorecards/${scorecardId}/defaultscorecarddisplay`);
    }

    public getScocrecardEffects(
        scorecardId: string,
        scorecardDisplayId: string
    ): Observable<ScorecardEffectDisplay[]> {
        return this.http.get<ScorecardEffectDisplay[]>(
            `scorecards/${scorecardId}/scorecarddisplays/${scorecardDisplayId}/effects`
        );
    }

    /**
     * Gets effects
     * @returns effects
     */
    public getEffects(): Observable<Effect[]> {
        return this.http.get<Effect[]>(`effects`);
    }

    public updateScorecardEffects(
        scorecardId: string,
        scorecardEffectsDisplay: ScorecardEffectDisplay[]
    ) {
        return this.http.put<ScorecardEffectDisplay[]>(
            `scorecards/${scorecardId}/effects`,
            scorecardEffectsDisplay
        );
    }

    public updateScorecardDisplayEffects(
        scorecardId: string,
        scorecardDisplayId: string,
        scorecardEffectsDisplay: ScorecardEffectDisplay[]
    ) {
        return this.http.put<ScorecardEffectDisplay[]>(
            `scorecards/${scorecardId}/scorecarddisplays/${scorecardDisplayId}/effects`,
            scorecardEffectsDisplay
        );
    }

    public setScorecard(name: string): Observable<Scorecard> {
        return this.http.post<Scorecard>('scorecards', { name });
    }

    public updateScorecard(id: string, name: string): Observable<Scorecard> {
        return this.http.put(`scorecards/${id}`, { name });
    }

    public deleteScorecard(id: string): Observable<Scorecard> {
        return this.http.delete(`scorecards/${id}`);
    }

    //#region ventilation axis

    public getVentilationAxes(): Observable<VentilationAxis[]> {
        return this.http.get<VentilationAxis[]>('ventilationaxes');
    }

    public getVentilationAxis(id: string): Observable<VentilationAxis> {
        return this.http.get<VentilationAxis>(`ventilationaxes/${id}`);
    }

    public setVentilationAxis(ventilationAxis: VentilationAxis): Observable<VentilationAxis> {
        return this.http.post<VentilationAxis>('ventilationaxes', ventilationAxis);
    }

    public updateVentilationAxis(ventilationAxis: VentilationAxis): Observable<VentilationAxis> {
        return this.http.put<VentilationAxis>('ventilationaxes', ventilationAxis);
    }

    public updateEffectVentilationAxes(effectVentilation: EffectVentilation[]): Observable<any> {
        return this.http.put('effectVentilationAxes', effectVentilation);
    }

    public deleteVentilationAxis(id: string): Observable<any> {
        return this.http.delete(`ventilationaxes/${id}`);
    }

    //#endregion

    //#region Financial States

    public getFinancialStates(): Observable<FinancialState[]> {
        return this.http.get<FinancialState[]>('financialstates');
    }

    public getFinancialState(id: string): Observable<FinancialState> {
        return this.http.get<FinancialState>(`financialstates/${id}`);
    }

    public setFinancialState(financialState: FinancialState): Observable<FinancialState> {
        return this.http.post<FinancialState>('financialstates', financialState);
    }

    public updateFinancialState(
        id: string,
        financialState: FinancialState
    ): Observable<FinancialState> {
        return this.http.put<FinancialState>(`financialstates/${id}`, financialState);
    }

    public updateFinancialStatesSummaryOrder(financialStates: FinancialState[]): Observable<FinancialState[]> {
        return this.http.put<FinancialState[]>(`financialstates/updateSummaryOrder`, financialStates);
    }

    public deleteFinancialState(id: string): Observable<FinancialState> {
        return this.http.delete(`financialstates/${id}`);
    }

    //#endregion

    //#region Scorecard Display(view)

    public getScorecardsDisplay(): Observable<Scorecard[]> {
        return this.http.get<Scorecard[]>('scorecards/scorecarddisplays');
    }

    public getAllScorecardDisplaysForScorecard(
        scorecardId: string
    ): Observable<ScorecardDisplay[]> {
        return this.http.get<ScorecardDisplay[]>(`scorecards/${scorecardId}/scorecarddisplays`);
    }

    public getScorecardDisplay(id: string): Observable<ScorecardDisplay> {
        return this.http.get<ScorecardDisplay>(`scorecards/scorecarddisplays/${id}`);
    }

    public setScorecardDisplay(
        scorecardId: string,
        scorecardDisplayName: string
    ): Observable<ScorecardDisplay> {
        return this.http.post<ScorecardDisplay>(`scorecards/${scorecardId}/scorecarddisplays`, {
            name: scorecardDisplayName,
        });
    }

    public updateScorecardDisplay(
        scorecardId: string,
        scorecardDisplayId: string,
        scorecardDisplayName: string
    ) {
        return this.http.put<ScorecardDisplay>(
            `scorecards/${scorecardId}/scorecarddisplays/${scorecardDisplayId}`,
            { name: scorecardDisplayName }
        );
    }

    public deleteScorecardDisplay(
        scorecardId: string,
        scorecardDisplayId: string
    ): Observable<ScorecardDisplay> {
        return this.http.delete<ScorecardDisplay>(
            `scorecards/${scorecardId}/scorecarddisplays/${scorecardDisplayId}`
        );
    }
    //#endregion
}
