import { Injectable } from '@angular/core';
import { Observable, Subject, forkJoin } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { TopBarService } from '@depfac/dfakto-ui/src/lib/top-bar';

import { Instance } from '../models/instance';
import { AdmniRights, InstanceUser } from '../models/user';
import { environment } from 'src/environments/environment';
import { RequestOptions } from '../models/request-options';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    public isInGlobal = false;
    public isInProfile = false;
    public selectedInstance: Instance;
    selectedInstanceChange: Subject<Instance> = new Subject<Instance>();
    public userLoaded: Subject<InstanceUser> = new Subject();

    public actualUser: InstanceUser;
    options: RequestOptions;

    constructor(
        private http: HttpClient,
        private router: Router,
        private permissionsService: NgxPermissionsService,
        private topBarService: TopBarService
    ) {
        if (!environment.production) {
            this.options = {
                headers: new HttpHeaders({
                    MyTrend_Username: environment.username,
                    MyTrend_ApiKey: environment.apiKey,
                    MyTrend_Salt: environment.salt,
                }),
            };
        }
    }

    public getActualUser(returnUrl: string): Observable<InstanceUser> {
        return new Observable((observer) => {
            const meRequest = this.http.get<InstanceUser>(
                `${window.location.origin}/${environment.proxyUrl}api/users/me`,
                this.options
            );
            const realMeRequest = this.http.get<InstanceUser>(
                `${window.location.origin}/${environment.proxyUrl}api/users/realme`,
                this.options
            );
            forkJoin([meRequest, realMeRequest]).subscribe((res) => {
                this.actualUser = res[0];

                // if user is logged as
                if (res[1]) {
                    this.actualUser.logedAsRealDisplayName = res[1].displayName;
                    this.actualUser.logedAsRealId = res[1].id;
                    this.topBarService.updateWarningMode(true);
                } else {
                    this.topBarService.updateWarningMode(false);
                }

                observer.next(res[0]);
                this.userLoaded.next(res[0]);
            });
        });
    }

    public selectInstance(instance: Instance): Observable<boolean> {
        this.selectedInstance = instance;

        this.selectedInstanceChange.next(this.selectedInstance);
        return new Observable<boolean>((observer) => {
            this.permissionsService.flushPermissions();
            this.http
                .get<AdmniRights[]>(
                    `${window.location.origin}/${environment.proxyUrl}${this.selectedInstance.name}/api/users/me/adminrights`,
                    this.options
                )
                .subscribe(
                    (res) => {
                        const permissions: string[] = [];

                        res.forEach((p) => {
                            return permissions.push(p.id);
                        });

                        // no permission navigate to
                        if (permissions.length === 0) {
                            this.router.navigate(['error/401']);
                            return;
                        }

                        // load permisison in permission control
                        this.permissionsService.loadPermissions(permissions);

                        this.selectedInstanceChange.next(this.selectedInstance);

                        observer.next(true);
                    },
                    (err) => {
                        alert('navigate to not authorized page!!');
                        observer.next(false);
                    }
                );
        });
    }
}
