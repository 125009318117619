import { Injectable } from '@angular/core';
import {
    Router,
    ActivatedRouteSnapshot,
    CanActivate,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router';

// Models
import { AuthenticatedUser } from '../models';

// Rxjs
import { Observable } from 'rxjs';

// Services
import { UserService } from '../services/repositories';

@Injectable({
    providedIn: 'root',
})
export class IsLocalAdminGuard implements CanActivate {
    /**
     * Creates an instance of is local admin guard.
     * @param router
     * @param userService
     */
    constructor(private router: Router, private readonly userService: UserService) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        return new Observable((observer) => {
            this.userService.getActualUser().subscribe((actualUser: AuthenticatedUser) => {
                if (actualUser.isLocalAdministrator) {
                    observer.next(true);
                } else {
                    this.router.navigate([`error/401`]);
                }
            });
        });
    }
}
