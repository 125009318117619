import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';

import { ScorecardService } from '../../../../../shared/services/repositories/scorecard.service';
import { VentilationAxis } from '../../../../../shared/models/scorecard';
import { ConfirmationDialogService } from '../../../../../shared/components/utils/confirmation/ConfirmationDialog.service';
import { BaseComponent } from '../../../../shared/components/base/base.component';
import { _ } from '../../../../../shared/services/translation.service';
import { Router } from '@angular/router';
import { WorkflowService } from '../../../../shared/services/workflow.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-ventilation-axis',
  templateUrl: './ventilation-axis.component.html',
  styleUrls: ['./ventilation-axis.component.scss']
})
export class VentilationAxisComponent extends BaseComponent implements OnInit {

  public dtTrigger: Subject<any> = new Subject();
  public ventilationAxes: VentilationAxis[] = [];

  constructor(private scorecardService: ScorecardService, private confirmationService: ConfirmationDialogService,
              private messageService: MessageService, public router: Router, protected workflowService: WorkflowService,
              private translate: TranslateService) {
    super(router, workflowService);
    this.dtOptions = {
      pagingType: 'full_numbers'
    };
  }

  ngOnInit() {
    this.getVentilationAxes();
  }

  getVentilationAxes() {
    this.scorecardService.getVentilationAxes().subscribe(res => {
      this.ventilationAxes = res;
      this.refreshTable('#table', this.dtTrigger);
    });
  }

  removeAxis(axis: VentilationAxis) {
    this.confirmationService.confirm(this.translate.instant(_('Do you want to delete ')) +
      axis.name + this.translate.instant(_(' axis?')))
      .subscribe(result => {
        if (result) {
          this.scorecardService.deleteVentilationAxis(axis.id).subscribe(() => {
            this.messageService.add(
              {
                severity: 'success',
                summary: this.translate.instant(_('Succes!')),
                detail: this.translate.instant(_('Ventilation Axis removed with success!'))
              }
            );
          });
        }
      });
  }
}
