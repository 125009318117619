export interface IAppConfig {
  app: IApp;
  environment: Environment;
  sentryDsn: string;
  lang: string;
}

export enum Environment {
  prod = 'production',
  dev = 'dev',
  test = 'test'
}

export interface IApp {
  name: string;
  version: string;
  homeUrl: string;
}
