import { EffectOperator } from './enums';
import { ScorecardEffectDisplay } from './scorecard';

export class Effect {
  id: string;
  parentId: string;
  name: string;
  description: string;
  aggregation: string;
  bold: boolean;
  italic: boolean;
  autoCalculated: boolean;
  order: number;
  isNotCurrency: boolean;
  restriction: string;
  levelAggregationFlag: boolean;
  calculations: string[];
  tagEffectNames: string[];
}



export class EffectViewModel {
  effectId: string;
  parentId: string;
  name: string;
  description: string;
  aggregation: string;
  bold: boolean;
  italic: boolean;
  autoCalculated: boolean;
  order: number;
  numberParent: number;
  isNotCurrency: boolean;
  restriction: string;
  levelAggregationFlag: boolean;
  numberOfDirectChildren: number;
  numberOfScorecards: number;

  children: EffectViewModel[];
  calculations: EffectCalculation[];
  tagEffectNames: string[];
}

export class EffectCalculation {

  public static get PLUS(): string { return '+'; }

  public static get MINUS(): string { return '-'; }

  public static get TIMES(): string { return '*'; }

  public static get DIVIDED_BY(): string { return '/'; }

  public id: string;
  public order: number;
  public operator: EffectOperator;
  public value: string;

  public constructor(operator: EffectOperator, value: string, order: number) {
    this.operator = operator;
    this.value = value;
    this.order = order;
  }
}

export class Calculation {
  id: string;
  name: string;
}

// display helper
export class ScorecareDisplayEffectModel {
  inScorecard = false;
  effectId: string;
  effect: EffectViewModel;
  scorecardEffect: ScorecardEffectDisplay;
}

