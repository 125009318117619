import { Component, OnInit, TemplateRef } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { FinancialState } from '../../../../../shared/models/financialState';
import { ScorecardService } from '../../../../../shared/services/repositories/scorecard.service';
import { ConfirmationDialogService } from '../../../../../shared/components/utils/confirmation/ConfirmationDialog.service';
import { BaseComponent } from '../../../../shared/components/base/base.component';
import { fadeInOut } from 'src/app/shared/animations/fade';
import { _ } from '../../../../../shared/services/translation.service';
import { Router } from '@angular/router';
import { WorkflowService } from '../../../../shared/services/workflow.service';
import { TranslateService } from '@ngx-translate/core';
import { object } from 'underscore';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'app-financial-states',
    templateUrl: './financial-states.component.html',
    styleUrls: ['./financial-states.component.scss'],
    animations: [fadeInOut],
})
export class FinancialStatesComponent extends BaseComponent implements OnInit {
    public addModalRef: BsModalRef;
    public editModalRef: BsModalRef;
    public dtTrigger: Subject<any> = new Subject();

    public financialStates: FinancialState[] = [];

    public loadingSummaryOrderUpdate = false;

    financialStateForm: FormGroup;

    constructor(
        private scorecardService: ScorecardService,
        private confirmationService: ConfirmationDialogService,
        private messageService: MessageService,
        private modalService: BsModalService,
        public router: Router,
        protected workflowService: WorkflowService,
        private translate: TranslateService,
        private fb: FormBuilder
    ) {
        super(router, workflowService);
        // disable order in specific columns
        this.dtOptions.columnDefs = [{ orderable: false, targets: 3 }];
        this.generateBreadCrumb();


    }

    ngOnInit() {
        this.dtOptions.order = [[0, 'asc']];
        this.getFinancialStates();
    }

    getFinancialStates() {
        this.loading = true;
        this.scorecardService.getFinancialStates().subscribe((res) => {
            this.financialStates = res;
            this.refreshTable('#table', this.dtTrigger);
        });
    }

    removeFinancialState(financialState: FinancialState) {
        this.confirmationService
            .confirm(
                this.translate.instant(_('Do you want to delete ')) +
                financialState.displayName +
                this.translate.instant(_(' financial state?'))
            )
            .subscribe((result) => {
                if (result) {
                    this.scorecardService.deleteFinancialState(financialState.id).subscribe(
                        () => {
                            this.messageService.add({
                                severity: 'success',
                                summary: this.translate.instant(_('Succes!')),
                                detail: this.translate.instant(
                                    _('Financial State removed with success!')
                                ),
                            });
                        },
                        (error) => {
                            return console.log('Error: ', error);
                        },
                        () => {
                            return this.getFinancialStates();
                        }
                    );
                }
            });
    }

    //#region add
    addFiancialStateModal(modal: TemplateRef<any>) {
        this.financialStateForm = this.fb.group({
            name: ['', [Validators.required, Validators.pattern('[a-zA-Z0-9_-]*')]],
            displayName: ['', Validators.required],
            order: [0]
        });
        this.addModalRef = this.modalService.show(modal, this.modalConfig);
    }

    addFinancialState() {
        const values = this.financialStateForm.getRawValue();

        const newFinancialState = new FinancialState();
        newFinancialState.displayName = values.name;
        newFinancialState.scorecardDisplay = values.displayName;
        newFinancialState.order = values.order;

        this.scorecardService.setFinancialState(newFinancialState).subscribe(
            () => {
                this.messageService.add({
                    severity: 'success',
                    summary: this.translate.instant(_('Succes!')),
                    detail: this.translate.instant(_('Financial State created with success!')),
                });
            },
            (error) => {
                return console.log('Error: ', error);
            },
            () => {
                return this.getFinancialStates();
            }
        );
        this.addModalRef.hide();

    }

    //#region edit
    editFinancialStateModal(financialState: FinancialState, modal: TemplateRef<any>) {
        this.financialStateForm = this.fb.group({
            id: [financialState.id],
            name: [financialState.displayName, [Validators.required, Validators.pattern('[a-zA-Z0-9_-]*')]],
            displayName: [financialState.scorecardDisplay, Validators.required],
            order: [financialState.order, Validators.required],
            system: [financialState.system]
        });

        this.editModalRef = this.modalService.show(modal, this.modalConfig);
    }

    editFinancialState() {
        // null or white spaces
        const values = this.financialStateForm.getRawValue();

        const edtFinancialState = new FinancialState();
        edtFinancialState.id = values.id;
        edtFinancialState.displayName = values.name;
        edtFinancialState.scorecardDisplay = values.displayName;
        edtFinancialState.order = values.order;
        edtFinancialState.system = values.system;
        console.log('🚀 ~ edtFinancialState', edtFinancialState)
        

        this.scorecardService
            .updateFinancialState(edtFinancialState.id, edtFinancialState)
            .subscribe(
                () => {
                    this.messageService.add({
                        severity: 'success',
                        summary: this.translate.instant(_('Succes!')),
                        detail: this.translate.instant(
                            _('Financial stated edited with success!')
                        ),
                    });
                },
                (error) => {
                    return console.log('Error: ', error);
                },
                () => {
                    return this.getFinancialStates();
                }
            );
        this.editModalRef.hide();

    }

    // summary order
    public summaryOrder(financialState: FinancialState, movement: number) {
        this.loadingSummaryOrderUpdate = true;

        const targetOrder = financialState.summaryOrder + movement;

        // find existing fs with the current FS summary order, replace it
        let fs = this.financialStates.find(x => { return x.summaryOrder === targetOrder });
        if (fs) {
            fs.summaryOrder = financialState.summaryOrder;
        }

        // change the summary order of the current fs
        financialState.summaryOrder = targetOrder;

        // Ensure list is in summary order 0,1,2,3,4
        let financialStatesOrdered = this.financialStates.sort((a, b) => { return a.summaryOrder - b.summaryOrder });

        let order = 1;
        financialStatesOrdered.forEach((fs) => {
            fs.summaryOrder = order++;
        });

        this.scorecardService.updateFinancialStatesSummaryOrder(financialStatesOrdered).subscribe(
            {
                next: () => {
                    return this.messageService.add({
                        severity: 'success',
                        summary: this.translate.instant(_('Success!')),
                        detail: this.translate.instant(_('Summary Order updated with success')),
                    });
                },
                error: (err) => {
                    return console.log(err);
                },
                complete: () => {
                    this.loadingSummaryOrderUpdate = false;
                    this.getFinancialStates();
                }
            });


        // To not broke visualizations put list is in normal order 0,1,2,3,4
        this.financialStates = this.financialStates.sort((a, b) => { return a.order - b.order });
    }

    private generateBreadCrumb() {
        this.dynamicBreadCrumb('Scorecard');
    }
}
