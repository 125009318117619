import { Injectable } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';

// Rxjs
import { filter, pairwise } from 'rxjs/operators';

/** A router wrapper, adding extra functions. */
@Injectable()
export class RouterExtService {
    /**
     * Previous url of router ext service
     */
    private previousUrl: string = undefined;

    /**
     * Creates an instance of router ext service.
     * @param router
     */
    constructor(private readonly router: Router) {
        this.router.events
            .pipe(
                filter((evt: any) => {
                    return evt instanceof RoutesRecognized;
                }),
                pairwise()
            )
            .subscribe((events: RoutesRecognized[]) => {
                this.previousUrl = events[0].urlAfterRedirects;
            });
    }

    /**
     * Gets previous url
     * @returns
     */
    public getPreviousUrl() {
        return this.previousUrl;
    }
}
