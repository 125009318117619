import { Component, OnInit } from '@angular/core';
import { forkJoin, Subject } from 'rxjs';
import { MessageService } from 'primeng/api';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { EffectViewModel, ScorecareDisplayEffectModel } from '../../../../../shared/models/effect';
import { ScorecardDisplay, ScorecardEffectDisplay } from '../../../../../shared/models/scorecard';
import { ScorecardService } from '../../../../../shared/services/repositories/scorecard.service';
import { EffectService } from '../../../../../shared/services/repositories/effect.service';
import { BaseComponent } from '../../../../shared/components/base/base.component';
import { fadeInOut } from 'src/app/shared/animations/fade';
import { _ } from '../../../../../shared/services/translation.service';
import { WorkflowService } from '../../../../shared/services/workflow.service';
import { AuthService } from '../../../../../shared/services/auth.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-scorecard-view-effects',
    templateUrl: './scorecard-view-effects.component.html',
    styleUrls: ['./scorecard-view-effects.component.scss'],
    animations: [fadeInOut],
})
export class ScorecardViewEffectsComponent extends BaseComponent implements OnInit {
    public dtTrigger: Subject<any> = new Subject();

    private effects: EffectViewModel[];

    public scoredcardDisplay: ScorecardDisplay; // default scorecard display
    public effectsModel: ScorecareDisplayEffectModel[] = [];

    public socrecardEffects: ScorecardEffectDisplay[];

    constructor(
        private scorecardService: ScorecardService,
        private effectService: EffectService,
        private route: ActivatedRoute,
        private messageService: MessageService,
        public router: Router,
        public authService: AuthService,
        private location: Location,
        protected workflowService: WorkflowService,
        private translate: TranslateService
    ) {
        super(router, workflowService);
        this.dtOptions = {
            ordering: false,
            searching: false,
            paging: false,
        };
    }

    ngOnInit() {
        this.route.paramMap.subscribe((params) => {
            this.getScorecardDisplay(params.get('id'));
        });
    }

    public getScorecardDisplay(id: string) {
        this.loading = true;
        // get default scorecard display
        this.scorecardService.getScorecardDisplay(id).subscribe((res) => {
            this.scoredcardDisplay = res;
            this.generateBreadCrumb();
            // then get all organized effects and scorecard display effects -> merge them on a list to display on table
            forkJoin(
                this.effectService.getAllEffectsInTree(),
                this.scorecardService.getScocrecardEffects(
                    this.scoredcardDisplay.scorecardId,
                    this.scoredcardDisplay.id
                )
            ).subscribe((fRes) => {
                this.effects = fRes[0];
                this.socrecardEffects = fRes[1];

                this.prepareEffects(this.effects);
                this.refreshTable('#table', this.dtTrigger);
            });
        });
    }

    public save() {
        const scorecardEffects = this.effectsModel
            .filter((x) => {
                return x.scorecardEffect.effectId;
            })
            .map((effect) => {
                return effect.scorecardEffect;
            });

        this.scorecardService
            .updateScorecardDisplayEffects(
                this.scoredcardDisplay.scorecardId,
                this.scoredcardDisplay.id,
                scorecardEffects
            )
            .subscribe(() => {
                this.messageService.add({
                    severity: 'success',
                    summary: this.translate.instant(_('Succes!')),
                    detail: this.translate.instant(
                        _('Scorecard display effects updated with success!')
                    ),
                });
                this.router.navigate([
                    '/admin/' +
                        this.authService.selectedInstance.name +
                        '/scorecards/scorecardviews',
                ]);
            });
    }

    private prepareEffects(effects: EffectViewModel[]) {
        effects.forEach((effect) => {
            const effectModel = new ScorecareDisplayEffectModel();
            effectModel.effectId = effect.effectId;
            effectModel.effect = effect;
            effectModel.scorecardEffect = this.socrecardEffects.find((x) => {
                return x.effectId === effect.effectId;
            });
            // check in scorecard
            if (effectModel.scorecardEffect != null) {
                this.effectsModel.push(effectModel);
                if (effect.children) {
                    this.prepareEffects(effect.children);
                }
            }
        });
    }

    private generateBreadCrumb() {
        const breadCrumbElement = this.scoredcardDisplay.name + '- Effects';
        this.dynamicBreadCrumb('Scorecard', 'Scorecards Views', [
            {
                name: breadCrumbElement,
                link: '',
                isActive: false,
                subElements: [],
                permissions: null,
            },
        ]);
    }

    public goBack() {
        this.location.back();
    }
}
