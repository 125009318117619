import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';

// dFakto-ui
import { DSidebarModule, SidebarService } from '@depfac/dfakto-ui/src/lib/sidebar';
import { DTopBarModule } from '@depfac/dfakto-ui/src/lib/top-bar';
import { DColorPickerModule } from '@depfac/dfakto-ui/src/lib/colorpicker';
import { DTreeNodeModule } from '@depfac/dfakto-ui/src/lib/tree-node';

// Ngx
import { TranslateModule } from '@ngx-translate/core';
import { TranslateService } from '@ngx-translate/core';

// PrimeNg
import { InputTextModule } from 'primeng/inputtext';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MessageService } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { BadgeModule } from 'primeng/badge';
import { TooltipModule } from 'primeng/tooltip';
import { TagModule } from 'primeng/tag';

// Modules
import { MyTrendAdminModule } from '../admin/instance-admin/admin.module';
import { CoreRoutingModule } from './route/core-routing.module';
import { SharedModule } from '../shared/shared.module';

// Services
import { AuthService } from '../shared/services';
import { HttpErrorInterceptor } from '../shared/interceptors/http-error.interceptor';

// Views
import { ErrorComponent } from '../shared/components';
import { CoreLayoutComponent } from './layout/layout.component';
import { WorkspaceLayoutComponent } from '../mytrend/workspace/workspace-layout.component';
import { TopBarUpdateManagerComponent } from './layout/top-bar/update-manager/top-bar-update-manager.component';
import { TopBarUpdateManagerPropertiesComponent } from './layout/top-bar/update-manager/properties/top-bar-update-manager-properties.component';
import { TopBarUpdatesComponent } from './layout/top-bar/updates/top-bar-updates.component';
import { TopBarTreenodeUpdateComponent } from './layout/top-bar/updates/treenode-update/top-bar-treenode-update.component';
@NgModule({
    declarations: [
        CoreLayoutComponent,
        ErrorComponent,
        WorkspaceLayoutComponent,
        TopBarUpdateManagerComponent,
        TopBarUpdateManagerPropertiesComponent,
        TopBarUpdatesComponent,
        TopBarTreenodeUpdateComponent,
    ],
    imports: [
        CommonModule,
        DTopBarModule,
        CoreRoutingModule,
        DColorPickerModule,
        FormsModule,
        MyTrendAdminModule,
        DSidebarModule,
        SharedModule,
        InputTextModule,
        InputSwitchModule,
        TranslateModule,
        DTreeNodeModule,
        ProgressSpinnerModule,
        BreadcrumbModule,
        ButtonModule,
        BadgeModule,
        TooltipModule,
        TagModule,
    ],
    providers: [
        SidebarService,
        MessagesModule,
        MessageModule,
        TranslateModule,
        MessageService,
        ConfirmationService,
        AuthService,
        TranslateService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            deps: [Router, MessageService, ConfirmationService, TranslateService],
            multi: true,
        },
    ],

    bootstrap: [CoreLayoutComponent],
})
export class CoreModule {}
