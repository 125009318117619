export class PaginatedSearch {
    offset: number;
    max: number;
}

export class PagingResult<T> extends PaginatedSearch {
    results: T[];
    total: number;
    queryDuration: string;
}


export class PaginatedQuerySearch extends PaginatedSearch {
    query: string;
}
