import { NgModule, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';

import { MyTrendRouting } from './routes/admin.routing';

// Ngx
import { NgxPermissionsModule } from 'ngx-permissions';
import { NgxFileDropModule } from 'ngx-file-drop';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

// Bootstrap
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { AlertModule } from 'ngx-bootstrap/alert';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

// Primeng
import { MessageService as PrimeMessageService } from 'primeng/api';
import { EditorModule } from 'primeng/editor';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { DialogModule } from 'primeng/dialog';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { PickListModule } from 'primeng/picklist';
import { ChipsModule } from 'primeng/chips';
import { CalendarModule } from 'primeng/calendar';

// Pipes
import { PropertySectionsPipe } from './pipes/propertySections.pipe';

// Services
import { KpiService } from '../../shared/services/repositories/kpi.service';
import { EffectService } from '../../shared/services/repositories/effect.service';
import { JobService } from '../../shared/services/repositories/job.service';
import { MessageService } from '../../shared/services/repositories/message.service';
import { TopService } from '../../shared/services/repositories/top.service';
import { ScorecardService } from '../../shared/services/repositories/scorecard.service';
import { UserService } from '../../shared/services/repositories/user.service';
import { AdminService } from '../../shared/services/repositories/admin.service';
import { HistoryService } from '../../shared/services/repositories/history.service';
import { TreenodeService } from '../../shared/services/repositories/treenode.service';
import { EmailService } from '../../shared/services/repositories/email.service';
import { MaturityService } from '../../shared/services/repositories/maturity.service';
import { PropertyService } from '../../shared/services/repositories/property.service';
import { ConfigService } from '../../shared/services/repositories/config.service';
import { AuthService } from '../../shared/services/auth.service';

import { TopExportService } from '../../shared/services/repositories/top-export.service';
import {
    httpExtendedGlobalFactory,
    HttpExtendedGlobalService,
    httpExtendedInstanceFactory,
    HttpExtendedInstanceService,
} from 'src/app/shared/services';

// Components
import { BreadcrumbComponent } from '../shared/components/breadcrumb/breadcrumb.component';
import { HomeComponent } from './components/home/home.component';
import { LayoutRootComponent } from '../shared/components/layout-root/layout-root.component';
import { IndexationComponent } from './components/updateCycle/indexation/indexation.component';
import { UsersComponent } from './components/usersGroups/users/users.component';
import { GroupsComponent } from './components/usersGroups/groups/groups.component';
import { UserHistoryComponent } from './components/usersGroups/user-history/user-history.component';
import { SpinnerComponent } from '../../shared/components/spinner/spinner.component';
import { UserGroupsComponent } from './components/usersGroups/users/user-groups/user-groups.component';
import { GroupUsersComponent } from './components/usersGroups/groups/group-users/group-users.component';
import { DataTablesModule } from 'angular-datatables';
import { MessagesComponent } from './components/communication/messages/messages.component';
import { MessageComponent } from './components/communication/messages/message/message.component';
import { GeneratedEmailsComponent } from './components/communication/generated-emails/generated-emails.component';
import { EmailTemplatesComponent } from './components/communication/email-templates/email-templates.component';
import { GroupEmailsComponent } from './components/communication/group-emails/group-emails.component';
import { EmailTemplateComponent } from './components/communication/email-template/email-template.component';
import { GeneratedEmailComponent } from './components/communication/generated-email/generated-email.component';
import { ExchangeRatesComponent } from './components/exchangerate/exchange-rates/exchange-rates.component';
import { JobsComponent } from './components/monitoring/jobs/jobs.component';
import { JobComponent } from './components/monitoring/job/job.component';
import { HistoryComponent } from './components/monitoring/history/history.component';
import { MaturitiesComponent } from './components/maturity/maturities/maturities.component';
import { MaturityStrategiesComponent } from './components/maturity/maturity-strategies/maturity-strategies.component';
// tslint:disable-next-line:max-line-length
import { MaturityStrategyMaturitiesComponent } from './components/maturity/maturity-strategy-maturities/maturity-strategy-maturities.component';
// tslint:disable-next-line:max-line-length
import { MaturityStrategyMaturityPathsComponent } from './components/maturity/maturity-strategy-maturity-paths/maturity-strategy-maturity-paths.component';
import { ChecklistsComponent } from './components/maturity/checklists/checklists.component';
// tslint:disable-next-line:max-line-length
import { ChecklistChecklistDeliverablesComponent } from './components/maturity/checklists/deliverables/checklist-checklist-deliverables.component';
import { DeliverablesComponent } from './components/maturity/deliverables/deliverables.component';
import { KpisComponent } from './components/nodeConfiguration/kpis/kpis.component';
import { DeleteNodesComponent } from './components/nodeConfiguration/delete-nodes/delete-nodes.component';
import { PropertiesComponent } from './components/nodeConfiguration/properties/properties.component';
import { PropertyAccessRightsComponent } from './components/nodeConfiguration/property-access-rights/property-access-rights.component';
import { PropertyListValuesComponent } from './components/nodeConfiguration/property-list-values/property-list-values.component';
import { PropertySetsComponent } from './components/nodeConfiguration/property-sets/property-sets.component';
import { PropertySetPropertiesComponent } from './components/nodeConfiguration/property-set-properties/property-set-properties.component';
import { FlagsComponent } from './components/nodeConfiguration/flags/flags.component';
import { SectionsComponent } from './components/nodeConfiguration/sections/sections.component';
import { ScorecardsComponent } from './components/scorecard/scorecards/scorecards.component';
import { ScorecardViewsComponent } from './components/scorecard/scorecard-views/scorecard-views.component';
import { ScorecardViewEffectsComponent } from './components/scorecard/scorecard-view-effects/scorecard-view-effects.component';
import { ScorecardEffectsComponent } from './components/scorecard/scorecard-effects/scorecard-effects.component';
import { EffectsComponent } from './components/scorecard/effects/effects.component';
import { EffectComponent } from './components/scorecard/effect/effect.component';
import { FinancialStatesComponent } from './components/scorecard/financial-states/financial-states.component';
import { VentilationsComponent } from './components/scorecard/ventilations/ventilations.component';
import { WorkflowService } from '../shared/services/workflow.service';
import { ConfirmationDialogService } from '../../shared/components/utils/confirmation/ConfirmationDialog.service';
import { ConfirmationDialogComponent } from '../../shared/components/utils/confirmation/confirmation-dialog.component';
import { CurrenciesComponent } from './components/exchangerate/currencies/currencies.component';
import { VentilationAxisComponent } from './components/scorecard/ventilation-axis/ventilation-axis.component';
import { UpdatePeriodComponent } from './components/updateCycle/update-period/update-period.component';
import { ImportsComponent } from './components/nodeConfiguration/imports/imports.component';
import { ConfigurationComponent } from './components/configurationPage/configuration/configuration.component';
import { ImportUsersComponent } from './components/usersGroups/import-users/import-users.component';
import { ImportGroupsComponent } from './components/usersGroups/import-groups/import-groups.component';
import { ImportExchangeRatesComponent } from './components/exchangerate/import-exchange-rates/import-exchange-rates.component';
import { TopsComponent } from './components/structure/tops/tops.component';
import { TopLevelStructuresComponent } from './components/structure/topLevelStructures/topLevelStructures.component';
import { MyTrendConfig } from '../../shared/services/myTrendConfig.service';
import { AuthGuard } from '../../shared/guards/auth.guard';
import { SubLevelsComponent } from './components/structure/topLevelStructures/sub-levels/sub-levels.component';
import { FooterComponent } from '../../shared/components/footer/footer.component';
import { MenuComponent } from '../shared/components/layout/menu/menu.component';
import { LayoutComponent } from '../shared/components/layout/layout.component';
import { NotAuthorizedComponent } from '../shared/components/notAuthorized/notAuthorized.component';
import { AdminAuthGuard } from '../shared/guards/admin-auth.guard';
import { TemplateComponent } from './components/structure/template/template.component';
import { UserAssignationsComponent } from './components/usersGroups/users/user-assignations/user-assignations.component';
import { GroupPropertiesComponent } from './components/usersGroups/groups/group-properties/group-properties.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { TagsComponent } from '../../shared/components/utils/tags/tags.component';
import { SnapshotComponent } from './components/reporting/snapshot/snapshot.component';
import { DuplicationsComponent } from './components/structure/topLevelStructures/duplications/duplications.component';
// tslint:disable-next-line:max-line-length
import { ImportDuplicationComponent } from './components/structure/topLevelStructures/duplications/import-duplication/import-duplication.component';
import { PropertySetDisplaysComponent } from './components/nodeConfiguration/property-set-displays/property-set-displays.component';
import { PropertySetDisplayComponent } from './components/nodeConfiguration/property-set-display/property-set-display.component';
import { ReportTriggerComponent } from './components/reporting/report-trigger/report-trigger.component';

// Directives
import { NumberRangeValidatorDirective } from '../../shared/validators/numberRangeValidator.directive';
import { ModalModule } from 'ngx-bootstrap/modal';
import { SharedAdminModule } from '../shared/shared-admin.module';
import { SourceAgreementComponent } from './components/structure/source-agreement/source-agreement.component';

export function initializeApp(myTrendConfig: MyTrendConfig) {
    return (): Promise<any> => {
        return myTrendConfig.load();
    };
}

@NgModule({
    imports: [
        MyTrendRouting,
        CommonModule,
        SharedAdminModule,
        NgxPermissionsModule.forChild(),
        NgxSpinnerModule,
        FormsModule,
        DataTablesModule,
        HttpClientModule,
        EditorModule,
        MessagesModule,
        MessageModule,
        DialogModule,
        AutoCompleteModule,
        DropdownModule,
        MultiSelectModule,
        InputTextareaModule,
        ConfirmDialogModule,
        PickListModule,
        ChipsModule,
        CalendarModule,
        SelectDropDownModule,
        NgxFileDropModule,
        ModalModule.forRoot(),
        PaginationModule.forRoot(),
        BsDatepickerModule.forRoot(),
        BsDropdownModule.forRoot(),
        AlertModule.forRoot(),
        AccordionModule.forRoot(),
        TooltipModule.forRoot(),
        TranslateModule,
        ReactiveFormsModule 
    ],
    declarations: [
        FooterComponent,
        MenuComponent,
        LayoutComponent,
        NotAuthorizedComponent,
        PropertySectionsPipe,
        BreadcrumbComponent,
        HomeComponent,
        LayoutRootComponent,
        IndexationComponent,
        UsersComponent,
        GroupsComponent,
        UserHistoryComponent,
        UserGroupsComponent,
        GroupUsersComponent,
        MessagesComponent,
        MessageComponent,
        GeneratedEmailsComponent,
        EmailTemplatesComponent,
        GroupEmailsComponent,
        EmailTemplateComponent,
        GeneratedEmailComponent,
        ExchangeRatesComponent,
        JobsComponent,
        JobComponent,
        HistoryComponent,
        MaturitiesComponent,
        MaturityStrategiesComponent,
        MaturityStrategyMaturitiesComponent,
        MaturityStrategyMaturityPathsComponent,
        ChecklistsComponent,
        ChecklistChecklistDeliverablesComponent,
        DeliverablesComponent,
        KpisComponent,
        DeleteNodesComponent,
        PropertiesComponent,
        PropertyAccessRightsComponent,
        PropertyListValuesComponent,
        PropertySetsComponent,
        PropertySetPropertiesComponent,
        PropertySetDisplayComponent,
        PropertySetDisplaysComponent,
        FlagsComponent,
        SectionsComponent,
        ScorecardsComponent,
        ScorecardViewsComponent,
        ScorecardViewEffectsComponent,
        ScorecardEffectsComponent,
        EffectsComponent,
        EffectComponent,
        FinancialStatesComponent,
        VentilationsComponent,
        ConfirmationDialogComponent,
        CurrenciesComponent,
        VentilationAxisComponent,
        UpdatePeriodComponent,
        ImportsComponent,
        ConfigurationComponent,
        ImportUsersComponent,
        ImportGroupsComponent,
        ImportExchangeRatesComponent,
        TopsComponent,
        TopLevelStructuresComponent,
        DuplicationsComponent,
        ImportDuplicationComponent,
        SubLevelsComponent,
        TemplateComponent,
        UserAssignationsComponent,
        GroupPropertiesComponent,
        TagsComponent,
        NumberRangeValidatorDirective,
        SnapshotComponent,
        SourceAgreementComponent,
        ReportTriggerComponent,
    ],
    providers: [
        MyTrendConfig,
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApp,
            deps: [MyTrendConfig],
            multi: true,
        },
        KpiService,
        EffectService,
        MessageService,
        TopService,
        ScorecardService,
        UserService,
        JobService,
        AdminService,
        HistoryService,
        TreenodeService,
        MaturityService,
        EmailService,
        WorkflowService,
        PropertyService,
        ConfirmationDialogService,
        ConfigService,
        TopExportService,
        AuthGuard,
        AuthService,
        AdminAuthGuard,
        PrimeMessageService,
        TranslateService,
        // {
        //     provide: HttpExtendedInstanceService,
        //     useFactory: httpExtendedFactory,
        //     deps: [HttpClient, AuthService],
        // },
        {
            provide: HttpExtendedGlobalService,
            useFactory: httpExtendedGlobalFactory,
            deps: [HttpClient],
        },
        {
            provide: HttpExtendedInstanceService,
            useFactory: httpExtendedInstanceFactory,
            deps: [HttpClient, AuthService],
        },
    ],
    exports: [BreadcrumbComponent, SpinnerComponent, SubLevelsComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MyTrendAdminModule {}
