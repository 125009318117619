import { Component, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Subject, forkJoin } from 'rxjs';
import * as __ from 'underscore';

import { fadeInOut } from 'src/app/shared/animations/fade';
import {
    PropertySet,
    Section,
    PropertySetProperty,
    Property,
} from '../../../../../shared/models/property';
import { PropertyService } from '../../../../../shared/services/repositories/property.service';
import { BaseComponent } from '../../../../shared/components/base/base.component';
import { WorkflowService } from '../../../../shared/services/workflow.service';
import { TypeProperty, VisibilityProperty, UpdateBehavior } from 'src/app/shared/models/enums';
import { _ } from '../../../../../shared/services/translation.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
    selector: 'app-property-set-properties',
    templateUrl: './property-set-properties.component.html',
    styleUrls: ['./property-set-properties.component.scss'],
    animations: [fadeInOut],
})
export class PropertySetPropertiesComponent extends BaseComponent implements OnInit {
    private propertySetId: string;
    public editMode: boolean;
    public propertySet: PropertySet = new PropertySet();
    public sections: Section[] = [];
    public properties: Property[] = [];

    public propertySetProperty: PropertySetProperty = new PropertySetProperty();

    public TypeProperty = TypeProperty;
    public VisibilityProperty = VisibilityProperty;
    public UpdateBehavior = UpdateBehavior;

    public addModalRef: BsModalRef;
    public editModalRef: BsModalRef;

    public dtTrigger: Subject<any> = new Subject();

    constructor(
        private propertyService: PropertyService,
        private route: ActivatedRoute,
        private messageService: MessageService,
        private modalService: BsModalService,
        public router: Router,
        protected workflowService: WorkflowService,
        private translate: TranslateService
    ) {
        super(router, workflowService);
        this.generateBreadCrumb();
        this.dtOptions = {
            searching: true,
            lengthChange: false,
            paging: false,
            order: [],
        };
        this.dtOptions.columnDefs = [
            { orderable: false, targets: 7 },
            { orderable: false, targets: 8 },
        ];
    }

    ngOnInit() {
        this.route.paramMap.subscribe((params) => {
            const id = params.get('id');
            this.editMode = true;
            this.propertySetId = id;
            this.getPropertySet(this.propertySetId);
        });
    }

    getPropertySet(propertySetId: string) {
        this.loading = true;
        forkJoin(
            this.propertyService.getPropertySet(propertySetId),
            this.propertyService.getSections(),
            this.propertyService.getProperties()
        ).subscribe((res) => {
            this.propertySet = res[0];
            this.sections = res[1];
            const properties = res[2];
            this.properties = __.sortBy(this.properties, 'name');
            this.properties = [];
            properties.forEach((x) => {
                if (
                    !this.propertySet.propertySetProperties.some((y) => {
                        return y.propertyId === x.id;
                    })
                ) {
                    this.properties.push(x);
                }
            });

            this.refreshTable('#table', this.dtTrigger);
        });
    }

    //#region remove
    removeProperty(propertySetProperty: PropertySetProperty) {
        this.propertyService
            .removePropertySetProperty(this.propertySetId, propertySetProperty.propertyId)
            .subscribe(
                () => {
                    this.messageService.add({
                        severity: 'success',
                        summary: this.translate.instant(_('Success!')),
                        detail: this.translate.instant(_('Property removed with success!')),
                    });
                },
                (error) => {
                    return console.log('Error: ', error);
                },
                () => {
                    return this.getPropertySet(this.propertySetId);
                }
            );
    }

    //#region add
    addPropertySetPropertyModal(modal: TemplateRef<any>) {
        this.propertySetProperty = new PropertySetProperty();
        this.propertySetProperty.propertySetId = this.propertySetId;
        this.addModalRef = this.modalService.show(modal, this.modalConfig);
    }

    addPropertySetProperty() {
        // null or white spaces
        if (this.propertySetProperty.propertyId !== null) {
            this.propertyService.AddPropertySetProperty(this.propertySetProperty).subscribe(
                () => {
                    this.messageService.add({
                        severity: 'success',
                        summary: this.translate.instant(_('Success!')),
                        detail:
                            this.translate.instant(_('Property added with success! to')) +
                            this.propertySet.name,
                    });
                },
                (error) => {
                    return console.log('Error: ', error);
                },
                () => {
                    return this.getPropertySet(this.propertySetId);
                }
            );
            this.addModalRef.hide();
        }
    }

    //#region edit
    editPropertySetPropertyModal(
        propertySetProperty: PropertySetProperty,
        modal: TemplateRef<any>
    ) {
        this.propertySetProperty = new PropertySetProperty();
        this.propertySetProperty.propertySetId = propertySetProperty.propertySetId;
        this.propertySetProperty.propertyId = propertySetProperty.propertyId;
        this.propertySetProperty.order = propertySetProperty.order;
        this.propertySetProperty.isUpdateRequired = propertySetProperty.isUpdateRequired;
        this.editModalRef = this.modalService.show(modal, this.modalConfig);
    }

    editPropertySetProperty() {
        // null or white spaces
        this.propertyService.EditPropertySetProperty(this.propertySetProperty).subscribe(
            () => {
                this.messageService.add({
                    severity: 'success',
                    summary: this.translate.instant(_('Success!')),
                    detail: this.translate.instant(_('Property updated with success!')),
                });
            },
            (error) => {
                return console.log('Error: ', error);
            },
            () => {
                return this.getPropertySet(this.propertySetId);
            }
        );
        this.editModalRef.hide();
    }
    private generateBreadCrumb() {
        this.dynamicBreadCrumb('Node Configuration', 'Properties set', [
            {
                name: 'Manage properties',
                link: '',
                isActive: false,
                subElements: [],
                permissions: null,
            },
        ]);
    }
}
