import { Component, OnInit, OnDestroy } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, interval } from 'rxjs';
import { saveAs } from 'file-saver';
import { takeUntil } from 'rxjs/operators';

import { ExportType } from './../../../../../../shared/models/enums';
import { TopService } from './../../../../../../shared/services/repositories/top.service';
import { TopExportService } from './../../../../../../shared/services/repositories/top-export.service';
import { WorkflowService } from './../../../../../shared/services/workflow.service';
import { TopExport, TopExportView } from './../../../../../../shared/models/topExport';
import { Top } from './../../../../../../shared/models/top';
import { fadeInOut } from './../../../../../../shared/animations/fade';
import { BaseComponent } from 'src/app/admin/shared/components/base/base.component';

@Component({
    selector: 'app-duplications',
    templateUrl: './duplications.component.html',
    styleUrls: ['./duplications.component.scss'],
    animations: [fadeInOut],
})
export class DuplicationsComponent extends BaseComponent implements OnInit, OnDestroy {
    public dtTrigger: Subject<any> = new Subject();
    private onDestroy$ = new Subject<void>();

    topExportsJobs: TopExportView[];
    topLevels: Top[];
    selectedTopLevel: Top;

    constructor(
        public router: Router,
        protected workflowService: WorkflowService,
        private topExportService: TopExportService,
        private topService: TopService,
        private cdRef: ChangeDetectorRef
    ) {
        super(router, workflowService);
        // disable order in specific columns
        this.dtOptions.columnDefs = [{ orderable: false, targets: 2 }];
        this.dynamicBreadCrumb('Structure Management', 'Duplications');
    }

    ngOnInit() {
        this.loading = true;
        interval(30000)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(() => {
                if (this.selectedTopLevel) {
                    this.onTopSelect(this.selectedTopLevel.id, false);
                }
            });
        this.topService.getTops().subscribe((data) => {
            this.topLevels = data;
            this.onTopSelect(this.topLevels[0].id);
            this.loading = false;
        });
    }

    ngOnDestroy() {
        this.onDestroy$.next();
    }

    onTopSelect(id: string, loading = true) {
        this.loading = loading;
        this.selectedTopLevel = this.topLevels.find((x) => {
            return x.id === id;
        });
        if (!this.selectedTopLevel) {
            return;
        }

        this.topExportService
            .getTopExports(this.selectedTopLevel.id, 'ExportDuplicationTop')
            .subscribe((data) => {
                this.topExportsJobs = data;
                this.topExportsJobs.sort(this.dateSort);
                this.refreshTable('#table', this.dtTrigger);
                this.cdRef.detectChanges();
            });
    }

    exportTop() {
        this.topExportService.exportTop(this.selectedTopLevel.id, ExportType.Duplication).subscribe(
            (data: TopExport) => {},
            (error) => {
                return console.log(error, error.Message);
            },
            () => {
                return this.ngOnInit();
            }
        );
    }

    downloadExport(exportId: string) {
        this.topExportService.getTopExport(this.selectedTopLevel.id, exportId).subscribe((data) => {
            const filename = data.headers
                .get('Content-Disposition')
                .split(';')[1]
                .trim()
                .split('=')[1]
                .replace(/"/g, '');
            saveAs(data.body, filename);
        });
    }

    getStartedInterval(topExport: TopExportView): number {
        return Math.floor(
            (new Date().getTime() - new Date(topExport.creationDate).getTime()) / 1000
        );
    }

    getGeneratedInterval(topExport: TopExportView): number {
        return Math.floor(
            (new Date(topExport.lastUpdateDate).getTime() -
                new Date(topExport.creationDate).getTime()) /
                1000
        );
    }

    dateSort(exportA: TopExportView, exportB: TopExportView): number {
        return new Date(exportB.creationDate).getTime() - new Date(exportA.creationDate).getTime();
    }
}
