import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';

// Ngx
import { NgxPermissionsModule } from 'ngx-permissions';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

// PrimeNg
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ToastModule } from 'primeng/toast';

import { HttpClient } from '@angular/common/http';
import { CoreModule } from './core/core.module';

// Routing
import { AppRoutingModule } from './app-routing.module';

// Services
import { WebpackTranslateLoader } from './shared/translate/translateLoader';
import { AppConfig } from './shared/services/config.service';
import { RouterExtService } from './shared/services';

// Views
import { AppComponent } from './app.component';

export function initializeApp(appConfig: AppConfig) {
    return () => {
        return appConfig.load();
    };
}

registerLocaleData(localeDe);

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        CoreModule,
        ProgressSpinnerModule,
        ToastModule,
        NgxPermissionsModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: WebpackTranslateLoader,
                deps: [HttpClient],
            },
        }),
    ],
    providers: [
        RouterExtService,
        AppConfig,
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApp,
            deps: [AppConfig],
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
