import { Component, OnInit, Input, EventEmitter, Output, TemplateRef } from '@angular/core';
import { MessageService } from 'primeng/api';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import * as __ from 'underscore';

import { WorkflowService } from '../../../../../shared/services/workflow.service';
import { _ } from '../../../../../../shared/services/translation.service';

import {
    ChecklistDeliverableView,
    AddDeliverableModel,
    IDeliverableView,
    ChecklistView,
    DeliverableModel,
} from '../../../../../../shared/models/checklist';
import { ChecklistService } from '../../../../../../shared/services/repositories/checklist.service';
import { Property } from '../../../../../../shared/models/property';
import { BaseComponent } from '../../../../../shared/components/base/base.component';
import { forkJoin } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: '[app-checklist-checklist-deliverables]',
    templateUrl: './checklist-checklist-deliverables.component.html',
    styleUrls: ['./checklist-checklist-deliverables.component.scss'],
})
export class ChecklistChecklistDeliverablesComponent extends BaseComponent implements OnInit {
    _checklist: ChecklistView;
    @Output() checklistChange = new EventEmitter();

    @Input()
    get checklist() {
        return this._checklist;
    }

    set checklist(val) {
        this._checklist = val;
        this.checklistChange.emit(this._checklist);
    }

    public checklistDeliverables: ChecklistDeliverableView[];
    public deliverables: IDeliverableView[] = [];
    public selectedDeliverable: IDeliverableView;

    public loadingDeliverables = false;
    public loadingDeliverablesUpdate = false;

    // Manage deliverables
    public addModalRef: BsModalRef;
    public newDeliverable: DeliverableModel;
    public deliverableProperties: Property[] = [];

    constructor(
        private checklistService: ChecklistService,
        private messageService: MessageService,
        private modalService: BsModalService,
        public router: Router,
        protected workflowService: WorkflowService,
        private translate: TranslateService
    ) {
        super(router, workflowService);
    }

    ngOnInit() {
        this.getChecklistDeliverables();
        this.getDeliverableProperties();
    }

    public getChecklistDeliverables() {
        this.loadingDeliverables = true;
        this.checklistDeliverables = [];
        this.checklistService.getChecklistDeliverables(this.checklist.id).subscribe((res) => {
            this.checklistDeliverables = res;
            this.checklistDeliverables = __.sortBy(this.checklistDeliverables, 'order');
            this.getDeliverables();
            this.loadingDeliverables = false;
        });
    }

    getDeliverableProperties() {
        this.checklistService.getDeliverableProperties().subscribe((res) => {
            return (this.deliverableProperties = res);
        });
    }

    public getDeliverables(preselectDeliverableId = null) {
        this.checklistService.getDeliverablesview().subscribe((res) => {
            this.deliverables = [];
            res.forEach((del) => {
                if (
                    this.checklistDeliverables.every((x) => {
                        return x.deliverableId !== del.id;
                    })
                ) {
                    this.deliverables.push(del);
                    if (preselectDeliverableId != null && preselectDeliverableId === del.id) {
                        this.selectedDeliverable = del;
                    }
                }
            });
        });
    }

    public order(checklistDeliverable: ChecklistDeliverableView, movement: number) {
        this.loadingDeliverablesUpdate = true;
        // get index
        const index = this.checklistDeliverables.indexOf(checklistDeliverable);
        // change order
        this.checklistDeliverables = this.array_move(
            this.checklistDeliverables,
            index,
            index + movement
        ); // movement can be +1 or -1
        // Ensure list is in normal order 0,1,2,3,4
        let order = 0;
        this.checklistDeliverables.forEach((del) => {
            del.order = order++;
        });
        // update on API all at same time
        const requests = [];
        this.checklistDeliverables.forEach((item) => {
            const addDeliverableModel = new AddDeliverableModel();
            addDeliverableModel.deliverableId = item.deliverableId;
            addDeliverableModel.order = item.order;
            requests.push(
                this.checklistService.updateChecklistDeliverable(
                    this.checklist.id,
                    addDeliverableModel
                )
            );
        });

        forkJoin(requests).subscribe(
            () => {
                this.messageService.add({
                    severity: 'success',
                    summary: this.translate.instant(_('Success!')),
                    detail: this.translate.instant(_('Checklist Items updated with success!')),
                });
            },
            (error) => {
                return console.log('Error: ', error);
            },
            () => {
                return (this.loadingDeliverablesUpdate = false);
            }
        );
    }

    public array_move(arr, old_index, new_index) {
        if (new_index >= arr.length) {
            let k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr;
    }

    //#region remove
    public removeChecklistDeliverable(checklistDeliverable: ChecklistDeliverableView) {
        this.checklistService
            .deleteChecklistDeliverable(
                checklistDeliverable.checklistId,
                checklistDeliverable.deliverableId
            )
            .subscribe(
                () => {
                    this.messageService.add({
                        severity: 'success',
                        summary: this.translate.instant(_('Success!')),
                        detail: this.translate.instant(
                            _('Item removed from checklist with success!')
                        ),
                    });
                    this.updateChecklist(-1);
                },
                (error) => {
                    return console.log('Error: ', error);
                },
                () => {
                    return this.getChecklistDeliverables();
                }
            );
    }

    // Add deliverable
    public addSaveChecklistDeliverable() {
        // Get max order of deliverables
        const max = Math.max.apply(
            Math,
            this.checklistDeliverables.map(function (o) {
                return o.order;
            })
        );

        const addDeliverableModel: AddDeliverableModel = {
            deliverableId: this.selectedDeliverable.id,
            order: max + 1,
        };

        this.checklistService
            .addChecklistDeliverable(this.checklist.id, addDeliverableModel)
            .subscribe(
                () => {
                    this.messageService.add({
                        severity: 'success',
                        summary: this.translate.instant(_('Success!')),
                        detail: this.translate.instant(_('item added with success!')),
                    });
                    this.selectedDeliverable = null;
                    this.updateChecklist(1);
                },
                (error) => {
                    return console.log('Error: ', error);
                },
                () => {
                    return this.getChecklistDeliverables();
                }
            );
    }

    // Manage deliverables
    //#region add
    addDeliverableModal(modal: TemplateRef<any>) {
        this.newDeliverable = new DeliverableModel();
        this.addModalRef = this.modalService.show(modal, this.modalConfig);
    }

    addDeliverable() {
        // null or white spaces
        if (this.newDeliverable.name !== null && this.newDeliverable.name.match(/^ *$/) === null) {
            this.checklistService.setDeliverable(this.newDeliverable).subscribe(
                (res) => {
                    this.getDeliverables(res.id);
                },
                (error) => {
                    return console.log('Error: ', error);
                }
            );
            this.addModalRef.hide();
        }
    }

    private updateChecklist(add: number) {
        this.checklist.numberOfDeliverables += add;
    }
}
