import { Component, OnInit, OnDestroy } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Subject, interval } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { IDeletedNode } from '../../../../../shared/models/treenode';
import { TreenodeService } from '../../../../../shared/services/repositories/treenode.service';

import { BaseComponent } from '../../../../shared/components/base/base.component';
import { fadeInOut } from 'src/app/shared/animations/fade';
import { _ } from '../../../../../shared/services/translation.service';
import { Router } from '@angular/router';
import { WorkflowService } from '../../../../shared/services/workflow.service';
import { ConfirmationDialogService } from '../../../../../shared/components/utils/confirmation/ConfirmationDialog.service';
import { TranslateService } from '@ngx-translate/core';
import { JobStatus } from '../../../../../shared/models/enums';

@Component({
    selector: 'app-delete-nodes',
    templateUrl: './delete-nodes.component.html',
    styleUrls: ['./delete-nodes.component.scss'],
    animations: [fadeInOut],
})
export class DeleteNodesComponent extends BaseComponent implements OnInit, OnDestroy {
    public dtTrigger: Subject<any> = new Subject();

    public deletedNodes: IDeletedNode[] = [];
    public numberDraftNodes = 0;
    public deletedNodesToCheck: IDeletedNode[] = [];

    JobStatus = JobStatus;

    destroy$: Subject<boolean> = new Subject<boolean>();

    constructor(
        private treenodeService: TreenodeService,
        private messageService: MessageService,
        private confirmationService: ConfirmationDialogService,
        public router: Router,
        protected workflowService: WorkflowService,
        private translate: TranslateService
    ) {
        super(router, workflowService);
        this.dtOptions = {
            ordering: false,
            lengthChange: false,
            info: false,
        };
        this.generateBreadCrumb();
    }

    ngOnInit() {
        this.getDeletedNodes();
        this.getTotalDraftNodes();
        this.checkNodes();
        this.checkNodes();
    }

    private getDeletedNodes() {
        this.loading = true;
        this.treenodeService.getDeletedNodes().subscribe((res) => {
            this.deletedNodes = res;

            this.refreshTable('#table', this.dtTrigger);
        });
    }

    private getTotalDraftNodes() {
        this.treenodeService.getTotalDraftNodes().subscribe((res) => {
            return (this.numberDraftNodes = res);
        });
    }

    public undeleteNode(treenode: IDeletedNode) {
        this.confirmationService
            .confirm(
                this.translate.instant(
                    _(`Do you want to undelete this treenode (${treenode.name})?`)
                )
            )
            .subscribe((result) => {
                if (result) {
                    this.treenodeService.undeleteNode(treenode.id).subscribe(
                        () => {
                            this.messageService.add({
                                severity: 'success',
                                summary: this.translate.instant(_('Success!')),
                                detail: this.translate.instant(
                                    _(
                                        `${treenode.structureName} ${treenode.name} (${treenode.code}) undeleted!`
                                    )
                                ),
                            });
                        },
                        (error) => {
                            return console.log('Error: ', error);
                        },
                        () => {
                            return this.getDeletedNodes();
                        }
                    );
                }
            });
    }

    public deleteNode(treenode: IDeletedNode) {
        this.confirmationService
            .confirm(
                this.translate.instant(_(`Do you want to delete this treenode (${treenode.name})?`))
            )
            .subscribe((result) => {
                if (result) {
                    this.treenodeService.deleteNodeDefinitively(treenode.id).subscribe(
                        () => {
                            this.messageService.add({
                                severity: 'success',
                                summary: this.translate.instant(_('Success!')),
                                detail: this.translate.instant(
                                    _(
                                        `Job to delete ${treenode.structureName} ${treenode.name} (${treenode.code}) created!`
                                    )
                                ),
                            });
                            this.deletedNodesToCheck.push(treenode);
                        },
                        (error) => {
                            return console.log('Error: ', error);
                        },
                        () => {
                            return this.getDeletedNodes();
                        }
                    );
                }
            });
    }

    public deleteDraftNodes() {
        this.treenodeService.deleteDraftNodes().subscribe(
            () => {
                this.messageService.add({
                    severity: 'success',
                    summary: this.translate.instant(_('Success!')),
                    detail: this.translate.instant(
                        _(`All draft treenodes created before the last hour have been deleted`)
                    ),
                });
            },
            (error) => {
                return console.log('Error: ', error);
            },
            () => {
                return this.getTotalDraftNodes();
            }
        );
    }

    private checkNodes() {
        interval(1000)
            .pipe(takeUntil(this.destroy$))
            .subscribe((val) => {
                if (this.deletedNodesToCheck.length === 0) {
                    //  this.destroy$.next(true);
                    return;
                }

                this.deletedNodesToCheck.forEach((node) => {
                    this.treenodeService.getDeletedNode(node.id).subscribe(
                        function (id, res) {
                            const deletedNode = this.deletedNodes.find((x) => {
                                return x.id === id;
                            });
                            if (
                                res &&
                                (res.jobStatus === JobStatus.Running ||
                                    res.jobStatus === JobStatus.Waiting)
                            ) {
                                if (deletedNode) {
                                    deletedNode.jobStatus = res.jobStatus;
                                }
                            } else {
                                this.treenodeService
                                    .getDeletedNodes()
                                    .subscribe((nodesToDelete) => {
                                        this.deletedNodes = nodesToDelete;
                                    });
                            }
                        }.bind(this, node.id)
                    );
                });
            });
    }

    private generateBreadCrumb() {
        this.dynamicBreadCrumb('Node Configuration');
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        // Now let's also unsubscribe from the subject itself:
        this.destroy$.unsubscribe();
    }
}
