import { TreeNode } from 'primeng/api';
import { User } from 'src/app/shared/models';
import { EmailScheduleType, JobStatus, Frequency, GroupMailType } from '../../../shared/models/enums';


export class EmailTemplate {
  id: string;
  name: string;
  type: string;
  globalTemplateId: string;
  globalMailTemplateId?: string;
  system: boolean;
  subjectTemplate: string;
  titleTemplate: string;
  subTitleTemplate: string;
  bodyTemplate: string;
  footerTemplate: string;
}

export class EmailTemplateView extends EmailTemplate {
  numberOfGroupMails: number;
  numberOfTopsAffected: number;
}

export class GlobalMailTemplate {
  id: string;
  name: string;
}

export interface IEmailSchedule {
  id: string;
  jobId: string;
  type: EmailScheduleType;
  topId: string;
  firstDate: Date;
  date: Date;
  Data: any;
}

export interface IEmailScheduleView {
  id: string;
  topId: string;
  topName: string;
  jobId: string;
  type: EmailScheduleType;
  status: JobStatus;
  firstDate: Date;
  date: Date;
  creationDate: Date;
  endJobDate: Date;
  sendEmailStatus: JobStatus;
  numberOfEmails: number;
  lastUpdateBy: string;
}

export interface IEmailScheduleDetailsTemplateValues {
  templateId: string;
  sharedRecipients: string[];
  treeNodes: TreeNode[];
  treeNodesSynchronized: TreeNode[];

  treeNodesParentMilestones: TreeNode[];
}

export interface IEmailScheduleDetailsValues {
  user: User;
  templateDetailValues: IEmailScheduleDetailsTemplateValues[];
}

export interface IEmailScheduleDetail extends IEmailSchedule {
  emailValues: IEmailScheduleDetailsValues[];
}


export class GroupMail {
  id: string;
  name: string;
  frequency: Frequency = Frequency.Monthly;
  type: GroupMailType = GroupMailType.Progress;
}

export class GroupMailView extends GroupMail {
  numberOfTemplates: number;
  numberOfTreeNodesAffected: number;
}

export class GroupMailTemplateView {
  id: string;
  groupMailId: string;
  mailTemplateId: string;
  groupType: GroupMailType;
  frequncy: Frequency;
  groupName: string;
  templateName: string;
  templateType: string;
  order: string;
}
