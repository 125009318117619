import { Injectable } from '@angular/core';

// Models
import { Effect, EffectViewModel } from '../../models/effect';

// Rxjs
import { Observable } from 'rxjs';

// Services
import { HttpExtendedInstanceService } from 'src/app/shared/services';

@Injectable({
    providedIn: 'root',
})
export class EffectService {
    constructor(private http: HttpExtendedInstanceService) { }

    //#region Effects
    getAllEffectsInTree(): Observable<EffectViewModel[]> {
        return this.http.get<EffectViewModel[]>('alleffectsintree');
    }

    getAllEffects(): Observable<Effect[]> {
        return this.http.get<Effect[]>('effects');
    }

    getEffect(id: string): Observable<EffectViewModel> {
        return this.http.get<EffectViewModel>(`effects/${id}`);
    }

    setEffect(effect: Effect) {
        return this.http.post('effects', effect);
    }

    updateEffect(id: string, effect: Effect) {
        return this.http.put(`effects/${id}`, effect);
    }

    deleteEffect(id: string) {
        return this.http.delete(`effects/${id}`);
    }

    getCumulativeEffctsKeys(): Observable<string[]> {
        return this.http.get<string[]>('effectscumulativekeys');
    }

    getRestrictionEffctsKeys(): Observable<string[]> {
        return this.http.get<string[]>('effectsrestrictionkeys');
    }

}
