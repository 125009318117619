import { Injectable } from '@angular/core';

// Models
import {
    Property,
    Section,
    AccessRight,
    PropertyListValue,
    PropertySet,
    PropertySetProperty,
    PropertySetView,
    Flag,
    UpdatePropertyValue,
    PropertyValue,
    UserGroupProperty,
} from '../../models/property';
import {
    GpspPropertySetDisplay,
    PropertySetPropertyDisplay,
    PropertySetDisplayModel,
} from './../../models/property';
import { Maturity } from '../../models/maturity';

// Rxjs
import { Observable, of } from 'rxjs';

// Services
import { HttpExtendedGlobalService, HttpExtendedInstanceService } from 'src/app/shared/services';
import { PropertyValueCheck } from 'src/app/mytrend/workspace/models';

@Injectable({
    providedIn: 'root',
})
export class PropertyService {
    /**
     * Creates an instance of property service.
     * @param httpInstance
     * @param httpGlobal
     */
    constructor(
        private readonly httpInstance: HttpExtendedInstanceService,
        private readonly httpGlobal: HttpExtendedGlobalService
    ) {}

    /**
     * Posts property
     * @param treenodeId
     * @param propertyId
     * @param data
     * @returns property
     */
    public postProperty(
        treenodeId: string,
        propertyId: string,
        data: any
    ): Observable<PropertyValueCheck> {
        return this.httpInstance.post<PropertyValueCheck>(
            `treenodes/${treenodeId}/properties/${propertyId}`,
            data
        );
    }

    /**
     * Puts property
     * @param treenodeId
     * @param propertyId
     * @param data
     * @returns property
     */
    public putProperty(
        treenodeId: string,
        propertyId: string,
        data: any
    ): Observable<PropertyValue> {
        return this.httpInstance.put<PropertyValue>(
            `treenodes/${treenodeId}/properties/${propertyId}`,
            data
        );
    }

    //#region Properties
    getProperties(): Observable<Property[]> {
        return this.httpInstance.get<Property[]>('properties');
    }

    getProperty(id: string): Observable<Property> {
        return this.httpInstance.get<Property>(`properties/${id}`);
    }

    getUserProperties(treeNodeId: string): Observable<UserGroupProperty[]> {
        return this.httpInstance.get<UserGroupProperty[]>(`properties/treenode/${treeNodeId}`);
    }

    setProperty(property: Property): Observable<Property> {
        return this.httpInstance.post<Property>(`properties`, property);
    }

    updateProperty(id: string, property: Property): Observable<Property> {
        return this.httpInstance.put<Property>(`properties/${id}`, property);
    }

    deleteProperty(id: string) {
        return this.httpInstance.delete(`properties/${id}`);
    }
    //#endregion

    //#region Property Values
    updatePropertyValue(
        propertyId: string,
        treeNodeId: string,
        propertyValue: UpdatePropertyValue,
        instance: string = null
    ) {
        if (instance) {
            return this.httpGlobal.putForInstance(
                instance,
                `treenodes/${treeNodeId}/properties/${propertyId}`,
                propertyValue
            );
        }
        return this.httpInstance.put(
            `treenodes/${treeNodeId}/properties/${propertyId}`,
            propertyValue
        );
    }

    getPropertyValue(
        propertyId: string,
        treeNodeId: string,
        instance: string = null
    ): Observable<PropertyValue> {
        if (instance) {
            return this.httpGlobal.getForInstance<PropertyValue>(
                instance,
                `treenodes/${treeNodeId}/properties/${propertyId}`
            );
        } else {
            return this.httpInstance.get<PropertyValue>(
                `treenodes/${treeNodeId}/properties/${propertyId}`
            );
        }
    }

    getPropertiesValues(propertyId: string): Observable<PropertyListValue[]> {
        return this.httpInstance.get<PropertyListValue[]>(`properties/${propertyId}/values`);
    }

    //#endregion

    //#region Property set
    getPropertySets(): Observable<PropertySetView[]> {
        return this.httpInstance.get<PropertySetView[]>('propertySet');
    }

    getPropertySet(id: string): Observable<PropertySet> {
        return this.httpInstance.get<PropertySet>(`propertySet/${id}`);
    }

    setPropertySet(propertySet: PropertySet): Observable<PropertySet> {
        return this.httpInstance.post<PropertySet>(`propertySets`, propertySet);
    }

    updatePropertySet(id: string, propertySet: PropertySet): Observable<PropertySet> {
        return this.httpInstance.put<PropertySet>(`propertySets/${id}`, propertySet);
    }

    deletePropertySet(id: string): Observable<PropertySet> {
        return this.httpInstance.delete<PropertySet>(`propertySets/${id}`);
    }

    // property set display
    getPropertySetDisplays(): Observable<GpspPropertySetDisplay[]> {
        return this.httpInstance.get<GpspPropertySetDisplay[]>('propertySetDisplays');
    }

    getPropertySetDisplaysSetId(propertySetId: string): Observable<GpspPropertySetDisplay[]> {
        return this.httpInstance.get<GpspPropertySetDisplay[]>(
            `propertySetDisplay/${propertySetId}`
        );
    }

    GetPropertySetDisplayById(
        propertySetId: string,
        propertySetDisplayId: string
    ): Observable<GpspPropertySetDisplay> {
        return this.httpInstance.get<GpspPropertySetDisplay>(
            `propertySetDisplay/${propertySetId}/displays/${propertySetDisplayId}`
        );
    }

    removePropertySetDisplay(propertySetDisplayId: string): Observable<any> {
        return this.httpInstance.delete<GpspPropertySetDisplay[]>(
            `propertysetdisplays/${propertySetDisplayId}`
        );
    }

    // Properties in Displays
    getPropertiesOfPropertySetDisplay(
        propertySetId: string,
        propertySetDisplayId: string
    ): Observable<PropertySetPropertyDisplay[]> {
        return this.httpInstance.get<PropertySetPropertyDisplay[]>(
            `propertySetDisplay/${propertySetId}/displays/${propertySetDisplayId}/properties`
        );
    }

    setPropertiesOfPropertySetDisplay(
        propertySetDisplayModel: PropertySetDisplayModel
    ): Observable<any> {
        return this.httpInstance.post<any>(`propertysetdisplays`, propertySetDisplayModel);
    }

    updatePropertiesOfPropertySetDisplay(
        propertySetDisplayModel: PropertySetDisplayModel
    ): Observable<any> {
        return this.httpInstance.put<any>(`propertysetdisplays`, propertySetDisplayModel);
    }

    //#endregion

    //#region propertySetProperties

    AddPropertySetProperty(propertySetProperty: PropertySetProperty): Observable<Property> {
        return this.httpInstance.post<Property>(`propertySets/property`, propertySetProperty);
    }

    EditPropertySetProperty(propertySetProperty: PropertySetProperty): Observable<Property> {
        return this.httpInstance.put<Property>(`propertySets/property`, propertySetProperty);
    }

    removePropertySetProperty(propertySetId: string, propertyId: string): Observable<Property> {
        return this.httpInstance.delete<Property>(
            `propertySets/${propertySetId}/property/${propertyId}`
        );
    }
    //#endregion

    //#region Property access rights
    getPropertyAccessRights(propertyId: string): Observable<AccessRight> {
        return this.httpInstance.get<AccessRight>(`properties/${propertyId}/accessrights`);
    }

    updatePropertyAccessRights(
        propertyId: string,
        accessrights: AccessRight
    ): Observable<Property> {
        return this.httpInstance.put<Property>(
            `properties/${propertyId}/accessrights`,
            accessrights
        );
    }
    //#endregion

    //#region Property list values
    getPropertyListvalues(propertyId: string): Observable<PropertyListValue[]> {
        return this.httpInstance.get<PropertyListValue[]>(`properties/${propertyId}/values`);
    }

    setPropertyListValue(
        propertyId: string,
        propertyListValue: PropertyListValue
    ): Observable<PropertyListValue> {
        return this.httpInstance.post<PropertyListValue>(
            `properties/${propertyId}/values`,
            propertyListValue
        );
    }

    updatePropertyListValue(
        propertyId: string,
        propertyListValue: PropertyListValue
    ): Observable<PropertyListValue> {
        return this.httpInstance.put<PropertyListValue>(
            `properties/${propertyId}/values`,
            propertyListValue
        );
    }

    deletePropertyListValue(
        propertyId: string,
        propertyListValueId: string
    ): Observable<PropertyListValue> {
        return this.httpInstance.delete<PropertyListValue>(
            `properties/${propertyId}/values/${propertyListValueId}`
        );
    }
    //#endregion

    //#region Sections
    getSections(): Observable<Section[]> {
        return this.httpInstance.get<Section[]>('sections');
    }

    setSection(section: Section): Observable<Section> {
        return this.httpInstance.post<Section>(`sections`, section);
    }

    updateSection(id: string, section: Section): Observable<Section> {
        return this.httpInstance.put<Section>(`sections/${id}`, section);
    }

    deleteSection(id: string): Observable<Section> {
        return this.httpInstance.delete<Section>(`sections/${id}`);
    }
    //#endregion

    //#region Flags
    getFlags(): Observable<Flag[]> {
        return this.httpInstance.get<Flag[]>('tags');
    }

    setFlag(flag: Flag): Observable<Flag> {
        return this.httpInstance.post<Flag>(`tags`, flag);
    }

    updateFlag(id: string, flag: Flag): Observable<Flag> {
        return this.httpInstance.put<Flag>(`tags/${id}`, flag);
    }

    deleteFlag(id: string): Observable<Flag> {
        return this.httpInstance.delete<Flag>(`tags/${id}`);
    }
    //#endregion
}
