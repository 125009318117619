import { Injectable } from "@angular/core";
import { LayoutRootElement } from "../../../shared/models/navigationElement";
import { _ } from "../../../shared/services/translation.service";
import { TranslateService } from "@ngx-translate/core";
import { MyTrendConfig } from "../../../shared/services/myTrendConfig.service";
import { Rights } from "../../instance-admin/models/rights";

@Injectable({
  providedIn: "root",
})
export class WorkflowService {
  public layoutElements: LayoutRootElement[] = [];

  constructor(private translate: TranslateService) {
    // Update Cycle
    const updateCycleLayoutElement = new LayoutRootElement();
    updateCycleLayoutElement.type = "UpdateCycle";
    updateCycleLayoutElement.link = "updatecycle";
    updateCycleLayoutElement.title = this.translate.instant(_("Update Cycle"));
    updateCycleLayoutElement.description = this.translate.instant(
      _(`In the "Update cycle" section, you will be able manage all
     the functionalities to set up the updates periods and import specific information during the update cycle.`)
    );
    updateCycleLayoutElement.permissions = [
      Rights.UpdatePeriod,
      Rights.SendMail,
    ];
    updateCycleLayoutElement.modulesElements = [];

    updateCycleLayoutElement.modulesElements.push(
      {
        title: this.translate.instant(_("Update Period")),
        icon: "bookmark-icon fas fa-sync",
        description: this.translate.instant(_("Manage update period")),
        link: "updateperiod",
        type: "UpdateCycle",
        disabled: false,
        tag: ["update cycle", "update period"],
        permissions: [Rights.UpdatePeriod, Rights.SendMail],
      },
      {
        title: this.translate.instant(_("Indexation")),
        icon: "bookmark-icon fa fa-hashtag",
        description: this.translate.instant(_("Refresh indexation")),
        link: "indexation",
        type: "UpdateCycle",
        disabled: false,
        tag: ["update cycle", "indexation"],
        permissions: [Rights.UpdatePeriod, Rights.SendMail],
        // TODO Create rights for indexation
      },
      {
        title: this.translate.instant(_("Frozen Periods")),
        icon: "bookmark-icon far fa-snowflake",
        description: this.translate.instant(_("Manage frozen periods")),
        link: "frozenperiods",
        type: "UpdateCycle",
        disabled: true,
        tag: ["update cycle", "frozen periods"],
        permissions: [],
      },
      {
        title: this.translate.instant(_("Change Requests")),
        icon: "bookmark-icon fa fa-table",
        description: this.translate.instant(_("Manage change requests")),
        link: "changerequests",
        type: "UpdateCycle",
        disabled: true,
        tag: ["update cycle", "change requests"],
        permissions: [],
      },
      {
        title: this.translate.instant(_("Transfer Requests")),
        icon: "bookmark-icon fa fa-arrows-h",
        description: this.translate.instant(_("Manage transfer requests")),
        link: "transferrequests",
        type: "UpdateCycle",
        disabled: true,
        tag: ["update cycle", "transfer requests"],
        permissions: [],
      }
    );

    this.layoutElements.push(updateCycleLayoutElement);

    // Users & Groups
    const userGroupsLayoutElement = new LayoutRootElement();
    userGroupsLayoutElement.type = "UsersAndGroups";
    userGroupsLayoutElement.link = "usersandgroups";
    userGroupsLayoutElement.title = this.translate.instant(_("Users & Groups"));
    userGroupsLayoutElement.description = this.translate.instant(
      _(`In the "users and groups" section, you will be able to
    manage the creation and the editing of users and groups. You may create users either
    none by one or through the massive import process.`)
    );
    userGroupsLayoutElement.permissions = [Rights.UserAndGroup, Rights.Import];
    userGroupsLayoutElement.modulesElements = [];

    userGroupsLayoutElement.modulesElements.push(
      {
        title: this.translate.instant(_("Users")),
        icon: "bookmark-icon fa fa-user",
        description: this.translate.instant(_("Manage users")),
        link: "users",
        type: "UsersAndGroups",
        disabled: false,
        tag: ["users and groups"],
        permissions: [Rights.UserAndGroup],
      },
      {
        title: this.translate.instant(_("Groups")),
        icon: "bookmark-icon fa fa-users",
        description: this.translate.instant(_("Manage groups")),
        link: "groups",
        type: "UsersAndGroups",
        disabled: false,
        tag: ["users", "groups"],
        permissions: [Rights.UserAndGroup],
      },
      {
        title: this.translate.instant(_("Import Users")),
        icon: "bookmark-icon fa fa-download",
        description: this.translate.instant(_("Import users")),
        link: "users/import",
        type: "UsersAndGroups",
        disabled: false,
        tag: ["users", "groups", "import"],
        permissions: [Rights.Import],
      },
      {
        title: this.translate.instant(_("Import Groups")),
        icon: "bookmark-icon fa fa-download",
        description: this.translate.instant(_("Import groups")),
        link: "groups/import",
        type: "UsersAndGroups",
        disabled: false,
        tag: ["users", "groups", "import"],
        permissions: [Rights.Import],
      }
    );

    this.layoutElements.push(userGroupsLayoutElement);

    // Communication
    const comunicationLayoutElement = new LayoutRootElement();
    comunicationLayoutElement.type = "Communication";
    comunicationLayoutElement.link = "communication";
    comunicationLayoutElement.title = this.translate.instant(
      _("Communication")
    );
    comunicationLayoutElement.description = this.translate.instant(
      _(`In the "communication" section, you will be able to manage every
                                            communication channel for an efficient communication to the users.`)
    );
    comunicationLayoutElement.permissions = [
      Rights.GroupMailAndTemplate,
      Rights.SendMail,
      Rights.Message,
    ];

    comunicationLayoutElement.modulesElements = [];

    comunicationLayoutElement.modulesElements.push(
      {
        title: this.translate.instant(_("Emails")),
        icon: "bookmark-icon fas fa-envelope",
        description: this.translate.instant(_("Manage emails")),
        link: "mailtemplates",
        type: "Communication",
        disabled: false,
        tag: ["communication", "emails"],
        permissions: [Rights.GroupMailAndTemplate],
      },
      {
        title: this.translate.instant(_("Email Group")),
        icon: "bookmark-icon fa fa-share",
        description: this.translate.instant(_("Email management")),
        link: "groupmails",
        type: "Communication",
        disabled: false,
        tag: ["communication", "email group"],
        permissions: [Rights.GroupMailAndTemplate],
      },
      {
        title: this.translate.instant(_("Email Diffusion")),
        icon: "bookmark-icon fa fa-share",
        description: this.translate.instant(_("Email diffusion")),
        link: "generatedmails",
        type: "Communication",
        disabled: false,
        tag: ["communication", "email diffusion"],
        permissions: [Rights.SendMail, Rights.UpdatePeriod],
      },
      {
        title: this.translate.instant(_("Messages")),
        icon: "bookmark-icon fas fa-comment-dots",
        description: this.translate.instant(_("Manage messages")),
        link: "messages",
        type: "Communication",
        disabled: false,
        tag: ["communication", "messages"],
        permissions: [Rights.Message],
      }
    );

    this.layoutElements.push(comunicationLayoutElement);

    // Reporting
    const reportingLayoutElement = new LayoutRootElement();
    reportingLayoutElement.type = "Reporting";
    reportingLayoutElement.link = "reporting";
    reportingLayoutElement.title = this.translate.instant(_("Reporting"));
    reportingLayoutElement.description = this.translate.instant(
      _(`In the "reporting" section, you will be able manage the manual
                                          production of reports and related snapshot dates.`)
    );
    reportingLayoutElement.permissions = [Rights.Export, Rights.SnapshotDates];
    reportingLayoutElement.modulesElements = [];

    reportingLayoutElement.modulesElements.push(
      {
        title: this.translate.instant(_("Reports")),
        icon: "bookmark-icon fas fa-chart-line",
        description: this.translate.instant(_("Generate reports")),
        link: "reports",
        type: "Reporting",
        disabled: false,
        tag: ["reporting", "reports", "dataFactory", "reporting"],
        permissions: [Rights.Export],
      },
      {
        title: this.translate.instant(_("Snapshot Dates")),
        icon: "bookmark-icon fa fa-calendar",
        description: this.translate.instant(_("Manage snapshot dates")),
        link: "snapshotdates",
        type: "Reporting",
        disabled: false,
        tag: [
          "snapshot dates",
          "reports",
          "reporting",
          "dataFactory",
          "data factory",
        ],
        permissions: [Rights.SnapshotDates],
      }
    );

    this.layoutElements.push(reportingLayoutElement);

    // Exchange Rate
    const exchangeRateLayoutElement = new LayoutRootElement();
    exchangeRateLayoutElement.type = "ExchangeRate";
    exchangeRateLayoutElement.link = "exchangerate";
    exchangeRateLayoutElement.title = this.translate.instant(
      _("Exchange Rate")
    );
    exchangeRateLayoutElement.description = this.translate.instant(
      _(`In the "exchange rate" section, you will be able to manage the
                                            currencies used in your tops and the rates that needs to be applied.`)
    );
    exchangeRateLayoutElement.permissions = [
      Rights.TopLevelCurrency,
      Rights.ExchangeRate,
      Rights.Import,
    ];
    exchangeRateLayoutElement.modulesElements = [];

    exchangeRateLayoutElement.modulesElements.push(
      {
        title: this.translate.instant(_("Currencies")),
        icon: "bookmark-icon fas fa-dollar-sign",
        description: this.translate.instant(_("Manage currencies")),
        link: "currencies",
        type: "ExchangeRate",
        disabled: false,
        tag: ["exchange rate", "currencies"],
        permissions: [Rights.TopLevelCurrency],
      },
      {
        title: this.translate.instant(_("Exchange Rates")),
        icon: "bookmark-icon fas fa-exchange-alt",
        description: this.translate.instant(_("Manage exchange rates")),
        link: "exchangerates",
        type: "ExchangeRate",
        disabled: false,
        tag: ["exchanges rates"],
        permissions: [Rights.ExchangeRate],
      },
      {
        title: this.translate.instant(_("Import Exchange Rates")),
        icon: "bookmark-icon fa fa-download",
        description: this.translate.instant(_("Import exchange rates")),
        link: "exchangerates/import",
        type: "ExchangeRate",
        disabled: false,
        tag: ["exchanges rates", "imports"],
        permissions: [Rights.Import],
      }
    );

    this.layoutElements.push(exchangeRateLayoutElement);

    // Monitoring
    const monitoringLayoutElement = new LayoutRootElement();
    monitoringLayoutElement.type = "Monitoring";
    monitoringLayoutElement.link = "monitoring";
    monitoringLayoutElement.title = this.translate.instant(_("Monitoring"));
    monitoringLayoutElement.description = this.translate.instant(
      _(`In the "monitoring" section, you will be able
     to visualize all of the information needed regarding the auditability of the actions performed.`)
    );
    monitoringLayoutElement.permissions = [];
    monitoringLayoutElement.modulesElements = [];

    monitoringLayoutElement.modulesElements.push(
      {
        title: this.translate.instant(_("Jobs")),
        icon: "bookmark-icon fa fa-list",
        description: this.translate.instant(_("View jobs")),
        link: "jobs",
        type: "Monitoring",
        disabled: false,
        tag: ["monitoring", "jobs"],
        permissions: [],
      },
      {
        title: this.translate.instant(_("History")),
        icon: "bookmark-icon fa fa-history",
        description: this.translate.instant(_("View history")),
        link: "history",
        type: "Monitoring",
        disabled: false,
        tag: ["monitoring", "history"],
        permissions: [],
      }
    );

    this.layoutElements.push(monitoringLayoutElement);

    // Structure Management
    const structureLayoutElement = new LayoutRootElement();
    structureLayoutElement.type = "Structure";
    structureLayoutElement.link = "structure";
    structureLayoutElement.title = this.translate.instant(
      _("Structure Management")
    );
    structureLayoutElement.description = this.translate.instant(
      _(`In the "structure management" section, You will be able to manage
                                      the structure of your project in order to respond effectively to the piloting needs.`)
    );
    structureLayoutElement.permissions = [];
    structureLayoutElement.modulesElements = [];

    structureLayoutElement.modulesElements.push(
      {
        title: this.translate.instant(_("Top Management")),
        icon: "bookmark-icon fa fa-home",
        description: this.translate.instant(_("Manage top")),
        link: "tops",
        type: "Structure",
        disabled: false,
        tag: ["structure", "top management"],
        permissions: [Rights.TopLevelCreation, Rights.TopLevelScorecardInfo],
      },
      {
        title: this.translate.instant(_("Level Management")),
        icon: "bookmark-icon fa fa-sitemap",
        description: this.translate.instant(_("Manage levels")),
        link: "levels",
        disabled: false,
        type: "Structure",
        tag: ["structure", "level management"],
        permissions: [Rights.Structure],
      },
      {
        title: _("Duplication Exports"),
        icon: "bookmark-icon fa fa-clone",
        description: _("Manage duplication exports"),
        link: "duplications/export",
        type: "Structure",
        disabled: false,
        tag: ["structure", "duplications", "exports"],
        permissions: [Rights.Export],
      },
      {
        title: _("Import Duplication"),
        icon: "bookmark-icon fa fa-download",
        description: _("Duplication import"),
        link: "duplications/import",
        type: "Structure",
        disabled: false,
        tag: ["structure", "duplications", "imports"],
        permissions: [],
      },
      {
        title: _("Source Agreement"),
        icon: "bookmark-icon fas fa-file-contract",
        description: _("Source Agreement"),
        link: "sourceagreement",
        type: "Structure",
        disabled: false,
        tag: ["structure", "agreement"],
        permissions: [],
      }
    );

    this.layoutElements.push(structureLayoutElement);

    // Maturity Management
    const maturityLayoutElement = new LayoutRootElement();
    maturityLayoutElement.type = "Maturities";
    maturityLayoutElement.link = "maturities";
    maturityLayoutElement.title = this.translate.instant(_("Maturity"));
    maturityLayoutElement.description = this.translate.instant(
      _(`In the maturity section, you will be able to define
     the life cycles of your initiative through maturities that will best fit your management habits.`)
    );
    maturityLayoutElement.permissions = [
      Rights.MaturityAndStrategy,
      Rights.CheckistAndDeliverable,
    ];
    maturityLayoutElement.modulesElements = [];

    maturityLayoutElement.modulesElements.push(
      {
        title: this.translate.instant(_("Workflows")),
        icon: "bookmark-icon far fa-star",
        description: this.translate.instant(_("Manage maturity workflows")),
        link: "maturitystrategies",
        type: "Maturities",
        disabled: false,
        tag: ["maturities", "maturity", "workflow", "strategies"],
        permissions: [Rights.MaturityAndStrategy],
      },
      {
        title: this.translate.instant(_("Workflow Status")),
        icon: "bookmark-icon fas fa-star",
        description: this.translate.instant(_("Manage maturities")),
        link: "maturities",
        type: "Maturities",
        disabled: false,
        tag: ["maturities", "workflow", "status"],
        permissions: [Rights.MaturityAndStrategy],
      },
      {
        title: this.translate.instant(_("Checklists")),
        icon: "bookmark-icon fa fa-list",
        description: this.translate.instant(_("Manage checklists")),
        link: "checklists",
        type: "Maturities",
        disabled: false,
        tag: ["maturities", "checklists"],
        permissions: [Rights.CheckistAndDeliverable],
      },
      {
        title: this.translate.instant(_("Checklist items")),
        icon: "bookmark-icon fa fa-list-ul",
        description: this.translate.instant(_("Manage checklist items")),
        link: "deliverables",
        type: "Maturities",
        disabled: false,
        tag: ["maturities", "checklist items", "deliverables"],
        permissions: [Rights.CheckistAndDeliverable],
      }
    );

    this.layoutElements.push(maturityLayoutElement);

    
    // Node Configuration
    const nodeLayoutElement = new LayoutRootElement();
    nodeLayoutElement.type = "Nodes";
    nodeLayoutElement.link = "nodes";
    nodeLayoutElement.title = this.translate.instant(_("Node Configuration"));
    nodeLayoutElement.description = this.translate.instant(
      _(`In the "node configuration" section, you can manage
                                    different elements needed for the collect of information.`)
    );
    nodeLayoutElement.permissions = [
      Rights.PropertyAndSet,
      Rights.CreateKPI,
      Rights.DeletedNode,
    ];
    nodeLayoutElement.modulesElements = [];

    nodeLayoutElement.modulesElements.push(
      {
        title: this.translate.instant(_("Properties")),
        icon: "bookmark-icon fa fa-cog",
        description: this.translate.instant(_("Manage properties")),
        link: "properties",
        type: "Nodes",
        disabled: false,
        tag: ["node configuration", "properties"],
        permissions: [Rights.PropertyAndSet],
      },
      {
        title: this.translate.instant(_("Properties set")),
        icon: "bookmark-icon fa fa-cogs",
        description: this.translate.instant(_("Manage properties set")),
        link: "propertysets",
        type: "Nodes",
        disabled: false,
        tag: ["node configuration", "properties set"],
        permissions: [Rights.PropertyAndSet],
      },
      {
        title: this.translate.instant(_("Flags")),
        icon: "bookmark-icon fa fa-flag",
        description: this.translate.instant(_("Manage flags")),
        link: "flags",
        type: "Nodes",
        disabled: false,
        tag: ["node configuration", "flags"],
        permissions: [Rights.PropertyAndSet],
      },
      {
        title: this.translate.instant(_("Sections")),
        icon: "bookmark-icon fa fa-puzzle-piece",
        description: this.translate.instant(_("Manage sections")),
        link: "sections",
        type: "Nodes",
        disabled: false,
        tag: ["node configuration", "sections"],
        permissions: [Rights.PropertyAndSet],
      },
      {
        title: this.translate.instant(_("Operational Kpis")),
        icon: "bookmark-icon fa fa-trophy",
        description: this.translate.instant(_("Manage operation kpis")),
        link: "kpis",
        type: "Nodes",
        disabled: false,
        tag: ["node configuration", "operational kpis"],
        permissions: [Rights.CreateKPI],
      },
      {
        title: this.translate.instant(_("Recycle Bin")),
        icon: "bookmark-icon fa fa-recycle",
        description: this.translate.instant(_("Manage recycle bin")),
        link: "deletenodes",
        type: "Nodes",
        disabled: false,
        tag: ["node configuration", "recycle bin"],
        permissions: [Rights.DeletedNode],
      },
      {
        title: this.translate.instant(_("Imports")),
        icon: "bookmark-icon fa fa-download",
        description: this.translate.instant(_("Manage imports")),
        link: "imports",
        type: "Nodes",
        disabled: false,
        tag: ["node configuration", "imports"],
        permissions: [Rights.Import],
      }
    );

    this.layoutElements.push(nodeLayoutElement);

    // Scorecards
    const scorecardLayoutElement = new LayoutRootElement();
    scorecardLayoutElement.type = "Scorecards";
    scorecardLayoutElement.link = "scorecards";
    scorecardLayoutElement.title = this.translate.instant(_("Scorecard"));
    scorecardLayoutElement.description = this.translate.instant(
      _(`In the "scorecard" section, you can manage the configuration
     of the scorecard to track easily the evolution of quantitative information.`)
    );
    scorecardLayoutElement.permissions = [Rights.ScorecardAndEffect];
    scorecardLayoutElement.modulesElements = [];

    scorecardLayoutElement.modulesElements.push(
      {
        title: this.translate.instant(_("Scorecards")),
        icon: "bookmark-icon fa fa-table",
        description: this.translate.instant(_("Configure scorecards")),
        link: "scorecards",
        type: "Scorecards",
        disabled: false,
        tag: ["scorecards"],
        permissions: [Rights.ScorecardAndEffect],
      },
      {
        title: this.translate.instant(_("Scorecards Views")),
        icon: "bookmark-icon fa fa-table",
        description: this.translate.instant(_("Configure scorecard views")),
        link: "scorecardviews",
        type: "Scorecards",
        disabled: false,
        tag: ["scorecards views"],
        permissions: [Rights.ScorecardAndEffect],
      },
      {
        title: this.translate.instant(_("Effects")),
        icon: "bookmark-icon fa fa-list-ol",
        description: this.translate.instant(_("Configure effects")),
        link: "effects",
        type: "Scorecards",
        disabled: false,
        tag: ["scorecards", "effects"],
        permissions: [Rights.ScorecardAndEffect],
      },
      {
        title: this.translate.instant(_("Financial States")),
        icon: "bookmark-icon fa fa-bars",
        description: this.translate.instant(_("Configure financial states")),
        link: "financialstates",
        type: "Scorecards",
        disabled: false,
        tag: ["scorecards", "financial states"],
        permissions: [Rights.ScorecardAndEffect],
      }
    );

    this.layoutElements.push(scorecardLayoutElement);
  }
}
