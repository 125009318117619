export class Deliverable {
  id: string;
  name: string;
  responsibleId: string;
}

export class IDeliverableView extends Deliverable {
  responsibleName: string;
  NumberOfChecklists: number;
}

export class DeliverableModel {
  id: string; // helper only
  name: string;
  responsibleId: string;
}

export class Checklist {
  id: string;
  name: string;
  treeNodeDeliverables?: TreeNodeDeliverable[];
}

export class ChecklistView extends Checklist {
  numberOfDeliverables: number;

  // view helper
  isExpanded: boolean;
}

export class ChecklistDeliverable {
  id: string;
  checklistId: string;
  deliverableId: string;
  order: number;
}

export class ChecklistDeliverableView extends ChecklistDeliverable {
  deliverableName: string;
  responsibleId: string;
  responsibleName: string;
}

export class AddDeliverableModel {
  deliverableId: string;
  order: number;
}

export class TreeNodeDeliverable {
  checklistValueId: string;
  deliverableId: string;
  idValidatedBy: string;
  lastUpdateAt: Date;
  maturityId: string;
  name: string;
  nameValidatedBy: string;
  order: number;
  responsibleId: string;
  responsibleName: string;
  treeNodeId: string;

  isEditable: boolean;
  isResponsible: boolean;

  public isChecked(): boolean {
    return this.checklistValueId !== null;
  }
}

export enum ChecklistComputingState {
  OnlyCurrentLevel = 0,
  CurrentLevelAndPrevious = 1,
  OnlyLastLevel = 2
}

