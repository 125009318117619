import { ScorecardFrequency } from './scorecard';

export class TopLevelStructure {
    public id: string;
    public toplevelId?: string;
    public typeName: string;
    public level?: number;
    public propertySetId?: string;
    public propertySetName?: string;
    public maturityStrategyName?: string;
    public maturityStrategyId?: string;
    public hidden?: boolean;
    public hasCorrection?: boolean;
    public isPartOfUpdatePeriod?: boolean;
    public scorecardDisplayName?: string;
    public scorecardFrequency?: ScorecardFrequency;
    public creationRoute?: string;
    public hasKpi?: boolean;
    public financialStates?: { [index: string]: boolean } = {};
    public reportingSetId?: string;
    public reportingSetName?: string;

    // gpsp
    public hasForm?: boolean;

    // view helper
    isExpanded?: boolean;
    numberOfValidations?: number;

    public static get TOP_LEVEL(): number {
        return 0;
    }
}

export enum TopLevelStructureLevel {
    topLevel = 0,
    milestone = 3000,
}

export class EditTopLevelStructureModel {
    public topLevelName: string;
    public topLevelSructureId: string;
    public topLevelId: string;
    public name: string;
    public level: number;
    public propertySetId: string;
    public propertySetName: string;
    public maturityStrategyName: string;
    public maturityStrategyId: string;
    public hidden: boolean;
    public hasCorrection: boolean;
    public isPartOfUpdatePeriod: boolean;
    public scorecardDisplayId: string;
    public scorecardDisplayName: string;
    public scorecardFrequency: ScorecardFrequency;
    public creationRoute: string;
    public hasKpi: boolean;
    public hasFinancialStates: { [index: string]: boolean } = {};
    public reportingSetId: string;

    // gpsp
    public hasForm: boolean;
}
