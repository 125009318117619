export enum Right {
    READ = '50000000-0000-0000-0000-000000000002',
    WRITE = '50000000-0000-0000-0000-000000000003',
    SCORECARD = '50000000-0000-0000-0000-000000000004',
    READCORRECTION = '50000000-0000-0000-0000-000000000005',
    VALIDATION = '50000000-0000-0000-0000-000000000008',
    ADMIN = '50000000-0000-0000-0000-000000000009',
    TAGS = '50000000-0000-0000-0000-000000000011',
    VENTILATIONS_VALUE_UPDATE = '50000000-0000-0000-0001-000000000002',
    CHANGE_REQUEST_VALIDATION = '50000000-0000-0000-0001-000000000001',
    CREATE_KPI = '50000000-0000-0000-0000-000000000020',
    EDIT_KPI = '50000000-0000-0000-0000-000000000019',
    SCORECARDBUDGET = '50000000-0000-0000-0000-000000000007',
    UPDATE_ACTUALS = '50000000-0000-0000-0000-000000000014',
}
