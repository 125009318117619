import { Injectable } from '@angular/core';

// Models
import { FAQ } from '../../models';

// Rxjs
import { Observable } from 'rxjs';

// Services
import { HttpExtendedGlobalService } from 'src/app/shared/services';

@Injectable({
    providedIn: 'root',
})
export class ProfileFAQService {
    /**
     * Creates an instance of profile faqservice.
     * @param http
     */
    constructor(private http: HttpExtendedGlobalService) {}

    /**
     * Gets faqs
     * @returns faqs
     */
    public getFAQs(): Observable<FAQ[]> {
        return this.http.get<FAQ[]>(`faqs`);
    }
}
