export class FinancialState {
    id: string;
    order: number;
    displayName: string;
    scorecardDisplay: string;
    rightId: string;
    readRightId: string;
    summaryOrder: number;
    system: boolean;
}
