import { Component, OnInit, TemplateRef } from '@angular/core';
import { Subject } from 'rxjs';
import { MessageService } from 'primeng/api';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { BaseComponent } from '../../../../shared/components/base/base.component';
import { PropertyService } from '../../../../../shared/services/repositories/property.service';
import { fadeInOut } from 'src/app/shared/animations/fade';
import { Section, PropertySet, PropertySetView } from '../../../../../shared/models/property';
import { ConfirmationDialogService } from '../../../../../shared/components/utils/confirmation/ConfirmationDialog.service';
import { Router } from '@angular/router';
import { WorkflowService } from '../../../../shared/services/workflow.service';
import { TypeProperty, VisibilityProperty, UpdateBehavior } from 'src/app/shared/models/enums';
import { _ } from '../../../../../shared/services/translation.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-property-sets',
    templateUrl: './property-sets.component.html',
    styleUrls: ['./property-sets.component.scss'],
    animations: [fadeInOut],
})
export class PropertySetsComponent extends BaseComponent implements OnInit {
    public addModalRef: BsModalRef;
    public editModalRef: BsModalRef;

    public dtTrigger: Subject<any> = new Subject();

    public propertySets: PropertySetView[] = [];
    public propertySet: PropertySet = new PropertySet();
    public sections: Section[] = [];
    public sec: Section;

    public TypeProperty = TypeProperty;
    public VisibilityProperty = VisibilityProperty;
    public UpdateBehavior = UpdateBehavior;

    constructor(
        private propertyService: PropertyService,
        private confirmationService: ConfirmationDialogService,
        private messageService: MessageService,
        private modalService: BsModalService,
        public router: Router,
        protected workflowService: WorkflowService,
        private translate: TranslateService
    ) {
        super(router, workflowService);
        // disable order in specific columns
        this.dtOptions.columnDefs = [
            { orderable: false, targets: 2 },
            { orderable: false, targets: 3 },
            { orderable: false, targets: 4 },
        ];
        this.generateBreadCrumb();
    }

    ngOnInit() {
        this.getPropertySets();
    }

    getPropertySets() {
        this.loading = true;
        this.propertyService.getPropertySets().subscribe((res) => {
            this.propertySets = res;
            this.refreshTable('#table', this.dtTrigger);
        });
    }

    //#region remove
    delete(propertySet: PropertySet) {
        this.confirmationService
            .confirm(`Do you want to delete property set: ${propertySet.name}?`)
            .subscribe((result) => {
                if (result) {
                    this.propertyService.deletePropertySet(propertySet.propertySetId).subscribe(
                        () => {
                            this.messageService.add({
                                severity: 'success',
                                summary: this.translate.instant(_('Success!')),
                                detail: this.translate.instant(
                                    _('Property Set removed with success!')
                                ),
                            });
                        },
                        (error) => {
                            return console.log('Error: ', error);
                        },
                        () => {
                            return this.getPropertySets();
                        }
                    );
                }
            });
    }

    //#region add
    addPropertySetModal(modal: TemplateRef<any>) {
        this.propertySet = new PropertySet();
        this.addModalRef = this.modalService.show(modal, this.modalConfig);
    }

    addPropertySet() {
        // null or white spaces
        if (this.propertySet.name !== null && this.propertySet.name.match(/^ *$/) === null) {
            this.propertyService.setPropertySet(this.propertySet).subscribe(
                () => {
                    this.messageService.add({
                        severity: 'success',
                        summary: this.translate.instant(_('Success!')),
                        detail: this.translate.instant(_('Property Set added with success!')),
                    });
                },
                (error) => {
                    return console.log('Error: ', error);
                },
                () => {
                    return this.getPropertySets();
                }
            );
            this.addModalRef.hide();
        }
    }

    //#region edit
    editPropertySetModal(propertySet: PropertySetView, modal: TemplateRef<any>) {
        this.propertySet = {
            propertySetId: propertySet.propertySetId,
            name: propertySet.name,
            system: false,
            propertySetProperties: [],
        };
        this.editModalRef = this.modalService.show(modal, this.modalConfig);
    }

    editPropertySet() {
        // null or white spaces
        if (this.propertySet.name !== null && this.propertySet.name.match(/^ *$/) === null) {
            this.propertyService
                .updatePropertySet(this.propertySet.propertySetId, this.propertySet)
                .subscribe(
                    () => {
                        this.messageService.add({
                            severity: 'success',
                            summary: this.translate.instant(_('Success!')),
                            detail: this.translate.instant(_('Property Set updated with success!')),
                        });
                    },
                    (error) => {
                        return console.log('Error: ', error);
                    },
                    () => {
                        return this.getPropertySets();
                    }
                );
            this.editModalRef.hide();
        }
    }
    private generateBreadCrumb() {
        this.dynamicBreadCrumb('Node Configuration');
    }
}
