import { Component, Input } from '@angular/core';
import { AuthService } from '../../../../shared/services/auth.service';
import { BreadcrumElement } from '../../../../shared/models/navigationElement';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent {
    @Input() elements: BreadcrumElement[];

    constructor(
        public authService: AuthService,
        private router: Router,
        private activeRoute: ActivatedRoute
    ) {}

    updateRoute(link: string) {
        this.router.navigate([link], { relativeTo: this.activeRoute.parent });
    }
}
