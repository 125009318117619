import { Injectable } from '@angular/core';

// Models
import {
    User,
    UserSearch,
    GroupSearch,
    IGroup,
    IGroupView,
    UserModel,
    AuthenticatedUser,
} from '../../models/user';
import { UserGroupProperty } from '../../models/property';
import { PagingResult } from '../../models/pagingResult';
import { FavoriteTile } from '../../models/favoriteTile';

// Services
import { HttpExtendedGlobalService, HttpExtendedInstanceService } from 'src/app/shared/services';

// Rxjs
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    /**
     * Creates an instance of user service.
     * @param httpInstance
     * @param httpGlobal
     */
    constructor(
        private readonly httpInstance: HttpExtendedInstanceService,
        private readonly httpGlobal: HttpExtendedGlobalService
    ) { }

    /**
     * Gets users
     * @param userSearch
     * @returns users
     */
    public getUsers(userSearch: UserSearch): Observable<PagingResult<User>> {
        return this.httpInstance.get<PagingResult<User>>(
            'users',
            this.httpInstance.baseOptions(),
            userSearch
        );
    }

    /**
     * Gets user
     * @param id
     * @returns user
     */
    public getUser(id: string): Observable<User> {
        return this.httpInstance.get<User>(`users/${id}`);
    }

    /**
     * Gets actual user
     * @returns actual user
     */
    public getActualUser(): Observable<AuthenticatedUser> {
        return this.httpInstance.get<AuthenticatedUser>(`users/me`);
    }

    /**
     * Gets user admin favorites
     * @returns user admin favorites
     */
    public getUserAdminFavorites(): Observable<FavoriteTile[]> {
        return this.httpInstance.get<FavoriteTile[]>('users/me/favorites');
    }

    /**
     * Gets real user
     * @returns real user
     */
    public getRealUser(): Observable<AuthenticatedUser> {
        return this.httpInstance.get<AuthenticatedUser>(`users/realme`);
    }

    /**
     * Sets user
     * @param user
     * @returns user
     */
    public setUser(user: UserModel): Observable<User> {
        return this.httpInstance.post<User>('users', user);
    }

    /**
     * Updates user
     * @param id
     * @param user
     * @returns user
     */
    public updateUser(id: string, user: UserModel): Observable<User> {
        return this.httpInstance.put<User>(`users/${id}`, user);
    }

    /**
     * Updates user profile
     * @param user
     * @returns user profile
     */
    public updateUserProfile(user: UserModel): Observable<User> {
        return this.httpGlobal.put<User>(`users/me`, user);
    }

    /**
     * Disables user
     * @param id
     * @returns user
     */
    public disableUser(id: string): Observable<User> {
        return this.httpInstance.put<User>(`users/${id}/disable`, null);
    }

    /**
     * Enables user
     * @param id
     * @returns user
     */
    public enableUser(id: string): Observable<User> {
        return this.httpInstance.put<User>(`users/${id}/enable`, null);
    }

    /**
     * Connects as
     * @param id
     * @returns as
     */
    public connectAs(id: string): Observable<string> {
        return this.httpGlobal.put<string>(`users/${id}/connect`, null);
    }

    /**
     * Transfers access
     * @param userId
     * @param toUserId
     * @returns access
     */
    public transferAccess(userId: string, toUserId: string): Observable<User> {
        return this.httpInstance.put<User>(`users/${userId}/transferaccessto/${toUserId}`, null);
    }

    /**
     * Gets groups
     * @param groupSearch
     * @returns groups
     */
    public getGroups(groupSearch: GroupSearch): Observable<PagingResult<IGroupView>> {
        return this.httpInstance.get<PagingResult<IGroupView>>(
            'groups',
            this.httpInstance.baseOptions(),
            groupSearch
        );
    }

    /**
     * Gets group views
     * @param groupSearch
     * @returns group views
     */
    public getGroupViews(groupSearch: GroupSearch): Observable<PagingResult<IGroupView>> {
        return this.httpInstance.get<PagingResult<IGroupView>>(
            'groupsview',
            this.httpInstance.baseOptions(),
            groupSearch
        );
    }

    /**
     * Gets group
     * @param id
     * @returns group
     */
    public getGroup(id: string): Observable<IGroup> {
        return this.httpInstance.get<IGroup>(`groups/${id}`);
    }

    /**
     * Sets group
     * @param name
     * @returns group
     */
    public setGroup(name: string): Observable<IGroup> {
        return this.httpInstance.post<IGroup>('groups', { name });
    }

    /**
     * Updates group
     * @param id
     * @param name
     * @returns group
     */
    public updateGroup(id: string, name: string): Observable<IGroup> {
        return this.httpInstance.put(`groups/${id}`, { name });
    }

    /**
     * Deletes group
     * @param id
     * @returns group
     */
    public deleteGroup(id: string): Observable<IGroup> {
        return this.httpInstance.delete<IGroup>(`groups/${id}`);
    }

    /**
     * Gets user groups
     * @param userId
     * @param groupSearch
     * @returns user groups
     */
    public getUserGroups(userId: string, groupSearch: GroupSearch): Observable<IGroup[]> {
        return this.httpInstance.get<IGroup[]>(
            `users/${userId}/groups`,
            this.httpInstance.baseOptions(),
            groupSearch
        );
    }

    /**
     * Gets group users
     * @param groupId
     * @returns group users
     */
    public getGroupUsers(groupId: string): Observable<User[]> {
        return this.httpInstance.get<User[]>(`groups/${groupId}/users`);
    }

    /**
     * Gets groups for users
     * @param userId
     * @param query
     * @returns groups for users
     */
    public getGroupsForUsers(userId: string, query: string): Observable<IGroup[]> {
        return this.httpInstance.get<IGroup[]>(
            `users/${userId}/groupsforuser`,
            this.httpInstance.baseOptions(),
            {
                query,
            }
        );
    }

    /**
     * Gets users for group
     * @param groupId
     * @param query
     * @returns users for group
     */
    public getUsersForGroup(groupId: string, query: string): Observable<User[]> {
        return this.httpInstance.get<User[]>(
            `groups/${groupId}/usersforgroup`,
            this.httpInstance.baseOptions(),
            {
                query,
            }
        );
    }

    /**
     * Adds group to user
     * @param userId
     * @param groupId
     * @returns
     */
    public addGroupToUser(userId: string, groupId: string) {
        return this.httpInstance.post(`groups/${groupId}/users/${userId}`, null);
    }

    /**
     * Removes user from group
     * @param userId
     * @param groupId
     * @returns
     */
    public removeUserFromGroup(userId: string, groupId: string) {
        return this.httpInstance.delete(`groups/${groupId}/users/${userId}`);
    }

    /**
     * Gets user properties
     * @param userId
     * @returns user properties
     */
    public getUserProperties(userId: string): Observable<UserGroupProperty[]> {
        return this.httpInstance.get<UserGroupProperty[]>(`users/${userId}/userproperties`);
    }

    /**
     * remove  user from all  properties
     * @param userId
     * @returns void
     */
    public removeUserFromAllNodesAssignations(userId: string): Observable<any> {
        return this.httpInstance.delete(`users/${userId}/userproperties`);
    }

    /**
     * Logs back as
     * @param usserId
     * @returns back as
     */
    public logBackAs(usserId: string): Observable<any> {
        return this.httpGlobal.put<any>(`users/${usserId}/connect`, null);
    }

    /**
     * Logs out
     * @returns out
     */
    public logOut(): Observable<any> {
        return this.httpGlobal.post<any>(`logout`, null);
    }
}
