import { Injectable } from '@angular/core';
import { Observable, observable } from 'rxjs';
import { HttpExtendedInstanceService } from 'src/app/shared/services';
import { Snapshot } from '../../models/snapshot';

@Injectable({
    providedIn: 'root',
})
export class SnapshotService {
    constructor(private http: HttpExtendedInstanceService) {}

    getSnapshots(): Observable<Snapshot[]> {
        return this.http.get<Snapshot[]>('gpspSnapshots');
    }

    getSnapshotById(id: string): Observable<Snapshot> {
        return this.http.get<Snapshot>(`gpspSnapshots/${id}`);
    }

    createSnapshot(snapshot: any): Observable<Snapshot> {
        return this.http.post<Snapshot>('gpspSnapshots', snapshot);
    }

    updateSnapshot(snapshot: any): Observable<Snapshot> {
        return this.http.put<Snapshot>('gpspSnapshots', snapshot);
    }

    removeSnapshot(id: string): Observable<any> {
        return this.http.delete(`gpspSnapshots/${id}`);
    }
}
