import { EmailService } from './../../../../../shared/services/repositories/email.service';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { IEmailScheduleDetailsValues } from '../../../../shared/models/email';
import { BaseComponent } from '../../../../shared/components/base/base.component';
import { fadeInOut } from 'src/app/shared/animations/fade';
import { _ } from '../../../../../shared/services/translation.service';
import { WorkflowService } from '../../../../shared/services/workflow.service';

@Component({
  selector: 'app-generated-email',
  templateUrl: './generated-email.component.html',
  styleUrls: ['./generated-email.component.scss'],
  animations: [fadeInOut]
})
export class GeneratedEmailComponent extends BaseComponent implements OnInit {

  public dtTrigger: Subject<any> = new Subject();
  modalRef: BsModalRef;

  public emailScheduleId: string;
  public emailScheduleDetails: IEmailScheduleDetailsValues[];

  public emailScheduleSample: any;

  constructor(private emailService: EmailService, private route: ActivatedRoute, private modalService: BsModalService,
    public router: Router, protected workflowService: WorkflowService) {
    super(router, workflowService);
    this.generateBreadCrumb();
    this.dtOptions = {
      ordering: false,
      searching: false,
      paging: false
    };
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.emailScheduleId = params.get('id');
      this.getEmail(this.emailScheduleId);
    });
  }

  getEmail(emailId: string) {
    this.loading = true;

    this.emailService.getEmailSchedule(emailId).subscribe(res => {
      this.emailScheduleDetails = res.emailValues;
      this.refreshTable('#table', this.dtTrigger);
    });
  }

  viewEmailSchedule(emailScheduleDetail: IEmailScheduleDetailsValues, template: TemplateRef<any>) {
    this.emailService.getEmailScheduleSample(this.emailScheduleId, emailScheduleDetail.templateDetailValues[0].templateId,
      emailScheduleDetail.user.id).subscribe(res => {
        this.emailScheduleSample = res;

        this.modalRef = this.modalService.show(template, this.modalConfig);
      });
  }

  private generateBreadCrumb() {
    this.dynamicBreadCrumb('Communication');
  }

}
