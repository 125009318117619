import { Component, TemplateRef } from '@angular/core';
import { MessageService } from 'primeng/api';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';

import { BaseComponent } from '../../../../shared/components/base/base.component';
import { fadeInOut } from 'src/app/shared/animations/fade';
import { MaturityService } from '../../../../../shared/services/repositories/maturity.service';
import {
    MaturityStrategyView,
    MaturityStrategyMaturityPathsModel,
    MaturityStrategyPathView,
} from 'src/app/shared/models/maturity';
import { WorkflowService } from 'src/app/admin/shared/services/workflow.service';
import { _ } from '../../../../../shared/services/translation.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-maturity-strategy-maturity-paths',
    templateUrl: './maturity-strategy-maturity-paths.component.html',
    styleUrls: ['./maturity-strategy-maturity-paths.component.scss'],
    animations: [fadeInOut],
})
export class MaturityStrategyMaturityPathsComponent extends BaseComponent {
    public addModalRef: BsModalRef;
    public editModalRef: BsModalRef;
    public dtTrigger: Subject<any> = new Subject();

    public maturityStrategyId: string;
    public maturityStrategy: MaturityStrategyView;
    public maturityId: string;
    public maturityPaths: MaturityStrategyMaturityPathsModel =
        new MaturityStrategyMaturityPathsModel();
    public maturityPathToAdd: MaturityStrategyPathView = new MaturityStrategyPathView();

    constructor(
        private maturityService: MaturityService,
        private messageService: MessageService,
        private modalService: BsModalService,
        public router: Router,
        public route: ActivatedRoute,
        protected workflowService: WorkflowService,
        private translate: TranslateService
    ) {
        super(router, workflowService);
        // disable order in specific columns
        this.dtOptions.columnDefs = [
            { orderable: false, targets: 2 },
            { orderable: false, targets: 3 },
            { orderable: false, targets: 4 },
        ];

        this.route.paramMap.subscribe((params) => {
            const id = params.get('id');
            const maturityId = params.get('maturityid');
            if (id && maturityId) {
                this.maturityStrategyId = id;
                this.maturityId = maturityId;
                this.getMaturityStrategyMaturities();
            }
        });
    }

    getMaturityStrategyMaturities() {
        this.loading = true;
        this.maturityService
            .getMaturityStraregyMaturitityPaths(this.maturityStrategyId, this.maturityId)
            .subscribe((res) => {
                this.maturityPaths = res;
                this.refreshTable('#table', this.dtTrigger);
                this.maturityService.getMaturityStraregy(this.maturityStrategyId).subscribe((x) => {
                    this.maturityStrategy = x;
                    this.generateBreadCrumb();
                });
            });
    }

    //#region remove
    remove(toMaturityId: string) {
        this.maturityService
            .removeMaturityStrategyMaturityPath(
                this.maturityStrategyId,
                this.maturityId,
                toMaturityId
            )
            .subscribe(
                () => {
                    this.messageService.add({
                        severity: 'success',
                        summary: this.translate.instant(_('Success!')),
                        detail: this.translate.instant(_('Path removed with success!')),
                    });
                },
                (error) => {
                    return console.log('Error: ', error);
                },
                () => {
                    return this.getMaturityStrategyMaturities();
                }
            );
    }

    //#region add
    addPathModal(modal: TemplateRef<any>) {
        this.maturityPathToAdd = new MaturityStrategyPathView();
        this.maturityPathToAdd.maturityStrategyId = this.maturityStrategyId;
        this.maturityPathToAdd.fromMaturityId = this.maturityId;

        this.maturityPaths.listOfConditions.forEach((x) => {
            return (x.checked = false);
        });
        this.addModalRef = this.modalService.show(modal, this.modalConfig);
    }

    addPath() {
        this.maturityPathToAdd.conditions = this.maturityPaths.listOfConditions
            .filter((x) => {
                return x.checked;
            })
            .map((x) => {
                return x.name;
            });
        this.maturityService.addMaturityStrategyMaturityPath(this.maturityPathToAdd).subscribe(
            () => {
                this.messageService.add({
                    severity: 'success',
                    summary: this.translate.instant(_('Success!')),
                    detail: this.translate.instant(_('Path created with success!')),
                });
            },
            (error) => {
                return console.log('Error: ', error);
            },
            () => {
                return this.getMaturityStrategyMaturities();
            }
        );
        this.addModalRef.hide();
    }

    //#region edit
    editPathModal(maturityPath: MaturityStrategyPathView, modal: TemplateRef<any>) {
        this.maturityPathToAdd = new MaturityStrategyPathView();
        this.maturityPathToAdd.maturityStrategyId = this.maturityStrategyId;
        this.maturityPathToAdd.fromMaturityId = this.maturityId;
        this.maturityPathToAdd.toMaturityId = maturityPath.toMaturityId;
        this.maturityPathToAdd.adminOnly = maturityPath.adminOnly;
        this.maturityPathToAdd.validationRequired = maturityPath.validationRequired;
        if (maturityPath.conditions) {
            this.maturityPaths.listOfConditions.forEach((x) => {
                return (x.checked = maturityPath.conditions.some((y) => {
                    return y === x.id;
                }));
            });
        }
        this.editModalRef = this.modalService.show(modal, this.modalConfig);
    }

    editPath() {
        this.maturityPathToAdd.conditions = this.maturityPaths.listOfConditions
            .filter((x) => {
                return x.checked;
            })
            .map((x) => {
                return x.id;
            });
        this.maturityService.updateMaturityStrategyMaturityPath(this.maturityPathToAdd).subscribe(
            () => {
                this.messageService.add({
                    severity: 'success',
                    summary: this.translate.instant(_('Success!')),
                    detail: this.translate.instant(_('Path updated with success!')),
                });
            },
            (error) => {
                return console.log('Error: ', error);
            },
            () => {
                return this.getMaturityStrategyMaturities();
            }
        );
        this.editModalRef.hide();
    }

    private generateBreadCrumb() {
        const elements = [
            {
                name: this.maturityStrategy.name,
                link: `maturities/maturitystrategies/${this.maturityStrategyId}/maturities`,
                isActive: true,
                subElements: [],
                permissions: null,
            },
            {
                name: this.maturityPaths.fromMaturityName,
                link: '',
                isActive: false,
                subElements: [],
                permissions: null,
            },
        ];
        this.dynamicBreadCrumb('Maturity', 'Workflows', elements);
    }
}
