import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { LayoutRootElement, FlowElement } from '../../../../shared/models/navigationElement';
import { BaseComponent } from '../base/base.component';

import { _ } from '../../../../shared/services/translation.service';
import { WorkflowService } from '../../services/workflow.service';
import { AuthService } from '../../../../shared/services/auth.service';

@Component({
    selector: 'app-layout-root',
    templateUrl: './layout-root.component.html',
    styleUrls: ['./layout-root.component.scss'],
})
export class LayoutRootComponent extends BaseComponent implements OnInit {
    public rootElement: LayoutRootElement = new LayoutRootElement();
    public actualModule: string;

    constructor(
        private route: ActivatedRoute,
        protected workflowService: WorkflowService,
        public router: Router,
        public authService: AuthService
    ) {
        super(router, workflowService);
    }

    ngOnInit() {
        this.route.paramMap.subscribe((params) => {
            const module = params.get('module');
            this.selectModule(module);
        });
    }

    private selectModule(selectedModule: string) {
        this.rootElement = this.workflowService.layoutElements.find((x) => {
            return x.type.toUpperCase() === selectedModule.toUpperCase();
        });
        if (this.rootElement === undefined) {
            this.router.navigate(['/']);
            return;
        }

        this.actualModule = selectedModule;
        this.breadCrumbElements = [];
        this.breadCrumbElements.push({
            name: this.rootElement.title,
            link: '',
            isActive: true,
            subElements: [],
            permissions: null,
        });
    }

    public navigateToModule(flowElement: FlowElement) {
        if (!flowElement.disabled) {
            this.router.navigate([
                'admin/' +
                    this.authService.selectedInstance.name +
                    '/' +
                    this.actualModule +
                    '/' +
                    flowElement.link,
            ]);
        }
    }
}
