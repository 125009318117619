import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

import { fadeInOut } from 'src/app/shared/animations/fade';
import { EffectService } from '../../../../../shared/services/repositories/effect.service';
import { EffectViewModel } from '../../../../../shared/models/effect';
import { BaseComponent } from '../../../../shared/components/base/base.component';
import { Router } from '@angular/router';
import { WorkflowService } from '../../../../shared/services/workflow.service';
import { ConfirmationDialogService } from '../../../../../shared/components/utils/confirmation/ConfirmationDialog.service';
import { MessageService } from 'primeng/api';
import { _ } from '../../../../../shared/services/translation.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-effects',
    templateUrl: './effects.component.html',
    styleUrls: ['./effects.component.scss'],
    animations: [fadeInOut],
})
export class EffectsComponent extends BaseComponent implements OnInit {
    public dtTrigger: Subject<any> = new Subject();

    public effects: EffectViewModel[];
    public effectsModel: EffectViewModel[] = [];

    constructor(
        private effectService: EffectService,
        public router: Router,
        protected workflowService: WorkflowService,
        private confirmationService: ConfirmationDialogService,
        private messageService: MessageService,
        private translate: TranslateService
    ) {
        super(router, workflowService);
        this.dtOptions = {
            ordering: false,
            searching: false,
            paging: false,
        };

        this.generateBreadCrumb();
    }

    ngOnInit() {
        this.getEffects();
    }

    private getEffects() {
        this.loading = true;
        this.effectService.getAllEffectsInTree().subscribe((res) => {
            this.effects = res;
            this.effectsModel = [];
            this.prepareEffects(this.effects);
            this.refreshTable('#table', this.dtTrigger);
        });
    }

    private prepareEffects(effects: EffectViewModel[]) {
        effects.forEach((effect) => {
            this.effectsModel.push(effect);
            if (effect.children) {
                this.prepareEffects(effect.children);
            }
        });
    }

    public getCalculation(effect: EffectViewModel): string {
        let calculation = '';
        if (effect.autoCalculated) {
            effect.calculations.sort((a, b) => {
                return b.order > a.order ? -1 : a.order > b.order ? 1 : 0;
            });
            effect.calculations.forEach((c) => {
                if (c.operator === 1) {
                    const eff = this.effectsModel.find((x) => {
                        return x.effectId.replace(/-/g, '') === c.value;
                    });
                    calculation += eff ? eff.name + ' ' : ' ';
                } else {
                    calculation += c.value + ' ';
                }
            });
        }
        return calculation;
    }

    public deleteEffect(effect: EffectViewModel) {
        this.confirmationService
            .confirm(`Do you want to delete effect: ${effect.name}?`)
            .subscribe((result) => {
                if (result) {
                    this.effectService.deleteEffect(effect.effectId).subscribe(
                        () => {
                            this.messageService.add({
                                severity: 'success',
                                summary: this.translate.instant(_('Succes!')),
                                detail: this.translate.instant(_('Effect removed with success!')),
                            });
                        },
                        (error) => {
                            return console.log('Error: ', error);
                        },
                        () => {
                            return this.getEffects();
                        }
                    );
                }
            });
    }

    private generateBreadCrumb() {
        this.dynamicBreadCrumb('Scorecard');
    }
}
