import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

import { TopService } from './../../../../../shared/services/repositories/top.service';
import {
    Scorecard,
    ScorecardDisplay,
    ScorecardFrequency,
} from './../../../../../shared/models/scorecard';
import { WorkflowService } from './../../../../shared/services/workflow.service';
import { ConfirmationDialogService } from './../../../../../shared/components/utils/confirmation/ConfirmationDialog.service';
import { fadeInOut } from './../../../../../shared/animations/fade';
import { PropertySetView } from './../../../../../shared/models/property';
import { PropertyService } from './../../../../../shared/services/repositories/property.service';
import { MaturityService } from './../../../../../shared/services/repositories/maturity.service';
import { MaturityStrategyView } from './../../../../../shared/models/maturity';
import { ScorecardService } from './../../../../../shared/services/repositories/scorecard.service';
import {
    TopLevelStructure,
    TopLevelStructureLevel,
    EditTopLevelStructureModel,
} from './../../../../../shared/models/topLevelStructure';
import { Top } from './../../../../../shared/models/top';
import { FinancialState } from './../../../../../shared/models/financialState';
import { _ } from './../../../../../shared/services/translation.service';
import { BaseComponent } from 'src/app/admin/shared/components/base/base.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-levels',
    templateUrl: './topLevelStructures.component.html',
    styleUrls: ['./topLevelStructures.component.scss'],
    animations: [fadeInOut],
})
export class TopLevelStructuresComponent extends BaseComponent implements OnInit {
    public dtTrigger: Subject<any> = new Subject();

    public topLevels: Top[];
    public selectedTopLevel: Top;
    public editModalRef: BsModalRef;
    public maturityStrategies: MaturityStrategyView[];
    public scorecardViews: Scorecard[];
    public propertySets: PropertySetView[];

    public scorecardFrequency = ScorecardFrequency;
    public scorecardFrequencyKeys;
    public periods: string[];

    public levels: TopLevelStructure[];
    public financialStates: FinancialState[];

    public editLevelModel: EditTopLevelStructureModel;
    public createLevel: boolean;

    constructor(
        public router: Router,
        public workflowService: WorkflowService,
        private topService: TopService,
        private modalService: BsModalService,
        private scorecadService: ScorecardService,
        private maturityService: MaturityService,
        private messageService: MessageService,
        private translate: TranslateService,
        private propertyService: PropertyService,
        private confirmationService: ConfirmationDialogService
    ) {
        super(router, workflowService);
        this.dtOptions = {
            ordering: false,
            searching: false,
            paging: false,
            lengthChange: false,
        };
    }

    ngOnInit() {
        this.dynamicBreadCrumb('Structure Management');
        this.getTopLevels();
        this.getFinancialStates();
        this.scorecardFrequencyKeys = Object.keys(this.scorecardFrequency);
        this.scorecardFrequencyKeys = this.scorecardFrequencyKeys.slice(
            0,
            this.scorecardFrequencyKeys.length / 2
        );
    }

    private getTopLevels() {
        this.loading = true;
        this.topService.getTops().subscribe((data) => {
            this.topLevels = data;
            if (this.topLevels.length === 1) {
                this.selectedTopLevel = this.topLevels[0];
                this.getStructure(this.selectedTopLevel.id);
            }
            this.loading = false;
        });
    }

    public hasMoreThanOneTop() {
        return this.topLevels.length > 1;
    }

    public isSystem(level: TopLevelStructure): boolean {
        return (
            level.level === TopLevelStructureLevel.topLevel ||
            level.level === TopLevelStructureLevel.milestone
        );
    }

    public getFinancialStates() {
        this.scorecadService.getFinancialStates().subscribe((states) => {
            this.financialStates = states;
        });
    }

    public getStructure(id: string) {
        this.loading = true;
        this.selectedTopLevel = this.topLevels.find((x) => {
            return x.id === id;
        });
        this.topService.getLevelsForTop(this.selectedTopLevel.id).subscribe((data) => {
            this.levels = data;
            this.refreshTable('#table', this.dtTrigger);
        });
    }

    public frequencyToString(i: number) {
        return ScorecardFrequency[i];
    }

    //#region edit
    public editLevelModal(level: TopLevelStructure, modal: TemplateRef<any>) {
        this.createLevel = false;
        this.editLevelModel = new EditTopLevelStructureModel();
        Object.assign(this.editLevelModel, level);
        this.editLevelModel.topLevelName = this.getNameOfSelectedTopLevel();
        this.editLevelModel.name = level.typeName;
        this.editLevelModel.hasFinancialStates = level.financialStates;
        this.editLevelModel.topLevelSructureId = level.id;
        this.financialStates.forEach((element) => {
            this.editLevelModel.hasFinancialStates[element.id] = level.financialStates[element.id];
        });

        this.editModalRef = this.modalService.show(modal, this.modalConfig);
        this.getMaturityStrategies();
        this.getScorecardViews();
        this.getPropertySets();
    }

    private getNameOfSelectedTopLevel(): string {
        return this.topLevels.find((x) => {
            return x.id === this.selectedTopLevel.id;
        }).name;
    }

    private getMaturityStrategies() {
        this.maturityService
            .getMaturityStraregies()
            .subscribe((maturityStrategies: MaturityStrategyView[]) => {
                this.maturityStrategies = maturityStrategies;
            });
    }

    private getScorecardViews() {
        this.scorecadService
            .getAllScorecardDisplaysForScorecard(this.selectedTopLevel.scorecardId)
            .subscribe((scorecardViews: ScorecardDisplay[]) => {
                this.scorecardViews = [];

                scorecardViews.forEach((x) => {
                    this.scorecardViews.push({
                        id: x.id,
                        name: x.name,
                        scorecardDisplays: null,
                    });
                });
            });
    }

    private getPropertySets() {
        this.propertyService.getPropertySets().subscribe((propertySets: PropertySetView[]) => {
            this.propertySets = propertySets;
        });
    }

    public editSaveLevel() {
        console.log(this.editLevelModel);
        if (this.createLevel) {
            this.topService.addLevel(this.editLevelModel).subscribe(
                () => {
                    return this.messageService.add({
                        severity: 'success',
                        summary: this.translate.instant(_('Succes!')),
                        detail: this.translate.instant(
                            _('Toplevel structure created with success!')
                        ),
                    });
                },
                (error) => {
                    return console.log('Error', error);
                },
                () => {
                    return this.getStructure(this.selectedTopLevel.id);
                }
            );
        } else {
            this.topService.updateLevel(this.editLevelModel).subscribe(
                () => {
                    return this.messageService.add({
                        severity: 'success',
                        summary: this.translate.instant(_('Succes!')),
                        detail: this.translate.instant(
                            _('Toplevel structure updated with success!')
                        ),
                    });
                },
                (error) => {
                    return console.log('Error', error);
                },
                () => {
                    return this.getStructure(this.selectedTopLevel.id);
                }
            );
        }
        this.editModalRef.hide();
    }

    public addLevelModal(modal: TemplateRef<any>) {
        this.createLevel = true;
        this.editLevelModel = new EditTopLevelStructureModel();
        this.editLevelModel.topLevelId = this.selectedTopLevel.id;
        this.financialStates.forEach((element) => {
            this.editLevelModel.hasFinancialStates[element.id] = false;
        });
        this.editModalRef = this.modalService.show(modal, this.modalConfig);
        this.getMaturityStrategies();
        this.getScorecardViews();
        this.getPropertySets();
    }

    removeLevel(level: TopLevelStructure) {
        this.confirmationService.confirm(
            _('Do you want to delete ') + level.typeName + _(' top level strcture?')
        );
        this.confirmationService
            .confirm(
                this.translate.instant(_('Do you want to delete ')) +
                    level.typeName +
                    this.translate.instant(_(' top level strcture?'))
            )
            .subscribe((result) => {
                if (result) {
                    this.topService.removeLevel(level).subscribe(
                        () => {
                            this.messageService.add({
                                severity: 'success',
                                summary: this.translate.instant(_('Succes!')),
                                detail: this.translate.instant(
                                    _('Top level structure removed with success!')
                                ),
                            });
                        },
                        (error) => {
                            return console.log('Error', error);
                        },
                        () => {
                            return this.getStructure(this.selectedTopLevel.id);
                        }
                    );
                }
            });
    }

    expandSubLevels(structure: TopLevelStructure) {
        if (structure.isExpanded) {
            structure.isExpanded = false;
            return;
        }

        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let index = 0; index < this.levels.length; index++) {
            this.levels[index].isExpanded = false;
        }

        structure.isExpanded = true;
    }
}
