import { ChangeRequestValidation } from 'src/app/mytrend/workspace/models';
import { TypeProperty, VisibilityProperty, UpdateBehavior, ApprovalStatus } from './enums';

// Property
export interface IFilterBySection {
    sectionId: string;
}

export class Property implements IFilterBySection {
    public id: string;
    public name: string;
    public displayName: string;
    public type: TypeProperty;
    public updateBehavior: UpdateBehavior;
    public visibility: VisibilityProperty;
    public sectionId: string;
    public system: boolean;
    public mandatory: boolean;
    public defaultValue: string;
    public description: string;
    public phases: string;

    // Helper - just used to create node
    public currentValue: any;

    // Helper - just used to Change Request
    public changeRequestValidation?: ChangeRequestValidation;

    public static get MATURITY_ID(): string {
        return '00000000-0000-0000-0002-000000000000';
    }
    public static get NAME_ID(): string {
        return '00000000-0000-0000-0013-000000000000';
    }
    public static get PROGRESS_ID(): string {
        return '00000000-0000-0000-0003-000000000000';
    }
    public static get CODE_ID(): string {
        return '00000000-0000-0000-0014-000000000000';
    }
    public static get START_DATE_ID(): string {
        return '00000000-0000-0000-0006-000000000000';
    }
    public static get END_DATE_ID(): string {
        return '00000000-0000-0000-0009-000000000000';
    }
}

// Units
export class Unit {
    public id: string;
    public name: string;
    public mask: string;
    public symbol: string;
}

// Property Values
export class PropertyValue {
    treeNodeId: string;
    propertyId: string;
    approvalStatus: ApprovalStatus;
    displayName: string;
    name: string;
    type: TypeProperty;
    updateBehavior: UpdateBehavior;
    visibility: VisibilityProperty;
    sectionId: string;
    isSynchronized: boolean;
    comment: string;
    pendingValidationId: string;
    validationUserId: string;
    validationDate: Date;
    initialValue: any;
    currentValue: any;
    revisedValue: any;
    lastUpdateDate: Date;

    //helper
    requireValidation: boolean;

    /**
     * Creates an instance of property value.
     * @param property
     */
    constructor(property) {
        Object.assign(this, property);
    }

    /**
     * Sorts property value
     * @param propertyToCompare
     * @returns sort
     */
    sort?(propertyToCompare): number {
        switch (this.type) {
            case TypeProperty.String:
            case TypeProperty.Date:
            case TypeProperty.Text: {
                return this.compareStrings(this.currentValue, propertyToCompare?.currentValue);
            }
            case TypeProperty.List:
            case TypeProperty.ImageList: {
                return this.compareStrings(
                    this.currentValue?.value,
                    propertyToCompare?.currentValue?.value
                );
            }
            case TypeProperty.Group: {
                return this.compareStrings(
                    this.currentValue?.name,
                    propertyToCompare?.currentValue?.name
                );
            }
            case TypeProperty.Number:
            case TypeProperty.Boolean:
            case TypeProperty.StatCompleted: {
                return this.compareNumbers(+this.currentValue, +propertyToCompare?.currentValue);
            }
            case TypeProperty.User: {
                return this.compareNumbers(
                    +this.currentValue?.users?.length,
                    +propertyToCompare?.currentValue?.users?.length
                );
            }
        }
    }

    /**
     * Compares numbers
     * @param first
     * @param second
     * @returns numbers
     */
    compareNumbers?(first: number, second: number): number {
        if (first === second) {
            return 0;
        }

        return first - second < 0 ? -1 : 1;
    }

    comparePercentage?(first: number, second: number): number {
        if (first === second) {
            return 0;
        }

        return first - second < 0 ? -1 : 1;
    }

    /**
     * Compares strings
     * @param [first]
     * @param [second]
     * @returns strings
     */
    compareStrings?(first = '', second = ''): number {
        if (this.type === TypeProperty.Text) {
            return this.removeHtmlTags(first)?.localeCompare(this.removeHtmlTags(second));
        }

        return first?.localeCompare(second);
    }

    /**
     * Removes html tags
     * @param htmlText
     * @returns html tags
     */
    removeHtmlTags?(htmlText: string): string {
        if (htmlText) {
            return htmlText.replace(/<[^>]*>?/g, ' ');
        }

        return null;
    }

    /**
     * Formats image url
     * @param url
     * @returns image url
     */
    formatImageUrl?(url: string): string {
        const hasHttp = /^http(?:s)?:\/{2}\S+$/.test(url);

        if (hasHttp) {
            return url;
        }

        return `//${url}`;
    }
}

export class UpdatePropertyValue {
    value: string;
    comment: string;
    system?: boolean;
    propertyId: string;

    constructor(obj: any) {
        Object.assign(this, obj);
    }
}

// User Property
export class UserGroupProperty {
    propertyId: string;
    treeNodeId: string;
    treeNodeName: string;
    treeNodeCode: string;
    structureName: string;
    propertyDisplayName: string;
    isGroup: boolean;
    isSystemGroup: boolean;
    isDeleted: boolean;
    propertyType: string;
}

// Property Set
export class PropertySetProperty implements IFilterBySection {
    public id: string;
    public propertySetId: string;
    public propertyId: string;
    public order: number;
    public isUpdateRequired: boolean;
    public system: boolean;
    public name: string;
    public displayName: string;
    public type: TypeProperty;
    public updateBehavior: UpdateBehavior;
    public visibility: VisibilityProperty;
    public sectionId: string;
    public sectionName: string;
    public systemProperty: boolean;
    public requiredProperty: boolean;
}

export class PropertySet {
    public propertySetId: string;
    public name: string;
    public system: boolean;
    public propertySetProperties: PropertySetProperty[];
}

export class PropertySetView {
    public propertySetId: string;
    public name: string;
    public system: boolean;
    public numberOfProperties: number;
    public numberOfTopLevelStructures: number;
}

export class GpspPropertySetDisplay {
    public id: string;
    public propertySetId: string;
    public name: string;
    public system: boolean;
}

export class PropertySetDisplayModel {
    public id: string;
    public name: string;
    public propertySetId: string;
    public propertySetProperties: PropertySetProperty[];
    public propertySetDisplayProperties: { [index: string]: boolean } = {};
}

export class PropertySetPropertyDisplay {
    public id: string;
    public propertySetDisplayId: string;
    public propertyId: string;
}

// Section
export class Section {
    public id: string;
    public name: string;
    public order: number;
    public system: boolean;
    public parentSectionId: string;

    // view helper
    public parentSectionName: string;
}

// Flag
export class Flag {
    public id: string;
    public name: string;
    public numberOfTreeNodes: number;
}

// acess rights
export class RoleRightModel {
    public id: string;
    public description: string;
    public displayName: string;
    public permission: number;
    public checked: boolean;
}

export class AccessRight {
    public propertyId: string;
    public editAccessRights: boolean;
    public roles: RoleRightModel[];
    public rights: RoleRightModel[];
}

// List values
export class PropertyListValue {
    public id: string;
    public propertyId: string;
    public value: string;
    public order: number;
    public asset: string;
    public default: boolean;
    public imageUrl: string;

    public contentType: string;
    public content: string | ArrayBuffer;
    public contentFileName: string;

    // interface helper
    data: string | ArrayBuffer;
}

export enum SystemProperty {
    METEO = '00000000-0000-0000-0001-000000000000',
    MATURITY = '00000000-0000-0000-0002-000000000000',
    PROGRESS = '00000000-0000-0000-0003-000000000000',
    DESCRIPTION = '00000000-0000-0000-0004-000000000000',
    START_DATE = '00000000-0000-0000-00006-000000000000',
    TREND = '00000000-0000-0000-0007-000000000000',
    END_DATE = '00000000-0000-0000-0009-000000000000',
    HIDDEN_PROGRESS = '00000000-0000-0000-0011-000000000000',
    MILESTONE_ACHIEVEMENT_DATE = '00000000-0000-0000-0012-000000000000',
    NAME = '00000000-0000-0000-0013-000000000000',
    CODE = '00000000-0000-0000-0014-000000000000',
    SYNCHRONIZE_ID = '00000000-0000-0000-0015-000000000000',
}
