import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { MessageService } from 'primeng/api';
import { finalize } from 'rxjs/operators';

import { _ } from '../../../../../shared/services/translation.service';
import { ConfigService } from '../../../../../shared/services/repositories/config.service';
import { TranslateService } from '@ngx-translate/core';
import { Config } from 'protractor';
import { slideInOut } from 'src/app/shared/animations/slideInOut';
import { ConfigType } from 'src/app/shared/models/enums';
import { BreadcrumElement } from '../../../../../shared/models/navigationElement';

@Component({
    selector: 'app-configuration',
    templateUrl: './configuration.component.html',
    styleUrls: ['./configuration.component.scss'],
    animations: [slideInOut],
})
export class ConfigurationComponent implements OnInit {
    public configs: Config[] = [];
    public dangerousConfigs: Config[] = [];
    public dbConfigs: string[] = []; // to prevent only update changed values

    public ConfigType = ConfigType;
    public showDangerousContent = false;
    public saving = false;

    public breadCrumbElements: BreadcrumElement[] = [];

    constructor(
        private configService: ConfigService,
        private messageService: MessageService,
        private translate: TranslateService
    ) {
        this.breadCrumbElements.push({
            isActive: false,
            link: '',
            name: 'Config Page',
            subElements: [],
            permissions: null,
        });
    }

    ngOnInit() {
        this.getConfigurations();
    }

    getConfigurations() {
        this.showDangerousContent = false;
        this.configs = [];
        this.dbConfigs = [];
        this.dangerousConfigs = [];
        this.configService
            .getConfigs()
            .pipe()
            .subscribe((res) => {
                res.forEach((element) => {
                    if (element.type === this.ConfigType.Array) {
                        const elements: string[] = JSON.parse(element.value);
                        element.listElements = elements;
                    }
                    element.dangerous
                        ? this.dangerousConfigs.push(element)
                        : this.configs.push(element);
                    this.dbConfigs[element.key] = element.value;
                });
            });
    }

    save() {
        const requests = [];
        this.saving = true;
        this.configs.forEach((x) => {
            if (x.type === this.ConfigType.Array) {
                x.value = JSON.stringify(x.listElements);
            }
            if (this.dbConfigs[x.key] !== x.value) {
                requests.push(this.configService.updateConfig(x.key, x.value));
            }
        });

        if (requests.length > 0) {
            forkJoin(requests)
                .pipe(
                    finalize(() => {
                        this.saving = false;
                    })
                )
                .subscribe(
                    () => {
                        this.messageService.add({
                            severity: 'success',
                            summary: this.translate.instant(_('Success!')),
                            detail: this.translate.instant(
                                _('Configuration updated with success!')
                            ),
                        });
                    },
                    (error) => {
                        this.messageService.add({
                            severity: 'error',
                            summary: this.translate.instant(_('error!')),
                            detail: error,
                        });
                        console.log('Error: ', error);
                    },
                    () => {
                        return this.getConfigurations();
                    }
                );
        } else {
            this.messageService.add({
                severity: 'info',
                summary: this.translate.instant(_('Info!')),
                detail: this.translate.instant(_('Nothing to update!')),
            });
            this.saving = false;
        }
    }

    addArrayElementToList(config: Config) {
        config.listElements.push('');
    }

    removeElementFromList(config: Config, index: number) {
        config.listElements.splice(index, 1);
    }

    // helper in primitive objects *ngFor
    trackByIdx(index: number, obj: any): any {
        return index;
    }
}
