import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// Models
import { PendingValidation } from 'src/app/mytrend/workspace/models';

// Services
import { ValidationService } from 'src/app/mytrend/workspace/views/update-manager/services';
import { AuthService } from 'src/app/shared/services';

@Component({
    selector: 'app-top-bar-update-manager',
    templateUrl: './top-bar-update-manager.component.html',
    styleUrls: ['./top-bar-update-manager.component.scss'],
})
export class TopBarUpdateManagerComponent implements OnInit {
    /**
     * Validations  of top bar update manager component
     */
    public validations: PendingValidation[] = null;

    /**
     * Creates an instance of top bar update manager component.
     * @param router
     * @param validationService
     * @param authService
     */
    constructor(
        private readonly router: Router,
        private readonly validationService: ValidationService,
        private readonly authService: AuthService
    ) {}

    public ngOnInit(): void {
        this.validationService
            .getUpdateManagerValidations()
            .subscribe((validations: PendingValidation[]) => {
                this.validations = this.loadGroup(validations);
            });
    }

    /**
     * Loads group
     * @param elements
     * @returns group
     */
    private loadGroup(elements: PendingValidation[]): PendingValidation[] {
        const group = elements.reduce((r, a) => {
            r[a.treeNodeId] = r[a.treeNodeId] || [];
            r[a.treeNodeId].push(a);
            return r;
        }, Object.create(null));

        return Object.values(group);
    }

    /**
     * Redirects to update manager
     */
    public redirectToUpdateManager(): void {
        this.router.navigate([
            `${this.authService.selectedInstance.name}/treenode/${this.validations[0][0].parents[0].id}/update-manager`,
        ]);
    }
}
