import { Component, OnInit, TemplateRef } from '@angular/core';
import { Subject } from 'rxjs';
import { MessageService } from 'primeng/api';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { BaseComponent } from '../../../../shared/components/base/base.component';
import { PropertyService } from '../../../../../shared/services/repositories/property.service';
import { fadeInOut } from 'src/app/shared/animations/fade';
import { Flag } from '../../../../../shared/models/property';
import { ConfirmationDialogService } from '../../../../../shared/components/utils/confirmation/ConfirmationDialog.service';
import { Router } from '@angular/router';
import { WorkflowService } from '../../../../shared/services/workflow.service';
import { _ } from '../../../../../shared/services/translation.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-flags',
    templateUrl: './flags.component.html',
    styleUrls: ['./flags.component.scss'],
    animations: [fadeInOut],
})
export class FlagsComponent extends BaseComponent implements OnInit {
    public addModalRef: BsModalRef;
    public editModalRef: BsModalRef;

    public dtTrigger: Subject<any> = new Subject();

    public flags: Flag[] = [];
    public flag: Flag = new Flag();

    constructor(
        private propertyService: PropertyService,
        private confirmationService: ConfirmationDialogService,
        private messageService: MessageService,
        private modalService: BsModalService,
        public router: Router,
        protected workflowService: WorkflowService,
        private translate: TranslateService
    ) {
        super(router, workflowService);
        // disable order in specific columns
        this.dtOptions.columnDefs = [{ orderable: false, targets: 2 }];
        this.generateBreadCrumb();
    }

    ngOnInit() {
        this.getFlags();
    }

    getFlags() {
        this.loading = true;
        this.propertyService.getFlags().subscribe((res) => {
            this.flags = res;
            this.refreshTable('#table', this.dtTrigger);
        });
    }

    //#region remove
    delete(flag: Flag) {
        this.confirmationService
            .confirm(`Do you want to delete Flag: ${flag.name}?`)
            .subscribe((result) => {
                if (result) {
                    this.propertyService.deleteFlag(flag.id).subscribe(
                        () => {
                            this.messageService.add({
                                severity: 'success',
                                summary: this.translate.instant(_('Success!')),
                                detail: this.translate.instant(_('Flag removed with success!')),
                            });
                        },
                        (error) => {
                            return console.log('Error: ', error);
                        },
                        () => {
                            return this.getFlags();
                        }
                    );
                }
            });
    }

    //#region add
    addFlagModal(modal: TemplateRef<any>) {
        this.flag = new Flag();
        this.addModalRef = this.modalService.show(modal, this.modalConfig);
    }

    addFlag() {
        this.propertyService.setFlag(this.flag).subscribe(
            () => {
                this.messageService.add({
                    severity: 'success',
                    summary: this.translate.instant(_('Success!')),
                    detail: this.translate.instant(_('Flag added with success!')),
                });
            },
            (error) => {
                return console.log('Error: ', error);
            },
            () => {
                return this.getFlags();
            }
        );
        this.addModalRef.hide();
    }

    //#region edit
    editFlagModal(flag: Flag, modal: TemplateRef<any>) {
        this.flag = { id: flag.id, name: flag.name, numberOfTreeNodes: flag.numberOfTreeNodes };
        this.editModalRef = this.modalService.show(modal, this.modalConfig);
    }

    editFlag() {
        this.propertyService.updateFlag(this.flag.id, this.flag).subscribe(
            () => {
                this.messageService.add({
                    severity: 'success',
                    summary: this.translate.instant(_('Success!')),
                    detail: this.translate.instant(_('Flag updated with success!')),
                });
            },
            (error) => {
                return console.log('Error: ', error);
            },
            () => {
                return this.getFlags();
            }
        );
        this.editModalRef.hide();
    }

    private generateBreadCrumb() {
        this.dynamicBreadCrumb('Node Configuration');
    }
}
