import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { JobService } from '../../../../../shared/services/repositories/job.service';
import { BaseImportReport } from '../../../../shared/models/BaseImportReport';
import { IJob } from '../../../../../shared/models/job';
import { BaseComponent } from '../../../../shared/components/base/base.component';
import { fadeInOut } from 'src/app/shared/animations/fade';
import { _ } from '../../../../../shared/services/translation.service';
import { WorkflowService } from '../../../../shared/services/workflow.service';


@Component({
  selector: 'app-job',
  templateUrl: './job.component.html',
  styleUrls: ['./job.component.scss'],
  animations: [fadeInOut]
})
export class JobComponent extends BaseComponent implements OnInit {

  public reports: BaseImportReport;
  public job: IJob;


  constructor(private jobService: JobService, private route: ActivatedRoute, public router: Router,
              protected workflowService: WorkflowService) {
    super(router, workflowService);

  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      const id = params.get('id');
      this.getJob(id);
      this.getJobImportReport(id);
    });
  }

  getJob(id: string) {
    this.jobService.getJob(id).subscribe(res => {
      this.job = res;
      this.generateBreadCrumb();
      this.loading = false;
    });
  }

  getJobImportReport(jobId: string) {
    this.loading = true;
    this.jobService.getJobImportReport(jobId).subscribe(res => {
      this.reports = res;
      this.loading = false;
    });
  }

  private generateBreadCrumb() {
    this.dynamicBreadCrumb('Monitoring');
  }

}
