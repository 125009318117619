import { Component, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MessageService } from 'primeng/api';
import { forkJoin } from 'rxjs';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

import { EmailService } from '../../../../../shared/services/repositories/email.service';
import { EmailTemplate, GlobalMailTemplate } from '../../../../shared/models/email';
import { BaseComponent } from '../../../../shared/components/base/base.component';
import { _ } from '../../../../../shared/services/translation.service';
import { WorkflowService } from '../../../../shared/services/workflow.service';
import { AuthService } from '../../../../../shared/services/auth.service';


@Component({
    selector: 'app-email-template',
    templateUrl: './email-template.component.html',
    styleUrls: ['./email-template.component.scss'],
})
export class EmailTemplateComponent extends BaseComponent implements OnInit {
    modalRef: BsModalRef;

    public emailTemplate: EmailTemplate = new EmailTemplate();
    public emailTemplateSample: any;

    public types: GlobalMailTemplate[];

    constructor(
        private emailService: EmailService,
        private route: ActivatedRoute,
        private messageService: MessageService,
        public router: Router,
        private modalService: BsModalService,
        public authService: AuthService,
        private location: Location,
        protected workflowService: WorkflowService,
        private translate: TranslateService
    ) {
        super(router, workflowService);
    }

    ngOnInit() {
        this.route.paramMap.subscribe((params) => {
            const id = params.get('id');
            this.getEmailTemplate(id);
        });
    }

    getEmailTemplate(id: string) {
        forkJoin(
            this.emailService.getEmailTemplate(id),
            this.emailService.getGlobalMailTemplates()
        ).subscribe((res) => {
            this.emailTemplate = res[0];
            this.types = res[1];
            this.generateBreadCrumb();
        });
    }

    save() {
        if (this.emailTemplate.name !== null && this.emailTemplate.name.match(/^ *$/) === null) {
            this.emailService
                .updateEmailTemplate(this.emailTemplate.id, this.emailTemplate)
                .subscribe(
                    () => {
                        this.messageService.add({
                            severity: 'success',
                            summary: this.translate.instant(_('Success!')),
                            detail: this.translate.instant(
                                _('Email template updated with success!')
                            ),
                        });
                        this.goBack();
                    },
                    (error) => {
                        return console.log(this.translate.instant(_('Error: '), error), () => {
                            return this.getEmailTemplate(this.emailTemplate.id);
                        });
                    }
                );
        }
    }

    testEmailTemplate(template: TemplateRef<any>) {
        this.emailService.getMailTemplateSampleFromTemplate(this.emailTemplate).subscribe((res) => {
            this.emailTemplateSample = res;
            this.modalRef = this.modalService.show(template, this.modalConfig);
        });
    }

    public goBack() {
        this.location.back();
    }

    private generateBreadCrumb() {
        this.dynamicBreadCrumb('Communication', 'Emails', [
            { name: 'Email', link: '', isActive: false, subElements: [], permissions: null },
        ]);
    }
}
