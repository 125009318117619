import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpExtendedInstanceService } from 'src/app/shared/services';

import {
    Maturity,
    MaturityStrategyView,
    AddMaturityToMaturityStrategyModel,
    MaturityStrategyMaturityPathsModel,
    MaturityStrategyPathModel,
} from '../../models/maturity';

@Injectable({
    providedIn: 'root',
})
export class MaturityService {
    constructor(private http: HttpExtendedInstanceService) {}

    // maturity
    public getMaturities(): Observable<Maturity[]> {
        return this.http.get<Maturity[]>('maturities');
    }

    public getMaturity(id: string): Observable<Maturity> {
        return this.http.get<Maturity>(`maturities/${id}`);
    }

    public setMaturity(maturity: Maturity): Observable<Maturity> {
        return this.http.post<Maturity>(`maturities`, maturity);
    }

    public updateMaturity(id: string, maturity: Maturity): Observable<Maturity> {
        return this.http.put<Maturity>(`maturities/${id}`, maturity);
    }

    public deleteMaturity(id: string) {
        return this.http.delete(`maturities/${id}`);
    }

    // maturity strategies
    public getMaturityStraregies(): Observable<MaturityStrategyView[]> {
        return this.http.get<MaturityStrategyView[]>('maturitystrategies');
    }

    public getMaturityStraregy(id: string): Observable<MaturityStrategyView> {
        return this.http.get<MaturityStrategyView>(`maturitystrategies/${id}`);
    }

    public setMaturityStraregy(name: string): Observable<MaturityStrategyView> {
        return this.http.post<MaturityStrategyView>('maturitystrategies', { name });
    }

    public updateMaturityStraregy(id: string, name: string): Observable<MaturityStrategyView> {
        return this.http.put<MaturityStrategyView>(`maturitystrategies/${id}`, { name });
    }

    public deleteMaturityStrategy(id: string) {
        return this.http.delete(`maturityStrategies/${id}`);
    }

    // maturity strategies maturities
    public getMaturityStraregyMaturitities(
        matutityStrategyId: string
    ): Observable<AddMaturityToMaturityStrategyModel> {
        return this.http.get<AddMaturityToMaturityStrategyModel>(
            `maturitystrategies/${matutityStrategyId}/maturities`
        );
    }

    public addMaturityStrategyMaturity(
        addMaturityToMaturityStrategyModel: AddMaturityToMaturityStrategyModel
    ): Observable<AddMaturityToMaturityStrategyModel> {
        return this.http.post<AddMaturityToMaturityStrategyModel>(
            `maturityStrategyMaturities`,
            addMaturityToMaturityStrategyModel
        );
    }

    public updateMaturityStrategyMaturity(
        addMaturityToMaturityStrategyModel: AddMaturityToMaturityStrategyModel
    ): Observable<AddMaturityToMaturityStrategyModel> {
        return this.http.put<AddMaturityToMaturityStrategyModel>(
            `maturityStrategyMaturities`,
            addMaturityToMaturityStrategyModel
        );
    }

    public removeMaturityStrategyMaturity(maturityStrategyId: string, maturtityId: string) {
        return this.http.delete(
            `maturityStrategies/${maturityStrategyId}/maturities/${maturtityId}`
        );
    }

    // maturity strategies maturities paths
    public getMaturityStraregyMaturitityPaths(
        matutityStrategyId: string,
        maturityId: string
    ): Observable<MaturityStrategyMaturityPathsModel> {
        return this.http.get<MaturityStrategyMaturityPathsModel>(
            `maturitystrategies/${matutityStrategyId}/maturities/${maturityId}/paths`
        );
    }

    public addMaturityStrategyMaturityPath(
        addMaturityStrategyPathModel: MaturityStrategyPathModel
    ) {
        return this.http.post(`maturityStrategyPaths`, addMaturityStrategyPathModel);
    }

    public updateMaturityStrategyMaturityPath(
        addMaturityStrategyPathModel: MaturityStrategyPathModel
    ) {
        return this.http.put(`maturityStrategyPaths`, addMaturityStrategyPathModel);
    }

    public removeMaturityStrategyMaturityPath(
        maturityStrategyId: string,
        maturityId: string,
        toMaturityId: string
    ) {
        return this.http.delete(
            `maturitystrategies/${maturityStrategyId}/maturities/${maturityId}/paths/${toMaturityId}`
        );
    }
}
