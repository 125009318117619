import { EventEmitter, Injectable } from '@angular/core';

// Models
import { Notification } from '../../models';

// Rxjs
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// Services
import { HttpExtendedInstanceService } from '..';
import { UpdateTypes } from '../../models/enums';
import { TreeNode } from '../../models/treenode';

@Injectable({
    providedIn: 'root',
})
export class NotificationService {

       /**
     * Creates an instance of notification service.
     * @param httpInstance
     */
    constructor(private readonly httpInstance: HttpExtendedInstanceService) { }

    /**
     * Gets total not viewed
     * @param instance
     * @returns total not viewed
     */
    public getTotalNotViewed(): Observable<number> {
        return this.httpInstance.get<Notification[]>(`validations/numbers`).pipe(
            map((response: Notification[]) => {
                return response.reduce((total, notifications) => {
                    return total + notifications.count;
                }, 0);
            })
        );
    }

    /**
 * Gets total not viewed
 * @param instance
 * @returns total not viewed
 */
    public getTotalNodesToUpdate(type: UpdateTypes): Observable<string[]> {
        return this.httpInstance.get<string[]>(`treenodes/updateIds/${type}`);
    }

    /**
* Gets total not viewed
* @param instance
* @returns total not viewed
*/
    public getNodesToUpdate(type: UpdateTypes): Observable<TreeNode[]> {
        return this.httpInstance.get<TreeNode[]>(`treenodes/null/users/null/updates/${type}`);
    }

}
