import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { MessageService } from 'primeng/api';
import '../../../../../shared/services/extended-tools';

import { WorkflowService } from '../../../../shared/services/workflow.service';
import { BaseComponent } from '../../../../shared/components/base/base.component';
import { EditTopLevelDates, Top } from '../../../../../shared/models/top';
import { TopService } from '../../../../../shared/services/repositories/top.service';
import { _ } from '../../../../../shared/services/translation.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-update-period',
    templateUrl: './update-period.component.html',
    styleUrls: ['./update-period.component.scss'],
})
export class UpdatePeriodComponent extends BaseComponent implements OnInit {
    public topLevels: Top[];
    public selectedTopLevel: Top;
    public now = new Date();

    // define min/max dates
    minMaxstartOfProgressUpdatePeriod: Date;
    minMaxstartOfFinancialUpdatePeriod: Date;
    minMaxnextStatusUpdateFirstDate: Date;
    minMaxnextScorecardUpdateFirstDate: Date;
    minMaxnextStatusUpdateSecondDate: Date;
    minMaxnextScorecardUpdateSecondDate: Date;

    constructor(
        public router: Router,
        private workflowService: WorkflowService,
        private topService: TopService,
        private location: Location,
        private messageService: MessageService,
        private translate: TranslateService
    ) {
        super(router, workflowService);
    }

    ngOnInit() {
        this.generateBreadCrumb();
        this.getTopLevels();
    }

    private getTopLevels() {
        this.loading = true;
        this.topService.getTops().subscribe((data) => {
            this.topLevels = data;
            if (this.selectedTopLevel) {
                this.topChanged(this.selectedTopLevel.id);
                return;
            }

            if (this.topLevels.length === 1) {
                this.topChanged(this.topLevels[0].id);
            }
        });
    }

    private topChanged(topId: string) {
        this.selectedTopLevel = new Top();
        Object.assign(
            this.selectedTopLevel,
            this.topLevels.find((x) => {
                return x.id === topId;
            })
        );
        // Had to reset Date as date otherwise invalid date for the bsDatepicker
        this.selectedTopLevel.startOfProgressUpdatePeriod = this.selectedTopLevel
            .startOfProgressUpdatePeriod
            ? new Date(this.selectedTopLevel.startOfProgressUpdatePeriod)
            : null;
        this.selectedTopLevel.endOfProgressUpdatePeriod = this.selectedTopLevel
            .endOfProgressUpdatePeriod
            ? new Date(this.selectedTopLevel.endOfProgressUpdatePeriod)
            : null;
        this.selectedTopLevel.nextStatusUpdateFirstDate = this.selectedTopLevel
            .nextStatusUpdateFirstDate
            ? new Date(this.selectedTopLevel.nextStatusUpdateFirstDate)
            : null;
        this.selectedTopLevel.nextStatusUpdateSecondDate = this.selectedTopLevel
            .nextStatusUpdateSecondDate
            ? new Date(this.selectedTopLevel.nextStatusUpdateSecondDate)
            : null;
        this.selectedTopLevel.nextStatusUpdateThirdDate = this.selectedTopLevel
            .nextStatusUpdateThirdDate
            ? new Date(this.selectedTopLevel.nextStatusUpdateThirdDate)
            : null;

        this.selectedTopLevel.startOfFinancialUpdatePeriod = this.selectedTopLevel
            .startOfFinancialUpdatePeriod
            ? new Date(this.selectedTopLevel.startOfFinancialUpdatePeriod)
            : null;
        this.selectedTopLevel.endOfFinancialUpdatePeriod = this.selectedTopLevel
            .endOfFinancialUpdatePeriod
            ? new Date(this.selectedTopLevel.endOfFinancialUpdatePeriod)
            : null;
        this.selectedTopLevel.nextScorecardUpdateFirstDate = this.selectedTopLevel
            .nextScorecardUpdateFirstDate
            ? new Date(this.selectedTopLevel.nextScorecardUpdateFirstDate)
            : null;
        this.selectedTopLevel.nextScorecardUpdateSecondDate = this.selectedTopLevel
            .nextScorecardUpdateSecondDate
            ? new Date(this.selectedTopLevel.nextScorecardUpdateSecondDate)
            : null;
        this.selectedTopLevel.nextScorecardUpdateThirdDate = this.selectedTopLevel
            .nextScorecardUpdateThirdDate
            ? new Date(this.selectedTopLevel.nextScorecardUpdateThirdDate)
            : null;

        this.onDateChange();
    }

    public save() {
        const topLevelDates = new EditTopLevelDates();
        topLevelDates.topLevelId = this.selectedTopLevel.id;
        topLevelDates.name = this.selectedTopLevel.name;
        topLevelDates.startOfProgressUpdatePeriod =
            (this.selectedTopLevel.startOfProgressUpdatePeriod &&
                this.selectedTopLevel.startOfProgressUpdatePeriod.toCustomString()) ||
            null;
        topLevelDates.endOfProgressUpdatePeriod =
            (this.selectedTopLevel.endOfProgressUpdatePeriod &&
                this.selectedTopLevel.endOfProgressUpdatePeriod.toCustomString()) ||
            null;
        topLevelDates.nextStatusUpdateFirstDate =
            (this.selectedTopLevel.nextStatusUpdateFirstDate &&
                this.selectedTopLevel.nextStatusUpdateFirstDate.toCustomString()) ||
            null;
        topLevelDates.nextStatusUpdateSecondDate =
            (this.selectedTopLevel.nextStatusUpdateSecondDate &&
                this.selectedTopLevel.nextStatusUpdateSecondDate.toCustomString()) ||
            null;
        topLevelDates.nextStatusUpdateThirdDate =
            (this.selectedTopLevel.nextStatusUpdateThirdDate &&
                this.selectedTopLevel.nextStatusUpdateThirdDate.toCustomString()) ||
            null;

        topLevelDates.startOfFinancialUpdatePeriod =
            (this.selectedTopLevel.startOfFinancialUpdatePeriod &&
                this.selectedTopLevel.startOfFinancialUpdatePeriod.toCustomString()) ||
            null;
        topLevelDates.endOfFinancialUpdatePeriod =
            (this.selectedTopLevel.endOfFinancialUpdatePeriod &&
                this.selectedTopLevel.endOfFinancialUpdatePeriod.toCustomString()) ||
            null;
        topLevelDates.nextScorecardUpdateFirstDate =
            (this.selectedTopLevel.nextScorecardUpdateFirstDate &&
                this.selectedTopLevel.nextScorecardUpdateFirstDate.toCustomString()) ||
            null;
        topLevelDates.nextScorecardUpdateSecondDate =
            (this.selectedTopLevel.nextScorecardUpdateSecondDate &&
                this.selectedTopLevel.nextScorecardUpdateSecondDate.toCustomString()) ||
            null;
        topLevelDates.nextScorecardUpdateThirdDate =
            (this.selectedTopLevel.nextScorecardUpdateThirdDate &&
                this.selectedTopLevel.nextScorecardUpdateThirdDate.toCustomString()) ||
            null;

        this.topService.updateTopLevelDates(topLevelDates).subscribe(
            () => {
                this.messageService.add({
                    severity: 'success',
                    summary: this.translate.instant(_('Succes!')),
                    detail: this.translate.instant(_('Top Level Dates updated with success!')),
                });
                this.getTopLevels();
            },
            () => {
                this.messageService.add({
                    severity: 'error',
                    summary: this.translate.instant(_('Error!')),
                    detail: this.translate.instant(_('Error updating Top Level Dates!')),
                });
            }
        );
    }

    public onDateChange() {
        // update min and max dates ( add 1 day )
        this.minMaxstartOfProgressUpdatePeriod = this.selectedTopLevel.startOfProgressUpdatePeriod
            ? new Date(this.selectedTopLevel.startOfProgressUpdatePeriod.getTime() + 86400000)
            : null;
        this.minMaxnextStatusUpdateFirstDate = this.selectedTopLevel.nextStatusUpdateFirstDate
            ? new Date(this.selectedTopLevel.nextStatusUpdateFirstDate.getTime() + 86400000)
            : null;
        this.minMaxnextStatusUpdateSecondDate = this.selectedTopLevel.nextStatusUpdateSecondDate
            ? new Date(this.selectedTopLevel.nextStatusUpdateSecondDate.getTime() + 86400000)
            : null;

        this.minMaxstartOfFinancialUpdatePeriod = this.selectedTopLevel.startOfFinancialUpdatePeriod
            ? new Date(this.selectedTopLevel.startOfFinancialUpdatePeriod.getTime() + 86400000)
            : null;
        this.minMaxnextScorecardUpdateFirstDate = this.selectedTopLevel.nextScorecardUpdateFirstDate
            ? new Date(this.selectedTopLevel.nextScorecardUpdateFirstDate.getTime() + 86400000)
            : null;
        this.minMaxnextScorecardUpdateSecondDate = this.selectedTopLevel
            .nextScorecardUpdateSecondDate
            ? new Date(this.selectedTopLevel.nextScorecardUpdateSecondDate.getTime() + 86400000)
            : null;
    }

    public goBack() {
        this.location.back();
    }

    private generateBreadCrumb() {
        this.dynamicBreadCrumb('Update Cycle');
    }
}
