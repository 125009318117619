export class Template {
  id: string;
  primaryColor: string;
  secondaryColor: string;
  thirdColor: string;
  logo: string | ArrayBuffer;
  logoType: string;
  system: boolean;
}

export class TemplateModel {
  templateId: string;
  primaryColor: string;
  secondaryColor: string;
  thirdColor: string;
  logo: string | ArrayBuffer;
  logoType: string;
}
