import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Guards
import { AdminAuthGuard } from '../../shared/guards/admin-auth.guard';

// Models
import { Rights } from '../models/rights';

// Ngx
import { NgxPermissionsGuard } from 'ngx-permissions';

// Views
import { PropertySetDisplaysComponent } from './../components/nodeConfiguration/property-set-displays/property-set-displays.component';
import { HomeComponent } from '../components/home/home.component';
import { IndexationComponent } from '../components/updateCycle/indexation/indexation.component';
import { UsersComponent } from '../components/usersGroups/users/users.component';
import { UserHistoryComponent } from '../components/usersGroups/user-history/user-history.component';
import { GroupsComponent } from '../components/usersGroups/groups/groups.component';
import { MessagesComponent } from '../components/communication/messages/messages.component';
import { MessageComponent } from '../components/communication/messages/message/message.component';
import { GeneratedEmailsComponent } from '../components/communication/generated-emails/generated-emails.component';
import { EmailTemplatesComponent } from '../components/communication/email-templates/email-templates.component';
import { GroupEmailsComponent } from '../components/communication/group-emails/group-emails.component';
import { EmailTemplateComponent } from '../components/communication/email-template/email-template.component';
import { GeneratedEmailComponent } from '../components/communication/generated-email/generated-email.component';
import { ExchangeRatesComponent } from '../components/exchangerate/exchange-rates/exchange-rates.component';
import { JobsComponent } from '../components/monitoring/jobs/jobs.component';
import { JobComponent } from '../components/monitoring/job/job.component';
import { HistoryComponent } from '../components/monitoring/history/history.component';
import { MaturitiesComponent } from '../components/maturity/maturities/maturities.component';
import { MaturityStrategiesComponent } from '../components/maturity/maturity-strategies/maturity-strategies.component';
// tslint:disable-next-line:max-line-length
import { MaturityStrategyMaturitiesComponent } from '../components/maturity/maturity-strategy-maturities/maturity-strategy-maturities.component';
// tslint:disable-next-line:max-line-length
import { MaturityStrategyMaturityPathsComponent } from '../components/maturity/maturity-strategy-maturity-paths/maturity-strategy-maturity-paths.component';
import { ChecklistsComponent } from '../components/maturity/checklists/checklists.component';
import { DeliverablesComponent } from '../components/maturity/deliverables/deliverables.component';
import { KpisComponent } from '../components/nodeConfiguration/kpis/kpis.component';
import { DeleteNodesComponent } from '../components/nodeConfiguration/delete-nodes/delete-nodes.component';
import { PropertiesComponent } from '../components/nodeConfiguration/properties/properties.component';
import { PropertyAccessRightsComponent } from '../components/nodeConfiguration/property-access-rights/property-access-rights.component';
import { PropertyListValuesComponent } from '../components/nodeConfiguration/property-list-values/property-list-values.component';
import { PropertySetsComponent } from '../components/nodeConfiguration/property-sets/property-sets.component';
import { PropertySetPropertiesComponent } from '../components/nodeConfiguration/property-set-properties/property-set-properties.component';
import { FlagsComponent } from '../components/nodeConfiguration/flags/flags.component';
import { SectionsComponent } from '../components/nodeConfiguration/sections/sections.component';
import { ScorecardsComponent } from '../components/scorecard/scorecards/scorecards.component';
import { ScorecardViewsComponent } from '../components/scorecard/scorecard-views/scorecard-views.component';
import { ScorecardViewEffectsComponent } from '../components/scorecard/scorecard-view-effects/scorecard-view-effects.component';
import { ScorecardEffectsComponent } from '../components/scorecard/scorecard-effects/scorecard-effects.component';
import { EffectsComponent } from '../components/scorecard/effects/effects.component';
import { EffectComponent } from '../components/scorecard/effect/effect.component';
import { FinancialStatesComponent } from '../components/scorecard/financial-states/financial-states.component';
import { UpdatePeriodComponent } from '../components/updateCycle/update-period/update-period.component';
import { ImportsComponent } from '../components/nodeConfiguration/imports/imports.component';
import { ConfigurationComponent } from '../components/configurationPage/configuration/configuration.component';
import { ImportGroupsComponent } from '../components/usersGroups/import-groups/import-groups.component';
import { ImportUsersComponent } from '../components/usersGroups/import-users/import-users.component';
import { ImportExchangeRatesComponent } from '../components/exchangerate/import-exchange-rates/import-exchange-rates.component';
import { CurrenciesComponent } from '../components/exchangerate/currencies/currencies.component';
import { TopsComponent } from '../components/structure/tops/tops.component';
import { TopLevelStructuresComponent } from '../components/structure/topLevelStructures/topLevelStructures.component';
import { TemplateComponent } from '../components/structure/template/template.component';
import { LayoutRootComponent } from '../../shared/components/layout-root/layout-root.component';
import { SnapshotComponent } from '../components/reporting/snapshot/snapshot.component';
import { DuplicationsComponent } from '../components/structure/topLevelStructures/duplications/duplications.component';
// tslint:disable-next-line: max-line-length
import { ImportDuplicationComponent } from '../components/structure/topLevelStructures/duplications/import-duplication/import-duplication.component';
import { PropertySetDisplayComponent } from '../components/nodeConfiguration/property-set-display/property-set-display.component';
import { SourceAgreementComponent } from '../components/structure/source-agreement/source-agreement.component';
import { ReportTriggerComponent } from '../components/reporting/report-trigger/report-trigger.component';

export const adminRoutes: Routes = [
    {
        path: '',
        component: HomeComponent,
    },
    // ### Configuration page
    // configuration
    {
        path: 'config',
        canActivate: [AdminAuthGuard],
        component: ConfigurationComponent,
    },
    // Layout Root Component
    {
        path: ':module',
        component: LayoutRootComponent,
    },
    // ### UpdateCycle
    // Indexation
    {
        path: 'updatecycle/indexation',
        component: IndexationComponent,
        data: {
            permissions: {
                only: [], // TODO CREATE RIGHT FOR INDEXATION
                redirectTo: 'error/401',
            },
        },
    },
    // Update Period
    {
        path: 'updatecycle/updateperiod',
        component: UpdatePeriodComponent,
        data: {
            permissions: {
                only: [Rights.UpdatePeriod],
                redirectTo: 'error/401',
            },
        },
    },
    // ### User & Groups
    // users
    {
        path: 'usersandgroups/users',
        component: UsersComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
            permissions: {
                only: [Rights.UserAndGroup],
                redirectTo: 'error/401',
            },
        },
    },
    {
        path: 'usersandgroups/users/:id/history',
        component: UserHistoryComponent,
        data: {
            permissions: {
                only: [Rights.UserAndGroup],
                redirectTo: 'error/401',
            },
        },
    },
    // Groups
    {
        path: 'usersandgroups/groups',
        component: GroupsComponent,
        data: {
            permissions: {
                only: [Rights.UserAndGroup],
                redirectTo: 'error/401',
            },
        },
    },
    // Import User
    {
        path: 'usersandgroups/groups/import',
        component: ImportGroupsComponent,
        data: {
            permissions: {
                only: [Rights.UserAndGroup, Rights.Import],
                redirectTo: 'error/401',
            },
        },
    },
    // Import Groups
    {
        path: 'usersandgroups/users/import',
        component: ImportUsersComponent,
        data: {
            permissions: {
                only: [Rights.UserAndGroup, Rights.Import],
                redirectTo: 'error/401',
            },
        },
    },
    // ### Communication
    // Messages
    {
        path: 'communication/messages',
        component: MessagesComponent,
        data: {
            permissions: {
                only: [Rights.Message],
                redirectTo: 'error/401',
            },
        },
    },
    {
        path: 'communication/messages/:id',
        component: MessageComponent,
        data: {
            permissions: {
                only: [Rights.Message],
                redirectTo: 'error/401',
            },
        },
    },
    // Emails
    {
        path: 'communication/generatedmails',
        component: GeneratedEmailsComponent,
        data: {
            permissions: {
                only: [Rights.SendMail],
                redirectTo: 'error/401',
            },
        },
    },
    {
        path: 'communication/mailtemplates',
        component: EmailTemplatesComponent,
        data: {
            permissions: {
                only: [Rights.GroupMailAndTemplate],
                redirectTo: 'error/401',
            },
        },
    },
    {
        path: 'communication/groupmails',
        component: GroupEmailsComponent,
        data: {
            permissions: {
                only: [Rights.GroupMailAndTemplate],
                redirectTo: 'error/401',
            },
        },
    },
    {
        path: 'communication/mailtemplates/:id',
        component: EmailTemplateComponent,
        data: {
            permissions: {
                only: [Rights.GroupMailAndTemplate],
                redirectTo: 'error/401',
            },
        },
    },
    {
        path: 'communication/generatedmails/:id',
        component: GeneratedEmailComponent,
        data: {
            permissions: {
                only: [Rights.SendMail],
                redirectTo: 'error/401',
            },
        },
    },

    // # Exchange Rate
    // exchange rates
    {
        path: 'exchangerate/exchangerates',
        component: ExchangeRatesComponent,
        data: {
            permissions: {
                only: [Rights.ExchangeRate],
                redirectTo: 'error/401',
            },
        },
    },
    {
        path: 'exchangerate/exchangerates/import',
        component: ImportExchangeRatesComponent,
        data: {
            permissions: {
                only: [Rights.ExchangeRate, Rights.Import],
                redirectTo: 'error/401',
            },
        },
    },
    {
        path: 'exchangerate/currencies',
        component: CurrenciesComponent,
        data: {
            permissions: {
                only: [Rights.TopLevelCurrency, Rights.ExchangeRate],
                redirectTo: 'error/401',
            },
        },
    },

    // ### Monitoring
    // jobs
    {
        path: 'monitoring/jobs',
        component: JobsComponent,
        data: {
            permissions: {
                only: [],
                redirectTo: 'error/401',
            },
        },
    },
    {
        path: 'monitoring/jobs/:id',
        component: JobComponent,
        data: {
            permissions: {
                only: [],
                redirectTo: 'error/401',
            },
        },
    },
    // history
    {
        path: 'monitoring/history',
        component: HistoryComponent,
        data: {
            permissions: {
                only: [],
                redirectTo: 'error/401',
            },
        },
    },

    // ### Maturities
    // Maturities
    {
        path: 'maturities/maturities',
        component: MaturitiesComponent,
        data: {
            permissions: {
                only: [Rights.MaturityAndStrategy],
                redirectTo: 'error/401',
            },
        },
    },
    {
        path: 'maturities/maturitystrategies',
        component: MaturityStrategiesComponent,
        data: {
            permissions: {
                only: [Rights.MaturityAndStrategy],
                redirectTo: 'error/401',
            },
        },
    },
    {
        path: 'maturities/maturitystrategies/:id/maturities',
        component: MaturityStrategyMaturitiesComponent,
        data: {
            permissions: {
                only: [Rights.MaturityAndStrategy],
                redirectTo: 'error/401',
            },
        },
    },
    {
        path: 'maturities/maturitystrategies/:id/maturities/:maturityid/paths',
        component: MaturityStrategyMaturityPathsComponent,
        data: {
            permissions: {
                only: [Rights.MaturityAndStrategy],
                redirectTo: 'error/401',
            },
        },
    },
    // checklists
    {
        path: 'maturities/checklists',
        component: ChecklistsComponent,
        data: {
            permissions: {
                only: [Rights.CheckistAndDeliverable],
                redirectTo: 'error/401',
            },
        },
    },
    {
        path: 'maturities/deliverables',
        component: DeliverablesComponent,
        data: {
            permissions: {
                only: [Rights.CheckistAndDeliverable],
                redirectTo: 'error/401',
            },
        },
    },

    // ### Nodes configuration
    // KPI
    {
        path: 'nodes/kpis',
        component: KpisComponent,
        data: {
            permissions: {
                only: [Rights.CreateKPI],
                redirectTo: 'error/401',
            },
        },
    },
    // Delete Nodes
    {
        path: 'nodes/deletenodes',
        component: DeleteNodesComponent,
        data: {
            permissions: {
                only: [Rights.DeletedNode],
                redirectTo: 'error/401',
            },
        },
    },
    // Properties
    {
        path: 'nodes/properties',
        component: PropertiesComponent,
        data: {
            permissions: {
                only: [Rights.PropertyAndSet],
                redirectTo: 'error/401',
            },
        },
    },
    {
        path: 'nodes/properties/:id/accessrights',
        component: PropertyAccessRightsComponent,
        data: {
            permissions: {
                only: [Rights.PropertyAndSet],
                redirectTo: 'error/401',
            },
        },
    },
    {
        path: 'nodes/properties/:id/listvalues',
        component: PropertyListValuesComponent,
        data: {
            permissions: {
                only: [Rights.PropertyAndSet],
                redirectTo: 'error/401',
            },
        },
    },
    // Property sets
    {
        path: 'nodes/propertysets',
        component: PropertySetsComponent,
        data: {
            permissions: {
                only: [Rights.PropertyAndSet],
                redirectTo: 'error/401',
            },
        },
    },
    {
        path: 'nodes/propertysets/:id/properties',
        component: PropertySetPropertiesComponent,
        data: {
            permissions: {
                only: [Rights.PropertyAndSet],
                redirectTo: 'error/401',
            },
        },
    },
    {
        path: 'nodes/propertysets/:id/displays',
        component: PropertySetDisplaysComponent,
        data: {
            permissions: {
                only: [Rights.PropertyAndSet],
                redirectTo: 'error/401',
            },
        },
    },
    {
        path: 'nodes/propertysets/:id/displays/:displayId',
        component: PropertySetDisplayComponent,
        data: {
            permissions: {
                only: [Rights.PropertyAndSet],
                redirectTo: 'error/401',
            },
        },
    },
    // Flags
    {
        path: 'nodes/flags',
        component: FlagsComponent,
        data: {
            permissions: {
                only: [Rights.PropertyAndSet],
                redirectTo: 'error/401',
            },
        },
    },
    // Sections
    {
        path: 'nodes/sections',
        component: SectionsComponent,
        data: {
            permissions: {
                only: [Rights.PropertyAndSet],
                redirectTo: 'error/401',
            },
        },
    },
    // Imports
    {
        path: 'nodes/imports',
        component: ImportsComponent,
        data: {
            permissions: {
                only: [Rights.Import],
                redirectTo: 'error/401',
            },
        },
    },
    // ### scorecard
    // scorecards
    {
        path: 'scorecards/scorecards',
        component: ScorecardsComponent,
        data: {
            permissions: {
                only: [Rights.ScorecardAndEffect],
                redirectTo: 'error/401',
            },
        },
    },
    {
        path: 'scorecards/scorecardviews',
        component: ScorecardViewsComponent,
        data: {
            permissions: {
                only: [Rights.ScorecardAndEffect],
                redirectTo: 'error/401',
            },
        },
    },
    {
        path: 'scorecards/scorecardviews/:id/effects',
        component: ScorecardViewEffectsComponent,
        data: {
            permissions: {
                only: [Rights.ScorecardAndEffect],
                redirectTo: 'error/401',
            },
        },
    },
    {
        path: 'scorecards/scorecards/:id/effects',
        component: ScorecardEffectsComponent,
        data: {
            permissions: {
                only: [Rights.ScorecardAndEffect],
                redirectTo: 'error/401',
            },
        },
    },
    // effects
    {
        path: 'scorecards/effects',
        component: EffectsComponent,
        data: {
            permissions: {
                only: [Rights.ScorecardAndEffect],
                redirectTo: 'error/401',
            },
        },
    },
    {
        path: 'scorecards/effects/:id',
        component: EffectComponent,
        data: {
            permissions: {
                only: [Rights.ScorecardAndEffect],
                redirectTo: 'error/401',
            },
        },
    },
    // financial states
    {
        path: 'scorecards/financialstates',
        component: FinancialStatesComponent,
        data: {
            permissions: {
                only: [Rights.ScorecardAndEffect],
                redirectTo: 'error/401',
            },
        },
    },
    // Ventilations
    // {
    //   path: 'scorecards/ventilations',
    //   component: VentilationsComponent,
    //   data: {
    //     permissions: {
    //       only: ['USERANDGROUP', 'MODERATOR'],
    //       redirectTo: 'error/401'
    //     }
    //   }
    // },
    // ## Structure
    {
        path: 'structure/tops',
        component: TopsComponent,
        data: {
            permissions: {
                only: [Rights.TopLevelScorecardInfo, Rights.TopLevelCreation],
                redirectTo: 'error/401',
            },
        },
    },
    {
        path: 'structure/levels',
        component: TopLevelStructuresComponent,
        data: {
            permissions: {
                only: [Rights.Structure],
                redirectTo: 'error/401',
            },
        },
    },
    {
        path: 'structure/templates',
        component: TemplateComponent,
        data: {
            permissions: {
                only: [Rights.Template],
                redirectTo: 'error/401',
            },
        },
    },
    {
        path: 'structure/duplications/export',
        component: DuplicationsComponent,
        data: {
            permissions: {
                only: [],
                redirectTo: 'error/401',
            },
        },
    },
    {
        path: 'structure/duplications/import',
        component: ImportDuplicationComponent,
        data: {
            permissions: {
                only: [],
                redirectTo: 'error/401',
            },
        },
    },
    {
        path: 'structure/sourceagreement',
        component: SourceAgreementComponent,
        data: {
            permissions: {
                only: [],
                redirectTo: 'error/401',
            },
        },
    },
    {
        path: 'reporting/reports',
        component: ReportTriggerComponent,
        data: {
            permissions: {
                only: [Rights.Export],
                redirectTo: 'error/401',
            },
        },
    },
    {
        path: 'reporting/snapshotdates',
        component: SnapshotComponent,
        data: {
            permissions: {
                only: [Rights.SnapshotDates],
                redirectTo: 'error/401',
            },
        },
    }
];

@NgModule({
    imports: [RouterModule.forChild(adminRoutes)],
    exports: [RouterModule],
})
export class MyTrendRouting {}
