import { JobStatus, SortType, TreeNodeStatus, TypeProperty } from './enums';
import { Right } from './right';

abstract class BaseTreeNode {
    id: string;
    name: string;
    structureName: string;
    level: number;
    permission: number;
    rights: string[];
    canAdmin() {
        return this.hasRight(Right.ADMIN);
    }

    canRead() {
        return this.hasRight(Right.READ);
    }

    /**
     * Determines whether tags can
     * @returns true if tags
     */
    public canTags(): boolean {
        return this.hasRight(Right.TAGS);
    }

    private hasRight(right: Right) {
        return this.rights.some((x) => {
            return x === right;
        });
    }
}

export class ChildDisplayDefinition {
    id: string;
    topLevelStructureId: string;
    propertyId: string;
    calculationId: string;
    order: number;
    name: string;
    sort: SortType;
}
export class TreeNode extends BaseTreeNode {
    parentId: string;
    topLevelStructureId: string;
    phase: number;
    hidden: boolean;
    hasScorecard: boolean;
    hasAggregatedScorecard: boolean;
    hasCorrectionScorecard: boolean;
    status: TreeNodeStatus;
    scorecardCurrency: string;
    scorecardDisplayId: string;
    lastUpdatePropertiesDate: Date;
    lastUpdateScorecardDate: Date;
    lastUpdateMilestonesDate: Date;
    lastScorecardUpdateByAnybodyDate: Date;
    lastSynchronizationDate: Date;
    mandatory: boolean;
    synchronizationSource: string;
    code: string;
    maturityId: string;
    scorecardId: string;
    parents: TreeNodeParent[];
}

export class TreeNodeParent extends BaseTreeNode {}

export interface IDeletedNode {
    id: string;
    name: string;
    code: string;
    structureName: string;
    parentId: string;
    parentName: string;
    parentCode: string;
    jobStatus?: JobStatus;
}

export interface ChildrenDisplay {
    propertyId: string;
    calculationId: string;
    type: string;
    order: number;
    headers: string[];
    values: any[];
    hideProperty: boolean;
}
