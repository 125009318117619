import { Component, OnInit, TemplateRef } from '@angular/core';
import { MessageService } from 'primeng/api';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

import { IDeliverableView, DeliverableModel } from '../../../../../shared/models/checklist';
import { ChecklistService } from '../../../../../shared/services/repositories/checklist.service';
import { ConfirmationDialogService } from '../../../../../shared/components/utils/confirmation/ConfirmationDialog.service';
import { Property } from '../../../../../shared/models/property';
import { BaseComponent } from '../../../../shared/components/base/base.component';
import { fadeInOut } from 'src/app/shared/animations/fade';
import { _ } from '../../../../../shared/services/translation.service';
import { Router } from '@angular/router';
import { WorkflowService } from '../../../../shared/services/workflow.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-deliverables',
    templateUrl: './deliverables.component.html',
    styleUrls: ['./deliverables.component.scss'],
    animations: [fadeInOut],
})
export class DeliverablesComponent extends BaseComponent implements OnInit {
    public addModalRef: BsModalRef;
    public editModalRef: BsModalRef;
    public dtTrigger: Subject<any> = new Subject();

    public deliverables: IDeliverableView[] = [];
    public deliverableProperties: Property[] = [];

    public newDeliverable: DeliverableModel;
    public edtDeliverable: DeliverableModel;

    constructor(
        private checklistService: ChecklistService,
        private confirmationService: ConfirmationDialogService,
        private messageService: MessageService,
        private modalService: BsModalService,
        public router: Router,
        protected workflowService: WorkflowService,
        private translate: TranslateService
    ) {
        super(router, workflowService);
        // disable order in specific columns
        this.dtOptions.columnDefs = [{ orderable: false, targets: 2 }];
        this.generateBreadCrumb();
    }

    ngOnInit() {
        this.getDeliverables();
        this.getDeliverableProperties();
    }

    getDeliverables() {
        this.loading = true;
        this.checklistService.getDeliverablesview().subscribe((res) => {
            this.deliverables = res;
            this.refreshTable('#table', this.dtTrigger);
        });
    }

    getDeliverableProperties() {
        this.checklistService.getDeliverableProperties().subscribe((res) => {
            return (this.deliverableProperties = res);
        });
    }

    //#region remove
    removeDeliverable(deliverable: IDeliverableView) {
        this.confirmationService
            .confirm(
                this.translate.instant(_('Do you want to delete ')) +
                    deliverable.name +
                    this.translate.instant(_(' deliverable?'))
            )
            .subscribe((result) => {
                if (result) {
                    this.checklistService.deleteDeliverable(deliverable.id).subscribe(
                        () => {
                            this.messageService.add({
                                severity: 'success',
                                summary: this.translate.instant(_('Success!')),
                                detail: this.translate.instant(
                                    _('Deliverable removed with success!')
                                ),
                            });
                        },
                        (error) => {
                            return console.log('Error: ', error);
                        },
                        () => {
                            return this.getDeliverables();
                        }
                    );
                }
            });
    }

    //#region add
    addDeliverableModal(modal: TemplateRef<any>) {
        this.newDeliverable = new DeliverableModel();
        this.addModalRef = this.modalService.show(modal, this.modalConfig);
    }

    addDeliverable() {
        // null or white spaces
        if (this.newDeliverable.name !== null && this.newDeliverable.name.match(/^ *$/) === null) {
            this.checklistService.setDeliverable(this.newDeliverable).subscribe(
                () => {
                    this.messageService.add({
                        severity: 'success',
                        summary: this.translate.instant(_('Success!')),
                        detail: this.translate.instant(_('Deliverable added with success!')),
                    });
                },
                (error) => {
                    return console.log('Error: ', error);
                },
                () => {
                    return this.getDeliverables();
                }
            );
            this.addModalRef.hide();
        }
    }

    //#region edit
    editDeliverabletModal(deliverable: IDeliverableView, modal: TemplateRef<any>) {
        this.edtDeliverable = {
            id: deliverable.id,
            name: deliverable.name,
            responsibleId: deliverable.responsibleId,
        };
        this.editModalRef = this.modalService.show(modal, this.modalConfig);
    }

    editDeliverable() {
        // null or white spaces
        if (this.edtDeliverable.name !== null && this.edtDeliverable.name.match(/^ *$/) === null) {
            this.checklistService
                .updateDeliverable(this.edtDeliverable.id, this.edtDeliverable)
                .subscribe(
                    () => {
                        this.messageService.add({
                            severity: 'success',
                            summary: this.translate.instant(_('Success!')),
                            detail: this.translate.instant(_('Deliverable edited with success!')),
                        });
                    },
                    (error) => {
                        return console.log('Error: ', error);
                    },
                    () => {
                        return this.getDeliverables();
                    }
                );
            this.editModalRef.hide();
        }
    }

    private generateBreadCrumb() {
        this.dynamicBreadCrumb('Maturity');
    }
}
