import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { TopExport, TopExportView } from '../../models/topExport';
import { ExportType } from 'src/app/shared/models/enums';
import { HttpExtendedInstanceService } from 'src/app/shared/services';

@Injectable({
    providedIn: 'root',
})
export class TopExportService {
    constructor(private http: HttpExtendedInstanceService) {}

    public exportTop(topId: string, exportType: ExportType): Observable<TopExport> {
        return this.http.post<TopExport>(`toplevels/${topId}/export/${exportType}`, {});
    }

    public executeTrigger(): Observable<any> {
        return this.http.get<any>(`reports/execute`);
    }

    public getTopExport(topId: string, exportId: string): Observable<HttpResponse<Blob>> {
        return this.http.get<HttpResponse<Blob>>(
            `toplevels/${topId}/export/${exportId}`,
            this.http.blobOptions()
        );
    }

    public getTopExports(topId: string, exportType: string): Observable<TopExportView[]> {
        return this.http.get(`toplevels/${topId}/exports/${exportType}`);
    }
}
