import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { _ } from '../../../../../shared/services/translation.service';
import { fadeInOut } from 'src/app/shared/animations/fade';
import { MessageService } from 'primeng/api';
// components
import { BaseComponent } from 'src/app/admin/shared/components/base/base.component';
// models
import { ReportTrigger } from 'src/app/admin/shared/models/reportTrigger';
import { PaginatedQuerySearch } from 'src/app/shared/models/pagingResult';
// services
import { TranslateService } from '@ngx-translate/core';
import { WorkflowService } from 'src/app/admin/shared/services/workflow.service';
import { ReportService } from 'src/app/admin/shared/services/report.service';

@Component({
  selector: 'app-report-trigger',
  templateUrl: './report-trigger.component.html',
  styleUrls: ['./report-trigger.component.scss'],
  animations: [fadeInOut],
})
export class ReportTriggerComponent extends BaseComponent implements OnInit {
  public dtTrigger: Subject<any> = new Subject();

  public search: PaginatedQuerySearch = { max: 20, offset: 0, query: '' };

  public reportTriggers: ReportTrigger[] = [];
  public page: Number = 1;
  public totalPages: Number = 1;

  constructor(public router: Router,
    protected workflowService: WorkflowService,
    private readonly reportService: ReportService,
    private messageService: MessageService,
    private translate: TranslateService,) {
    super(router, workflowService);
    this.dynamicBreadCrumb('Reporting', 'Reports');
    this.dtOptions = {
      ordering: false,
      searching: false,
      paging: false,
      info: false,
    };
  }

  ngOnInit(): void {
    this.getReportTriggers();
  }

  getReportTriggers() {
    this.loading = true;
    this.reportService.getReportTriggers(this.search).subscribe((res) => {
      this.totalPages = res.total;
      this.page = +res.offset / this.search.max + 1;
      this.reportTriggers = res.results;
      this.refreshTable('#table', this.dtTrigger);
    });
  }

  TriggerReportGeneration() {
    this.reportService.triggerReportGeneration().subscribe(()=>{
      this.messageService.add({
        severity: 'success',
        summary: this.translate.instant(_('Success!')),
        detail: this.translate.instant(_('Snapshot created with success!')),
      });
      this.getReportTriggers();
    });
  }

  public pageChanged(event: any): void {
    this.search.offset = (event.page - 1) * this.search.max;
    this.getReportTriggers();
  }


}
