import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { MessageService } from 'primeng/api';

import { User, UserSearch, UserModel, AuthenticatedUser } from '../../../../../shared/models/user';
import { BaseComponent } from '../../../../shared/components/base/base.component';
import { fadeInOut } from 'src/app/shared/animations/fade';
import { _ } from '../../../../../shared/services/translation.service';
import { WorkflowService } from '../../../../shared/services/workflow.service';
import { MyTrendConfig } from '../../../../../shared/services/myTrendConfig.service';
import { UserService } from '../../../../../shared/services/repositories/user.service';

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss'],
    animations: [fadeInOut],
})
export class UsersComponent extends BaseComponent implements OnInit {
    public dtTrigger: Subject<any> = new Subject();
    public addModalRef: BsModalRef;
    public editModalRef: BsModalRef;
    public transferAccessModalRef: BsModalRef;

    public transferAccessFromUser: User = new User();
    public transferAccessSelectedUser: User = new User();
    public usersTransferAccess: User[] = [];
    public transferConfirmation = false;

    public userSearch: UserSearch = new UserSearch();
    public realUser: AuthenticatedUser = new AuthenticatedUser();

    public users: User[] = [];
    public page: Number = 1;
    public totalPages: Number = 1;

    public usersFilter = 'active';
    public newUser: UserModel;
    public edtUser: UserModel;
    public errorMessage: string;

    private firstQuery = true;

    constructor(
        private userService: UserService,
        public router: Router,
        private messageService: MessageService,
        private modalService: BsModalService,
        private workflowService: WorkflowService,
        private route: ActivatedRoute,
        private translate: TranslateService
    ) {
        super(router, workflowService);
        this.dtOptions = {
            ordering: false,
            searching: false,
            paging: false,
            info: false,
        };
        this.generateBreadCrumb();
    }

    ngOnInit() {
        this.userSearch.max = 20;
        this.getRealUser();
        this.getUsers();
    }

    public pageChanged(event: any): void {
        this.userSearch.offset = (event.page - 1) * this.userSearch.max;
        this.getUsers();
    }

    public getRealUser() {
        this.userService.getActualUser().subscribe((res) => {
            this.realUser = res;
        });
    }

    public getUsers() {
        this.loading = true;
        this.route.queryParams.subscribe((params) => {
            const username = params.username;

            if (username && this.firstQuery) {
                this.userSearch.query = username;
            }
            this.convertFilter(this.usersFilter);
            this.userService.getUsers(this.userSearch).subscribe((res) => {
                this.totalPages = res.total;
                this.page = +res.offset / this.userSearch.max + 1;
                this.users = res.results;
                this.refreshTable('#table', this.dtTrigger);
            });
        });
    }

    public logAs(user: User) {
        this.userService.connectAs(user.id).subscribe(() => {
            this.messageService.add({
                severity: 'success',
                summary: this.translate.instant(_('Succes!')),
                detail: this.translate.instant(_('Logged as with success!')),
            });
            window.location.href = MyTrendConfig.myTrendSettings.myTrendUrl;
        });
    }

    public disableUser(user: User) {
        this.userService.disableUser(user.id).subscribe(
            () => {
                this.messageService.add({
                    severity: 'success',
                    summary: this.translate.instant(_('Succes!')),
                    detail: this.translate.instant(_('User disabled with success!')),
                });
            },
            (error) => {
                return console.log('Error: ', error);
            },
            () => {
                return this.ngOnInit();
            }
        );
    }

    public enableUser(user: User) {
        this.userService.enableUser(user.id).subscribe(
            () => {
                this.messageService.add({
                    severity: 'success',
                    summary: this.translate.instant(_('Succes!')),
                    detail: this.translate.instant(_('User enabled with success!')),
                });
            },
            (error) => {
                return console.log('Error: ', error);
            },
            () => {
                return this.ngOnInit();
            }
        );
    }

    //#region add
    addUserModal(modal: TemplateRef<any>) {
        this.errorMessage = null;
        this.newUser = new UserModel();
        this.addModalRef = this.modalService.show(modal, this.modalConfig);
    }

    addUser() {
        this.errorMessage = null;
        if (this.newUser.password !== this.newUser.confirmPassword) {
            this.messageService.add({
                severity: 'error',
                summary: this.translate.instant(_('Error!')),
                detail: this.translate.instant(_('Password and confirmation doesnt match!')),
            });
            return;
        }
        // null or white spaces
        if (this.newUser.username !== null && this.newUser.username.match(/^ *$/) === null) {
            this.userService.setUser(this.newUser).subscribe(
                () => {
                    this.messageService.add({
                        severity: 'success',
                        summary: this.translate.instant(_('Succes!')),
                        detail: this.translate.instant(_('User added with success!')),
                    });
                    this.addModalRef.hide();
                },
                (error) => {
                    this.errorMessage = error;
                },
                () => {
                    return this.ngOnInit();
                }
            );
        }
    }

    //#region tranfer access
    transferAccessModal(user: User, modal: TemplateRef<any>) {
        this.transferAccessFromUser = user;
        this.transferConfirmation = false;
        this.transferAccessModalRef = this.modalService.show(modal, this.modalConfig);
    }

    transferAccessConfirmation() {
        if (this.transferAccessSelectedUser) {
            this.transferConfirmation = true;
        }
    }

    transferAccess() {
        this.transferAccessModalRef.hide();
        this.userService
            .transferAccess(this.transferAccessFromUser.id, this.transferAccessSelectedUser.id)
            .subscribe(
                () => {
                    this.messageService.add({
                        severity: 'success',
                        summary: this.translate.instant(_('Succes!')),
                        detail: this.translate.instant(_('User access tranfered with success!')),
                    });
                },
                (error) => {
                    return console.log('Error: ', error);
                },
                () => {
                    return this.ngOnInit();
                }
            );
    }

    // search for users available
    search(event) {
        const search = new UserSearch();
        search.query = event.query;
        this.userService.getUsers(search).subscribe((res) => {
            this.usersTransferAccess = res.results;
        });
    }

    //#region edit
    editUserModal(user: User, modal: TemplateRef<any>) {
        this.errorMessage = null;
        this.edtUser = {
            id: user.id,
            username: user.username,
            email: user.email,
            displayName: user.displayName,
            password: null,
            confirmPassword: null,
            requireNewPasswordAtLogon: false,
            sendMail: false,
        };
        this.editModalRef = this.modalService.show(modal, this.modalConfig);
    }

    editUser() {
        this.errorMessage = null;
        if (this.edtUser.password !== this.edtUser.confirmPassword) {
            this.messageService.add({
                severity: 'error',
                summary: this.translate.instant(_('Error!')),
                detail: this.translate.instant(_('Password and confirmation doesnt match!')),
            });
            return;
        }

        // null or white spaces
        if (this.edtUser.username !== null && this.edtUser.username.match(/^ *$/) === null) {
            this.userService.updateUser(this.edtUser.id, this.edtUser).subscribe(
                () => {
                    this.messageService.add({
                        severity: 'success',
                        summary: this.translate.instant(_('Success!')),
                        detail: this.translate.instant(_('User updated with success!')),
                    });
                    this.editModalRef.hide();
                },
                (error) => {
                    return console.log('Error: ', error);
                },
                () => {
                    return this.ngOnInit();
                }
            );
        }
    }

    expandUserGroups(user: User) {
        if (user.isExpanded) {
            user.isExpanded = false;
            return;
        }

        this.closeExpand();

        user.isExpanded = true;
    }

    closeExpand() {
        this.users.forEach((user) => {
            user.isExpanded = false;
            user.isAssignationsExpanded = false;
        });
    }

    expandAssignations(user: User) {
        if (user.isAssignationsExpanded) {
            user.isAssignationsExpanded = false;
            return;
        }

        this.closeExpand();

        user.isAssignationsExpanded = true;
    }

    allUsersChanged(e) {
        if (e.target.checked) {
            this.userSearch.isBlocked = false;
            this.userSearch.isDisabled = false;
            this.userSearch.isAdmin = false;
            this.getUsers();
        }
    }

    adminChanged(e) {
        if (e.target.checked) {
            this.userSearch.all = false;
        } else if (
            this.userSearch.isBlocked === false &&
            this.userSearch.isDisabled === false &&
            this.userSearch.isAdmin === false
        ) {
            this.userSearch.all = true;
        }
        this.getUsers();
    }

    convertFilter(value: string) {
        this.userSearch.all = false;
        this.userSearch.isAdmin = false;
        this.userSearch.isDisabled = false;
        this.userSearch.isBlocked = false;

        switch (value) {
            case 'active':
                this.userSearch.all = true;
                this.userSearch.isAdmin = undefined;
                break;
            case 'administrators':
                this.userSearch.isAdmin = true;
                break;
            case 'disabled':
                this.userSearch.isDisabled = true;
                this.userSearch.isAdmin = undefined;
                break;
            case 'blocked':
                this.userSearch.isBlocked = true;
                this.userSearch.isAdmin = undefined;
                break;
        }
    }

    private generateBreadCrumb() {
        this.dynamicBreadCrumb('Users & Groups');
    }

    public navigateToUserImport() {
        this.router.navigate(['/import', {}], { relativeTo: this.route });
    }
}
