import { Injectable } from '@angular/core';

// Models
import { ProfileHistoryApiResponse, ProfileHistoryQuery } from '../../models';

// Rxjs
import { Observable } from 'rxjs';

// Services
import { HttpExtendedGlobalService } from 'src/app/shared/services';

@Injectable({
    providedIn: 'root',
})
export class ProfileHistoryService {
    /**
     * Creates an instance of profile history service.
     * @param http
     */
    constructor(private readonly http: HttpExtendedGlobalService) {}

    /**
     * Gets histories
     * @param [queryParams]
     * @returns histories
     */
    public getHistories(queryParams?: ProfileHistoryQuery): Observable<ProfileHistoryApiResponse> {
        return this.http.get<ProfileHistoryApiResponse>(
            `history`,
            this.http.baseOptions(),
            queryParams
        );
    }
}
