import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

// dFakto-ui
import { TopBarService } from '@depfac/dfakto-ui/src/lib/top-bar';
import { DMenuItem } from '@depfac/dfakto-ui/src/lib/menu-item';
import { DTreeNode, DTreeNodeService } from '@depfac/dfakto-ui/src/lib/tree-node';
import { SidebarService } from '@depfac/dfakto-ui/src/lib/sidebar';

// Models
import { TopLevelParent } from './models';

// Ngx
import { TranslateService } from '@ngx-translate/core';

// Rxjs
import { concat, of, Subscription } from 'rxjs';
import { debounceTime, finalize, map, switchMap, tap } from 'rxjs/operators';

// Services
import { _ } from '../../shared/services/translation.service';
import { AuthService, TopBarGeneratorService } from 'src/app/shared/services';
import { ToplevelApiService } from 'src/app/mytrend/home/services';
import { ValidationService } from './views/update-manager/services';
import { WorkspaceService } from './views/shared/services/workspace.service';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';

@Component({
    selector: 'app-workspace-layout',
    templateUrl: './workspace-layout.component.html',
    styleUrls: ['./workspace-layout.component.scss'],
})
export class WorkspaceLayoutComponent implements OnInit, OnDestroy {
    /**
     * Subscriptions  of workspace layout component
     */
    private readonly subscriptions: Subscription = new Subscription();

    /**
     * Instance  of workspace layout component
     */
    private instance: string;

    /**
     * Treenode id of workspace layout component
     */
    private treenodeId: string;

    /**
     * Top parent id of workspace layout component
     */
    private topParentId: TopLevelParent;

    /**
     * Assignation tree nodes of workspace layout component
     */
    private assignationTreeNodes: DTreeNode[] = [];

    /**
     * Determines whether assignation enabled is
     */
    private isAssignationEnabled: boolean = false;

    /**
     * Search input ctrl of workspace layout component
     */
    public searchInputCtrl: FormControl = new FormControl();

    /**
     * User assignation ctrl of workspace layout component
     */
    public userAssignationCtrl: FormControl = new FormControl();

    /**
     * Determines whether search active is
     */
    public isSearchActive = false;

    /**
     * Loading tree node of workspace layout component
     */
    public loadingTreeNode: boolean;

    /**
     * Search tree nodes of workspace layout component
     */
    public searchTreeNodes: DTreeNode[] = [];

    /**
     * Tree nodes of workspace layout component
     */
    public treeNodes: DTreeNode[] = [];

    /**
     * Hasvalidationright  of workspace layout component
     */
    private hasvalidationright: boolean;

    /**
     * Creates an instance of workspace layout component.
     * @param route
     * @param translate
     * @param topBarService
     * @param topBarGeneratorService
     * @param sidebarService
     * @param toplevelApiService
     * @param treeNodeComponentService
     * @param validationService
     * @param authService
     */
    constructor(
        private readonly route: ActivatedRoute,
        private readonly translate: TranslateService,
        private readonly topBarService: TopBarService,
        private readonly topBarGeneratorService: TopBarGeneratorService,
        private readonly sidebarService: SidebarService,
        private readonly toplevelApiService: ToplevelApiService,
        private readonly treeNodeComponentService: DTreeNodeService,
        private readonly validationService: ValidationService,
        private readonly authService: AuthService,
        private readonly workspaceService: WorkspaceService
    ) { }

    /**
     * on init
     */
    public ngOnInit(): void {
        this.route.data.subscribe((param) => {
            this.instance = this.route.snapshot.paramMap.get('instance');
            this.treenodeId = this.route.snapshot.paramMap.get('treenodeId');
            this.hasvalidationright = param.response[1] as boolean;

            this.topParentId = param.response[0] as TopLevelParent;

            this.updateTopbar();

            this.initFieldSubscriptions();

            this.treeNodeComponentService.expandTreeFromNode(this.treenodeId);
        });

        this.workspaceService.structureChanged$.subscribe(() => { this.updateSidebar(); });
        this.workspaceService.selectedTreenodeChanged$.subscribe((treenodeId) => {
            this.treenodeId = treenodeId;
            this.treeNodeComponentService.expandTreeFromNode(this.treenodeId);
        });        
        this.updateSidebar();
    }

    /**
     * Updates topbar
     */
    private updateTopbar() {
        const hasValidationRight$ = this.authService.actualUser.isAdministrator
            ? of(true)
            : this.validationService.getValidations();

        hasValidationRight$
            .pipe(
                tap((result: boolean) => {
                    this.topBarService.updateMenuItemList(
                        this.topBarGeneratorService.getBaseTopBar(true, result)
                    );
                }),
                switchMap((result: boolean) => {
                    const totalNewDocs$ = this.topBarGeneratorService.getTotalNewDocuments(
                        false,
                        true,
                        result
                    );
                    const totalNewNotifications$ = this.topBarGeneratorService.getTotalNewNotifications(true, result);

                    return concat(totalNewDocs$, totalNewNotifications$);
                })
            )
            .subscribe((items: DMenuItem[]) => {
                if (items) {
                    this.topBarService.updateMenuItemList(items);
                }
            });


    }

    /**
     * Updates sidebar
     */
    private updateSidebar() {
        this.sidebarService.updateMenuItemList(this.getMenuItems());

        this.getTreeNodes(this.instance, this.topParentId.id);
    }

    /**
     * Inits field subscriptions
     */
    private initFieldSubscriptions() {
        const searchInputCtrlChanges$ = this.searchInputCtrl.valueChanges
            .pipe(
                map((input: string) => {
                    const value = input || '';
                    this.isSearchActive = !!(value && value.length);

                    return value.trim();
                }),
                debounceTime(300),
                switchMap((searchText: string) => {
                    if (!searchText || !searchText.length) {
                        return of([]);
                    }

                    this.loadingTreeNode = true;
                    return this.toplevelApiService
                        .getTreeNodesSearch(this.instance, this.topParentId.id, searchText)
                        .pipe(
                            finalize(() => {
                                return (this.loadingTreeNode = false);
                            })
                        );
                })
            )
            .subscribe((searchResults) => {
                return (this.searchTreeNodes = this.formatTreeNodeWithLink(searchResults));
            });

        const userAssignationCtrlChanges$ = this.userAssignationCtrl.valueChanges
            .pipe(debounceTime(300))
            .subscribe((toggleAssignation: boolean) => {
                this.isAssignationEnabled = toggleAssignation;
                if (!this.assignationTreeNodes.length) {
                    this.getTreeNodesWithAssignation();
                }
            });

        this.subscriptions.add(searchInputCtrlChanges$);
        this.subscriptions.add(userAssignationCtrlChanges$);
    }

    /**
     * Clears search
     */
    public clearSearch(): void {
        this.searchInputCtrl.setValue(null);
        this.isSearchActive = false;
    }

    /**
     * Gets menu items
     * @returns menu items
     */
    private getMenuItems(): DMenuItem[] {
        const items = [];

        const itemUpdateManager = {
            id: 'update-manager',
            title: this.translate.instant('Update Manager'),
            routerLink: 'update-manager',
            icon: 'fas fa-bell',
        };

        items.push({
            id: 'home',
            title: this.translate.instant('Home'),
            routerLink: '/',
            icon: 'fa fa-home',
        });

        if (this.hasvalidationright) {
            items.push(itemUpdateManager);
        }
        return items;
    }

    /**
     * Gets tree node list
     * @returns tree node list
     */
    public getTreeNodeList(): DTreeNode[] {
        if (this.isSearchActive) {
            return this.searchTreeNodes;
        }

        if (this.isAssignationEnabled) {
            return this.assignationTreeNodes;
        }
        return this.treeNodes;
    }

    /**
     * Gets tree nodes with assignation
     */
    private getTreeNodesWithAssignation(): void {
        this.loadingTreeNode = true;
        this.toplevelApiService
            .getAssignedTreeNodes(this.instance, this.topParentId.id)
            .pipe(
                finalize(() => {
                    return (this.loadingTreeNode = false);
                })
            )
            .subscribe((res: DTreeNode[]) => {
                if (res && res.length) {
                    this.assignationTreeNodes = this.formatTreeNodeWithLink(res);
                }
            });
    }

    /**
     * Gets tree nodes
     * @param instance
     * @param treeNodeId
     */
    private getTreeNodes(instance: string, treeNodeId: string): void {
        this.loadingTreeNode = true;
        this.toplevelApiService
            .getTreeNodes(instance, treeNodeId)
            .pipe(
                finalize(() => {
                    return (this.loadingTreeNode = false);
                })
            )
            .subscribe((res: DTreeNode[]) => {
                if (res && res.length) {
                    const treeNodes = this.formatTreeNodeWithLink(res);

                    this.treeNodeComponentService.updateNodeList(treeNodes);
                    this.treeNodeComponentService.expandTreeFromNode(this.treenodeId);
                    this.treeNodes = treeNodes;
                }
            });
    }

    /**
     * Formats tree node with link
     * @param treeNodes
     * @returns tree node with link
     */
    private formatTreeNodeWithLink(treeNodes: DTreeNode[]): DTreeNode[] {
        return treeNodes.map((item: DTreeNode) => {
            if (!item.children || !item.children.length) {
                return {
                    ...item,
                    disabled: !item.hasAccess,
                    routerLink: `../${item.id}`,
                    label: null,
                    // TODO: Change to reset
                    command: () => {
                        this.sidebarService.setItemSelected({ id: null });
                    },
                };
            }

            return {
                ...item,
                routerLink: `../${item.id}`,
                disabled: !item.hasAccess,
                label: null,
                children: this.formatTreeNodeWithLink(item.children),
                // TODO: Change to reset
                command: () => {
                    this.sidebarService.setItemSelected({ id: null });
                },
            };
        });
    }

    /**
     * on destroy
     */
    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
