import { Component, OnInit, Input, TemplateRef } from '@angular/core';
import { IGroupView } from '../../../../../../shared/models/user';
import { UserGroupProperty, UpdatePropertyValue } from '../../../../../../shared/models/property';
import { TreeNode } from '../../../../../../shared/models/treenode';
import { MyTrendConfig } from '../../../../../../shared/services/myTrendConfig.service';
import { AuthService } from '../../../../../../shared/services/auth.service';
import { TreenodeService } from '../../../../../../shared/services/repositories/treenode.service';
import { GroupService } from '../../../../../../shared/services/repositories/group.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { PropertyService } from '../../../../../../shared/services/repositories/property.service';
import { _ } from '../../../../../../shared/services/translation.service';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: '[app-group-properties]',
    templateUrl: './group-properties.component.html',
    styleUrls: ['./group-properties.component.scss'],
})
export class GroupPropertiesComponent implements OnInit {
    @Input() group: IGroupView;
    loading: boolean;
    properties: UserGroupProperty[];
    treeNodes: TreeNode[];
    nodeProperties: UserGroupProperty[];

    remProperty: UserGroupProperty;
    removeModalRef: BsModalRef;

    selectedNode: TreeNode;
    selectedProperty: UserGroupProperty;

    addGroupModalRef: BsModalRef;
    addPropertyMessage: string;

    treeNodeDropDownConfig = {
        search: true,
        displayKey: 'label',
        height: '300px',
        placeholder: 'Select a TreeNode',
        searchPlaceholder: 'Select a TreeNode',
    };

    propertyDropDownConfig = {
        search: true,
        height: '300px',
        displayKey: 'propertyDisplayName',
        placeholder: 'Select a property',
        searchPlaceholder: 'Select a Property',
    };

    constructor(
        protected authService: AuthService,
        private treeNodeService: TreenodeService,
        private groupService: GroupService,
        private modalService: BsModalService,
        private propertyService: PropertyService,
        private messageService: MessageService,
        private router: Router,
        private translate: TranslateService
    ) {}

    ngOnInit() {
        this.getTreeNodes();
        this.getGroupProperties();
    }

    getGroupProperties() {
        this.loading = true;
        this.groupService.getGroupProperties(this.group.id).subscribe(
            (data) => {
                return (this.properties = data);
            },
            (err) => {
                return (error) => {
                    return console.log('Error: ', error);
                };
            },
            () => {
                return (this.loading = false);
            }
        );
    }

    getTreeNodes() {
        this.treeNodeService.getAllActiveTreeNodes().subscribe((result) => {
            this.treeNodes = result
                .sort((a, b) => {
                    return a.level - b.level;
                })
                .map((x) => {
                    const t = new TreeNodeSelect();
                    Object.assign(t, x);
                    t.label = `${x.structureName} > ${x.name} (${x.code})`;
                    return t;
                });
        });
    }

    onSelectNode() {
        this.selectedProperty = null;
        this.nodeProperties = null;
        if (!this.selectedNode) {
            return;
        }
        this.propertyService.getUserProperties(this.selectedNode.id).subscribe((data) => {
            this.nodeProperties = data
                .filter((x) => {
                    return (
                        this.properties.find((y) => {
                            return y.propertyId === x.propertyId && y.treeNodeId === x.treeNodeId;
                        }) == null && x.isGroup
                    );
                })
                .map((x) => {
                    const t = new UserGroupProperty();
                    Object.assign(t, x);
                    t.propertyDisplayName = `${x.propertyDisplayName}  [${x.propertyType} property]`;
                    return t;
                });
        });
    }

    goToNode(treeNodeId: string) {
        window.open(
            `${MyTrendConfig.myTrendSettings.myTrendUrl}/${this.authService.selectedInstance.name}/Instance#/treenode/${treeNodeId}/details`,
            '_blank'
        );
    }

    goToRecycleBin() {
        const baseUrl = window.location.href.replace(this.router.url, '');
        const url = this.router.serializeUrl(
            this.router.createUrlTree([
                `/${this.authService.selectedInstance.name}/nodes/deletenodes`,
            ])
        );
        window.open(baseUrl + url, '_blank');
    }

    removePropertyModal(template: TemplateRef<any>, property: UserGroupProperty) {
        this.remProperty = new UserGroupProperty();
        Object.assign(this.remProperty, property);
        this.removeModalRef = this.modalService.show(template);
    }

    removeProperty() {
        const update = new UpdatePropertyValue({
            value: null,
            comment: null,
            system: false,
            propertyId: this.remProperty.propertyId,
        });
        this.propertyService
            .updatePropertyValue(this.remProperty.propertyId, this.remProperty.treeNodeId, update)
            .subscribe(
                () => {
                    this.messageService.add({
                        severity: 'success',
                        summary: this.translate.instant(_('Succes!')),
                        detail: this.translate.instant(_('User removed from node!')),
                    });
                },
                (error) => {
                    return console.log('Error: ', error);
                },
                () => {
                    this.getGroupProperties();
                    this.removeModalRef.hide();
                }
            );
    }

    addGroupToProperty(template: TemplateRef<any>) {
        this.propertyService
            .getPropertyValue(this.selectedProperty.propertyId, this.selectedNode.id)
            .subscribe((data) => {
                const propertyValue = data.currentValue;
                if (propertyValue.id) {
                    this.addPropertyMessage = this.translate.instant(
                        _(`Impossible to add ${this.group.name}
           to property. A group is already assigned to this property,
          please navigate towards node to perform changes.`)
                    );
                    this.addGroupModalRef = this.modalService.show(template);
                } else {
                    const value = new UpdatePropertyValue({
                        value: this.group.id,
                        propertyId: this.selectedProperty.propertyId,
                        system: false,
                    });
                    this.propertyService
                        .updatePropertyValue(
                            this.selectedProperty.propertyId,
                            this.selectedNode.id,
                            value
                        )
                        .subscribe(
                            () => {
                                this.messageService.add({
                                    severity: 'success',
                                    summary: this.translate.instant(_('Succes!')),
                                    detail: this.translate.instant(_('User added to node!')),
                                });
                            },
                            (error) => {
                                return console.log('Error: ', error);
                            },
                            () => {
                                this.getGroupProperties();
                            }
                        );
                }
            });
    }
}

class TreeNodeSelect extends TreeNode {
    label: string;
}
