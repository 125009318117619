import { Component, Input, OnInit } from '@angular/core';

// Models
import { PendingValidation } from 'src/app/mytrend/workspace/models';
import { TreeNodeParent } from 'src/app/shared/models/treenode';

// Ngx
import { TranslateService } from '@ngx-translate/core';

// PrimeNg
import { MenuItem } from 'primeng/api';
import { Router } from '@angular/router';

// Services
import { _ } from '../../../../../shared/services/translation.service';
import { AuthService } from 'src/app/shared/services';

@Component({
    selector: 'app-top-bar-update-manager-properties',
    templateUrl: './top-bar-update-manager-properties.component.html',
    styleUrls: ['./top-bar-update-manager-properties.component.scss'],
})
export class TopBarUpdateManagerPropertiesComponent implements OnInit {
    /**
     * Input  of top bar update manager properties component
     */
    @Input() pendingValidations: PendingValidation[];

    /**
     * Breadcrumb items of top bar update manager properties component
     */
    public breadcrumbItems: MenuItem[] = [];

    /**
     * Grouped validations of top bar update manager properties component
     */
    public groupedValidations: PendingValidation[];

    /**
     * Creates an instance of top bar update manager properties component.
     * @param router
     * @param translate
     * @param authService
     */
    constructor(
        private readonly router: Router,
        private readonly translate: TranslateService,
        private readonly authService: AuthService
    ) {}

    /**
     * on init
     */
    public ngOnInit(): void {
        this.loadBreadcrumb();
        this.groupValidationsByType();
    }

    /**
     * Gets number of validations
     * @param type
     * @returns number of validations
     */
    public getNumberOfValidations(type: string): number {
        const number = this.pendingValidations.filter((pending: PendingValidation) => {
            return pending.type === type;
        }).length;

        return number;
    }

    /**
     * Groups validations by type
     */
    public groupValidationsByType(): void {
        this.groupedValidations = this.pendingValidations.reduce((acc, current) => {
            const x = acc.find((item: PendingValidation) => {
                return item.type === current.type;
            });
            if (!x) {
                return acc.concat([current]);
            } else {
                return acc;
            }
        }, []);
    }

    /**
     * Gets name of validation
     * @param type
     * @returns name of validation
     */
    public getNameOfValidation(type: string): string {
        switch (type) {
            case 'Property':
                return this.translate.instant(_('Pending Properties'));
            case 'Create':
                return this.translate.instant(_('Treenodes pending creation'));
            case 'Delete':
                return this.translate.instant(_('Treenodes pending deletion'));
            case 'ChangeRequest':
                return this.translate.instant(_('Change Request'));
        }
    }

    public getOnHoldValidations(type: string): number {
        const number = this.pendingValidations.filter((pending: PendingValidation) => {
            return pending.type === type && pending.onHold;
        }).length;

        return number;
    }

    /**
     * Loads breadcrumb
     * @param validations
     */
    private loadBreadcrumb(): void {
        this.pendingValidations[0].parents.forEach((parent: TreeNodeParent) => {
            this.breadcrumbItems.push({
                label: parent.name,
                command: () => {
                    this.router.navigate([
                        `${this.authService.selectedInstance.name}/treenode/${this.pendingValidations[0].treeNodeId}`,
                    ]);
                },
            });
        });
        this.breadcrumbItems.push({
            label: this.pendingValidations[0].treeNodeName,
            command: () => {
                this.router.navigate([
                    `${this.authService.selectedInstance.name}/treenode/${this.pendingValidations[0].treeNodeId}`,
                ]);
            },
        });
        this.breadcrumbItems[0].icon = 'fas fa-map-marker-alt';
    }
}
