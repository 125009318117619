import { Router } from '@angular/router';
import {
    HttpErrorResponse,
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
} from '@angular/common/http';

// Rxjs
import { tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

// Services
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { _ } from '../../shared/services/translation.service';
export class HttpErrorInterceptor implements HttpInterceptor {
    /**
     * Creates an instance of http error interceptor.
     * @param router
     * @param messageService
     * @param translate
     */
    constructor(
        private readonly router: Router,
        private readonly messageService: MessageService,
        private readonly translate: TranslateService
    ) {}

    /**
     * Intercepts http error interceptor
     * @param request
     * @param next
     * @returns intercept
     */
    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            tap(
                (incoming: any) => {},
                (error: HttpErrorResponse) => {
                    // in case error is blog fire exeption when bloberror loaded
                    const reader = new FileReader();
                    const _this = this;
                    reader.addEventListener('loadend', function () {
                        _this.messageService.add({
                            severity: 'error',
                            summary: 'Error!',
                            // Fix translate
                            // summary: _this.translate.instant(_('Error!')),
                            detail: reader.result.toString(),
                        });
                    });

                    let errMsg = '';
                    // Client Side Error
                    if (error.error instanceof ErrorEvent) {
                        errMsg = `Error: ${error.error.message} `;
                    } else {
                        // Server Side Error
                        errMsg = `Error Code: ${error.status}, Message: ${error.message} `;
                        switch (error.status) {
                            case 400: // Bad Request = MyTrendException
                                if (error.error) {
                                    if (error.error instanceof Blob) {
                                        reader.readAsText(error.error);
                                    } else {
                                        const err = error.error.message || error.error;
                                        errMsg = err;
                                        this.messageService.add({
                                            severity: 'error',
                                            summary: 'Error!',
                                            // Fix translate
                                            // summary: _this.translate.instant(_('Error!')),
                                            detail: err,
                                        });
                                    }
                                }
                                break;
                            case 401: // Unauthorized
                                this.router.navigate(['/auth/login']);
                                break;
                            case 500: // Internal Server Error
                            case 403: // Forbidden
                            case 404: // Not Found
                            case 502: // Bad Gateway
                            case 503: // Service Unavailable
                            case 504: // Gateway Timeout
                                this.router.navigate(['/error/' + error.status]);
                                break;
                            default:
                                this.router.navigate(['/error/' + error.status]);
                        }
                    }

                    return of(errMsg);
                }
            )
        );
    }
}
