import { Injectable } from '@angular/core';
import { ILogEntry, HistorySearch, HistoryExportModel } from '../../models/logEntry';
import { PagingResult } from '../../models/pagingResult';
import { Observable } from 'rxjs';
import { HttpExtendedInstanceService } from 'src/app/shared/services';
import { HttpResponse } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class HistoryService {
    constructor(private http: HttpExtendedInstanceService) {}

    getHistoryEntries(historySearch: HistorySearch): Observable<PagingResult<ILogEntry>> {
        return this.http.get<PagingResult<ILogEntry>>(
            'admin/history',
            this.http.baseOptions(),
            historySearch
        );
    }

    getHistory(historyExportModel: HistoryExportModel): Observable<HttpResponse<Blob>> {
        return this.http.post<HttpResponse<Blob>>(
            `export/history`,
            historyExportModel,
            this.http.blobOptions()
        );
    }
}
