import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/';
import { HttpExtendedInstanceService } from 'src/app/shared/services';

import { KpiColumn } from '../../models/Kpi';
@Injectable({
    providedIn: 'root',
})
export class KpiService {
    constructor(private http: HttpExtendedInstanceService) {}

    //#region KPI Column
    getKpisColumn(): Observable<KpiColumn[]> {
        return this.http.get<KpiColumn[]>('admin/kpicolumns');
    }

    /**
     * Gets instance kpis column
     * @returns instance kpis column
     */
    public getInstanceKpisColumn(): Observable<KpiColumn[]> {
        return this.http.get<KpiColumn[]>('kpis/columns');
    }

    getKpiColumn(id: string): Observable<KpiColumn> {
        return this.http.get<KpiColumn>(`admin/kpicolumns/${id}`);
    }

    setKpi(kpi: KpiColumn) {
        return this.http.post('admin/kpicolumns', kpi);
    }

    updateKpi(id: string, kpi: KpiColumn) {
        return this.http.put(`admin/kpicolumns/${id}`, kpi);
    }

    deleteKpi(id: string) {
        return this.http.delete(`admin/kpicolumns/${id}`);
    }
}
