import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MessageService } from 'primeng/api';

import { _ } from '../../../../../../shared/services/translation.service';
import { IGroup, User, IGroupView } from '../../../../../../shared/models/user';
import { UserService } from '../../../../../../shared/services/repositories/user.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: '[app-group-users]',
    templateUrl: './group-users.component.html',
    styleUrls: ['./group-users.component.scss'],
})
export class GroupUsersComponent implements OnInit {
    _group: IGroupView;
    // sub User Groups
    @Output() groupCChange = new EventEmitter();

    @Input()
    get groupC() {
        return this._group;
    }

    set groupC(val) {
        this._group = val;
        this.groupCChange.emit(this._group);
    }

    public groupUsers: User[] = [];
    public loadingUsers = false;
    public users: User[] = [];
    public selectedUser: IGroup;

    constructor(
        private userService: UserService,
        private messageService: MessageService,
        private translate: TranslateService
    ) {}

    ngOnInit() {
        this.getGroupUsers();
    }

    public getGroupUsers() {
        this.loadingUsers = true;
        this.groupUsers = [];
        this.searchGroupUsers();
    }

    private searchGroupUsers() {
        this.selectedUser = null;
        this.userService.getGroupUsers(this.groupC.id).subscribe((res) => {
            this.groupUsers = res;
            this.loadingUsers = false;
        });
    }

    private updateGroup(add: number) {
        this.groupC.numberOfUsers += add;
    }

    // search for users available
    search(event) {
        this.userService.getUsersForGroup(this.groupC.id, event.query).subscribe((res) => {
            this.users = res;
        });
    }

    addGroupUsers() {
        if (this.selectedUser) {
            this.userService.addGroupToUser(this.selectedUser.id, this.groupC.id).subscribe(
                () => {
                    this.messageService.add({
                        severity: 'success',
                        summary: this.translate.instant(_('Succes!')),
                        detail: this.translate.instant(_('User added with success to Group!')),
                    });
                },
                (error) => {
                    return console.log('Error: ', error);
                },
                () => {
                    this.searchGroupUsers();
                    this.updateGroup(1);
                }
            );
        }
    }

    removeUser(user: User) {
        this.userService.removeUserFromGroup(user.id, this.groupC.id).subscribe(
            () => {
                this.messageService.add({
                    severity: 'success',
                    summary: this.translate.instant(_('Succes!')),
                    detail: this.translate.instant(_('User removed from Group with success!')),
                });
            },
            (error) => {
                return console.log('Error: ', error);
            },
            () => {
                this.searchGroupUsers();
                this.updateGroup(-1);
            }
        );
    }
}
