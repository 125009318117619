import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';

// dFakto lib
import { DDropdownAlignment, DMenuItem, DMenuTypes } from '@depfac/dfakto-ui/src/lib/menu-item';
import { TopBarService } from '@depfac/dfakto-ui/src/lib/top-bar';

// Ngx
import { TranslateService } from '@ngx-translate/core';

// Rxjs
import { forkJoin, Observable, of } from 'rxjs';
import { delay, map } from 'rxjs/operators';

// Services
import { _ } from '../../shared/services/translation.service';
import { AuthService } from './auth.service';
import { UserService } from 'src/app/shared/services/repositories/user.service';
import { UserProfileService } from '.';
import { ProfileDocumentService } from 'src/app/mytrend/profile/services';
import { NotificationService } from './notification/notification.service';
import { UpdateTypes } from '../models/enums';

@Injectable({
    providedIn: 'root',
})
export class TopBarGeneratorService {
    /**
     * Language items of top bar generator service
     */
    private languageItems: DMenuItem[] = [];


    /**
     * Creates an instance of top bar generator service.
     * @param router
     * @param authService
     * @param userService
     * @param translate
     * @param topBarService
     * @param userProfileService
     * @param profileDocumentService
     * @param notificationService
     */
    constructor(
        public readonly router: Router,
        private readonly authService: AuthService,
        private readonly userService: UserService,
        private readonly translate: TranslateService,
        private readonly topBarService: TopBarService,
        private readonly userProfileService: UserProfileService,
        private readonly profileDocumentService: ProfileDocumentService,
        private readonly notificationService: NotificationService
    ) {
        this.translate.getLangs().forEach((l) => {
            this.languageItems.push({
                id: l,
                title: l.toUpperCase(),
                command: () => {
                    return this.translate.use(l);
                },
            });
        });
    }

    /**
     * Gets base top bar
     * @param [isWorkspace]
     * @returns base top bar
     */
    public getBaseTopBar(
        isWorkspace: boolean = false,
        hasvalidationright: boolean = false
    ): DMenuItem[] {
        const result: DMenuItem[] = [];

        if (isWorkspace && hasvalidationright) {
            result.push(this.getNotificationItem());
        }

        result.push(this.getDocumentFaqsItems());

        if (this.authService.actualUser.isAdministrator) {
            result.push(this.getAdminItem(isWorkspace));
        }

        if (this.languageItems.length > 1) {
            result.push(this.getLanguageItem());
        }

        result.push(this.getUserMenuItem());
        return result;
    }

    /**
     * Gets admin area top bar
     * @returns admin area top bar
     */
    public getAdminAreaTopBar(): DMenuItem[] {
        const result: DMenuItem[] = [];
        result.push(this.getDocumentFaqsItems());

        if (this.languageItems.length > 1) {
            result.push(this.getLanguageItem());
        }

        result.push(this.getUserMenuItem());
        return result;
    }

    /**
     * Gets user menu item
     * @returns user menu item
     */
    public getUserMenuItem(): DMenuItem {
        const menuitems: DMenuItem = {
            id: 'user',
            avatarLabel: this.userProfileService.getNameTwoLetters(
                this.authService.actualUser.displayName
            ),
            warningPropagation: true,
            title: this.authService.actualUser.displayName,
            details: this.authService.actualUser.logedAsRealId
                ? this.translate.instant(_('Logged as'))
                : '',
            type: DMenuTypes.USER,
            dropdownAlignment: DDropdownAlignment.RIGHT,
            items: [],
        };

        menuitems.items.push(
            ...[
                {
                    id: 'profile',
                    title: this.translate.instant(_('Profile')),
                    icon: 'fas fa-user',
                    type: DMenuTypes.BASIC,
                    routerLink: 'profile/home',
                    // command: () => {
                    //     return this.navigate('profile/home');
                    // },
                },
                {
                    id: 'history',
                    title: this.translate.instant(_('History')),
                    icon: 'fas fa-history',
                    type: DMenuTypes.BASIC,
                    routerLink: 'profile/history',
                    // command: () => {
                    //     return this.navigate('profile/history');
                    // },
                },
                { separator: true },
            ]
        );

        if (this.authService.actualUser.logedAsRealId) {
            menuitems.items.push({
                id: 'loggedAs',
                title:
                    this.translate.instant(_('Log back as ')) +
                    this.authService.actualUser.logedAsRealDisplayName,
                icon: 'fas fa-retweet',
                type: DMenuTypes.BASIC,
                command: () => {
                    return this.logAsBack();
                },
            });
        }

        menuitems.items.push({
            id: 'logout',
            title: this.translate.instant(_('Logout')),
            icon: 'fas fa-sign-out-alt',
            type: DMenuTypes.BASIC,
            command: () => {
                return this.logOut();
            },
        });

        return menuitems;
    }

    /**
     * Gets language item
     * @returns language item
     */
    private getLanguageItem(): DMenuItem {
        return {
            id: 'lang',
            title: this.translate.currentLang.toUpperCase(),
            items: this.languageItems,
            caret: true,
            dropdownAlignment: DDropdownAlignment.RIGHT,
            tooltip: 'Language',
        };
    }

    /**
     * Gets total new notifications
     * @param [isWorkspace]
     * @param [hasvalidationright]
     * @returns total new notifications
     */
    public getTotalNewNotifications(
        isWorkspace: boolean = false,
        hasvalidationright: boolean = false
    ): Observable<DMenuItem[]> {
        return forkJoin([this.notificationService.getTotalNodesToUpdate(UpdateTypes.Progress), this.notificationService.getTotalNodesToUpdate(UpdateTypes.Scorecard), this.notificationService.getTotalNotViewed()])
            .pipe(
                map((res) => {
                    let items = this.getBaseTopBar(isWorkspace, hasvalidationright);

                   
                    // updates item
                    // add 'updates' if exists each time
                    items = items.filter(x => { return x.id !== 'updates' });

                    const progressUpdates = res[0]?.length || 0;
                    const financialUpdates = res[1]?.length || 0;
                    const totalUpdates = progressUpdates + financialUpdates;

                    if (totalUpdates) {
                        let item = this.getUpdatesItem();
                        item.badge = totalUpdates;
                        items.unshift(item);
                    }

                    // validations item
                   
                    if (res[2]) {
                        const item = items.find((x) => {
                            return x.id === 'notifications';
                        });
                        item.badge = res[2];
                        item.items = [];
                    }

                    return items;
                })
            );
    }


    /**
     * Gets total new documents
     * @param [removeItem]
     * @param [isWorkspace]
     * @param [hasvalidationright]
     * @returns total new documents
     */
    public getTotalNewDocuments(
        removeItem: boolean = false,
        isWorkspace: boolean = false,
        hasvalidationright: boolean = false
    ): Observable<DMenuItem[]> {
        let items = this.getBaseTopBar(isWorkspace, hasvalidationright);
        if (removeItem) {
            items = items.filter((item: DMenuItem) => {
                return item.id !== 'documents-faqs';
            });
            return of(items);
        } else {
            return this.profileDocumentService.getTotalNotViewed().pipe(
                map((newDocs) => {
                    items = this.getBaseTopBar(isWorkspace, hasvalidationright);
                    const item = items.find((x) => {
                        return x.id === 'documents-faqs';
                    });
                    if (newDocs) {
                        item.badge = newDocs;
                        const childDocument = item.items.find((x) => {
                            return x.id === 'documents';
                        });
                        childDocument.badge = newDocs;
                    }
                    return items;
                })
            );
        }
    }

    /**
     * Gets document faqs items
     * @returns document faqs items
     */
    private getDocumentFaqsItems(): DMenuItem {
        const menuitems: DMenuItem = {
            id: 'documents-faqs',
            icon: 'fas fa-book',
            badgeSeverity: 'danger',
            dropdownAlignment: DDropdownAlignment.RIGHT,
            items: [],
            tooltip: 'Documents',
        };

        menuitems.items.push(
            ...[
                {
                    id: 'faq',
                    title: this.translate.instant(_('FAQ')),
                    icon: 'fas fa-question-circle',
                    type: DMenuTypes.BASIC,
                    routerLink: 'profile/faqs',
                    // command: () => {
                    //     return this.navigate('profile/faqs');
                    // },
                },
                {
                    id: 'documents',
                    title: this.translate.instant(_('Documents')),
                    icon: 'fas fa-file',
                    type: DMenuTypes.BASIC,
                    badgeSeverity: 'danger',
                    routerLink: 'profile/documents',
                    // command: () => {
                    //     return this.navigate('profile/documents');
                    // },
                },
            ]
        );
        return menuitems;
    }

    /**
     * Gets admin item
     * @returns admin item
     */
    private getAdminItem(isWorkspace: boolean = false): DMenuItem {
        return {
            id: 'admin',
            icon: 'fas fa-cog',
            tooltip: 'Admin',
            routerLink: `admin/${isWorkspace ? this.authService.selectedInstance.name : 'global'}`,
        };
    }

    /**
     * Gets notification item
     * @returns notification item
     */
    private getNotificationItem(): DMenuItem {
        return {
            id: 'notifications',
            icon: 'fas fa-check-circle',
            badgeSeverity: 'danger',
            routerLink: ``,
            tooltip: 'Notifications',
            dropdownAlignment: DDropdownAlignment.RIGHT,
        };
    }

    /**
 * Gets notification item
 * @returns notification item
 */
    private getUpdatesItem(): DMenuItem {
        return {
            id: 'updates',
            icon: 'fas fa-list',
            badgeSeverity: 'danger',
            routerLink: ``,
            tooltip: 'Updates',
            dropdownAlignment: DDropdownAlignment.RIGHT,
        };
    }

    /**
     * Logs as back
     */
    private logAsBack(): void {
        this.userService.logBackAs(this.authService.actualUser.logedAsRealId).subscribe((x) => {
            return this.authService.getActualUser('').subscribe(() => {
                this.topBarService.updateMenuItemList(this.getBaseTopBar());
                window.location.reload();
            });
        });
    }

    /**
     * Logs out
     */
    private logOut(): void {
        this.userService.logOut().subscribe((x) => {
            this.authService.actualUser = null;
            this.router.navigate([`auth/login`]);
        });
    }

    private navigate(path: string): void {
        this.router.navigate([path]);
    }
}
