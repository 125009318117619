import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PaginatedQuerySearch, PagingResult } from 'src/app/shared/models/pagingResult';
import { HttpExtendedInstanceService } from 'src/app/shared/services/http-extended/http-extended-instance.service';
import { ReportTrigger } from '../models/reportTrigger';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(private http: HttpExtendedInstanceService) { }

  getReportTriggers(search: PaginatedQuerySearch): Observable<PagingResult<ReportTrigger>> {
    return this.http.get<PagingResult<ReportTrigger>>('reports/executions', this.http.baseOptions(), search);
  }

  triggerReportGeneration(): Observable<void> {
    return this.http.post(`reports/execute`, null);
  }

}
