import { Injectable } from '@angular/core';
import { HttpExtendedInstanceService } from '../../../shared/services';
import { Observable } from 'rxjs';
import { Template, TemplateModel } from '../../../shared/models/template';

@Injectable({
    providedIn: 'root',
})
export class TemplateService {
    constructor(private http: HttpExtendedInstanceService) {}

    getAllTemplates(): Observable<Template[]> {
        return this.http.get<Template[]>('templates');
    }

    getTemplateById(templateId: string): Observable<Template> {
        return this.http.get<Template>(`templates/${templateId}`);
    }

    createTemplate(template: TemplateModel): Observable<Template> {
        return this.http.post<Template>(`templates`, template);
    }

    updateTemplate(template: TemplateModel): Observable<Template> {
        return this.http.put<Template>(`templates`, template);
    }
}
