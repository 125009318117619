import { Component, OnInit } from '@angular/core';
import { TopService } from '../../../../../shared/services/repositories/top.service';
import { BaseComponent } from '../../../../shared/components/base/base.component';
import { WorkflowService } from '../../../../shared/services/workflow.service';
import { Router } from '@angular/router';
import {
    TopLevelCurrency,
    Currency,
    CurrencyModel,
    EditTopLevelCurrencyModel,
} from '../../../../../shared/models/exchangerate';
import { Top } from '../../../../../shared/models/top';
import { fadeInOut } from 'src/app/shared/animations/fade';
import getSymbolFromCurrency from 'currency-symbol-map';
import { MessageService } from 'primeng/api';
import { forkJoin } from 'rxjs';
import { _ } from '../../../../../shared/services/translation.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-currencies',
    templateUrl: './currencies.component.html',
    styleUrls: ['./currencies.component.scss'],
    animations: [fadeInOut],
})
export class CurrenciesComponent extends BaseComponent implements OnInit {
    topLevels: Top[];
    selectedTopLevel: Top;

    currencies: { [index: string]: string };
    topLevelCurrencies: TopLevelCurrency[];

    availableCurrencies: CurrencyModel[];
    usedCurrencies: CurrencyModel[];

    constructor(
        public router: Router,
        private workflowService: WorkflowService,
        private topService: TopService,
        private messageService: MessageService,
        private translate: TranslateService
    ) {
        super(router, workflowService);
        this.dynamicBreadCrumb('Exchange Rate');
    }

    ngOnInit() {
        this.topService.getTops().subscribe((data) => {
            this.topLevels = data;
            if (this.topLevels.length === 1) {
                this.onTopSelect(this.topLevels[0].id);
            }
        });
    }

    onTopSelect(id: string, loading = true) {
        this.loading = loading;
        this.selectedTopLevel = this.topLevels.find((x) => {
            return x.id === id;
        });
        if (!this.selectedTopLevel) {
            return;
        }

        forkJoin(
            this.topService.getAllCurrencies(),
            this.topService.getTopLevelCurrencies(this.selectedTopLevel.id)
        ).subscribe(
            (data) => {
                this.currencies = data[0];
                this.topLevelCurrencies = data[1];
                this.usedCurrencies = [];
                this.availableCurrencies = [];
                // eslint-disable-next-line guard-for-in
                for (const key in this.currencies) {
                    const value = this.currencies[key];
                    const currency: CurrencyModel = { code: key, value };
                    if (
                        this.topLevelCurrencies.find((y) => {
                            return y.currencySymbol.toLowerCase() === currency.code.toLowerCase();
                        })
                    ) {
                        this.usedCurrencies.push(currency);
                    } else {
                        this.availableCurrencies.push(currency);
                    }
                }
            },
            (err) => {
                return console.log(err);
            },
            () => {
                return (this.loading = false);
            }
        );
    }

    saveCurrencies() {
        const editModel = new EditTopLevelCurrencyModel();
        editModel.topLevelId = this.selectedTopLevel.id;
        editModel.topLevelName = this.selectedTopLevel.name;
        editModel.currencies = {};
        this.usedCurrencies.forEach((currency) => {
            editModel.currencies[currency.code.toUpperCase()] = true;
        });
        this.topService.saveCurrencies(editModel).subscribe(
            (data) => {},
            (err) => {
                this.ngOnInit();
            },
            () => {
                this.messageService.add({
                    severity: 'success',
                    summary: this.translate.instant(_('Success!')),
                    detail: this.translate.instant(_('Currencies updated!')),
                });
            }
        );
    }

    getCurrencySymbol(currencyCode: string) {
        return getSymbolFromCurrency(currencyCode);
    }
}
