import { Subject } from 'rxjs';

import { _ } from '../../../../shared/services/translation.service';
import { Router } from '@angular/router';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker/bs-datepicker.config';
import { WorkflowService } from '../../services/workflow.service';
import { BreadcrumElement } from 'src/app/shared/models/navigationElement';

export abstract class BaseComponent {
    // datapicker
    // see doc to change color, 'theme-custom' is a custom color created on this project with variable colors
    // https://valor-software.com/ngx-bootstrap/#/datepicker
    public bsConfig: Partial<BsDatepickerConfig> = Object.assign(
        {},
        { containerClass: 'theme-custom', dateInputFormat: 'DD-MM-YYYY' }
    );

    // Datatables

    public dtOptions: DataTables.Settings = {
        ordering: true,
        paging: true,
        lengthChange: false,
        language: {
            searchPlaceholder: 'Filter',
            search: '',
        },
        order: [],
    };

    // Modal Config
    modalConfig = {
        ignoreBackdropClick: true,
        // class: 'modal-sm'
    };

    public loading = false;

    public breadCrumbElements: BreadcrumElement[] = [];

    constructor(public router: Router, protected workflowservice: WorkflowService) {}

    public refreshTable(id: string, dtTrigger: Subject<any>) {
        const table = $(id).DataTable({
            destroy: true,
        });
        table.destroy();
        dtTrigger.next(null);
        this.loading = false;
    }

    public dynamicBreadCrumb(
        titletofind: string,
        subElementTitle: string = null,
        params: BreadcrumElement[] = null
    ) {
        const route: string = this.router.url;
        let currentElement;
        this.breadCrumbElements = [];
        const workflowlayout = this.workflowservice.layoutElements.find((x) => {
            return x.title === titletofind;
        });
        this.breadCrumbElements.push({
            name: workflowlayout.title,
            link: workflowlayout.link,
            isActive: false,
            subElements: [],
            permissions: null,
        });
        const workflows = {
            name: workflowlayout.title,
            link: '',
            isActive: false,
            permissions: null,
            subElements: [],
        };
        let subElementFound = false;

        workflowlayout.modulesElements
            .filter((x) => {
                return !x.disabled;
            })
            .forEach((element) => {
                if (subElementTitle) {
                    if (subElementTitle === element.title) {
                        currentElement = element;
                        workflows.name = element.title;
                        subElementFound = true;
                    }
                } else if (route.endsWith(element.link)) {
                    currentElement = element;
                    workflows.name = element.title;
                }
                workflows.subElements.push({
                    name: element.title,
                    link: workflowlayout.type.toLowerCase() + '/' + element.link,
                    isActive: currentElement && currentElement.title === element.title,
                    subElements: [],
                    permissions: element.permissions,
                });
            });
        this.breadCrumbElements.push(workflows);
        if (params != null) {
            params.forEach((elem) => {
                this.breadCrumbElements.push(elem);
                workflows.name = subElementTitle;
            });
        }
    }
}
