import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MessageService } from 'primeng/api';

import { finalize } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { fadeInOut } from 'src/app/shared/animations/fade';
import { BaseComponent } from 'src/app/admin/shared/components/base/base.component';
import { Snapshot } from 'src/app/shared/models/snapshot';
import { WorkflowService } from 'src/app/admin/shared/services/workflow.service';
import { ConfirmationDialogService } from 'src/app/shared/components/utils/confirmation/ConfirmationDialog.service';
import { SnapshotService } from 'src/app/shared/services/repositories/snapshot.service';
import { _ } from '../../../../../shared/services/translation.service';
import { object } from 'underscore';

@Component({
    selector: 'app-snapshot',
    templateUrl: './snapshot.component.html',
    styleUrls: ['./snapshot.component.scss'],
    animations: [fadeInOut],
})
export class SnapshotComponent extends BaseComponent implements OnInit, OnDestroy {
    public dtTrigger: Subject<any> = new Subject();
    public snapshots: Array<Snapshot> = [];
    public addModalRef: BsModalRef;
    public editModalRef: BsModalRef;
    public errorMessage: string;
    public isHttpRequestRunning = false;

    public newSnapshot: Snapshot = new Snapshot();
    public edtSnapshot: Snapshot = new Snapshot();

    private onDestroy$ = new Subject<void>();
    private readonly DAY_MILLISECONDS = 86400000;

    constructor(
        public router: Router,
        protected workflowService: WorkflowService,
        private messageService: MessageService,
        private modalService: BsModalService,
        private translate: TranslateService,
        private confirmationService: ConfirmationDialogService,
        public snapshotService: SnapshotService
    ) {
        super(router, workflowService);
        this.dynamicBreadCrumb('Reporting', 'Snapshot Dates');
    }

    ngOnInit(): void {
        this.dtOptions = {
            ...this.dtOptions,
            order: [[0, 'asc']],
            // disable order in specific columns
            columnDefs: [{ orderable: false, targets: 3 }],
        };
        this.getSnapshots();
    }

    getSnapshots() {
        this.loading = true;

        this.snapshotService.getSnapshots().subscribe((res: Snapshot[]) => {
            this.snapshots = res;
            this.refreshTable('#table', this.dtTrigger);
        });
    }

    addSnapshotModal(modal: TemplateRef<any>) {
        this.errorMessage = null;
        this.newSnapshot = new Snapshot();
        this.addModalRef = this.modalService.show(modal, this.modalConfig);
    }

    addSnapshot() {
        this.isHttpRequestRunning = true;
        this.errorMessage = null;

        const model = {
          id: this.newSnapshot.id,
           reportingDate:  this.newSnapshot.reportingDate.toCustomString(),
          dataDate: this.newSnapshot.dataDate.toCustomString(),
          name: this.newSnapshot.name
        };

        this.snapshotService
            .createSnapshot(model)
            .pipe(
                finalize(() => {
                    return (this.isHttpRequestRunning = false);
                })
            )
            .subscribe(
                () => {
                    this.messageService.add({
                        severity: 'success',
                        summary: this.translate.instant(_('Success!')),
                        detail: this.translate.instant(_('Snapshot created with success!')),
                    });
                    this.addModalRef.hide();
                },
                (err) => {
                    return (this.errorMessage = err.error || err.message || err);
                },
                () => {
                    this.getSnapshots();
                }
            );
    }

    editSnapshotModal(modal: TemplateRef<any>, snapshot: Snapshot) {
        this.errorMessage = null;
        this.edtSnapshot = {
            ...snapshot,
            reportingDate: new Date(snapshot.reportingDate),
            dataDate: new Date(snapshot.dataDate),
        };

        this.editModalRef = this.modalService.show(modal, this.modalConfig);
    }

    editSnapshot() {
        this.isHttpRequestRunning = true;
        this.errorMessage = null;

        const model = {
          id: this.edtSnapshot.id,
           reportingDate:  this.edtSnapshot.reportingDate.toCustomString(),
          dataDate: this.edtSnapshot.dataDate.toCustomString(),
          name: this.edtSnapshot.name
        };

        this.snapshotService
            .updateSnapshot(model)
            .pipe(
                finalize(() => {
                    return (this.isHttpRequestRunning = false);
                })
            )
            .subscribe(
                () => {
                    this.messageService.add({
                        severity: 'success',
                        summary: this.translate.instant(_('Success!')),
                        detail: this.translate.instant(_('Snapshot updated with success!')),
                    });
                    this.editModalRef.hide();
                },
                (err) => {
                    return (this.errorMessage = err.error || err);
                },
                () => {
                    this.getSnapshots();
                }
            );
    }

    removeSnapshot(snapshot: any) {
        const confirmMessage = this.translate.instant(_('Do you want to delete this snapshot?'));

        this.confirmationService.confirm(confirmMessage).subscribe((result) => {
            if (result) {
                this.snapshotService.removeSnapshot(snapshot.id).subscribe(
                    () => {
                        this.messageService.add({
                            severity: 'success',
                            summary: this.translate.instant(_('Success!')),
                            detail: this.translate.instant(_('Snapshot removed with success!')),
                        });
                    },
                    (error) => {
                        return console.log('Error: ', error);
                    },
                    () => {
                        this.getSnapshots();
                    }
                );
            }
        });
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
    }
}
