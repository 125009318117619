import { MessageType } from './enums';

export class Message {
    id: string;
    text: string;
    fromDate: Date;
    endDate: Date;
    adminOnly: boolean;
    type: MessageType;
    topLevelId: string;

    // helper
    topName: string;
}

export interface MessageView {
    id: string;
    text: string;
    fromDate: Date;
    endDate: Date;
    adminOnly: boolean;
    type: MessageType;
    topLevelId: string;
    topLevelName: string;
}
