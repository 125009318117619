import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// Models
import { IMyTrendAppConfig } from '../../admin/shared/models/myTrendConfig';

@Injectable()
export class MyTrendConfig {
    /**
     * My trend settings of my trend config
     */
    static myTrendSettings: IMyTrendAppConfig;

    /**
     * Creates an instance of my trend config.
     * @param http
     */
    constructor(private http: HttpClient) {}

    /**
     * Loads my trend config
     * @returns
     */
    load() {
        const jsonFile = 'assets/config/config.json';
        return new Promise<void>((resolve, reject) => {
            this.http
                .get(jsonFile)
                .toPromise()
                .then((config: IMyTrendAppConfig) => {
                    MyTrendConfig.myTrendSettings = config;
                    resolve();
                })
                .catch((response: any) => {
                    reject(`Could not load file '${jsonFile}' : ${JSON.stringify(response)}`);
                });
        });
    }
}
