import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Views
import { CoreLayoutComponent } from './core/layout/layout.component';

// Routes
import { coreRoutes } from './core/route/core-routing.module';

const routes: Routes = [
    {
        path: '',
        component: CoreLayoutComponent,
        children: coreRoutes,
    },
    {
        path: 'auth',
        loadChildren: () => {
            return import('./mytrend/auth/auth.module').then((m) => {
                return m.AuthModule;
            });
        },
    },
    { path: '**', redirectTo: '' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
