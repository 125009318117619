import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

// Services
import { TreenodeService, UserService } from 'src/app/shared/services/repositories';
import { AuthService } from 'src/app/shared/services';
import { ValidationService } from '../views/update-manager/services';

// Rxjs
import { forkJoin, Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class WorkspaceLayoutResolver implements Resolve<any> {
    /**
     * Creates an instance of workspace layout resolver.
     * @param validationService
     * @param treenodeService
     * @param authService
     * @param userService
     */
    constructor(
        private readonly validationService: ValidationService,
        private readonly treenodeService: TreenodeService,
        private readonly authService: AuthService,
        private readonly userService: UserService
    ) {}

    /**
     * Resolves workspace layout resolver
     * @param route
     * @returns resolve
     */
    public resolve(route: ActivatedRouteSnapshot): Observable<any> {
        const treenodeId = route.paramMap.get('treenodeId');

        const topLevelId$ = this.treenodeService.getTreeNodeTopLevelId(treenodeId);
        const validations$ = this.authService.actualUser.isAdministrator
            ? of(true)
            : this.validationService.getValidations();
        const actualUser$ = this.userService.getActualUser();

        return forkJoin([topLevelId$, validations$, actualUser$]);
    }
}
