import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
  selector: '[appRange]',
  providers: [{ provide: NG_VALIDATORS, useExisting: NumberRangeValidatorDirective, multi: true }]
})
export class NumberRangeValidatorDirective implements Validator {
  @Input('appRange') rangeInput: string;

  validate(control: AbstractControl): { [key: string]: any } | null {
    const range: number[] = JSON.parse(this.rangeInput);

    const value = control.value as number;
    const valid = value >= range[0] && value <= range[1];
    return !valid ? { unvalidRange: { value: control.value } } : null;
  }
}
