import { Injectable } from '@angular/core';

// dfakto-lib
import { DTreeNode } from '@depfac/dfakto-ui/src/lib/tree-node';

// Rxjs
import { Observable } from 'rxjs';

// Services
import { HttpExtendedGlobalService } from 'src/app/shared/services';

@Injectable({
    providedIn: 'root',
})
export class ToplevelApiService {
    /**
     * Creates an instance of toplevel api service.
     * @param http
     */
    constructor(private http: HttpExtendedGlobalService) {}

    /**
     * Gets tree nodes
     * @param instance
     * @param toplevelId
     * @returns tree nodes
     */
    public getTreeNodes(instance: string, toplevelId: string): Observable<DTreeNode[]> {
        return this.http.getForInstance<DTreeNode[]>(
            instance,
            `toplevels/${toplevelId}/me/navigation`
        );
    }

    /**
     * Gets assigned tree nodes
     * @param instance
     * @param toplevelId
     * @returns assigned tree nodes
     */
    public getAssignedTreeNodes(instance: string, toplevelId: string): Observable<DTreeNode[]> {
        return this.http.getForInstance<DTreeNode[]>(
            instance,
            `toplevels/${toplevelId}/me/navigation/assigned`
        );
    }

    /**
     * Gets tree nodes search
     * @param instance
     * @param toplevelId
     * @param query
     * @returns tree nodes search
     */
    public getTreeNodesSearch(
        instance: string,
        toplevelId: string,
        query: string
    ): Observable<DTreeNode[]> {
        return this.http.getForInstance<DTreeNode[]>(
            instance,
            `toplevels/${toplevelId}/me/navigationsearch`,
            undefined,
            { query: query }
        );
    }
}
