import { Pipe, PipeTransform } from '@angular/core';
import { IFilterBySection } from '../../../shared/models/property';

@Pipe({
    name: 'propertySections',
})
export class PropertySectionsPipe implements PipeTransform {
    transform(items: IFilterBySection[], filter: string): any {
        if (!items || !filter) {
            return items;
        }
        // filter items array, items which match and return true will be
        // kept, false will be filtered out
        return items.filter((item) => {
            return item.sectionId.indexOf(filter) !== -1;
        });
    }
}
