import { Injectable } from '@angular/core';
import {
    Router,
    ActivatedRoute,
    ActivatedRouteSnapshot,
    CanActivate,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';
import { Location } from '@angular/common';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Title } from '@angular/platform-browser';
import { MyTrendConfig } from '../services/myTrendConfig.service';

@Injectable()
export class AuthGuard implements CanActivate {
    path: ActivatedRouteSnapshot[];

    constructor(
        private authService: AuthService,
        permissionsService: NgxPermissionsService,
        rolesService: NgxRolesService,
        private router: Router,
        private route: ActivatedRoute,
        private location: Location,
        private titleService: Title
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        const instanceName = route.paramMap.get('instance');
        return new Observable((observer) => {
            this.authService.getActualUser(this.location.path()).subscribe((user) => {
                let instance;
                if (!instanceName) {
                    if (this.authService.actualUser.instances) {
                        // select first instance
                        instance = user.instances[0];
                        this.router.navigate([instance.name]);
                    } else {
                        this.router.navigate([`error/401`]);
                    }
                } else {
                    this.authService.isInGlobal = false;
                    instance = user.instances.find((x) => {
                        return x.name.toUpperCase() === instanceName.toUpperCase();
                    });
                }

                this.authService.selectInstance(instance).subscribe((res) => {
                    if (res) {
                        // Change page title
                        this.titleService.setTitle(
                            `${MyTrendConfig.myTrendSettings.app.name} - Admin Area - ${instance.displayName}`
                        );
                        observer.next(res);
                    }
                });
            });
        });
    }
}
