import { Injectable } from '@angular/core';
import {
    Router,
    ActivatedRouteSnapshot,
    CanActivate,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router';

// Rxjs
import { Observable } from 'rxjs';

// Services
import { AuthService } from 'src/app/shared/services/auth.service';

@Injectable({
    providedIn: 'root',
})
export class WorkspaceAuthGuard implements CanActivate {
    path: ActivatedRouteSnapshot[];

    /**
     * Creates an instance of workspace auth guard.
     * @param authService
     * @param router
     */
    constructor(private readonly authService: AuthService, private readonly router: Router) {}

    /**
     * Determines whether activate can
     * @param route
     * @param state
     * @returns activate
     */
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        return new Observable((observer) => {
            const instanceName = route.paramMap.get('instance');
            this.authService.isInGlobal = instanceName === null;
            this.authService.getActualUser('').subscribe((user) => {
                if (user) {
                    if (instanceName) {
                        const instance = user.instances.find((x) => {
                            return x.name.toUpperCase() === instanceName.toUpperCase();
                        });
                        if (!instance) {
                            observer.next(false);
                            this.router.navigate([`error/401`]);
                        } else {
                            this.authService.selectInstance(instance);
                            observer.next(true);
                        }
                    }
                    observer.next(true);
                } else {
                    this.router.navigate([`error/401`]);
                }
            });
        });
    }
}
