import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// Services
import { RouterExtService } from '../../services';

@Component({
    selector: 'app-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit {
    /**
     * Code  of error component
     */
    public code: string;

    /**
     * Message  of error component
     */
    public message: string;

    /**
     * Previous url of error component
     */
    public previousUrl: string;

    /**
     * Creates an instance of error component.
     * @param route
     * @param router
     * @param routerExtService
     */
    constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly routerExtService: RouterExtService
    ) {}

    /**
     * on init
     */
    public ngOnInit() {
        this.route.paramMap.subscribe((params) => {
            this.previousUrl = this.routerExtService.getPreviousUrl();
            this.code = params.get('error');
            this.trateError(this.code);
        });
    }

    /**
     * Navigates to
     * @param url
     * @param queryParam
     */
    public navigateTo(url: string, queryParam: boolean) {
        if (queryParam) {
            this.router.navigate([url], {
                queryParams: { error: this.code, 'incident-url': this.previousUrl },
            });
        } else {
            this.router.navigate([url]);
        }
    }

    /**
     * Trates error
     * @param code
     */
    private trateError(code: string): void {
        switch (code) {
            case '404':
                this.message = 'The page you are looking for was not found!';
                break;
                break;
            case '403':
                this.message = 'Forbidden!';
                break;
            case '405':
                this.message = 'Method Not Allowed!';
                break;
            case '500':
                this.message = 'Internal server error';
                break;
            case '502':
                this.message = 'Bad Gateway!';
                break;
            case '503':
                this.message = 'The server is currently unavailable.';
                break;
            case '504':
                this.message = 'Gateway Timeout!';
                break;
            default:
                this.message = 'Something went wrong!';
        }
    }
}
