import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Services
import { HttpExtendedGlobalService } from 'src/app/shared/services';
// models
import { TagEffect } from 'src/app/admin/shared/models/TagEffect';

@Injectable({
    providedIn: 'root',
})
export class TagEffectsService {
    constructor(private http: HttpExtendedGlobalService) {}
    // tagEffects
    getTagEffects(): Observable<TagEffect[]> {
        return this.http.get<TagEffect[]>('tageffects');
    }

    getTagEffect(name: string): Observable<TagEffect> {
        return this.http.get<TagEffect>(`tageffects/${name}`);
    }

    createTagEffect(tagEffect: TagEffect): Observable<TagEffect> {
        return this.http.post<TagEffect>(`tageffects`, tagEffect);
    }

    updateTagEffect(tagEffect: TagEffect): Observable<TagEffect> {
        return this.http.put<TagEffect>(`tageffects`, tagEffect);
    }

    deleteTagEffect(tagEffect: TagEffect): Observable<TagEffect> {
        return this.http.put<TagEffect>(`tageffects/delete`, tagEffect);
    }
}
