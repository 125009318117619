import { Component, OnInit, TemplateRef } from '@angular/core';
import { Subject } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';

import { ScorecardService } from '../../../../../shared/services/repositories/scorecard.service';
import { IScorecardView, Scorecard } from '../../../../../shared/models/scorecard';
import { BaseComponent } from '../../../../shared/components/base/base.component';
import { ConfirmationDialogService } from '../../../../../shared/components/utils/confirmation/ConfirmationDialog.service';
import { fadeInOut } from 'src/app/shared/animations/fade';
import { _ } from '../../../../../shared/services/translation.service';
import { WorkflowService } from '../../../../shared/services/workflow.service';
import { AuthService } from '../../../../../shared/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { ScorecardAggregation } from 'src/app/shared/models/enums';

@Component({
    selector: 'app-scorecards',
    templateUrl: './scorecards.component.html',
    styleUrls: ['./scorecards.component.scss'],
    animations: [fadeInOut],
})
export class ScorecardsComponent extends BaseComponent implements OnInit {
    public addModalRef: BsModalRef;
    public editModalRef: BsModalRef;
    public addViewModalRef: BsModalRef;
    public dtTrigger: Subject<any> = new Subject();

    public scorecards: IScorecardView[];
    public newScorecardName: string;
    public edtScorecard: Scorecard;
    public newViewScorecardName: string;
    public selectedScorecardId: string;

    public ScorecardAggregation = ScorecardAggregation;

    constructor(
        private scorecardService: ScorecardService,
        private confirmationService: ConfirmationDialogService,
        private messageService: MessageService,
        private modalService: BsModalService,
        public router: Router,
        private authService: AuthService,
        protected workflowService: WorkflowService,
        private translate: TranslateService
    ) {
        super(router, workflowService);
        this.dtOptions = {
            ordering: false,
            searching: false,
            paging: false,
        };
        this.generateBreadCrumb();
    }

    ngOnInit() {
        this.getScorecards();
    }

    getScorecards() {
        this.loading = true;
        this.scorecardService.getScorecardsView().subscribe((res) => {
            this.scorecards = res;
            this.refreshTable('#table', this.dtTrigger);
        });
    }

    //#region remove
    removeScorecard(scorecard: IScorecardView) {
        this.confirmationService
            .confirm(
                this.translate.instant(_('Do you want to delete ')) +
                    scorecard.name +
                    this.translate.instant(_(' scorecard?'))
            )
            .subscribe((result) => {
                if (result) {
                    this.scorecardService.deleteScorecard(scorecard.id).subscribe(
                        () => {
                            this.messageService.add({
                                severity: 'success',
                                summary: this.translate.instant(_('Succes!')),
                                detail: this.translate.instant(
                                    _('Scorecard removed with success!')
                                ),
                            });
                        },
                        (error) => {
                            return console.log('Error: ', error);
                        },
                        () => {
                            return this.getScorecards();
                        }
                    );
                }
            });
    }

    //#region add
    addScorecardModal(modal: TemplateRef<any>) {
        this.newScorecardName = '';
        this.addModalRef = this.modalService.show(modal, this.modalConfig);
    }

    addScorecard() {
        // null or white spaces
        if (this.newScorecardName !== null && this.newScorecardName.match(/^ *$/) === null) {
            this.scorecardService.setScorecard(this.newScorecardName).subscribe(
                () => {
                    this.messageService.add({
                        severity: 'success',
                        summary: this.translate.instant(_('Succes!')),
                        detail: this.translate.instant(_('Scorecard added with success!')),
                    });
                },
                (error) => {
                    return console.log('Error: ', error);
                },
                () => {
                    return this.getScorecards();
                }
            );
            this.addModalRef.hide();
        }
    }

    //#region edit
    editScorecardModal(scorecard: IScorecardView, modal: TemplateRef<any>) {
        this.edtScorecard = { id: scorecard.id, name: scorecard.name, scorecardDisplays: null };
        this.editModalRef = this.modalService.show(modal, this.modalConfig);
    }

    editScorecard() {
        // null or white spaces
        if (this.edtScorecard.name !== null && this.edtScorecard.name.match(/^ *$/) === null) {
            this.scorecardService
                .updateScorecard(this.edtScorecard.id, this.edtScorecard.name)
                .subscribe(
                    () => {
                        this.messageService.add({
                            severity: 'success',
                            summary: this.translate.instant(_('Succes!')),
                            detail: this.translate.instant(_('Scorecard edited with success!')),
                        });
                    },
                    (error) => {
                        return console.log('Error: ', error);
                    },
                    () => {
                        return this.getScorecards();
                    }
                );
            this.editModalRef.hide();
        }
    }

    //#region add View
    addViewScorecardModal(scorecardId: string, modal: TemplateRef<any>) {
        this.newViewScorecardName = '';
        this.selectedScorecardId = scorecardId;
        this.addViewModalRef = this.modalService.show(modal, this.modalConfig);
    }

    addViewScorecard() {
        // null or white spaces
        if (
            this.newViewScorecardName !== null &&
            this.newViewScorecardName.match(/^ *$/) === null
        ) {
            this.scorecardService
                .setScorecardDisplay(this.selectedScorecardId, this.newViewScorecardName)
                .subscribe((res) => {
                    this.messageService.add({
                        severity: 'success',
                        summary: this.translate.instant(_('Succes!')),
                        detail: this.translate.instant(_('Scorecard View added with success!')),
                    });
                    this.addViewModalRef.hide();
                    this.router.navigate([
                        '/admin/' +
                            this.authService.selectedInstance.name +
                            '/scorecards/scorecardviews/' +
                            res.id +
                            '/effects',
                    ]);
                });
        }
    }

    private generateBreadCrumb() {
        this.dynamicBreadCrumb('Scorecard');
    }
}
