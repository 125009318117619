import { Injectable } from '@angular/core';
import { FavoriteTile } from '../../models/favoriteTile';
import { Observable } from 'rxjs';
import { HttpExtendedInstanceService } from 'src/app/shared/services';

@Injectable({
    providedIn: 'root',
})
export class FavoriteService {
    constructor(private http: HttpExtendedInstanceService) {}

    getFavorites(): Observable<FavoriteTile[]> {
        return this.http.get<FavoriteTile[]>('users/me/favorites');
    }

    setFavorite(tileKey: string, order: number): Observable<FavoriteTile> {
        return this.http.post<FavoriteTile>('users/me/favorites', { elementKey: tileKey, order });
    }
}
