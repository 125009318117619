import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { Location } from '@angular/common';
import { MessageService } from 'primeng/api';

import { BaseComponent } from 'src/app/admin/shared/components/base/base.component';
import { WorkflowService } from 'src/app/admin/shared/services/workflow.service';
import { PropertySet } from 'src/app/shared/models/property';
import { AuthService } from 'src/app/shared/services/auth.service';
import { PropertyService } from 'src/app/shared/services/repositories/property.service';
import { Rights } from '../../../models/rights';
import {
    GpspPropertySetDisplay,
    PropertySetDisplayModel,
    PropertySetPropertyDisplay,
} from './../../../../../shared/models/property';
import { fadeInOut } from './../../../../../shared/animations/fade';
import { _ } from './../../../../../shared/services/translation.service';

@Component({
    selector: 'app-property-set-display',
    templateUrl: './property-set-display.component.html',
    styleUrls: ['./property-set-display.component.scss'],
    animations: [fadeInOut],
})
export class PropertySetDisplayComponent extends BaseComponent implements OnInit {
    private propertySetId: string;
    private propertySetDisplayId: string;
    public editMode: boolean;

    public propertySet: PropertySet = new PropertySet();
    public propertySetDisplay: GpspPropertySetDisplay = new GpspPropertySetDisplay();
    public propertySetPropertyDisplays: PropertySetPropertyDisplay[] = [];

    public checkedProperties = [];

    constructor(
        private propertyService: PropertyService,
        private route: ActivatedRoute,
        private messageService: MessageService,
        private location: Location,
        public router: Router,
        private authService: AuthService,
        protected workflowService: WorkflowService,
        private translate: TranslateService
    ) {
        super(router, workflowService);
        this.generateBreadCrumb();
    }

    ngOnInit() {
        this.route.paramMap.subscribe((params) => {
            this.propertySetId = params.get('id');
            const propertySetDisplayId = params.get('displayId');
            if (propertySetDisplayId !== 'new') {
                this.editMode = true;
                this.propertySetDisplayId = propertySetDisplayId;
                this.getPropertySetDisplay(this.propertySetId, this.propertySetDisplayId);
            } else {
                this.getPropertySet(this.propertySetId);
            }
        });
    }

    getPropertySet(propertySetId: string) {
        this.propertyService.getPropertySet(propertySetId).subscribe((res) => {
            this.propertySet = res;
            this.propertySet.propertySetProperties.forEach((x) => {
                this.checkedProperties.push({
                    id: x.propertyId,
                    name: x.displayName,
                    checked: x.requiredProperty,
                    required: x.requiredProperty,
                });
            });
        });
    }

    getPropertySetDisplay(propertySetId: string, propertySetDisplayId: string) {
        this.loading = true;
        forkJoin(
            this.propertyService.getPropertySet(propertySetId),
            this.propertyService.GetPropertySetDisplayById(
                this.propertySetId,
                propertySetDisplayId
            ),
            this.propertyService.getPropertiesOfPropertySetDisplay(
                this.propertySetId,
                propertySetDisplayId
            )
        ).subscribe((res) => {
            this.propertySet = res[0];
            this.propertySetDisplay = res[1];
            this.propertySetPropertyDisplays = res[2];
            this.loading = false;

            this.propertySet.propertySetProperties.forEach((x) => {
                this.checkedProperties.push({
                    id: x.propertyId,
                    name: x.displayName,
                    checked:
                        this.propertySetPropertyDisplays.some((y) => {
                            return y.propertyId === x.propertyId;
                        }) || x.requiredProperty,
                    required: x.requiredProperty,
                });
            });
        });
    }

    save() {
        const propertySetDisplayModel = new PropertySetDisplayModel();
        propertySetDisplayModel.id = this.propertySetDisplay.id;
        propertySetDisplayModel.name = this.propertySetDisplay.name;
        propertySetDisplayModel.propertySetId = this.propertySetId;
        propertySetDisplayModel.propertySetProperties = this.propertySet.propertySetProperties;

        this.checkedProperties.forEach((x) => {
            propertySetDisplayModel.propertySetDisplayProperties[x.id] = x.checked;
        });

        if (this.editMode) {
            this.propertyService
                .updatePropertiesOfPropertySetDisplay(propertySetDisplayModel)
                .subscribe(() => {
                    this.messageService.add({
                        severity: 'success',
                        summary: this.translate.instant(_('Success!')),
                        detail: this.translate.instant(_('Property Display saved with success!')),
                    });
                    this.router.navigate([
                        `admin/${this.authService.selectedInstance.name}/nodes/propertysets/${this.propertySetId}/displays`,
                    ]);
                });
        } else {
            this.propertyService
                .setPropertiesOfPropertySetDisplay(propertySetDisplayModel)
                .subscribe(() => {
                    this.messageService.add({
                        severity: 'success',
                        summary: this.translate.instant(_('Success!')),
                        detail: this.translate.instant(_('Property Display saved with success!')),
                    });
                    this.router.navigate([
                        `admin/${this.authService.selectedInstance.name}/nodes/propertysets/${this.propertySetId}/displays`,
                    ]);
                });
        }
    }
    private generateBreadCrumb() {
        this.dynamicBreadCrumb('Node Configuration', 'Properties set', [
            {
                name: 'Display',
                link: '',
                isActive: false,
                subElements: [],
                permissions: [Rights.PropertyAndSet],
            },
            {
                name: 'Create property',
                link: '',
                isActive: false,
                subElements: [],
                permissions: [Rights.PropertyAndSet],
            },
        ]);
    }

    public goBack() {
        this.location.back();
    }
}
