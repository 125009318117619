import { Component, OnInit } from '@angular/core';

// dFakto-ui
import { DMenuItem } from '@depfac/dfakto-ui/src/lib/menu-item';
import { TopBarService } from '@depfac/dfakto-ui/src/lib/top-bar';

// Ngx
import { TranslateService } from '@ngx-translate/core';

// Services
import { _ } from '../../../../shared/services/translation.service';
import { TopBarGeneratorService } from '../../../../shared/services/top-bar-generator.service';
import { AuthService } from '../../../../shared/services/auth.service';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
    /**
     * Creates an instance of layout component.
     * @param authService
     * @param topBarService
     * @param translate
     * @param topBarGeneratorService
     */
    constructor(
        public readonly authService: AuthService,
        private readonly topBarService: TopBarService,
        private readonly translate: TranslateService,
        private readonly topBarGeneratorService: TopBarGeneratorService
    ) {}

    /**
     * on init
     */
    ngOnInit() {
        const languageItems: DMenuItem[] = [];
        this.translate.getLangs().forEach((l) => {
            languageItems.push({
                id: l,
                title: l.toUpperCase(),
                command: () => {
                    return this.translate.use(l);
                },
            });
        });
        this.topBarService.updateMenuItemList(this.topBarGeneratorService.getAdminAreaTopBar());
    }
}
