import { ScorecardAggregation } from './enums';

export class Scorecard {
    id: string;
    name: string;
    scorecardDisplays: ScorecardDisplay[];
}

export interface IScorecardView {
    id: string;
    name: string;
    aggregation: ScorecardAggregation;
    numberOfEffects: number;
    numberOfTopLevels: number;
    numberOfDisplays: number;
}

export class ScorecardEffectDisplay {
    id: string;
    scorecardDisplayId: string;
    effectId: string;
    hidden: boolean;
    aggregated: boolean;
    displayInFinancialSummary;
}

export class ScorecardDisplay {
    public static get EMPTY_ID(): string {
        return '00000000-0000-0000-0010-000000000000';
    }
    id: string;
    scorecardId: string;
    name: string;
    system: boolean;
    numberOfStructures: number;
    numberOfTreeNodes: number;
}

// VentilationAxis
export class VentilationAxis {
    id: string;
    name: string;
    displayName: string;
}

export class EffectVentilation {
    effectId: string;
    effectName: string;
    axes: VentilationAxis[];
    effectVentilations = [];
}

// ScorecardFrequency
export enum ScorecardFrequency {
    Monthly = 0,
    Quarterly = 1,
    Weekly = 2,
    Yearly = 3,
    Total = 4,
}
