import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Subject, forkJoin } from 'rxjs';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import * as __ from 'underscore';

import { MaturityService } from '../../../../../shared/services/repositories/maturity.service';

import { BaseComponent } from '../../../../shared/components/base/base.component';
import { fadeInOut } from 'src/app/shared/animations/fade';
import { Maturity } from '../../../../../shared/models/maturity';
import { _ } from '../../../../../shared/services/translation.service';
import { WorkflowService } from '../../../../shared/services/workflow.service';
import { ChecklistView } from '../../../../../shared/models/checklist';
import { FinancialState } from '../../../../../shared/models/financialState';
import { ChecklistService } from '../../../../../shared/services/repositories/checklist.service';
import { ScorecardService } from '../../../../../shared/services/repositories/scorecard.service';
import { ConfirmationDialogService } from '../../../../../shared/components/utils/confirmation/ConfirmationDialog.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-maturities',
    templateUrl: './maturities.component.html',
    styleUrls: ['./maturities.component.scss'],
    animations: [fadeInOut],
})
export class MaturitiesComponent extends BaseComponent implements OnInit {
    public dtTrigger: Subject<any> = new Subject();
    public modalRef: BsModalRef;

    dropdownSettings = {};

    public maturities: Maturity[];
    public systemMaturities: Maturity[];

    public maturity: Maturity = new Maturity();
    public editMode = false;
    public checkLists: ChecklistView[] = [];
    public financialStates: FinancialState[];

    constructor(
        private maturityService: MaturityService,
        private confirmationService: ConfirmationDialogService,
        private messageService: MessageService,
        private modalService: BsModalService,
        protected workflowService: WorkflowService,
        public router: Router,
        private checkListService: ChecklistService,
        private scorecardService: ScorecardService,
        private translate: TranslateService
    ) {
        super(router, workflowService);
        // disable order in specific columns
        this.dtOptions.columnDefs = [{ orderable: false, targets: 2 }];
        this.generateBreadCrumb();
    }

    ngOnInit(): void {
        this.getMaturities();
        this.getAllValues();
    }

    getMaturities() {
        this.loading = true;
        this.maturityService.getMaturities().subscribe((res) => {
            this.maturities = res;
            this.maturities = __.sortBy(this.maturities, 'system').reverse();
            this.maturities = __.sortBy(this.maturities, 'name');
            this.loading = false;
            this.refreshTable('#table', this.dtTrigger);
        });
    }

    private getAllValues() {
        forkJoin(
            this.checkListService.getChecklists(),
            this.scorecardService.getFinancialStates()
        ).subscribe((res) => {
            this.checkLists = res[0];
            this.financialStates = res[1];
        });
    }

    //#region remove
    removeMaturity(maturity: Maturity) {
        this.confirmationService
            .confirm(
                this.translate.instant(_('Do you want to delete ')) +
                    maturity.name +
                    this.translate.instant(_(' Maturity?'))
            )
            .subscribe((result) => {
                if (result) {
                    this.maturityService.deleteMaturity(maturity.id).subscribe(
                        () => {
                            this.messageService.add({
                                severity: 'success',
                                summary: this.translate.instant(_('Success!')),
                                detail: this.translate.instant(_('Maturity removed with success!')),
                            });
                        },
                        (error) => {
                            return console.log('Error: ', error);
                        },
                        () => {
                            return this.getMaturities();
                        }
                    );
                }
            });
    }

    addMaturityModal(modal: TemplateRef<any>) {
        this.maturity = new Maturity();
        this.maturity.financialStateVisibilities = {};
        this.financialStates.forEach((x) => {
            this.maturity.financialStateVisibilities[x.id] =
                this.maturity.visibleFinancialStates.some((y) => {
                    return y === x.id;
                });
        });
        this.editMode = false;
        this.modalRef = this.modalService.show(modal, this.modalConfig);
    }

    editMaturityModal(maturity: Maturity, modal: TemplateRef<any>) {
        this.maturityService.getMaturity(maturity.id).subscribe((res) => {
            this.maturity = res;
            this.maturity.financialStateVisibilities = {};
            this.financialStates.forEach((x) => {
                this.maturity.financialStateVisibilities[x.id] =
                    this.maturity.visibleFinancialStates.some((y) => {
                        return y === x.id;
                    });
            });
            this.editMode = true;
            this.modalRef = this.modalService.show(modal, this.modalConfig);
        });
    }

    public saveMaturity() {
        this.maturity.checklistId = this.maturity.checklistId || null;
        if (this.editMode) {
            this.maturityService.updateMaturity(this.maturity.id, this.maturity).subscribe(
                () => {
                    this.modalRef.hide();
                    this.messageService.add({
                        severity: 'success',
                        summary: this.translate.instant(_('Success!')),
                        detail: this.translate.instant(_('Maturity updated with success!')),
                    });
                },
                (error) => {
                    return console.log('Error: ', error);
                },
                () => {
                    return this.getMaturities();
                }
            );
        } else {
            this.maturityService.setMaturity(this.maturity).subscribe(
                () => {
                    this.modalRef.hide();
                    this.messageService.add({
                        severity: 'success',
                        summary: this.translate.instant(_('Success!')),
                        detail: this.translate.instant(_('Maturity created with success!')),
                    });
                },
                (error) => {
                    return console.log('Error: ', error);
                },
                () => {
                    return this.getMaturities();
                }
            );
        }
    }

    private generateBreadCrumb() {
        this.dynamicBreadCrumb('Maturity');
    }
}
