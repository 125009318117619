// webpack-translate-loader.ts
import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

export class WebpackTranslateLoader implements TranslateLoader {
    private prefix = 'assets/i18n/';
    private suffix = '.json';
    constructor(private http: HttpClient) {}

    getTranslation(lang: string): Observable<any> {
        return this.http.get(`${this.prefix}${lang}${this.suffix}`).pipe(
            map((result) => {
                return this.process(result);
            })
        );
    }

    private process(object: object) {
        return Object.keys(object)
            .filter((key) => {
                return object.hasOwnProperty(key) && object[key] !== '';
            })
            .reduce((result, key) => {
                return (
                    (result[key] =
                        typeof object[key] === 'object' ? this.process(object[key]) : object[key]),
                    result
                );
            }, {});
    }
}
