import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EmailScheduleType, GroupMailType } from 'src/app/shared/models/enums';
import { HttpExtendedInstanceService } from 'src/app/shared/services';
import {
    IEmailScheduleView,
    IEmailScheduleDetail,
    EmailTemplateView,
    EmailTemplate,
    GroupMail,
    GroupMailView,
    GroupMailTemplateView,
    GlobalMailTemplate,
} from '../../../admin/shared/models/email';

@Injectable({
    providedIn: 'root',
})
export class EmailService {
    constructor(protected http: HttpExtendedInstanceService) {}

    public getEmailScheduleTypeDescription(type: EmailScheduleType): string {
        switch (type) {
            case EmailScheduleType.FirstStatusUpdate:
                return 'First Status Update';
            case EmailScheduleType.SecondStatusUpdate:
                return 'Second Status Update';
            case EmailScheduleType.ThirdStatusUpdate:
                return 'Third Status Update';
            case EmailScheduleType.FirstScorecardUpdate:
                return 'First Scorecard Update';
            case EmailScheduleType.SecondScorecardUpdate:
                return 'Second Scorecard Update';
            case EmailScheduleType.ThirdScorecardUpdate:
                return 'Third Scorecard Update';
            default:
                return '';
        }
    }

    public getEmailSchedules(): Observable<IEmailScheduleView[]> {
        return this.http.get<IEmailScheduleView[]>('mails');
    }

    public getEmailSchedule(emailId: string): Observable<IEmailScheduleDetail> {
        return this.http.get<IEmailScheduleDetail>(`gpspmails/${emailId}/details`);
    }

    public sendEmailSchedules(emailId: string) {
        return this.http.post(`mails/${emailId}/send`, null);
    }

    public cancelEmailSchedules(emailId: string) {
        return this.http.post(`mails/${emailId}/cancel`, null);
    }

    public reloadEmailSchedules(emailId: string) {
        return this.http.post(`mails/${emailId}/reload`, null);
    }

    public getEmailScheduleSample(
        emailId: string,
        templateId: string,
        userId: string
    ): Observable<string> {
        return this.http.get<string>(
            `mailSchedule/${emailId}/templates/${templateId}/sample`,
            this.http.baseOptions(),
            { userId }
        );
    }

    // Email templates
    public getEmailTemplates(): Observable<EmailTemplateView[]> {
        return this.http.get<EmailTemplateView[]>('mailtemplates');
    }

    public getDefaultEmailTemplates(): Observable<EmailTemplateView[]> {
        return this.http.get<EmailTemplateView[]>('defaultmailtemplates');
    }

    public getEmailTemplate(id: string): Observable<EmailTemplate> {
        return this.http.get<EmailTemplate>(`mailtemplates/${id}`);
    }

    public getGlobalMailTemplates(): Observable<GlobalMailTemplate[]> {
        return this.http.get<GlobalMailTemplate[]>('globalmailtemplates');
    }

    public deleteEmailTemplate(id: string): Observable<EmailTemplate> {
        return this.http.delete<EmailTemplate>(`mailtemplates/${id}`);
    }

    public getEmailTemplateSample(idMailTemplate: string, type: string): Observable<string> {
        return this.http.get<string>(
            `mailtemplates/${idMailTemplate}/sample`,
            this.http.baseOptions(),
            { type }
        );
    }

    public updateEmailTemplate(
        id: string,
        emailTemplate: EmailTemplate
    ): Observable<EmailTemplate> {
        return this.http.put<EmailTemplate>(`mailtemplates/${id}`, emailTemplate);
    }

    public setEmailTemplate(emailTemplate: EmailTemplate): Observable<EmailTemplate> {
        return this.http.post<EmailTemplate>(`mailtemplates`, emailTemplate);
    }

    public getMailTemplateSampleFromTemplate(mailTemplate: EmailTemplate): Observable<string> {
        return this.http.post<string>('mailtemplates/sample', mailTemplate);
    }

    // Group mails
    public getGroupMails(): Observable<GroupMailView[]> {
        return this.http.get<GroupMailView[]>('mails/groupsview');
    }

    public setGroupMail(groupMail: GroupMail): Observable<GroupMail> {
        return this.http.post<GroupMail>(`mails/groups`, groupMail);
    }

    public updateGroupMail(id: string, groupMail: GroupMail): Observable<GroupMail> {
        return this.http.put<GroupMail>(`mails/groups/${id}`, groupMail);
    }

    public deleteGroupMail(id: string): Observable<GroupMail> {
        return this.http.delete<GroupMail>(`mails/groups/${id}`);
    }

    public getGroupMailPossibleTemplates(id: string): Observable<GroupMailTemplateView[]> {
        return this.http.get<GroupMailTemplateView[]>(`mails/groups/${id}/possibletemplates`);
    }

    public getGroupMailTemplates(type: GroupMailType): Observable<EmailTemplate[]> {
        return this.http.get<EmailTemplate[]>(`mails/grouptemplates/${type}`);
    }

    public addTemplateInGroupMail(
        idGroupMail: string,
        idTemplate
    ): Observable<GroupMailTemplateView> {
        return this.http.put<GroupMailTemplateView>(
            `mails/groups/${idGroupMail}/grouptemplates/${idTemplate}`,
            null
        );
    }

    public removeTemplateInGroupMail(
        idGroupMail: string,
        idTemplate
    ): Observable<GroupMailTemplateView> {
        return this.http.delete<GroupMailTemplateView>(
            `mails/groups/${idGroupMail}/grouptemplates/${idTemplate}`
        );
    }
}
