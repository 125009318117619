import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {

  constructor() { }

  /**
   * Gets name two letters
   * @param displayName 
   * @returns name two letters 
   */
  public getNameTwoLetters(displayName): string {
    const displayNames = displayName.split(' ');
    if (displayNames.length > 1) {
      return displayNames[0].charAt(0) + displayNames[1].charAt(0);
    }
    return displayName.charAt(0) + displayName.charAt(1);
  }
}
