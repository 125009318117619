import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';

import { AdminService } from '../../../../../shared/services/repositories/admin.service';
import { BaseComponent } from '../../../../shared/components/base/base.component';
import { _ } from '../../../../../shared/services/translation.service';
import { Router } from '@angular/router';
import { WorkflowService } from '../../../../shared/services/workflow.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-indexation',
    templateUrl: './indexation.component.html',
    styleUrls: ['./indexation.component.scss'],
})
export class IndexationComponent extends BaseComponent {
    constructor(
        private adminservice: AdminService,
        private messageService: MessageService,
        public router: Router,
        protected workflowService: WorkflowService,
        private translate: TranslateService
    ) {
        super(router, workflowService);
        this.generateBreadCrumb();
    }

    reindexTreenodes() {
        this.adminservice.ReIndexNodes().subscribe(() => {
            return this.messageService.add({
                severity: 'success',
                summary: this.translate.instant(_('Succes!')),
                detail: this.translate.instant(_('Job created')),
            });
        });
    }

    reindexHistory() {
        this.adminservice.ReIndexHistory().subscribe(() => {
            return this.messageService.add({
                severity: 'success',
                summary: this.translate.instant(_('Succes!')),
                detail: this.translate.instant(_('Job created')),
            });
        });
    }

    private generateBreadCrumb() {
        this.dynamicBreadCrumb('Update Cycle');
    }
}
