import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';

// Models
import { InstanceDocument } from 'src/app/shared/models/documents/instance-document';
import { LightDocument } from 'src/app/shared/models';

// Rxjs
import { Observable } from 'rxjs';

// Services
import { HttpExtendedGlobalService } from 'src/app/shared/services';

@Injectable({
    providedIn: 'root',
})
export class ProfileDocumentService {
    /**
     * Creates an instance of profile document service.
     * @param http
     */
    constructor(private readonly http: HttpExtendedGlobalService) {}

    /**
     * Gets global documents
     * @returns global documents
     */
    public getGlobalDocuments(): Observable<LightDocument[]> {
        return this.http.get<LightDocument[]>('documents/global');
    }

    /**
     * Gets instance documents
     * @returns instance documents
     */
    public getInstanceDocuments(): Observable<InstanceDocument[]> {
        return this.http.get<InstanceDocument[]>('documents/instances');
    }

    /**
     * Gets document by id
     * @param instanceName
     * @param documentId
     * @returns document by id
     */
    public getDocumentById(documentId: string, instanceName?: string): Observable<any> {
        const url = instanceName
            ? `documents/${documentId}/instances/${instanceName}`
            : `documents/${documentId}/global`;
        return this.http.get<HttpResponse<Blob>>(url, this.http.blobOptions());
    }

    /**
     * Gets total not viewed
     * @returns total not viewed
     */
    public getTotalNotViewed(): Observable<number> {
        return this.http.get<number>('documents/totalnotviewed');
    }

    /**
     * Uploads profile document service
     * @param files
     * @param [instanceName]
     * @returns upload
     */
    public upload(files: FormData, instanceName?: string): Observable<any> {
        const options = this.http.blobOptions();
        options.headers = options.headers.delete('Content-Type');
        const url = instanceName ? `documents/instances/${instanceName}` : `documents/global`;
        return this.http.post(url, files, options);
    }

    /**
     * Updates docs page user access
     * @returns docs page user access
     */
    public updateDocsPageUserAccess(): Observable<any> {
        return this.http.put<any>('documents/vieweddate', null);
    }

    /**
     * Deletes profile document service
     * @param documentId
     * @param [instanceName]
     * @returns delete
     */
    public delete(documentId: string, instanceName?: string): Observable<any> {
        const url = instanceName
            ? `documents/${documentId}/instances/${instanceName}`
            : `documents/${documentId}/global`;
        return this.http.delete<HttpResponse<void>>(url, this.http.blobOptions());
    }
}
