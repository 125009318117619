import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { forkJoin } from 'rxjs';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { FavoriteTile } from 'src/app/shared/models/favoriteTile';
import { FavoriteService } from 'src/app/shared/services/repositories/favorite.service';
import { BaseComponent } from 'src/app/admin/shared/components/base/base.component';
import { FlowElement } from 'src/app/shared/models/navigationElement';
import { AuthService } from 'src/app/shared/services/auth.service';
import { WorkflowService } from 'src/app/admin/shared/services/workflow.service';
import { _ } from '../../../../shared/services/translation.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
})
export class HomeComponent extends BaseComponent implements OnInit {
    modalRef: BsModalRef;
    public favoritsFlowElements: FlowElement[] = [];
    public editfavoritsFlowElements: FavoriteTile[] = [];
    private defaultElementKeys = ['users', 'jobs', 'scorecards', 'tops'];

    constructor(
        protected workflowService: WorkflowService,
        private modalService: BsModalService,
        public authService: AuthService,
        public router: Router,
        private favoriteService: FavoriteService,
        private messageService: MessageService,
        private translate: TranslateService
    ) {
        super(router, workflowService);
    }

    ngOnInit() {
        this.loadFavorites();
    }

    private loadFavorites() {
        this.favoritsFlowElements = [];
        this.favoriteService.getFavorites().subscribe((res) => {
            for (let index = 1; index < 5; index++) {
                const favoriteTile = res.find((x) => {
                    return x.order === index;
                });

                const flowElement = favoriteTile
                    ? this.findFlowElement(favoriteTile.elementKey) ||
                      this.findFlowElement(this.defaultElementKeys[index - 1])
                    : this.findFlowElement(this.defaultElementKeys[index - 1]);

                this.favoritsFlowElements.push(flowElement);
            }
        });
    }

    navigate(flowElement: FlowElement) {
        // find entyre url
        const layoutElement = this.workflowService.layoutElements.find((x) => {
            return x.type === flowElement.type;
        });
        if (layoutElement && !flowElement.disabled) {
            this.router.navigate([
                `admin/${this.authService.selectedInstance.name}/${layoutElement.link}/${flowElement.link}`,
            ]);
        }
    }

    openModal(template: TemplateRef<any>) {
        this.editfavoritsFlowElements = [];
        let count = 1;
        this.favoritsFlowElements.forEach((x) => {
            this.editfavoritsFlowElements.push({
                elementKey: x.link,
                order: count++,
                userId: '',
            });
        });

        this.modalRef = this.modalService.show(template, this.modalConfig);
    }

    editFavorites() {
        const requests = [];
        this.editfavoritsFlowElements.forEach((x) => {
            requests.push(this.favoriteService.setFavorite(x.elementKey, x.order));
        });

        forkJoin(requests).subscribe(
            () => {
                this.messageService.add({
                    severity: 'success',
                    summary: this.translate.instant(_('Success!')),
                    detail: this.translate.instant(_('Favorites updated with success!')),
                });
            },
            (error) => {
                return console.log('Error: ', error);
            },
            () => {
                return this.loadFavorites();
            }
        );
        this.modalRef.hide();
    }

    private findFlowElement(elementKey): FlowElement {
        let flowElement: FlowElement = null;
        this.workflowService.layoutElements.forEach((element) => {
            if (
                element.modulesElements.some((x) => {
                    return x.link === elementKey;
                })
            ) {
                flowElement = element.modulesElements.find((x) => {
                    return x.link === elementKey;
                });
                return;
            }
        });
        return flowElement;
    }
}
