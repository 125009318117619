import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MyTrendConfig } from '../../../../shared/services/myTrendConfig.service';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'app-notAuthorized',
    templateUrl: './notAuthorized.component.html',
    styleUrls: ['./notAuthorized.component.scss'],
})
export class NotAuthorizedComponent {
    public returnUrl: string;

    constructor(private route: ActivatedRoute, public router: Router) {
        this.route.queryParamMap.subscribe((params) => {
            this.returnUrl = params.get('returnurl');
        });
    }

    navigateToMyTrend() {
        window.location.href = MyTrendConfig.myTrendSettings.myTrendUrl;
    }

    navigateToHome() {
        this.router.navigate(['']);
    }
}
