import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { UpdateTreeNode } from 'src/app/core/models/UpdateTreeNode';
import { UpdateTypes } from 'src/app/shared/models/enums';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';

@Component({
  selector: 'app-top-bar-updates',
  templateUrl: './top-bar-updates.component.html',
  styleUrls: ['./top-bar-updates.component.scss']
})
export class TopBarUpdatesComponent implements OnInit {


  updateTreenodes: UpdateTreeNode[] = [];

  constructor(private notificationService: NotificationService) { }

  ngOnInit(): void {
    forkJoin([
      this.notificationService.getNodesToUpdate(UpdateTypes.Progress),
      this.notificationService.getNodesToUpdate(UpdateTypes.Scorecard)])
      .subscribe(res => {
        // progress
        res[0].forEach(x => {
          let updateTreenode: UpdateTreeNode = new UpdateTreeNode();
          Object.assign(updateTreenode, x);
          updateTreenode.hasProgressUpdates = true;
          this.updateTreenodes.push(updateTreenode);
        });

        // scorecard
        res[1].forEach(x => {
          let updateTreenode: UpdateTreeNode = this.updateTreenodes.find(y => { return y.id === x.id });
          if (!updateTreenode) {
            updateTreenode = new UpdateTreeNode();
            Object.assign(updateTreenode, x);
            this.updateTreenodes.push(updateTreenode);
          }
          updateTreenode.hasFinancialUpdates = true;
        });
      });
  }

}
