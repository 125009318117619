import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserGroupProperty } from 'src/app/shared/models/property';
import { HttpExtendedInstanceService } from 'src/app/shared/services';

@Injectable({
    providedIn: 'root',
})
export class GroupService {
    constructor(private http: HttpExtendedInstanceService) {}

    removeUserFromGroup(groupId: string, userId: string): Observable<any> {
        return this.http.delete<any>(`groups/${groupId}/users/${userId}`);
    }

    addUserToGroup(groupId: string, userId: string): Observable<any> {
        return this.http.post<any>(`groups/${groupId}/users/${userId}`, {});
    }

    getGroupProperties(groupId: string): Observable<UserGroupProperty[]> {
        return this.http.get<UserGroupProperty[]>(`/groups/${groupId}/properties`);
    }
}
