import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MomentModule } from 'angular2-moment';

// Directives
import { DynamicLoaderDirective } from './directives/dynamic-loader/dynamic-loader.directive';

// Ngx
import { TranslateModule } from '@ngx-translate/core';

// Guards
import { IsLocalAdminGuard, WorkspaceAuthGuard } from './guards';

// Pipes
import { StringjoinPipe } from 'src/app/shared/pipes/stringjoin.pipe';
import { MetricPipe } from './pipes/metric.pipe';


@NgModule({
    declarations: [DynamicLoaderDirective, StringjoinPipe, MetricPipe],
    imports: [CommonModule, TranslateModule, ReactiveFormsModule, MomentModule],
    providers: [WorkspaceAuthGuard, IsLocalAdminGuard],
    exports: [ReactiveFormsModule, DynamicLoaderDirective, StringjoinPipe, MetricPipe, MomentModule],
})
export class SharedModule { }
