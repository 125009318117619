import { Component } from '@angular/core';

// Ngx
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss'],
})
export class CoreLayoutComponent {
    /**
     * Creates an instance of core layout component.
     * @param translate
     */
    constructor(private readonly translate: TranslateService) {
        // TODO: manage multiple languages
        this.translate.use('en');
    }
}
