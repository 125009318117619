import { style, animate, transition, trigger } from '@angular/animations';

export const slideInOut = trigger('slideInOut', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('500ms ease-in', style({ opacity: 1 }))
  ]),
  transition(':leave', [
    animate('500ms ease-in', style({ opacity: 0 }))
  ])

]);


