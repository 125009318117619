import { Component, OnInit, TemplateRef } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ActivatedRoute, Router } from '@angular/router';

import { EmailTemplateView, EmailTemplate } from '../../../../shared/models/email';
import { EmailService } from '../../../../../shared/services/repositories/email.service';
import { ConfirmationDialogService } from '../../../../../shared/components/utils/confirmation/ConfirmationDialog.service';
import { BaseComponent } from '../../../../shared/components/base/base.component';
import { fadeInOut } from 'src/app/shared/animations/fade';
import { _ } from '../../../../../shared/services/translation.service';
import { AuthService } from '../../../../../shared/services/auth.service';
import { WorkflowService } from '../../../../shared/services/workflow.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-email-templates',
    templateUrl: './email-templates.component.html',
    styleUrls: ['./email-templates.component.scss'],
    animations: [fadeInOut],
})
export class EmailTemplatesComponent extends BaseComponent implements OnInit {
    public addModalRef: BsModalRef;

    public newEmail: EmailTemplate = new EmailTemplate();

    public dtTrigger: Subject<any> = new Subject();
    public emailTemplates: EmailTemplateView[] = [];
    public defatultMailTemplates: EmailTemplateView[] = [];

    constructor(
        private emailService: EmailService,
        private messageService: MessageService,
        private confirmationService: ConfirmationDialogService,
        public router: Router,
        private route: ActivatedRoute,
        protected workflowService: WorkflowService,
        private modalService: BsModalService,
        public authService: AuthService,
        private translate: TranslateService
    ) {
        super(router, workflowService);
        // disable order in specific columns
        this.dtOptions.columnDefs = [{ orderable: false, targets: 2 }];
        this.generateBreadCrumb();
    }

    ngOnInit() {
        this.dtOptions.order = [[0, 'asc']];
        this.getEmailTemplates();
    }

    getEmailTemplates() {
        this.loading = true;
        this.emailService.getEmailTemplates().subscribe((res) => {
            this.emailTemplates = res;
            this.refreshTable('#table', this.dtTrigger);
        });
        this.emailService.getDefaultEmailTemplates().subscribe((res) => {
            this.defatultMailTemplates = res;
        });
    }

    //#region remove
    removeEmailTemplate(emailTemplate: EmailTemplateView) {
        this.confirmationService
            .confirm(
                this.translate.instant(_('Do you want to delete ')) +
                emailTemplate.name +
                this.translate.instant(_(' email template?'))
            )
            .subscribe((result) => {
                if (result) {
                    this.emailService.deleteEmailTemplate(emailTemplate.id).subscribe(
                        () => {
                            this.messageService.add({
                                severity: 'success',
                                summary: this.translate.instant(_('Success!')),
                                detail: this.translate.instant(
                                    _('Mail template removed with success!')
                                ),
                            });
                        },
                        (error) => {
                            return console.log('Error: ', error);
                        },
                        () => {
                            return this.getEmailTemplates();
                        }
                    );
                }
            });
    }

    //#region add
    addEmailModal(modal: TemplateRef<any>) {
        this.newEmail = new EmailTemplate();
        this.newEmail.id = this.defatultMailTemplates[0].id;
        this.addModalRef = this.modalService.show(modal, this.modalConfig);
    }

    addEmailTemplate() {
        this.emailService.setEmailTemplate(this.newEmail).subscribe(
            (res) => {
                this.messageService.add({
                    severity: 'success',
                    summary: this.translate.instant(_('Success!')),
                    detail: this.translate.instant(_('Email template created with success!')),
                });
                this.router.navigate([res.id], { relativeTo: this.route });
            },
            (error) => {
                return console.log('Error: ', error);
            }
        );
        this.addModalRef.hide();
    }

    private generateBreadCrumb() {
        this.dynamicBreadCrumb('Communication');
    }
}
