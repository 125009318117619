import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

// Models
import { RequestOptions } from '../../models';

// Rxjs
import { Observable } from 'rxjs';

@Injectable()
export class HttpExtendedGlobalService {
    /**
     * Creates an instance of http extended service.
     * @param http
     * @param authService
     */
    constructor(private readonly http: HttpClient) {}

    /**
     * Gets http extended global service
     * @template T
     * @param endPoint
     * @param [options]
     * @param [args]
     * @returns get
     */
    public get<T>(endPoint: string, options = this.baseOptions(), args?: any): Observable<T> {
        if (args !== undefined) {
            options.params = args;
        }

        return this.http.get<T>(this.baseUrl() + endPoint, options);
    }

    /**
     * Posts http extended service
     * @template T
     * @param endPoint
     * @param body
     * @param [options]
     * @param [args]
     * @returns post
     */
    public post<T>(
        endPoint: string,
        body: any,
        options = this.baseOptions(),
        args?: any
    ): Observable<T> {
        if (args !== undefined) {
            options.params = args;
        }
        return this.http.post<T>(this.baseUrl() + endPoint, body, options);
    }

    /**
     * Puts http extended service
     * @template T
     * @param endPoint
     * @param body
     * @param [options]
     * @param [args]
     * @returns put
     */
    public put<T>(
        endPoint: string,
        body: any,
        options = this.baseOptions(),
        args?: any
    ): Observable<T> {
        if (args !== undefined) {
            options.params = args;
        }
        return this.http.put<T>(this.baseUrl() + endPoint, body, options);
    }

    /**
     * Deletes http extended service
     * @template T
     * @param endPoint
     * @param [options]
     * @returns delete
     */
    public delete<T>(endPoint: string, options = this.baseOptions()): Observable<T> {
        return this.http.delete<T>(this.baseUrl() + endPoint, options);
    }

    /**
     * Performs a request with `get` http method on a specific instance.
     * @template T
     * @param instance
     * @param endPoint
     * @param [options]
     * @param [args]
     * @returns for instance
     */
    public getForInstance<T>(
        instance: string,
        endPoint: string,
        options = this.baseOptions(),
        args?: any
    ): Observable<T> {
        if (args !== undefined) {
            options.params = args;
        }

        return this.http.get<T>(this.baseUrl(instance) + endPoint, options);
    }
    /**
     * Posts for instance
     * @template T
     * @param instance
     * @param endPoint
     * @param body
     * @param [options]
     * @param [args]
     * @returns for instance
     */
    public postForInstance<T>(
        instance: string,
        endPoint: string,
        body: any,
        options = this.baseOptions(),
        args?: any
    ): Observable<T> {
        if (args !== undefined) {
            options.params = args;
        }
        return this.http.post<T>(this.baseUrl(instance) + endPoint, body, options);
    }
    /**
     * Puts for instance
     * @template T
     * @param instance
     * @param endPoint
     * @param body
     * @param [options]
     * @param [args]
     * @returns for instance
     */
    public putForInstance<T>(
        instance: string,
        endPoint: string,
        body: any,
        options = this.baseOptions(),
        args?: any
    ): Observable<T> {
        if (args !== undefined) {
            options.params = args;
        }

        return this.http.put<T>(this.baseUrl(instance) + endPoint, body, options);
    }
    /**
     * Deletes for instance
     * @template T
     * @param instance
     * @param endPoint
     * @param [options]
     * @param [args]
     * @returns for instance
     */
    public deleteForInstance<T>(
        instance: string,
        endPoint: string,
        options = this.baseOptions(),
        args?: any
    ): Observable<T> {
        return this.http.delete<T>(this.baseUrl(instance) + endPoint, options);
    }

    /**
     * Bases options
     * @returns options
     */
    public baseOptions(): RequestOptions {
        const options = {
            headers: new HttpHeaders({
                'Cache-Control': 'no-cache',
                Pragma: 'no-cache',
                Expires: 'Sat, 01 Jan 2000 00:00:00 GMT',
                MyTrend_Username: environment.username,
                MyTrend_ApiKey: environment.apiKey,
                MyTrend_Salt: environment.salt,
            }),
        };
        return options;
    }

    /**
     * Blobs options
     * @returns options
     */
    public blobOptions(): RequestOptions {
        let options: RequestOptions;
        options = {
            headers: new HttpHeaders({
                'Content-type': 'application/json',
                MyTrend_Username: environment.username,
                MyTrend_ApiKey: environment.apiKey,
                MyTrend_Salt: environment.salt,
            }),
            responseType: 'blob' as 'json',
            observe: 'response' as 'body',
        };

        return options;
    }

    /**
     * Bases url
     * @param [instance]
     * @returns url
     */
    private baseUrl(instance: string = null): string {
        if (instance) {
            return `${window.location.origin}/${environment.proxyUrl}${instance}/api/`;
        }
        return `${window.location.origin}/${environment.proxyUrl}api/`;
    }
}

/**
 * Https extended global factory
 * @param http
 * @returns
 */
export function httpExtendedGlobalFactory(http: HttpClient) {
    return new HttpExtendedGlobalService(http);
}
