export class Kpi {
    id: string;
    code: string;
    name: string;
    displayName: string;
    description: string;
    comment: string;
    thresholdMin: number;
    thresholdMax: number;
    unitId: string;
    treeNodeId: string;
    values: KpiColumnValue[];
}

export class KpiColumnValue {
    id?: string;
    kpiId: string;
    columnId: string;
    value: number;
    formattedValue?: string;
    valueDisplay?: string;
    fromDate?: Date;
    endDate?: Date;
    header?: string;
    order: number;
    frozen: boolean;
}
export class KpiColumn {
    id: string;
    name: string;
    displayName: string;
    order: number;
    frozen: boolean;
    system: boolean;
}
