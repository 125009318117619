import { Calculation } from './effect';
import { PropertySetProperty } from './property';
import { TreeNode } from './treenode';

export class TreeNodeDisplay {
    treeNode: TreeNode;
    childDisplays: ChildDisplayModel[];
}

export class ChildDisplayModel {
    id?: string;
    topLevelStructureId?: string;
    propertyId?: string;
    calculationId?: string;
    order?: number;
    name?: string;
    sort?: SortType;
    // helper
    headers?: string[];
    values?: any;
    image?: any;
    hideProperty?: boolean;
    type?: string;
}

export class EditTopLevelStructureChildDisplay {
    topLevelId: string;
    topLevelName: string;
    topLevelStructureId: string;
    topLevelStructureName: string;
    childDisplayId: string;
    propertyId: string;
    propertyName: string;
    calculationId: string;
    calculationName: string;
    order: number;
    sort: SortType;
    properties: PropertySetProperty[];
    calculations: Calculation[];
    childrenDisplay: ChildDisplayModel[];
}

export enum SortType {
    ascending = 0,
    descending = 1,
}
