export class ExchangeRate {
    id: string;
    date: Date;
    currency: string;
    rate: number;
    stateId: string;
}

export class Currency {
  id: string;
  date: Date;
  currency: string;
  rate: number;
  stateId: string;
}

export class CurrencyModel {
  code: string;
  value: string;
}

export class TopLevelCurrency {
  id: string;
  topLevelId: string;
  currencySymbol: string;
}

export class EditTopLevelCurrencyModel {
  topLevelId: string;
  topLevelName: string;
  currencies: { [index: string]: boolean };
}
