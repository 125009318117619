import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import {
    ChildDisplayModel,
    EditTopLevelStructureChildDisplay,
    SortType,
} from '../../../../../../shared/models/childDisplay';
import { Calculation } from '../../../../../../shared/models/effect';
import { Property } from '../../../../../../shared/models/property';
import { TopService } from '../../../../../../shared/services/repositories/top.service';
import { MessageService } from 'primeng/api';
import { WorkflowService } from '../../../../../shared/services/workflow.service';
import { Router } from '@angular/router';
import { BaseComponent } from '../../../../../shared/components/base/base.component';
import { PropertyService } from '../../../../../../shared/services/repositories/property.service';
import {
    TopLevelStructure,
    TopLevelStructureLevel,
} from '../../../../../../shared/models/topLevelStructure';
import { forkJoin } from 'rxjs';
import { _ } from '../../../../../../shared/services/translation.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: '[app-sub-levels]',
    templateUrl: './sub-levels.component.html',
    styleUrls: ['./sub-levels.component.scss'],
})
export class SubLevelsComponent extends BaseComponent implements OnInit {
    @Input() subLevel: TopLevelStructure;
    public calculations: Calculation[] = [];
    public properties: Property[] = [];
    public selectedChild: Property | Calculation;
    public selectedEmpty = false;
    public selectedType: string;

    public levelDisplayLimit = 7;

    public loadingSubLevel = false;
    public loadingSubLevelUpdate = false;

    public loadCalculations = false;
    public loadProperties = false;

    public levelDisplays: ChildDisplayModel[] = [];

    constructor(
        private topService: TopService,
        private messageService: MessageService,
        public router: Router,
        protected workflowService: WorkflowService,
        private propertyService: PropertyService,
        private translate: TranslateService
    ) {
        super(router, workflowService);
    }

    ngOnInit() {
        this.getLevelDisplays();
    }

    public getLevelDisplays() {
        this.loadingSubLevel = true;
        this.topService.getStructureLevelDisplays(this.subLevel.id).subscribe(
            (data) => {
                this.levelDisplays = data.sort((a, b) => {
                    return a.order - b.order;
                });
            },
            (err) => {
                return console.log(err);
            },
            () => {
                this.getCalculations();
                this.getProperties();
                this.loadingSubLevel = false;
            }
        );
    }

    public getCalculations() {
        this.loadCalculations = true;
        this.topService.getStructureCalculations(this.subLevel.id).subscribe(
            (data) => {
                data.forEach((calc) => {
                    if (
                        !this.levelDisplays.find((x) => {
                            return x.calculationId && x.name === calc.name;
                        })
                    ) {
                        this.calculations.push(calc);
                    }
                });
            },
            (err) => {
                return console.log(err);
            },
            () => {
                return (this.loadCalculations = false);
            }
        );
    }

    public getProperties() {
        this.loadProperties = true;

        this.topService.getLevelsForTop(this.subLevel.toplevelId).subscribe((levels) => {
            const currentLevelIndex = levels.findIndex((x) => {
                return x.level === this.subLevel.level;
            });
            const level = levels[currentLevelIndex];
            forkJoin(
                this.propertyService.getPropertySet(level.propertySetId),
                this.propertyService.getProperties()
            ).subscribe(
                (res) => {
                    this.properties = [];
                    const propertySet = res[0];
                    const properties = res[1];
                    properties.forEach((prop) => {
                        if (
                            propertySet.propertySetProperties.some((x) => {
                                return x.propertyId === prop.id;
                            }) &&
                            !this.levelDisplays.find((y) => {
                                return y.propertyId && y.name === prop.name;
                            })
                        ) {
                            this.properties.push(prop);
                        }
                    });
                },
                (err) => {
                    return console.log(err);
                },
                () => {
                    return (this.loadProperties = false);
                }
            );
        });
    }

    public order(levelDisplay: ChildDisplayModel, movement: number) {
        this.loadingSubLevelUpdate = true;
        // get index
        const index = this.levelDisplays.indexOf(levelDisplay);
        // change order
        this.levelDisplays = this.array_move(this.levelDisplays, index, index + movement); // movement can be +1 or -1
        // Ensure list is in normal order 0,1,2,3,4
        let order = 0;
        this.levelDisplays.forEach((child) => {
            child.order = order++;
        });
        this.updateOrderSort();
    }

    public sort(levelDisplay: ChildDisplayModel) {
        let sort;
        if (levelDisplay.sort === null) {
            sort = SortType.ascending;
        } else if (levelDisplay.sort === SortType.ascending) {
            sort = SortType.descending;
        } else if (levelDisplay.sort === SortType.descending) {
            sort = null;
        }

        this.loadingSubLevelUpdate = true;
        this.levelDisplays.forEach((x) => {
            return (x.sort = null);
        });
        levelDisplay.sort = sort;
        this.updateOrderSort();
    }

    updateOrderSort() {
        this.topService.reorderChildrendDisplay(this.levelDisplays).subscribe(
            () => {
                return this.messageService.add({
                    severity: 'success',
                    summary: this.translate.instant(_('Succes!')),
                    detail: this.translate.instant(_('Sub level updated with success')),
                });
            },
            (err) => {
                return console.log(err);
            },
            () => {
                this.loadingSubLevelUpdate = false;
            }
        );
    }

    public array_move(arr, old_index, new_index) {
        if (new_index >= arr.length) {
            let k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr;
    }

    public addSaveChild() {
        this.loadingSubLevelUpdate = true;
        // Get max order of children displays
        const max = Math.max.apply(
            Math,
            this.levelDisplays.map(function (o) {
                return o.order;
            })
        );
        const levelDisplayModel = new EditTopLevelStructureChildDisplay();
        levelDisplayModel.topLevelStructureId = this.subLevel.id;
        levelDisplayModel.propertyId =
            this.selectedType === 'Calculation' ? null : this.selectedChild?.id;
        levelDisplayModel.calculationId =
            this.selectedType === 'Calculation' ? this.selectedChild?.id : null;
        levelDisplayModel.order = max + 1;
        // levelDisplayModel.sort = SortType.ascending;

        this.topService.editTopLevelStructureChildDisplay(levelDisplayModel).subscribe(
            () => {},
            (err) => {
                this.loadingSubLevelUpdate = false;
            },

            () => {
                this.messageService.add({
                    severity: 'success',
                    summary: this.translate.instant(_('Succes!')),
                    detail: this.translate.instant(_('Sub level update with success')),
                });
                this.getLevelDisplays();
                this.loadingSubLevelUpdate = false;
            }
        );

        this.selectedChild = null;
        this.selectedEmpty = false;
    }

    public removeLevelDisplay(levelDisplay: ChildDisplayModel) {
        this.loadingSubLevelUpdate = true;
        this.topService.deleteLevelDisplay(levelDisplay.id).subscribe(
            () => {},
            (err) => {
                return console.log(err);
            },
            () => {
                this.messageService.add({
                    severity: 'success',
                    summary: this.translate.instant(_('Success!')),
                    detail: this.translate.instant(_('Sub level update with success')),
                });
                this.getLevelDisplays();
                this.loadingSubLevelUpdate = false;
            }
        );
    }
}
