import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
// services
import { AuthService } from 'src/app/shared/services';
// models
import { UpdateTreeNode } from 'src/app/core/models/UpdateTreeNode';

@Component({
  selector: 'app-top-bar-treenode-update',
  templateUrl: './top-bar-treenode-update.component.html',
  styleUrls: ['./top-bar-treenode-update.component.scss']
})
export class TopBarTreenodeUpdateComponent implements OnInit {
  /**
      * Input  of top bar update manager properties component
      */
  @Input() updateTreenode: UpdateTreeNode;

  /**
   * Breadcrumb items of top bar update manager properties component
   */
  public breadcrumbItems: MenuItem[] = [];

  constructor(private readonly router: Router, private readonly authService: AuthService) { }

  ngOnInit(): void {

    this.updateTreenode.parents.forEach(x => {
      this.breadcrumbItems.push({ label: x.name });
    });

    // add ref treenode
    this.breadcrumbItems.push({ label: this.updateTreenode.name });

    // icon on first element
    this.breadcrumbItems[0].icon = 'fas fa-map-marker-alt';
  }


  navigateToProgress() {
    this.router.navigate([
      `${this.authService.selectedInstance.name}/treenode/${this.updateTreenode.id}/details`,
    ]);
  }

  navigateToFinancials() {   
    this.router.navigate([
      `${this.authService.selectedInstance.name}/treenode/${this.updateTreenode.id}/scorecard/Basic`,
    ]);
  }

}
