import { Component, OnInit, Input } from '@angular/core';
import { MessageService } from 'primeng/api';

import { _ } from '../../../../../../shared/services/translation.service';
import { IGroup, User, GroupSearch } from '../../../../../../shared/models/user';
import { UserService } from '../../../../../../shared/services/repositories/user.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: '[app-user-groups]',
    templateUrl: './user-groups.component.html',
    styleUrls: ['./user-groups.component.scss'],
})
export class UserGroupsComponent implements OnInit {
    @Input() user: User;

    public userGroups: IGroup[] = [];
    public loadingGroups = false;
    public groups: IGroup[] = [];
    public selectedGroup: IGroup;

    constructor(
        private userService: UserService,
        private messageService: MessageService,
        private translate: TranslateService
    ) {}

    ngOnInit() {
        this.getUserGroups();
    }

    public getUserGroups() {
        this.loadingGroups = true;
        this.userGroups = [];
        this.searchUserGroups();
    }

    private searchUserGroups() {
        this.selectedGroup = null;
        this.userService.getUserGroups(this.user.id, new GroupSearch()).subscribe((res) => {
            this.userGroups = res;
            this.loadingGroups = false;
        });
    }

    // search for groups available
    search(event) {
        this.userService.getGroupsForUsers(this.user.id, event.query).subscribe((res) => {
            this.groups = res;
        });
    }

    addUserGroups() {
        if (this.selectedGroup) {
            this.userService.addGroupToUser(this.user.id, this.selectedGroup.id).subscribe(
                () => {
                    this.messageService.add({
                        severity: 'success',
                        summary: this.translate.instant(_('Success!')),
                        detail: this.translate.instant(_('Group added with success to User!')),
                    });
                },
                (error) => {
                    return console.log('Error: ', error);
                },
                () => {
                    return this.searchUserGroups();
                }
            );
        }
    }

    removeGroup(group: IGroup) {
        this.userService.removeUserFromGroup(this.user.id, group.id).subscribe(
            () => {
                this.messageService.add({
                    severity: 'success',
                    summary: this.translate.instant(_('Success!')),
                    detail: this.translate.instant(_('Group removed from user with success!')),
                });
            },
            (error) => {
                return console.log('Error: ', error);
            },
            () => {
                return this.searchUserGroups();
            }
        );
    }
}
