import { Component, OnInit } from '@angular/core';
import { WorkflowService } from '../../../../shared/services/workflow.service';
import { BaseComponent } from '../../../../shared/components/base/base.component';
import { Router } from '@angular/router';
import { TemplateService } from '../../../../shared/services/template.service';
import { AuthService } from '../../../../../shared/services/auth.service';
import { Template, TemplateModel } from '../../../../../shared/models/template';
import { MessageService } from 'primeng/api';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { _ } from '../../../../../shared/services/translation.service';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-template',
    templateUrl: './template.component.html',
    styleUrls: ['./template.component.scss'],
})
export class TemplateComponent extends BaseComponent implements OnInit {
    template: Template;
    imageUrl: string;
    newImage: string;
    color = '#000';

    constructor(
        protected workflowService: WorkflowService,
        public router: Router,
        private templateService: TemplateService,
        private authService: AuthService,
        private messageService: MessageService,
        private translate: TranslateService
    ) {
        super(router, workflowService);
    }

    ngOnInit() {
        this.imageUrl = environment.proxyUrl + '/' + this.authService.selectedInstance.name;
        this.getTemplate();
    }

    getTemplate() {
        this.templateService.getAllTemplates().subscribe((templates) => {
            this.template = templates[0];
        });
    }

    updateTemplate() {
        const templateModel = new TemplateModel();
        Object.assign(templateModel, this.template);
        templateModel.templateId = this.template.id;
        this.templateService.updateTemplate(templateModel).subscribe(
            () => {
                return this.messageService.add({
                    severity: 'success',
                    summary: this.translate.instant(_('Succes!')),
                    detail: this.translate.instant(_('Template updated!')),
                });
            },
            (error) => {
                return console.log('Error: ', error);
            }
        );
    }

    fileDrop(files: NgxFileDropEntry[]) {
        const fileContent = files[0];
        const fileEntry = fileContent.fileEntry as FileSystemFileEntry;

        fileEntry.file((file: File) => {
            if (file.size > 10000000) {
                this.messageService.add({
                    severity: 'warn',
                    summary: this.translate.instant(_('Warning!')),
                    detail: this.translate.instant(_('File size must be under 10MB.')),
                });
                return null;
            }

            const myReader: FileReader = new FileReader();
            myReader.onloadend = (e: any) => {
                this.newImage = myReader.result as string;
                this.template.logoType = file.type;
                this.template.logo = (myReader.result as string).split(',')[1];
            };
            myReader.readAsDataURL(file);
        });
    }
}
