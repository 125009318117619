import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { saveAs } from 'file-saver';
import { fadeInOut } from 'src/app/shared/animations/fade';
import { BaseComponent } from '../../../../shared/components/base/base.component';
import { Router } from '@angular/router';
import { WorkflowService } from '../../../../shared/services/workflow.service';
import { TopService } from '../../../../../shared/services/repositories/top.service';
import { ImportService } from '../../../../../shared/services/repositories/import.service';
import { MessageService } from 'primeng/api';
import { Top } from '../../../../../shared/models/top';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';
import { Constants } from '../../../../../shared/models/constants';
import { _ } from '../../../../../shared/services/translation.service';
import { ImportType } from 'src/app/shared/models/enums';

@Component({
    selector: 'app-import-users',
    templateUrl: './import-users.component.html',
    styleUrls: ['./import-users.component.scss'],
    animations: [fadeInOut],
})
export class ImportUsersComponent extends BaseComponent implements OnInit {
    topLevels: Top[];
    selectedTopLevel: Top;

    file: FormData;
    fileName: string;

    constructor(
        public router: Router,
        protected workflowService: WorkflowService,
        private topService: TopService,
        private importService: ImportService,
        private messageService: MessageService,
        private translate: TranslateService
    ) {
        super(router, workflowService);
        this.dynamicBreadCrumb('Users & Groups');
    }

    ngOnInit() {
        this.loading = true;
        this.file = new FormData();

        this.topService.getTops().subscribe(
            (data) => {
                this.topLevels = data;
                this.onTopSelect(this.topLevels[0].id);
            },
            (err) => {
                return console.log(err, err.Message);
            },
            () => {
                return (this.loading = false);
            }
        );
    }

    onTopSelect(id: string) {
        this.selectedTopLevel = this.topLevels.find((x) => {
            return x.id === id;
        });
    }

    public getImportTemplate() {
        this.importService
            .getTemplate(this.selectedTopLevel.id, ImportType.Users)
            .subscribe((data) => {
                const filename = data.headers
                    .get('Content-Disposition')
                    .split(';')[1]
                    .trim()
                    .split('=')[1]
                    .replace(/"/g, '');
                saveAs(data.body, filename);
            });
    }

    public onFileDrop(files: NgxFileDropEntry[]) {
        if (this.fileName) {
            this.messageService.add({
                severity: 'warn',
                summary: this.translate.instant(_('Warning!')),
                detail: this.translate.instant(_('Users import only accept 1 file at the time')),
            });
            return;
        }
        files.forEach((file) => {
            if (file.fileEntry.isFile) {
                const fileEntry = file.fileEntry as FileSystemFileEntry;
                fileEntry.file((uploadFile: File) => {
                    this.file.append(file.fileEntry.name, uploadFile, file.fileEntry.name);
                    this.fileName = file.fileEntry.name;
                });
            }
        });
    }

    removeFile() {
        this.file = new FormData();
        this.fileName = undefined;
    }

    uploadFile() {
        this.importService
            .import(Constants.IMPORT_USERS, this.selectedTopLevel.id, this.file, null)
            .subscribe(
                () => {},
                (err) => {
                    return console.log(err, err.Message);
                },
                () => {
                    this.messageService.add({
                        severity: 'success',
                        summary: this.translate.instant(_('Succes!')),
                        detail: this.translate.instant(_('Import job created')),
                    });
                    this.fileName = undefined;
                    this.file = new FormData();
                }
            );
    }
}
