import { Component, OnInit, TemplateRef } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Subject, forkJoin } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';

import { ExchangeRate } from '../../../../../shared/models/exchangerate';
import { ExchangeRateService } from '../../../../../shared/services/repositories/exchangerate.service';
import { ConfirmationDialogService } from '../../../../../shared/components/utils/confirmation/ConfirmationDialog.service';
import { BaseComponent } from '../../../../shared/components/base/base.component';
import { fadeInOut } from 'src/app/shared/animations/fade';
import { _ } from '../../../../../shared/services/translation.service';
import { FinancialState } from '../../../../../shared/models/financialState';
import { ScorecardService } from '../../../../../shared/services/repositories/scorecard.service';
import { WorkflowService } from '../../../../shared/services/workflow.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-exchange-rates',
    templateUrl: './exchange-rates.component.html',
    styleUrls: ['./exchange-rates.component.scss'],
    animations: [fadeInOut],
})
export class ExchangeRatesComponent extends BaseComponent implements OnInit {
    public addModalRef: BsModalRef;
    public editModalRef: BsModalRef;
    public dtTrigger: Subject<any> = new Subject();

    public newExchangeRate: ExchangeRate;
    public edtExchangeRate: ExchangeRate;

    public exchangeRates: ExchangeRate[] = [];
    public currencies: { [index: string]: string } = {};
    public currencyKeys: string[] = [];
    public financialStates: FinancialState[];

    public months: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
    public month: number;
    public year: number;
    public selectedType: string;

    public selectedCurrencyValues = new Array<string>();
    public selectedExchangeRates: ExchangeRate[][];

    constructor(
        private exchangeRateService: ExchangeRateService,
        private confirmationService: ConfirmationDialogService,
        private messageService: MessageService,
        private modalService: BsModalService,
        public router: Router,
        protected workflowService: WorkflowService,
        private scorecardService: ScorecardService,
        private translate: TranslateService
    ) {
        super(router, workflowService);
        this.generateBreadCrumb();
    }

    ngOnInit() {
        this.year = new Date().getFullYear();
        this.month = new Date().getMonth();
        this.getInfo();
    }

    getInfo() {
        forkJoin(
            this.exchangeRateService.getExchangeRatesCurrencies(),
            this.scorecardService.getFinancialStates()
        ).subscribe((res) => {
            this.currencies = res[0];
            this.currencyKeys = Object.keys(this.currencies);

            this.financialStates = res[1];
            this.selectedType = this.financialStates[0].id;

            this.getExchangeRates(this.year);
        });
    }

    getExchangeRates(year: number) {
        this.year = year;
        this.loading = true;
        this.exchangeRateService.getExchangeRates(this.year).subscribe((res) => {
            this.exchangeRates = res;
            this.refreshTable('#table', this.dtTrigger);
            this.selectedCurrencyValues = this.getCurrencyIfExists(this.year, this.selectedType);
            this.prepareSelectedExchangeRates();
        });
    }

    prepareSelectedExchangeRates() {
        this.selectedExchangeRates = [];
        this.selectedCurrencyValues.forEach((c) => {
            this.selectedExchangeRates[c] = [];
            this.months.forEach((m) => {
                this.selectedExchangeRates[c].push(
                    this.getExchangeRate(this.selectedType, c, m, this.year)
                );
            });
        });
    }

    // Remove
    removeExchangeRate(exchangeRate: ExchangeRate) {
        this.confirmationService
            .confirm(this.translate.instant(_('Do you want to delete this exchange rate?')))
            .subscribe((result) => {
                if (result) {
                    this.exchangeRateService.deleteExchangeRate(exchangeRate.id).subscribe(
                        () => {
                            this.messageService.add({
                                severity: 'success',
                                summary: this.translate.instant(_('Success!')),
                                detail: this.translate.instant(
                                    _('Exchange rate removed with success!')
                                ),
                            });
                        },
                        (error) => {
                            return console.log('Error: ', error);
                        },
                        () => {
                            return this.getExchangeRates(this.year);
                        }
                    );
                }
            });
    }

    // #region add exchange rate
    addExchangeRateModal(modal: TemplateRef<any>) {
        this.newExchangeRate = new ExchangeRate();
        this.newExchangeRate.date = new Date(Date.UTC(this.year, this.month + 1));

        this.addModalRef = this.modalService.show(modal, this.modalConfig);
    }
    addExchangeRate() {
        this.newExchangeRate.currency = this.newExchangeRate.currency.toLocaleUpperCase();
        this.exchangeRateService.setExchangeRate(this.newExchangeRate).subscribe(
            (res) => {
                this.messageService.add({
                    severity: 'success',
                    summary: this.translate.instant(_('Success!')),
                    detail: this.translate.instant(_('Exchange rate created with success!')),
                });
            },
            (error) => {
                return console.log('Error: ', error);
            },
            () => {
                return this.getExchangeRates(this.year);
            }
        );
        this.addModalRef.hide();
    }

    // #region add exchange rate date
    addExchangeRateModalWithDate(
        modal: TemplateRef<any>,
        month: number,
        year: number,
        state: string,
        currency: string
    ) {
        this.newExchangeRate = new ExchangeRate();
        this.newExchangeRate.stateId = state;
        this.newExchangeRate.currency = currency;
        // this.newExchangeRate.date = new Date(year + '-' + (month + 1));
        this.newExchangeRate.date = new Date(Date.UTC(year, month));
        this.addModalRef = this.modalService.show(modal, this.modalConfig);
    }

    //#region edit
    editExchangeRateModal(exchangeRate: ExchangeRate, modal: TemplateRef<any>) {
        this.edtExchangeRate = new ExchangeRate();
        this.edtExchangeRate.id = exchangeRate.id;
        this.edtExchangeRate.currency = exchangeRate.currency;
        this.edtExchangeRate.rate = exchangeRate.rate;
        this.edtExchangeRate.stateId = exchangeRate.stateId;
        this.edtExchangeRate.date = exchangeRate.date;
        this.editModalRef = this.modalService.show(modal, this.modalConfig);
    }

    editExchangeRate() {
        this.exchangeRateService.updateExchangeRate(this.edtExchangeRate).subscribe(
            () => {
                this.messageService.add({
                    severity: 'success',
                    summary: this.translate.instant(_('Success!')),
                    detail: this.translate.instant(_('Exchange rate edited with success!')),
                });
            },
            (error) => {
                return console.log('Error: ', error);
            },
            () => {
                return this.getExchangeRates(this.year);
            }
        );
        this.editModalRef.hide();
    }

    getExchangeRate(financialStateId: string, currency: string, month: number, year: number) {
        return (
            this.exchangeRates.find((x) => {
                return (
                    x.stateId === financialStateId &&
                    x.currency === currency &&
                    new Date(x.date).getMonth() === month &&
                    new Date(x.date).getFullYear() === year
                );
            }) || null
        );
    }

    getCurrencyIfExists(year: number, state: string) {
        const currencies = new Array<string>();
        this.exchangeRates.forEach((element) => {
            if (
                new Date(element.date).getFullYear() === year &&
                currencies.indexOf(element.currency) === -1 &&
                element.stateId === state
            ) {
                currencies.push(element.currency);
            }
        });
        return currencies;
    }

    onFinancialStateChange() {
        this.selectedCurrencyValues = this.getCurrencyIfExists(this.year, this.selectedType);
        this.prepareSelectedExchangeRates();
    }

    private generateBreadCrumb() {
        this.dynamicBreadCrumb('Exchange Rate');
    }
}
