import { Injectable } from '@angular/core';
import {
    Checklist,
    Deliverable,
    AddDeliverableModel,
    ChecklistDeliverableView,
    ChecklistDeliverable,
    ChecklistView,
    IDeliverableView,
    DeliverableModel,
} from '../../models/checklist';
import { Observable } from 'rxjs';
import { Property } from '../../models/property';
import { HttpExtendedInstanceService } from 'src/app/shared/services';

@Injectable({
    providedIn: 'root',
})
export class ChecklistService {
    constructor(private http: HttpExtendedInstanceService) {}

    // checklist
    public getChecklists(): Observable<ChecklistView[]> {
        return this.http.get<ChecklistView[]>('checklistsView');
    }

    public getChecklist(id: string): Observable<Checklist> {
        return this.http.get<Checklist>(`checklists/${id}`);
    }

    public setChecklist(name: string): Observable<Checklist> {
        return this.http.post<Checklist>('checklists/', { name });
    }

    public updateChecklist(id: string, name: string): Observable<Checklist> {
        return this.http.put<Checklist>(`checklists/${id}`, { name });
    }

    public deleteChecklist(id: string): Observable<Checklist> {
        return this.http.delete<Checklist>(`checklists/${id}`);
    }

    // checklist deliverable
    public getChecklistDeliverables(checklistId: string): Observable<ChecklistDeliverableView[]> {
        return this.http.get<ChecklistDeliverableView[]>(`checklists/${checklistId}/deliverables`);
    }

    public addChecklistDeliverable(
        checklistId: string,
        deliverableModel: AddDeliverableModel
    ): Observable<Deliverable> {
        return this.http.post<Deliverable>(`checklists/${checklistId}`, deliverableModel);
    }

    public updateChecklistDeliverable(
        checklistId: string,
        deliverableModel: AddDeliverableModel
    ): Observable<ChecklistDeliverable> {
        return this.http.put<ChecklistDeliverable>(
            `checklists/${checklistId}/editdeliverable`,
            deliverableModel
        );
    }

    public deleteChecklistDeliverable(
        checklistId: string,
        deliverableId: string
    ): Observable<Deliverable> {
        return this.http.delete<Deliverable>(
            `checklists/${checklistId}/deliverables/${deliverableId}`
        );
    }

    // deliverables
    public getDeliverables(): Observable<Deliverable[]> {
        return this.http.get<Deliverable[]>('deliverables');
    }

    public getDeliverablesview(): Observable<IDeliverableView[]> {
        return this.http.get<IDeliverableView[]>('deliverablesview');
    }

    public getDeliverable(id: string): Observable<Deliverable> {
        return this.http.get<Deliverable>(`deliverables/${id}`);
    }

    public setDeliverable(newDeliverable: DeliverableModel): Observable<Deliverable> {
        return this.http.post<Deliverable>('deliverables', newDeliverable);
    }

    public updateDeliverable(id: string, editDeliverable): Observable<Deliverable> {
        return this.http.put<Deliverable>(`deliverables/${id}`, editDeliverable);
    }

    public deleteDeliverable(id: string): Observable<Deliverable> {
        return this.http.delete<Deliverable>(`deliverables/${id}`);
    }

    public getDeliverableProperties(): Observable<Property[]> {
        return this.http.get<Property[]>('deliverablesproperties');
    }
}
