import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { _ } from '../../../../../shared/services/translation.service';
import { FlowElement } from '../../../../../shared/models/navigationElement';
import { MenuItem } from '../../../models/MenuItem';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../../../../shared/services/auth.service';
import { WorkflowService } from '../../../services/workflow.service';
import { Rights } from 'src/app/admin/instance-admin/models/rights';
import { Instance } from 'src/app/shared/models/instance';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
})
export class MenuComponent {
    public activeMenu = '';
    public searchWord = '';
    public result: FlowElement[] = [];
    public favoritsFlowElements: FlowElement[] = [];
    public runningItems: MenuItem[] = [
        {
            name: this.translate.instant(this.translate.instant(_('Update cycle'))),
            permissions: [Rights.UpdatePeriod],
            route: 'updatecycle',
        },
        {
            name: this.translate.instant(this.translate.instant(_('Users & Groups'))),
            permissions: [Rights.UserAndGroup, Rights.Import],
            route: 'usersandgroups',
        },
        {
            name: this.translate.instant(this.translate.instant(_('Communications'))),
            permissions: [Rights.GroupMailAndTemplate, Rights.SendMail, Rights.Message],
            route: 'communication',
        },
        {
            name: this.translate.instant(this.translate.instant(_('Reporting'))),
            permissions: [Rights.Export, Rights.SnapshotDates],
            route: 'reporting',
        },
        {
            name: this.translate.instant(this.translate.instant(_('Exchange Rate'))),
            permissions: [Rights.ExchangeRate, Rights.TopLevelCurrency, Rights.Import],
            route: 'exchangerate',
        },
        {
            name: this.translate.instant(this.translate.instant(_('Monitoring'))),
            permissions: [],
            route: 'monitoring',
        },
    ];

    public configItems: MenuItem[] = [
        {
            name: this.translate.instant(this.translate.instant(_('Structure management'))),
            permissions: [],
            route: 'structure',
        },
        {
            name: this.translate.instant(this.translate.instant(_('Maturity management'))),
            permissions: [Rights.MaturityAndStrategy, Rights.CheckistAndDeliverable],
            route: 'maturities',
        },
        {
            name: this.translate.instant(_('Nodes configuration')),
            permissions: [
                Rights.PropertyAndSet,
                Rights.CreateKPI,
                Rights.Import,
                Rights.DeletedNode,
            ],
            route: 'nodes',
        },
        {
            name: this.translate.instant(_('Scorecards')),
            permissions: [Rights.ScorecardAndEffect],
            route: 'scorecards',
        },
    ];

    public globalItems: MenuItem[] = [
        {
            name: this.translate.instant(this.translate.instant(_('Users'))),
            permissions: [],
            route: 'users',
        },
        {
            name: this.translate.instant(this.translate.instant(_('Instances'))),
            permissions: [],
            route: 'instances',
        },
        {
            name: this.translate.instant(_('Emails')),
            permissions: [],
            route: 'mailtemplates',
        },
        {
            name: this.translate.instant(_('Indexation')),
            permissions: [],
            route: 'indexation',
        },
        {
            name: this.translate.instant(_('Global Messages')),
            permissions: [],
            route: 'messages',
        },
        {
            name: this.translate.instant(_('Tag Effects')),
            permissions: [],
            route: 'tageffects',
        },
    ];

    public flows: any[];

    constructor(
        private route: ActivatedRoute,
        public authService: AuthService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private workflowService: WorkflowService,
        private translate: TranslateService
    ) {
        this.route.paramMap.subscribe((params) => {
            const instanceName = params.get('instance');
            this.init(instanceName);
        });
    }

    init(instanceName: string) {
        const rewriteUlr = this.activatedRoute.snapshot.url[0]
            ? this.activatedRoute.snapshot.url[0].path
            : '';
        this.initFlows();
    }

    public navigateGlobal() {
        this.router.navigate([`/admin/global`]);
    }

    navigate(flowElement: FlowElement) {
        // find entyre url
        const layoutElement = this.workflowService.layoutElements.find((x) => {
            return x.type === flowElement.type;
        });
        if (layoutElement) {
            this.searchWord = '';
            this.expandProperMenu(layoutElement.link);
            this.router.navigate([
                `admin/${this.authService.selectedInstance.name}/${layoutElement.link}/${flowElement.link}`,
            ]);
        }
    }

    menuExpand(event, selectedMenu) {
        event.stopPropagation();
        if (selectedMenu === this.activeMenu) {
            this.activeMenu = '';
        } else {
            this.activeMenu = selectedMenu;
        }
    }

    selectInstance(instance: Instance) {
        this.authService.isInGlobal = false;
        this.router.navigate([`/admin/${instance.name}`]);
        this.activeMenu = '';
    }

    searchFunction() {
        this.result = [];
        const word = new RegExp('^.*' + this.searchWord.toLowerCase() + '.*$');
        this.workflowService.layoutElements.forEach((layoutEl) => {
            layoutEl.modulesElements.forEach((moduleEl) => {
                moduleEl.tag.forEach((tag) => {
                    if (tag.match(word)) {
                        if (this.result.indexOf(moduleEl) < 1 && moduleEl.disabled === false) {
                            this.result.push(moduleEl);
                        }
                    }
                });
            });
        });
    }

    private expandProperMenu(layoutRootElementLink: string) {
        if (
            this.runningItems.some((x) => {
                return x.route === layoutRootElementLink;
            })
        ) {
            this.activeMenu = 'running';
        } else if (
            this.configItems.some((x) => {
                return x.route === layoutRootElementLink;
            })
        ) {
            this.activeMenu = 'configuration';
        }
    }

    private initFlows() {
        if (this.authService.selectedInstance) {
            this.flows = [
                {
                    name: this.translate.instant(_('Setup and configure a Top Level')),
                    items: [
                        {
                            name: this.translate.instant(_('Top level creation')),
                            link: `structure/tops`,
                        },
                        {
                            name: this.translate.instant(_('Scorecard creation')),
                            link: `scorecards/scorecards`,
                        },
                        {
                            name: this.translate.instant(_('Effects creation in scorecard')),
                            link: `scorecards/effects`,
                        },
                        {
                            name: this.translate.instant(_('Scorecard views')),
                            link: `scorecards/scorecardviews`,
                        },
                        {
                            name: this.translate.instant(_('Financials creation')),
                            link: `scorecards/financialstates`,
                        },
                        {
                            name: this.translate.instant(_('Multiple levels creation')),
                            link: `structure/levels`,
                        },
                        {
                            name: this.translate.instant(_('Maturities creation')),
                            link: `maturities/maturities`,
                        },
                        {
                            name: this.translate.instant(_('Maturity strategy creation')),
                            link: `maturities/maturitystrategies`,
                        },
                        {
                            name: this.translate.instant(_('Maturity paths management')),
                            link: `maturities/maturitystrategies`,
                        },
                        {
                            name: this.translate.instant(_('Properties creation')),
                            link: `nodes/properties`,
                        },
                        {
                            name: this.translate.instant(_('Property Sets creation')),
                            link: `nodes/propertysets`,
                        },
                        {
                            name: this.translate.instant(_('KPI property values')),
                            link: `nodes/kpis`,
                        },
                        {
                            name: this.translate.instant(
                                _('Assign property sets, maturity strategies, scorecard per level')
                            ),
                            link: `structure/levels`,
                        },
                    ],
                    icon: 'fa fa-home',
                },
                {
                    name: this.translate.instant(_('Create a User')),
                    items: [
                        {
                            name: this.translate.instant(_('Create new user')),
                            link: `usersandgroups/users`,
                        },
                        {
                            name: this.translate.instant(_('Create new group')),
                            link: `usersandgroups/groups`,
                        },
                        {
                            name: this.translate.instant(_('Assign user to group')),
                            link: `usersandgroups/users`,
                        },
                        {
                            name: this.translate.instant(_('Transfer access rights')),
                            link: `usersandgroups/users`,
                        },
                    ],
                    icon: 'fa fa-user',
                },
                {
                    name: this.translate.instant(_('Setup Update Period')),
                    items: [
                        {
                            name: this.translate.instant(
                                _('Setup progress update & scorecard update')
                            ),
                            link: `updatecycle/updateperiod`,
                        },
                        {
                            name: this.translate.instant(
                                _('Assign update period to desired levels')
                            ),
                            link: `structure/levels`,
                        },
                        {
                            name: this.translate.instant(_('Activate edit rights to team')),
                            link: `nodes/properties`,
                        },
                        {
                            name: this.translate.instant(
                                _('Activate updates in desired maturities')
                            ),
                            link: `maturities/maturities`,
                        },
                        {
                            name: this.translate.instant(_('Create mail templates')),
                            link: `communication/mailtemplates`,
                        },
                        {
                            name: this.translate.instant(_('Create group mails')),
                            link: `communication/groupmails`,
                        },
                        {
                            name: this.translate.instant(_('Mail diffusion confirmation')),
                            link: `communication/generatedmails`,
                        },
                    ],
                    icon: 'fas fa-sync',
                },
            ];
        }
    }
}
