import { Component, TemplateRef } from '@angular/core';
import { MessageService } from 'primeng/api';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';

import { BaseComponent } from '../../../../shared/components/base/base.component';
import { fadeInOut } from 'src/app/shared/animations/fade';
import { WorkflowService } from '../../../../shared/services/workflow.service';
import {
    AddMaturityToMaturityStrategyModel,
    MaturityStrategyMaturityView,
} from '../../../../../shared/models/maturity';
import { MaturityService } from '../../../../../shared/services/repositories/maturity.service';
import { _ } from '../../../../../shared/services/translation.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-maturity-strategy-maturities',
    templateUrl: './maturity-strategy-maturities.component.html',
    styleUrls: ['./maturity-strategy-maturities.component.scss'],
    animations: [fadeInOut],
})
export class MaturityStrategyMaturitiesComponent extends BaseComponent {
    public addModalRef: BsModalRef;
    public editModalRef: BsModalRef;
    public dtTrigger: Subject<any> = new Subject();

    public maturityStrategyId: string;
    public maturityStrategyMaturities: AddMaturityToMaturityStrategyModel =
        new AddMaturityToMaturityStrategyModel();
    public maturityToAdd: AddMaturityToMaturityStrategyModel =
        new AddMaturityToMaturityStrategyModel();

    constructor(
        private maturityService: MaturityService,
        private messageService: MessageService,
        private modalService: BsModalService,
        public router: Router,
        public route: ActivatedRoute,
        protected workflowService: WorkflowService,
        private translate: TranslateService
    ) {
        super(router, workflowService);
        // disable order in specific columns
        this.dtOptions.columnDefs = [
            { orderable: false, targets: 3 },
            { orderable: false, targets: 4 },
            { orderable: false, targets: 5 },
        ];

        this.route.paramMap.subscribe((params) => {
            const id = params.get('id');
            if (id) {
                this.maturityStrategyId = id;
                this.getMaturityStrategyMaturities();
            }
        });
    }

    getMaturityStrategyMaturities() {
        this.loading = true;
        this.maturityService
            .getMaturityStraregyMaturitities(this.maturityStrategyId)
            .subscribe((res) => {
                this.maturityStrategyMaturities = res;
                this.refreshTable('#table', this.dtTrigger);
                this.generateBreadCrumb();
            });
    }

    //#region remove
    remove(maturityId: string) {
        this.maturityService
            .removeMaturityStrategyMaturity(this.maturityStrategyId, maturityId)
            .subscribe(
                () => {
                    this.messageService.add({
                        severity: 'success',
                        summary: this.translate.instant(_('Success!')),
                        detail: this.translate.instant(_('Maturity removed with success!')),
                    });
                },
                (error) => {
                    return console.log('Error: ', error);
                },
                () => {
                    return this.getMaturityStrategyMaturities();
                }
            );
    }

    //#region add
    addMaturityModal(modal: TemplateRef<any>) {
        this.maturityToAdd = new AddMaturityToMaturityStrategyModel();
        this.maturityToAdd.maturityStrategyId = this.maturityStrategyId;
        this.addModalRef = this.modalService.show(modal, this.modalConfig);
    }

    addMaturity() {
        this.maturityService.addMaturityStrategyMaturity(this.maturityToAdd).subscribe(
            () => {
                this.messageService.add({
                    severity: 'success',
                    summary: this.translate.instant(_('Success!')),
                    detail: this.translate.instant(_('Maturity added with success!')),
                });
            },
            (error) => {
                return console.log('Error: ', error);
            },
            () => {
                return this.getMaturityStrategyMaturities();
            }
        );
        this.addModalRef.hide();
    }

    //#region edit
    editMaturityModal(maturity: MaturityStrategyMaturityView, modal: TemplateRef<any>) {
        this.maturityToAdd = new AddMaturityToMaturityStrategyModel();
        this.maturityToAdd.maturityStrategyId = this.maturityStrategyId;
        this.maturityToAdd.maturityId = maturity.id;
        this.maturityToAdd.order = maturity.order;
        this.maturityToAdd.isDefault = maturity.isDefault;
        this.editModalRef = this.modalService.show(modal, this.modalConfig);
    }

    editMaturity() {
        this.maturityService.updateMaturityStrategyMaturity(this.maturityToAdd).subscribe(
            () => {
                this.messageService.add({
                    severity: 'success',
                    summary: this.translate.instant(_('Success!')),
                    detail: this.translate.instant(_('Maturity updated with success!')),
                });
            },
            (error) => {
                return console.log('Error: ', error);
            },
            () => {
                return this.getMaturityStrategyMaturities();
            }
        );
        this.editModalRef.hide();
    }

    private generateBreadCrumb() {
        const breadCrumbElement = this.maturityStrategyMaturities.maturityStrategyName;
        this.dynamicBreadCrumb('Maturity', 'Workflows', [
            {
                name: breadCrumbElement,
                link: '',
                isActive: false,
                subElements: [],
                permissions: null,
            },
        ]);
    }
}
