import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WorkspaceService {

  public structureChanged$: EventEmitter<boolean> = new EventEmitter();
  public selectedTreenodeChanged$: EventEmitter<string> = new EventEmitter();

  constructor() { }

  public structureChanged() {
    this.structureChanged$.next(true);
  }

  public selectedTreenodeChanged(treenodeId: string) {
    this.selectedTreenodeChanged$.next(treenodeId);
  }
}
