import { Component, OnInit, TemplateRef } from '@angular/core';
import { forkJoin, Subject } from 'rxjs';
import { MessageService } from 'primeng/api';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { EmailService } from '../../../../../shared/services/repositories/email.service';
import {
    GroupMail,
    GroupMailView,
    GroupMailTemplateView,
    EmailTemplate,
} from '../../../../shared/models/email';
import { BaseComponent } from '../../../../shared/components/base/base.component';
import { ConfirmationDialogService } from '../../../../../shared/components/utils/confirmation/ConfirmationDialog.service';
import { fadeInOut } from 'src/app/shared/animations/fade';
import { _ } from '../../../../../shared/services/translation.service';
import { Router } from '@angular/router';
import { WorkflowService } from '../../../../shared/services/workflow.service';
import { TranslateService } from '@ngx-translate/core';
import { GroupMailType, Frequency } from 'src/app/shared/models/enums';

@Component({
    selector: 'app-group-emails',
    templateUrl: './group-emails.component.html',
    styleUrls: ['./group-emails.component.scss'],
    animations: [fadeInOut],
})
export class GroupEmailsComponent extends BaseComponent implements OnInit {
    public addModalRef: BsModalRef;
    public editModalRef: BsModalRef;
    public editTemplatesModalRef: BsModalRef;
    public dtTrigger: Subject<any> = new Subject();

    public groupMails: GroupMailView[] = [];
    public newGroupMail: GroupMail;
    public edtGroupMail: GroupMail;

    public edtGroupMailTemplates: GroupMail;
    public groupMailTemplates: GroupMailTemplateView[];
    public possibleTemplates: EmailTemplate[];
    public selectedPossibleTemplateId: string;

    public GroupMailType = GroupMailType;
    public Frequency = Frequency;

    public groupMailKeys;
    public frequencyKeys;

    constructor(
        private emailService: EmailService,
        private confirmationService: ConfirmationDialogService,
        private messageService: MessageService,
        private modalService: BsModalService,
        public router: Router,
        protected workflowService: WorkflowService,
        private translate: TranslateService
    ) {
        super(router, workflowService);
        // disable order in specific columns
        this.dtOptions.columnDefs = [
            { orderable: false, targets: 4 },
            { orderable: false, targets: 5 },
        ];

        this.generateBreadCrumb();

        this.groupMailKeys = Object.keys(this.GroupMailType);
        this.groupMailKeys = this.groupMailKeys.slice(0, this.groupMailKeys.length / 2);

        this.frequencyKeys = Object.keys(this.Frequency);
        this.frequencyKeys = this.frequencyKeys.slice(0, this.frequencyKeys.length / 2);
    }

    ngOnInit() {
        this.getGroupMails();
    }

    getGroupMails() {
        this.loading = true;
        this.emailService.getGroupMails().subscribe((res) => {
            this.groupMails = res;
            this.refreshTable('#table', this.dtTrigger);
        });
    }

    //#region remove
    removeChecklist(groupMail: GroupMailView) {
        this.confirmationService
            .confirm(
                this.translate.instant(_('Do you want delete ')) +
                    groupMail.name +
                    this.translate.instant(_(' group mail?'))
            )
            .subscribe((result) => {
                if (result) {
                    this.emailService.deleteGroupMail(groupMail.id).subscribe(
                        () => {
                            this.messageService.add({
                                severity: 'success',
                                summary: this.translate.instant(_('Success!')),
                                detail: this.translate.instant(
                                    _('Group mail removed with success!')
                                ),
                            });
                        },
                        (error) => {
                            return console.log('Error: ', error);
                        },
                        () => {
                            return this.getGroupMails();
                        }
                    );
                }
            });
    }

    //#region add
    addGroupMailModal(modal: TemplateRef<any>) {
        this.newGroupMail = new GroupMail();
        this.addModalRef = this.modalService.show(modal, this.modalConfig);
    }

    addGroupMail() {
        // null or white spaces
        if (this.newGroupMail.name !== null && this.newGroupMail.name.match(/^ *$/) === null) {
            this.emailService.setGroupMail(this.newGroupMail).subscribe(
                () => {
                    this.messageService.add({
                        severity: 'success',
                        summary: this.translate.instant(_('Success!')),
                        detail: this.translate.instant(_('Group mail created with success!')),
                    });
                },
                (error) => {
                    return console.log('Error: ', error);
                },
                () => {
                    return this.getGroupMails();
                }
            );
            this.addModalRef.hide();
        }
    }

    //#region edit
    editGroupMailModal(groupMail: GroupMailView, modal: TemplateRef<any>) {
        this.edtGroupMail = {
            id: groupMail.id,
            name: groupMail.name,
            type: groupMail.type,
            frequency: groupMail.frequency,
        };
        this.editModalRef = this.modalService.show(modal, this.modalConfig);
    }

    editGroupMail() {
        // null or white spaces
        if (this.edtGroupMail.name !== null && this.edtGroupMail.name.match(/^ *$/) === null) {
            this.emailService.updateGroupMail(this.edtGroupMail.id, this.edtGroupMail).subscribe(
                () => {
                    this.messageService.add({
                        severity: 'success',
                        summary: this.translate.instant(_('Success!')),
                        detail: this.translate.instant(_('Group mail edited with success!')),
                    });
                },
                (error) => {
                    return console.log('Error: ', error);
                },
                () => {
                    return this.getGroupMails();
                }
            );
            this.editModalRef.hide();
        }
    }

    //#region edit group mail templates
    editGroupMailTemplatesModal(groupMail: GroupMailView, modal: TemplateRef<any>) {
        this.edtGroupMailTemplates = groupMail;
        this.groupMailTemplatesGetData();
        this.editTemplatesModalRef = this.modalService.show(modal, this.modalConfig);
    }

    groupMailTemplatesGetData() {
        forkJoin(
            this.emailService.getGroupMailPossibleTemplates(this.edtGroupMailTemplates.id),
            this.emailService.getGroupMailTemplates(this.edtGroupMailTemplates.type)
        ).subscribe((res) => {
            this.groupMailTemplates = res[0];
            this.possibleTemplates = res[1];
            if (this.possibleTemplates && this.possibleTemplates.length > 0) {
                this.selectedPossibleTemplateId = this.possibleTemplates[0].id;
            }
        });
    }

    addGroupToMailTemplate() {
        this.emailService
            .addTemplateInGroupMail(this.edtGroupMailTemplates.id, this.selectedPossibleTemplateId)
            .subscribe(
                () => {
                    this.messageService.add({
                        severity: 'success',
                        summary: this.translate.instant(_('Success!')),
                        detail: this.translate.instant(
                            _('Group mail template added with success!')
                        ),
                    });
                },
                (error) => {
                    return console.log('Error: ', error);
                },
                () => {
                    return this.groupMailTemplatesGetData();
                }
            );
    }

    removeGroupToMailTemplate(groupMailTemplate: GroupMailTemplateView) {
        //  this.confirmationService.confirm('Do you want remove template' + groupMailTemplate.templateType + ' from group mail?')
        //    .subscribe(result => {
        //       if (result) {
        this.emailService
            .removeTemplateInGroupMail(this.edtGroupMailTemplates.id, groupMailTemplate.id)
            .subscribe(
                () => {
                    this.messageService.add({
                        severity: 'success',
                        summary: this.translate.instant(_('Success!')),
                        detail: this.translate.instant(
                            _('Group mail template removed with success!')
                        ),
                    });
                },
                (error) => {
                    return console.log('Error: ', error);
                },
                () => {
                    return this.groupMailTemplatesGetData();
                }
            );
    }
    //  });

    private generateBreadCrumb() {
        this.dynamicBreadCrumb('Communication');
    }
}
