import { Injectable } from '@angular/core';

// Models
import { TopLevelStructure } from 'src/app/shared/models/topLevelStructure';

// Rxjs
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class UpdateManagerService {
    /**
     * Level  of update manager service
     */
    private level = new BehaviorSubject(null);

    /**
     * Current level of update manager service
     */
    public currentLevel = this.level.asObservable();

    /**
     * Validation updated of update manager service
     */
    private validationUpdated = new BehaviorSubject(null);

    /**
     * Current validation updated of update manager service
     */
    public currentValidationUpdated = this.validationUpdated.asObservable();

    /**
     * Creates an instance of update manager service.
     */
    constructor() {}

    /**
     * Next level
     * @param level
     */
    public nextLevel(level: TopLevelStructure) {
        this.level.next(level);
    }

    /**
     * Next validation updated
     * @param change
     */
    public nextValidationUpdated(change: boolean) {
        this.validationUpdated.next(change);
    }
}
