import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';

import { UserService } from '../../../../../shared/services/repositories/user.service';
import { User } from '../../../../../shared/models/user';
import { ILogEntry, HistorySearch } from '../../../../../shared/models/logEntry';
import { HistoryService } from '../../../../../shared/services/repositories/history.service';
import { BaseComponent } from '../../../../shared/components/base/base.component';
import { fadeInOut } from 'src/app/shared/animations/fade';
import { _ } from '../../../../../shared/services/translation.service';
import { WorkflowService } from '../../../../shared/services/workflow.service';
import { AuthService } from '../../../../../shared/services/auth.service';

@Component({
  selector: 'app-user-history',
  templateUrl: './user-history.component.html',
  styleUrls: ['./user-history.component.scss'],
  animations: [fadeInOut]
})
export class UserHistoryComponent extends BaseComponent implements OnInit {

  public dtTrigger: Subject<any> = new Subject();

  public historySearch: HistorySearch = new HistorySearch();
  public startDate: Date;
  public endDate: Date;
  public userId: string;
  public user: User;

  public logEntries: ILogEntry[] = [];
  public page: Number = 1;
  public totalPages: Number = 1;

  constructor(private userService: UserService, private historyService: HistoryService, private route: ActivatedRoute,
              private authService: AuthService, public router: Router, protected workflowService: WorkflowService) {
    super(router, workflowService);
    this.dtOptions = {
      ordering: false,
      searching: false,
      paging: false,
      info: false
    };
    this.historySearch.max = 20;
    this.endDate = new Date();
    this.startDate = new Date();
    this.startDate.setDate(-1);
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      const id = params.get('id');
      if (id) {
        this.userId = id;
        this.getUser();
        this.historySearch.userId = id;
        this.getHistoryEntries();
      }

    });
  }

  getUser() {
    this.userService.getUser(this.userId).subscribe(res => {
      this.user = res;
      this.generateBreadCrumb();
    });
  }

  public getHistoryEntries() {
    this.loading = true;
    this.historySearch.startDate = this.startDate ? new Date(this.startDate).toDateString() : '';
    this.historySearch.endDate = this.endDate ? new Date(this.endDate).toDateString() : '';

    this.historyService.getHistoryEntries(this.historySearch).subscribe(res => {
      this.totalPages = res.total;
      this.page = (+res.offset / this.historySearch.max) + 1;
      this.logEntries = res.results;
      this.refreshTable('#table', this.dtTrigger);
    });
  }

  public pageChanged(event: any): void {
    this.historySearch.offset = (event.page - 1) * this.historySearch.max;
    this.getHistoryEntries();
  }

  private generateBreadCrumb() {
    this.dynamicBreadCrumb('Users & Groups');
  }

}
