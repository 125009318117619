import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpExtendedGlobalService, HttpExtendedInstanceService } from 'src/app/shared/services';

import { Message, MessageView } from '../../models/message';

@Injectable({
    providedIn: 'root',
})
export class MessageService {
    constructor(
        private readonly httpInstance: HttpExtendedInstanceService,
        private readonly httpGlobal: HttpExtendedGlobalService
    ) {}

    public getMessages(): Observable<Message[]> {
        return this.httpInstance.get<Message[]>('messages');
    }

    // get all instances messages
    public getAllMessages(): Observable<MessageView[]> {
        return this.httpGlobal.get<MessageView[]>(
            'instances/all/messages',
            this.httpGlobal.baseOptions()
        );
    }

    public getMessage(id: string): Observable<Message> {
        return this.httpInstance.get<Message>(`messages/${id}`);
    }

    public setMessage(message: Message): Observable<Message> {
        return this.httpInstance.post<Message>('messages', message);
    }

    public updateMessage(id: string, message: Message): Observable<Message> {
        return this.httpInstance.put<Message>(`messages/${id}`, message);
    }

    public deleteMessage(id: string) {
        return this.httpInstance.delete<Message>(`messages/${id}`);
    }
}
