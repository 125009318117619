import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, RouterEvent, NavigationStart, NavigationEnd, NavigationError, NavigationCancel } from '@angular/router';
import { Title } from '@angular/platform-browser';

// Services
import { AppConfig } from './shared/services/config.service';
import { MyTrendConfig } from './shared/services/myTrendConfig.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  /**
   * Loading  of app component
   */
  public loading = false;

  /**
   * Creates an instance of app component.
   * @param translate 
   * @param router 
   * @param titleService 
   */
  constructor(
    private readonly translate: TranslateService,
    private readonly router: Router,
    private readonly titleService: Title,
  ) {
    // Change page title
    this.titleService.setTitle(`${MyTrendConfig.myTrendSettings.app.name}`);

    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang('en');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translate.use(AppConfig.settings.lang);

    this.router.events.subscribe((event: RouterEvent) => {
      if (event instanceof NavigationStart) {
        this.loading = true;
      }

      if (event instanceof NavigationEnd
        || event instanceof NavigationError
        || event instanceof NavigationCancel
      ) {
        this.loading = false;
      }
    });
  }
}
