import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-tags',
    templateUrl: './tags.component.html',
    styleUrls: ['./tags.component.scss'],
})
export class TagsComponent implements OnInit {
    @Input() possibleValues: string[];
    @Input() tags: string[];
    @Output() tagsChange: EventEmitter<string[]> = new EventEmitter<string[]>();

    private input: JQuery<HTMLElement>;
    possibleValuesFiltered: string[];

    constructor() {}

    ngOnInit() {
        this.input = $('.tags-container input');
        this.input.on('keyup', (e) => {
            this.filterExistingValue($(e.target).val() as string);
        });
    }

    filterExistingValue(input: string): void {
        if (input === '') {
            this.possibleValuesFiltered = [];
            return;
        }
        const regExp = new RegExp(input, 'gmi');

        this.possibleValuesFiltered = this.possibleValues.filter((x) => {
            return (
                x.match(regExp) &&
                !this.tags.some((y) => {
                    return x === y;
                })
            );
        });
    }

    addValue(value: string = null) {
        this.possibleValuesFiltered = [];
        this.input.val('');
        this.tags.push(value);

        this.onTagsChange();
    }

    onTagsChange() {
        this.tagsChange.emit(this.tags);
    }
}
