import { Injectable } from '@angular/core';
import { IAppConfig, Environment } from '../models/config';
import { HttpClient } from '@angular/common/http';
import * as Sentry from '@sentry/browser';

@Injectable()
export class AppConfig {
  static settings: IAppConfig;

  constructor(private http: HttpClient) {
  }

  load() {
    const jsonFile = 'assets/config/config.json';
    return new Promise<void>((resolve, reject) => {
      this.http.get(jsonFile).toPromise().then((config: IAppConfig) => {
        AppConfig.settings = config;

        if (config.sentryDsn) {
          /* INITIALIZE SENTRY */
          Sentry.init({
            dsn: AppConfig.settings.sentryDsn,
            environment: AppConfig.settings.environment === Environment.prod ? 'Production' : 'Test'
          });
        }

        resolve();
      }).catch((response: any) => {
        reject(`Could not load file '${jsonFile}' : ${JSON.stringify(response)}`);
      });
    });
  }
}
