export { } // this will make it module

declare global { // this is important to access it as global type String
  interface Date {
    toCustomString(): string;
  }
}

Date.prototype.toCustomString = function (): string {
  if (this) {
    // convert to us format. Remove special 'u200E' added by IE 11 ( the way to avoid this IE bug)
    return this.toLocaleDateString('en-US').replace(/\u200E/g, '');
  } else {
    return null;
  }
};
