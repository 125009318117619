import { MessageType } from '../models/enums';

export class PrimeTools {
    public static convertMessageType(messageType: MessageType): string {
        switch (messageType) {
            case MessageType.Error:
                return 'error';
            case MessageType.Warning:
                return 'warn';
            case MessageType.Info:
                return 'info';
            default:
                return 'info';
        }
    }

}