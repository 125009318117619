import { Component, OnInit, TemplateRef } from '@angular/core';
import { MessageService } from 'primeng/api';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject, forkJoin } from 'rxjs';

import { IGroupView, GroupSearch, IGroup } from '../../../../../shared/models/user';
import { UserService } from '../../../../../shared/services/repositories/user.service';
import { ConfirmationDialogService } from '../../../../../shared/components/utils/confirmation/ConfirmationDialog.service';
import { BaseComponent } from '../../../../shared/components/base/base.component';
import { fadeInOut } from 'src/app/shared/animations/fade';
import { _ } from '../../../../../shared/services/translation.service';
import { Router, ActivatedRoute } from '@angular/router';
import { WorkflowService } from '../../../../shared/services/workflow.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-groups',
    templateUrl: './groups.component.html',
    styleUrls: ['./groups.component.scss'],
    animations: [fadeInOut],
})
export class GroupsComponent extends BaseComponent implements OnInit {
    public dtTrigger: Subject<any> = new Subject();
    public addModalRef: BsModalRef;
    public editModalRef: BsModalRef;

    public groupSearch: GroupSearch = new GroupSearch();
    public groups: IGroupView[] = [];
    public page: Number = 1;
    public totalPages: Number = 1;

    public newGroupName: string;
    public edtGroup: IGroup;

    constructor(
        private userService: UserService,
        private confirmationService: ConfirmationDialogService,
        private messageService: MessageService,
        private modalService: BsModalService,
        public router: Router,
        protected workflowService: WorkflowService,
        private route: ActivatedRoute,
        private translate: TranslateService
    ) {
        super(router, workflowService);
        this.dtOptions = {
            ordering: false,
            searching: false,
            paging: false,
            info: false,
        };
        this.generateBreadCrumb();
        this.groupSearch.max = 20;
    }

    ngOnInit() {
        this.getGroups();
    }

    public pageChanged(event: any): void {
        this.groupSearch.offset = (event.page - 1) * this.groupSearch.max;
        this.getGroups();
    }

    getGroups() {
        this.loading = true;
        this.userService.getGroupViews(this.groupSearch).subscribe((res) => {
            this.totalPages = res.total;
            this.page = +res.offset / this.groupSearch.max + 1;
            this.groups = res.results;
            this.refreshTable('#table', this.dtTrigger);
        });
    }

    //#region remove
    removeChecklist(group: IGroupView) {
        this.confirmationService
            .confirm(
                this.translate.instant(_('Do you want to delete ')) +
                    group.name +
                    this.translate.instant(_(' group?'))
            )
            .subscribe((result) => {
                if (result) {
                    this.userService.deleteGroup(group.id).subscribe(
                        () => {
                            this.messageService.add({
                                severity: 'success',
                                summary: this.translate.instant(_('Succes!')),
                                detail: this.translate.instant(_('Group removed with success!')),
                            });
                        },
                        (error) => {
                            return console.log('Error: ', error);
                        },
                        () => {
                            return this.getGroups();
                        }
                    );
                }
            });
    }

    //#region add
    addCGroupModal(modal: TemplateRef<any>) {
        this.newGroupName = '';
        this.addModalRef = this.modalService.show(modal, this.modalConfig);
    }

    addGroup() {
        // null or white spaces
        if (this.newGroupName !== null && this.newGroupName.match(/^ *$/) === null) {
            this.userService.setGroup(this.newGroupName).subscribe(
                () => {
                    this.messageService.add({
                        severity: 'success',
                        summary: this.translate.instant(_('Succes!')),
                        detail: this.translate.instant(_('Group added with success!')),
                    });
                },
                (error) => {
                    return console.log('Error: ', error);
                },
                () => {
                    return this.getGroups();
                }
            );
            this.addModalRef.hide();
        }
    }

    //#region edit
    editGroupModal(group: IGroupView, modal: TemplateRef<any>) {
        this.edtGroup = {
            name: group.name,
            id: group.id,
            system: false,
            isExpanded: false,
            isPropertiesExpanded: false,
        };
        this.editModalRef = this.modalService.show(modal, this.modalConfig);
    }

    editGroup() {
        // null or white spaces
        if (this.edtGroup.name !== null && this.edtGroup.name.match(/^ *$/) === null) {
            this.userService.updateGroup(this.edtGroup.id, this.edtGroup.name).subscribe(
                () => {
                    this.messageService.add({
                        severity: 'success',
                        summary: this.translate.instant(_('Succes!')),
                        detail: this.translate.instant(_('Group edited with success!')),
                    });
                },
                (error) => {
                    return console.log('Error: ', error);
                },
                () => {
                    return this.getGroups();
                }
            );
            this.editModalRef.hide();
        }
    }

    expandGroupUsers(group: IGroup) {
        if (group.isExpanded) {
            group.isExpanded = false;
            return;
        }

        this.collapseGroups();

        group.isExpanded = true;
    }

    expandGroupProperties(group: IGroup) {
        if (group.isPropertiesExpanded) {
            group.isPropertiesExpanded = false;
            return;
        }

        this.collapseGroups();

        group.isPropertiesExpanded = true;
    }

    private collapseGroups() {
        this.groups.forEach((group) => {
            group.isExpanded = false;
            group.isPropertiesExpanded = false;
        });
    }

    private generateBreadCrumb() {
        this.dynamicBreadCrumb('Users & Groups');
    }

    public navigateToGroupImport() {
        this.router.navigate(['/import', {}], { relativeTo: this.route });
    }
}
