import { Component, OnInit, TemplateRef } from '@angular/core';
import { MessageService } from 'primeng/api';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { Scorecard, ScorecardDisplay } from '../../../../../shared/models/scorecard';
import { ScorecardService } from '../../../../../shared/services/repositories/scorecard.service';
import { ConfirmationDialogService } from '../../../../../shared/components/utils/confirmation/ConfirmationDialog.service';
import { BaseComponent } from '../../../../shared/components/base/base.component';
import { fadeInOut } from 'src/app/shared/animations/fade';
import { _ } from '../../../../../shared/services/translation.service';
import { Router } from '@angular/router';
import { WorkflowService } from '../../../../shared/services/workflow.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-scorecard-views',
    templateUrl: './scorecard-views.component.html',
    styleUrls: ['./scorecard-views.component.scss'],
    animations: [fadeInOut],
})
export class ScorecardViewsComponent extends BaseComponent implements OnInit {
    public scorecards: Scorecard[];

    public editModalRef: BsModalRef;
    public edtScorecard: ScorecardDisplay;

    constructor(
        private scorecardService: ScorecardService,
        private confirmationService: ConfirmationDialogService,
        private messageService: MessageService,
        private modalService: BsModalService,
        public router: Router,
        protected workflowService: WorkflowService,
        private translate: TranslateService
    ) {
        super(router, workflowService);
        this.dtOptions = {
            ordering: false,
            searching: false,
            paging: false,
            info: false,
        };
        this.generateBreadCrumb();
    }

    ngOnInit() {
        this.getScorecardsDisplay();
    }

    getScorecardsDisplay() {
        this.loading = true;
        this.scorecardService.getScorecardsDisplay().subscribe((res) => {
            this.scorecards = res;
            this.loading = false;
        });
    }

    removeFScorecardDisplay(scorecardDisplay: ScorecardDisplay) {
        this.confirmationService
            .confirm(
                this.translate.instant(_('Do you want to delete ')) +
                    scorecardDisplay.name +
                    this.translate.instant(_(' scorecard view?'))
            )
            .subscribe((result) => {
                if (result) {
                    this.scorecardService
                        .deleteScorecardDisplay(scorecardDisplay.scorecardId, scorecardDisplay.id)
                        .subscribe(
                            () => {
                                this.messageService.add({
                                    severity: 'success',
                                    summary: this.translate.instant(_('Succes!')),
                                    detail: this.translate.instant(
                                        _('Scorecard View removed with success!')
                                    ),
                                });
                            },
                            (error) => {
                                return console.log('Error: ', error);
                            },
                            () => {
                                return this.getScorecardsDisplay();
                            }
                        );
                }
            });
    }

    //#region edit
    editScorecardViewModal(scorecardDisplay: ScorecardDisplay, modal: TemplateRef<any>) {
        this.edtScorecard = new ScorecardDisplay();
        this.edtScorecard.id = scorecardDisplay.id;
        this.edtScorecard.name = scorecardDisplay.name;
        this.edtScorecard.scorecardId = scorecardDisplay.scorecardId;
        this.editModalRef = this.modalService.show(modal, this.modalConfig);
    }

    editScorecardView() {
        // null or white spaces
        if (this.edtScorecard.name !== null && this.edtScorecard.name.match(/^ *$/) === null) {
            this.scorecardService
                .updateScorecardDisplay(
                    this.edtScorecard.scorecardId,
                    this.edtScorecard.id,
                    this.edtScorecard.name
                )
                .subscribe(
                    () => {
                        this.messageService.add({
                            severity: 'success',
                            summary: this.translate.instant(_('Succes!')),
                            detail: this.translate.instant(
                                _('Scorecard display edited with success!')
                            ),
                        });
                    },
                    (error) => {
                        return console.log('Error: ', error);
                    },
                    () => {
                        return this.getScorecardsDisplay();
                    }
                );
            this.editModalRef.hide();
        }
    }

    private generateBreadCrumb() {
        this.dynamicBreadCrumb('Scorecard');
    }
}
