import { Injectable } from '@angular/core';
import { HttpParams, HttpResponse } from '@angular/common/http';

// Models
import { ChildrenDisplay, IDeletedNode, TreeNode, TreeNodeParent } from '../../models/treenode';
import { TreeNodeSearch, PagingResult } from '../../../admin/shared/models/search';
import { Maturity } from '../../models/maturity';
import { Checklist, TreeNodeDeliverable } from '../../models/checklist';
import {
    ChangeRequest,
    ChangeRequestBody,
    ChangeRequestQuery,
    ChangeRequestValidation,
    CreateTreeNode,
    FrozenPeriod,
    GpspTransferRequest,
    GroupMail,
    PropertySetDisplay,
    ScorecardDisplay,
    Section,
    TopLevelParent,
    TreeNodeDate,
    TreeNodeGroupMail,
    TreenodeNotification,
    WorkspaceHistoryApiResponse,
    WorkspaceHistoryQuery,
} from 'src/app/mytrend/workspace/models';
import { Flag, Property } from '../../models/property';
import { TreeNodeDisplay } from '../../models/childDisplay';
import { FinancialSummary } from '../../models/financialSummary';
import { Currency } from '../../models/currency';
import { Top } from '../../models/top';

// Rxjs
import { Observable, of } from 'rxjs';

// Services
import { HttpExtendedGlobalService, HttpExtendedInstanceService } from 'src/app/shared/services';
import { Kpi } from '../../models/Kpi';

@Injectable({
    providedIn: 'root',
})
export class TreenodeService {
    /**
     * Creates an instance of property service.
     * @param httpInstance
     * @param httpGlobal
     */
    constructor(
        private readonly httpInstance: HttpExtendedInstanceService,
        private readonly httpGlobal: HttpExtendedGlobalService
    ) { }

    getTreeNode(treeNodeId: string): Observable<TreeNode> {
        return this.httpInstance.get<TreeNode>(`treenodes/${treeNodeId}`);
    }

    /**
     * Deletes change request
     * @param changeRequestId
     * @returns change request
     */
    public deleteChangeRequest(changeRequestId: string): Observable<ChangeRequest> {
        return this.httpInstance.delete<ChangeRequest>(`changerequests/${changeRequestId}`);
    }

    /**
     * Deletes change request validation
     * @param changeRequestId
     * @returns change request validation
     */
    public deleteChangeRequestValidation(
        changeRequestId: string,
        changeRequestValidationId: string
    ): Observable<ChangeRequest> {
        return this.httpInstance.delete<ChangeRequest>(
            `changerequests/${changeRequestId}/validations/${changeRequestValidationId}`
        );
    }

    /**
     * Submits change request
     * @param changeRequestId
     * @returns change request
     */
    public submitChangeRequest(changeRequestId: string): Observable<ChangeRequest> {
        return this.httpInstance.post<ChangeRequest>(
            `changerequests/${changeRequestId}/submit`,
            null
        );
    }

    /**
     * Updates transfer requests
     * @param data
     * @returns transfer requests
     */
    public updateTransferRequests(data: ChangeRequestBody): Observable<ChangeRequest> {
        return this.httpInstance.put<ChangeRequest>(`transferRequests`, data);
    }

    /**
     * Gets tree node change request
     * @param treeNodeId
     * @returns tree node change request
     */
    public getTreeNodeChangeRequest(treeNodeId: string): Observable<ChangeRequest[]> {
        return this.httpInstance.get<ChangeRequest[]>(`treenodes/${treeNodeId}/changerequests`);
    }

    /**
     * Gets tree node change request validations
     * @param treeNodeId
     * @param changeRequestId
     * @returns tree node change request validations
     */
    public getTreeNodeChangeRequestValidations(
        treeNodeId: string,
        changeRequestId: string
    ): Observable<ChangeRequestValidation> {
        return this.httpInstance.get<ChangeRequestValidation>(
            `treenodes/${treeNodeId}/changerequests/${changeRequestId}`
        );
    }

    /**
     * Gets tree node validation properties
     * @param treeNodeId
     * @returns tree node validation properties
     */
    public getTreeNodeValidationProperties(treeNodeId: string): Observable<Property[]> {
        return this.httpInstance.get<Property[]>(`treenodes/${treeNodeId}/validationproperties`);
    }

    /**
     * Gets tree node user validation properties
     * @param treeNodeId
     * @returns tree node user validation properties
     */
    public getTreeNodeUserValidationProperties(treeNodeId: string): Observable<Property[]> {
        return this.httpInstance.get<Property[]>(
            `treenodes/${treeNodeId}/uservalidationproperties`
        );
    }

    /**
     * Gets tree node transfer request
     * @param treeNodeId
     * @returns tree node transfer request
     */
    public getTreeNodeTransferRequest(treeNodeId: string): Observable<GpspTransferRequest[]> {
        return this.httpInstance.get<GpspTransferRequest[]>(
            `treenodes/${treeNodeId}/transferrequests`
        );
    }

    /**
     * Gets tree node kpi
     * @param treeNodeId
     * @returns tree node kpi
     */
    public getTreeNodeKPI(treeNodeId: string): Observable<Kpi[]> {
        return this.httpInstance.get<Kpi[]>(`treenodes/${treeNodeId}/kpis`);
    }

    /**
     * Generates code tree node kpi
     * @returns code tree node kpi
     */
    public generateCodeTreeNodeKPI(): Observable<string> {
        return this.httpInstance.get<string>(`treenodes/kpis/generatecode`);
    }

    /**
     * Puts tree node kpi
     * @param treeNodeId
     * @param kpiId
     * @param data
     * @returns tree node kpi
     */
    public putTreeNodeKPI(treeNodeId: string, kpiId: string, data: Kpi): Observable<Kpi> {
        return this.httpInstance.put<Kpi>(`treenodes/${treeNodeId}/kpis/${kpiId}`, data);
    }

    /**
     * Posts tree node kpi
     * @param treeNodeId
     * @param data
     * @returns tree node kpi
     */
    public postTreeNodeKPI(treeNodeId: string, data: Kpi): Observable<any> {
        return this.httpInstance.post<any>(`treenodes/${treeNodeId}/kpis`, data);
    }

    /**
     * Deletes tree node kpi
     * @param treeNodeId
     * @param kpiId
     * @returns tree node kpi
     */
    public deleteTreeNodeKPI(treeNodeId: string, kpiId: string): Observable<any> {
        return this.httpInstance.delete<any>(`treenodes/${treeNodeId}/kpis/${kpiId}`);
    }

    /**
     * Uploads treenode change request
     * @param files
     * @param treeNodeId
     * @returns treenode change request
     */
    public uploadTreenodeChangeRequest(files: FormData, treeNodeId: string): Observable<any> {
        const options = this.httpInstance.blobOptions();
        options.headers = options.headers.delete('Content-Type');
        return this.httpInstance.post(`treenodes/${treeNodeId}/changerequests`, files, options);
    }

    /**
     * Uploads change request
     * @param files
     * @param id
     * @returns change request
     */
    public uploadChangeRequest(files: FormData, id: string): Observable<any> {
        const options = this.httpInstance.blobOptions();
        options.headers = options.headers.delete('Content-Type');
        return this.httpInstance.post(`changerequests/${id}`, files, options);
    }

    /**
     * Validates change request
     * @param changeRequestId
     * @param data
     * @returns change request
     */
    public validateChangeRequest(changeRequestId: string, data: any): Observable<any> {
        return this.httpInstance.post<any>(`changerequests/${changeRequestId}/validations`, data);
    }

    /**
     * Uploads transfere or change request
     * @param changeRequestId
     * @param queryParams
     * @returns transfere or change request
     */
    public uploadTransfereOrChangeRequest(
        changeRequestId: string,
        queryParams: ChangeRequestQuery,
        files: FormData
    ): Observable<ChangeRequestBody> {
        const options = this.httpInstance.blobOptions();

        options.headers = options.headers.delete('Content-Type');

        return this.httpInstance.post<ChangeRequestBody>(
            `treenodes/${changeRequestId}/changerequestTransfers`,
            files,
            options,
            { transfer: JSON.stringify(queryParams.transfer) }
        );
    }

    /**
     * Downloads treenode transfere or change request
     * @param treeNodeId
     * @param [type]
     * @returns treenode transfere or change request
     */
    public downloadTreenodeTransfereOrChangeRequest(
        treeNodeId: string,
        type: string
    ): Observable<any> {
        return this.httpInstance.get<HttpResponse<Blob>>(
            `treenodes/${treeNodeId}/${type}/excel`,
            this.httpInstance.blobOptions()
        );
    }

    /**
     * Downloads change request
     * @param treeNodeId
     * @returns change request
     */
    public downloadChangeRequest(treeNodeId: string): Observable<any> {
        return this.httpInstance.get<HttpResponse<Blob>>(
            `changerequests/${treeNodeId}/excel`,
            this.httpInstance.blobOptions()
        );
    }

    getTreeNodeTopLevel(treeNodeId: string): Observable<Top> {
        return this.httpInstance.get<Top>(`treenodes/${treeNodeId}/toplevel`);
    }

    /**
     * Gets tree node
     * @param treeNodeId
     * @returns tree node
     */
    public getTreeNodeTags(treeNodeId: string): Observable<Flag[]> {
        return this.httpInstance.get<Flag[]>(`treenodes/${treeNodeId}/tags`);
    }

    /**
     * Gets histories
     * @param treeNodeId
     * @param [queryParams]
     * @returns histories
     */
    public getHistories(
        treeNodeId: string,
        queryParams?: WorkspaceHistoryQuery
    ): Observable<WorkspaceHistoryApiResponse> {
        return this.httpInstance.get<WorkspaceHistoryApiResponse>(
            `treenodes/${treeNodeId}/history`,
            this.httpInstance.baseOptions(),
            queryParams
        );
    }

    /**
     * Gets tree node notifications
     * @param treeNodeId
     * @returns tree node notifications
     */
    public getTreeNodeNotifications(treeNodeId: string): Observable<TreenodeNotification[]> {
        return this.httpInstance.get<TreenodeNotification[]>(
            `treenodes/${treeNodeId}/notifications`
        );
    }

    /**
     * Gets tree node frozen periods
     * @param treeNodeId
     * @returns tree node frozen periods
     */
    public getTreeNodeFrozenPeriods(treeNodeId: string): Observable<FrozenPeriod[]> {
        return this.httpInstance.get<FrozenPeriod[]>(`treenodes/${treeNodeId}/frozenperiods`);
    }

    /**
     * Gets tree node inherit frozen periods
     * @param treeNodeId
     * @returns tree node inherit frozen periods
     */
    public getTreeNodeInheritFrozenPeriods(treeNodeId: string): Observable<any[]> {
        return this.httpInstance.get<FrozenPeriod[]>(
            `treenodes/${treeNodeId}/frozenperiods/inherit`
        );
    }

    /**
     * Puts tree node frozen periods
     * @param treeNodeId
     * @param data
     * @returns tree node frozen periods
     */
    public putTreeNodeFrozenPeriods(treeNodeId: string, data: FrozenPeriod): Observable<any> {
        return this.httpInstance.put<TreenodeNotification[]>(
            `treenodes/${treeNodeId}/frozenperiods/${data.id}`,
            data
        );
    }

    /**
     * Posts tree node frozen periods
     * @param treeNodeId
     * @param data
     * @returns tree node frozen periods
     */
    public postTreeNodeFrozenPeriods(
        treeNodeId: string,
        data: FrozenPeriod
    ): Observable<FrozenPeriod> {
        return this.httpInstance.post<FrozenPeriod>(`treenodes/${treeNodeId}/frozenperiods`, data);
    }

    /**
     * Posts modify move children tree node
     * @param treeNodeId
     * @param data
     * @returns modify move children tree node
     */
    public postModifyMoveChildrenTreeNode(treeNodeId: string, data: any): Observable<any> {
        return this.httpInstance.post<any>(`treenodes/move/${treeNodeId}`, data);
    }

    /**
     * Posts modify create tree node
     * @param data
     * @returns modify create tree node
     */
    public postModifyCreateTreeNode(data: CreateTreeNode): Observable<TreeNodeDisplay> {
        return this.httpInstance.post<TreeNodeDisplay>(`treenodes`, data);
    }

    /**
     * Posts modify move tree node
     * @param treeNodeId
     * @param data
     * @returns modify move tree node
     */
    public postModifyMoveTreeNode(treeNodeId: string, data: any): Observable<any> {
        return this.httpInstance.post<any>(`treenodes/${treeNodeId}/move`, data);
    }

    /**
     * Deletes tree node frozen periods
     * @param treeNodeId
     * @param frozenPeriodId
     * @returns tree node frozen periods
     */
    public deleteTreeNodeFrozenPeriods(
        treeNodeId: string,
        frozenPeriodId: string
    ): Observable<Flag> {
        return this.httpInstance.delete<Flag>(
            `treenodes/${treeNodeId}/frozenperiods/${frozenPeriodId}`
        );
    }

    /**
     * Deletes tree node
     * @param treeNodeId
     * @returns tree node
     */
    public deleteTreeNode(treeNodeId: string): Observable<TreeNode> {
        return this.httpInstance.delete<TreeNode>(`treenodes/${treeNodeId} `);
    }

    /**
     * Posts tree node notifications
     * @param treeNodeId
     * @returns tree node notifications
     */
    public postTreeNodeNotifications(
        treeNodeId: string,
        data: number[]
    ): Observable<TreenodeNotification[]> {
        return this.httpInstance.post<TreenodeNotification[]>(
            `treenodes/${treeNodeId}/notifications/todisable`,
            data
        );
    }

    /**
     * Gets tree node dates
     * @param treeNodeId
     * @returns tree node dates
     */
    public getTreeNodeDates(treeNodeId: string): Observable<TreeNodeDate[]> {
        return this.httpInstance.get<TreeNodeDate[]>(`treenodes/${treeNodeId}/dates`);
    }

    /**
     * Gets tree node sections
     * @param treeNodeId
     * @returns tree node sections
     */
    public getTreeNodeSections(treeNodeId: string): Observable<Section[]> {
        return this.httpInstance.get<Section[]>(`treenodes/${treeNodeId}/sections`);
    }

    /**
     * Gets tree nodechildren
     * @param treeNodeId
     * @returns tree nodechildren
     */
    public getTreeNodechildren(treeNodeId: string): Observable<TreeNodeDisplay[]> {
        return this.httpInstance.get<TreeNodeDisplay[]>(`treenodes/${treeNodeId}/children`);
    }

    /**
     * Gets mail groups
     * @returns mail groups
     */
    public getMailGroups(): Observable<GroupMail[]> {
        return this.httpInstance.get<GroupMail[]>(`mails/groups`);
    }

    /**
     * Gets treenode mail groups
     * @returns treenode mail groups
     */
    public getTreenodeMailGroups(treeNodeId: string): Observable<TreeNodeGroupMail[]> {
        return this.httpInstance.get<TreeNodeGroupMail[]>(`treenodes/${treeNodeId}/mails/groups`);
    }

    /**
     * Puts treenode mail groups
     * @param treeNodeId
     * @param data
     * @returns treenode mail groups
     */
    public putTreenodeMailGroups(treeNodeId: string, data: string[]): Observable<string[]> {
        return this.httpInstance.put<string[]>(`treenodes/${treeNodeId}/mails/groups`, data);
    }

    /**
     * Gets treenode property set displays
     * @param treeNodeId
     * @returns treenode property set displays
     */
    public getTreenodePropertySetDisplays(treeNodeId: string): Observable<PropertySetDisplay[]> {
        return this.httpInstance.get<PropertySetDisplay[]>(
            `treenodes/${treeNodeId}/propertysetdisplays`
        );
    }

    /**
     * Gets current treenode property set displays
     * @param treeNodeId
     * @returns current treenode property set displays
     */
    public getCurrentTreenodePropertySetDisplays(
        treeNodeId: string
    ): Observable<PropertySetDisplay> {
        return this.httpInstance.get<PropertySetDisplay>(
            `treenodes/${treeNodeId}/propertysetdisplays/current`
        );
    }

    /**
     * Puts treenode property set displays
     * @param treeNodeId
     * @param data
     * @returns treenode property set displays
     */
    public putTreenodePropertySetDisplays(
        treeNodeId: string,
        data: PropertySetDisplay
    ): Observable<PropertySetDisplay> {
        return this.httpInstance.put<PropertySetDisplay>(
            `treenodes/${treeNodeId}/propertysetdisplays/${data.id}`,
            data
        );
    }

    /**
     * Puts treenode scorecard displays
     * @param treeNodeId
     * @param data
     * @returns treenode scorecard displays
     */
    public putTreenodeScorecardDisplays(
        treeNodeId: string,
        data: ScorecardDisplay
    ): Observable<ScorecardDisplay> {
        return this.httpInstance.put<ScorecardDisplay>(
            `treenodes/${treeNodeId}/scorecarddisplays/${data.id}`,
            data
        );
    }

    /**
     * Posts tree node tag
     * @param treeNodeId
     * @param tagId
     * @param data
     * @returns tree node tag
     */
    public postTreeNodeTag(treeNodeId: string, tagId: string, data: Flag): Observable<Flag> {
        return this.httpInstance.post<Flag>(`treenodes/${treeNodeId}/tags/${tagId}`, data);
    }

    /**
     * Deletes tree node tag
     * @param treeNodeId
     * @param tagId
     * @returns tree node tag
     */
    public deleteTreeNodeTag(treeNodeId: string, tagId: string): Observable<Flag> {
        return this.httpInstance.delete<Flag>(`treenodes/${treeNodeId}/tags/${tagId}`);
    }

    getAffectedTreeNodes(instance: string): Observable<TreeNode[]> {
        return this.httpGlobal.getForInstance<TreeNode[]>(instance, 'treenodes/me');
    }

    getAllTreeNodes(search: TreeNodeSearch): Observable<PagingResult<TreeNode>> {
        return this.httpInstance.get<PagingResult<TreeNode>>(
            'treenodes',
            this.httpInstance.baseOptions(),
            search
        );
    }

    getAllActiveTreeNodes(): Observable<TreeNode[]> {
        return this.httpInstance.get<TreeNode[]>('alltreenodes');
    }

    public getTreeNodeParents(treeNodeId: string): Observable<TreeNodeParent[]> {
        return this.httpInstance.get<TreeNodeParent[]>(`treenodes/${treeNodeId}/parents`);
    }

    /**
     * Gets tree node top level id
     * @param treeNodeId
     * @returns tree node top level id
     */
    public getTreeNodeTopLevelId(treeNodeId: string): Observable<TopLevelParent> {
        return this.httpInstance.get<TreeNodeParent>(`treenodes/${treeNodeId}/toplevelid`);
    }

    /**
     * Determines whether display change request can
     * @returns display change request
     */
    public canDisplayChangeRequest(): Observable<boolean> {
        return this.httpInstance.get<boolean>(`changerequests/canvalidate`);
    }

    getMaturities(treeNodeId: string): Observable<Maturity[]> {
        return this.httpInstance.get<Maturity[]>(`treenodes/${treeNodeId}/maturities`);
    }

    GetTreeNodesPropertyDisplays(
        instance: string,
        treeNodeIds: string[]
    ): Observable<Record<string, ChildrenDisplay[]>> {
        return this.httpGlobal.putForInstance<Record<string, ChildrenDisplay[]>>(
            instance,
            `treenodes/displayproperties`,
            treeNodeIds
        );
    }

    getChecklist(treeNodeId: string): Observable<Checklist> {
        return this.httpInstance.get<Checklist>(`treenodes/${treeNodeId}/checklist`);
    }

    getRoles(treeNodeId: string): Observable<string[]> {
        return this.httpInstance.get<string[]>(`treenodes/${treeNodeId}/roles`);
    }

    updateTreeNodeDeliverable(
        treeNodeId: string,
        deliverableId: string
    ): Observable<TreeNodeDeliverable> {
        return this.httpInstance.put(
            `treenodes/${treeNodeId}/deliverables/${deliverableId}/update`,
            {}
        );
    }

    //#region Deleted nodes

    getDeletedNode(id: string): Observable<IDeletedNode> {
        return this.httpInstance.get<IDeletedNode>(`treenodes/deletednodes/${id}`);
    }

    getDeletedNodes(): Observable<IDeletedNode[]> {
        return this.httpInstance.get<IDeletedNode[]>('treenodes/deletednodes');
    }

    getTotalDraftNodes(): Observable<number> {
        return this.httpInstance.get<number>('treenodes/totaldraftnodes');
    }

    undeleteNode(treenodeId: string) {
        return this.httpInstance.post(`treenodes/${treenodeId}/undelete`, null);
    }

    deleteNodeDefinitively(treenodeId: string) {
        return this.httpInstance.delete(`treenodes/${treenodeId}/deletedefinitively`);
    }

    deleteDraftNodes() {
        return this.httpInstance.delete('drafttreenodes');
    }
    //#endregion

    //#region EffectValues
    getTreenodeFirstDate(treenodeId: string): Observable<Date> {
        return this.httpInstance.get<Date>(`treenodes/${treenodeId}/effectvalues/firstdate`);
    }

    getTreenodeLastDate(treenodeId: string): Observable<Date> {
        return this.httpInstance.get<Date>(`treenodes/${treenodeId}/effectvalues/lastdate`);
    }

    //#endregio

    //#region Financial Summary
    getFinancialSummary(treenodeId: string): Observable<FinancialSummary> {
        return this.httpInstance.get(`treenodes/${treenodeId}/financialsummary`);
    }
    //#endregion

    //#region Currencies
    public getCurrencies(treenodeId: string): Observable<Currency[]> {
        return this.httpInstance.get(`treenodes/${treenodeId}/currencies`);
    }
    public updateCurrency(treenodeId: string, currencyIso: string): Observable<Currency> {
        return this.httpInstance.put<Currency>(
            `treenodes/${treenodeId}/currencies/${currencyIso}`,
            null
        );
    }

    //#endregion

    //#region updates (update period)

    public updateProgress(treenodeId: string) {
        return this.httpInstance.put(`treenodes/${treenodeId}/updated`, null);
    }

    public updateFinancials(treenodeId: string) {
        return this.httpInstance.put(`treenodes/${treenodeId}/scorecard/updated`, null);
    }

    //#endregion
}
