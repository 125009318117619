import { Injectable } from '@angular/core';

// Models
import {
    Top,
    TopCreateModel,
    EditTopLevelDates,
    TopEditModel,
    TopEditScorecardModel,
} from '../../models/top';
import { TopLevelStructure, EditTopLevelStructureModel } from '../../models/topLevelStructure';
import { TopLevelCurrency, EditTopLevelCurrencyModel } from '../../models/exchangerate';
import { EditTopLevelStructureChildDisplay, ChildDisplayModel } from '../../models/childDisplay';
import { Calculation } from '../../models/effect';
import { HomeTop } from '../../models/homeTop';

// Rxjs
import { Observable } from 'rxjs';

// Services
import { HttpExtendedGlobalService, HttpExtendedInstanceService } from 'src/app/shared/services';
import { Property } from '../../models/property';
import { TreeNode } from '../../models/treenode';

@Injectable({
    providedIn: 'root',
})
export class TopService {
    /**
     * Creates an instance of property service.
     * @param httpInstance
     * @param httpGlobal
     */
    constructor(
        private readonly httpInstance: HttpExtendedInstanceService,
        private readonly httpGlobal: HttpExtendedGlobalService
    ) {}

    // get all affected tops of all instances
    getAffectedTops(): Observable<HomeTop[]> {
        return this.httpGlobal.get<HomeTop[]>('affectedtops');
    }

    public getTops(): Observable<Top[]> {
        return this.httpInstance.get<Top[]>('toplevels');
    }

    public getTopsForInstance(instance: string): Observable<Top[]> {
        return this.httpGlobal.getForInstance<Top[]>(instance, 'toplevels');
    }

    public getLevelsForTop(topLevelId: string): Observable<TopLevelStructure[]> {
        return this.httpInstance.get<TopLevelStructure[]>(
            `toplevels/${topLevelId}/gpspTopLevelStructures`
        );
    }

    /**
     * Gets top level structure
     * @param topLevelStructureId
     * @returns top level structure
     */
    public getTopLevelStructure(topLevelStructureId: string): Observable<TopLevelStructure> {
        return this.httpInstance.get<TopLevelStructure>(
            `gpspTopLevelStructures/${topLevelStructureId}`
        );
    }

    /**
     * Gets top level scorecard displays
     * @param topLevelId
     * @returns top level scorecard displays
     */
    public getTopLevelScorecardDisplays(topLevelId: string): Observable<TopLevelStructure> {
        return this.httpInstance.get<TopLevelStructure>(`toplevel/${topLevelId}/scorecarddisplays`);
    }

    /**
     * Gets top levels structures
     * @param topLevelId
     * @returns top levels structures
     */
    public getTopLevelsStructures(topLevelId: string): Observable<TopLevelStructure[]> {
        return this.httpInstance.get<TopLevelStructure[]>(`toplevels/${topLevelId}/structures`);
    }

    public addTop(model: TopCreateModel): Observable<Top> {
        return this.httpInstance.post<Top>('toplevels', model);
    }

    public editTop(topId: string, model: TopEditModel): Observable<Top> {
        return this.httpInstance.put<Top>(`toplevels/${topId}`, model);
    }

    public removeTop(top: Top): Observable<Top> {
        return this.httpInstance.delete<Top>(`toplevels/${top.id}`);
    }

    public removeLevel(level: TopLevelStructure): Observable<TopLevelStructure> {
        return this.httpInstance.delete<TopLevelStructure>(`toplevels/structure/${level.id}`);
    }

    public addLevel(level: EditTopLevelStructureModel): Observable<TopLevelStructure> {
        return this.httpInstance.post<TopLevelStructure>('gpspTopLevelStructures', level);
    }

    public updateLevel(level: EditTopLevelStructureModel): Observable<TopLevelStructure> {
        return this.httpInstance.put<TopLevelStructure>(`gpspTopLevelStructures`, level);
    }

    public updateTopLevelScorecard(topLevelEdit: TopEditScorecardModel): Observable<Top> {
        return this.httpInstance.put<Top>('topLevels/scorecard', topLevelEdit);
    }

    // Top Level Dates
    public updateTopLevelDates(editTopLevelDates: EditTopLevelDates) {
        return this.httpInstance.put('toplevels/topLevelDates', editTopLevelDates);
    }

    // Currencies
    public getTopLevelCurrencies(topLevelId: string): Observable<TopLevelCurrency[]> {
        return this.httpInstance.get<TopLevelCurrency[]>(`toplevels/${topLevelId}/currencies`);
    }

    public getAllCurrencies(): Observable<{ [index: string]: string }> {
        return this.httpInstance.get<{ [index: string]: string }>('currencies');
    }

    public saveCurrencies(editTopLevelCurrencyModel: EditTopLevelCurrencyModel): Observable<any> {
        return this.httpInstance.put('topLevels/currencies', editTopLevelCurrencyModel);
    }

    //#region ChidlrenDisplay

    public getStructureLevelDisplays(structureId: string): Observable<ChildDisplayModel[]> {
        return this.httpInstance.get<ChildDisplayModel[]>(
            `toplevels/structures/${structureId}/levelDisplays`
        );
    }

    public editTopLevelStructureChildDisplay(
        editStructureModel: EditTopLevelStructureChildDisplay
    ): Observable<any> {
        return this.httpInstance.put('topLevel/structureChildDisplay', editStructureModel);
    }

    public deleteLevelDisplay(childDisplayId: string): Observable<any> {
        return this.httpInstance.delete(`topLevels/structureChildDisplay/${childDisplayId}`);
    }

    public reorderChildrendDisplay(childrenDisplay: ChildDisplayModel[]): Observable<any> {
        return this.httpInstance.put('topLevels/structureChildDisplay/ordersort', childrenDisplay);
    }

    //#endregion

    //#region Calculations

    public getStructureCalculations(structureId: string): Observable<Calculation[]> {
        return this.httpInstance.get<Calculation[]>(
            `toplevels/structures/${structureId}/calculations`
        );
    }

    //#endregion

    /**
     * Gets mandatories properties
     * @param structureId
     * @returns mandatories properties
     */
    public getMandatoriesProperties(structureId: string): Observable<Property[]> {
        return this.httpInstance.get<Property[]>(
            `structures/${structureId}/properties/mandatories`
        );
    }

    /**
     * Gets structure treenode simple
     * @param structureId
     * @returns structure treenode simple
     */
    public getStructureTreenodeSimple(structureId: string): Observable<TreeNode[]> {
        return this.httpInstance.get<TreeNode[]>(`structures/${structureId}/treenodes`);
    }
}
