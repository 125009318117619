import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';

// Models
import { Notification } from 'src/app/shared/models';
import { Top } from 'src/app/shared/models/top';
import {
    ChangeRequestPendingValidation,
    ChangeRequestValidation,
    PendingValidation,
    PendingValidationsModel,
    TreenodePropertyPendingValidation,
    WorkspaceValidationPropertiesQuery,
} from '../../../models';

// Rxjs
import { Observable } from 'rxjs';

// Services
import { HttpExtendedInstanceService } from 'src/app/shared/services';

@Injectable({
    providedIn: 'root',
})
export class ValidationService {
    /**
     * Creates an instance of profile document service.
     * @param httpInstance
     */
    constructor(private readonly httpInstance: HttpExtendedInstanceService) {}

    /**
     * Gets validations
     * @returns validations
     */
    public getValidations(): Observable<boolean> {
        return this.httpInstance.get<boolean>('validations/hasvalidationright');
    }

    /**
     * Gets update manager validations
     * @returns update manager validations
     */
    public getUpdateManagerValidations(): Observable<PendingValidation[]> {
        return this.httpInstance.get<PendingValidation[]>(`validations`);
    }

    /**
     * Gets change request validations
     * @param changeRequestId
     * @returns change request validations
     */
    public getChangeRequestValidations(
        changeRequestId: string
    ): Observable<ChangeRequestValidation[]> {
        return this.httpInstance.get<ChangeRequestValidation[]>(
            `changerequests/${changeRequestId}/validations`
        );
    }

    /**
     * Gets available tops
     * @returns available tops
     */
    public getAvailableTops(): Observable<Top[]> {
        return this.httpInstance.get<Top[]>('validations/availabletops');
    }

    /**
     * Gets pending validations for levels
     * @param topLevelId
     * @returns pending validations for levels
     */
    public getPendingValidationsForLevels(topLevelId: string): Observable<Notification[]> {
        return this.httpInstance.get<Notification[]>(`toplevel/${topLevelId}/validations/numbers`);
    }

    /**
     * Gets treenode property pending validation
     * @param topLevelId
     * @param queryParams
     * @returns treenode property pending validation
     */
    public getTreenodePropertyPendingValidation(
        topLevelId: string,
        queryParams: WorkspaceValidationPropertiesQuery
    ): Observable<TreenodePropertyPendingValidation[]> {
        return this.httpInstance.get<TreenodePropertyPendingValidation[]>(
            `validations/${topLevelId}/properties`,
            this.httpInstance.baseOptions(),
            queryParams
        );
    }

    /**
     * Gets treenode pending create validation
     * @param topLevelId
     * @param queryParams
     * @returns treenode pending create validation
     */
    public getTreenodePendingCreateValidation(
        topLevelId: string,
        queryParams: WorkspaceValidationPropertiesQuery
    ): Observable<PendingValidation[]> {
        return this.httpInstance.get<PendingValidation[]>(
            `topLevel/${topLevelId}/create`,
            this.httpInstance.baseOptions(),
            queryParams
        );
    }

    /**
     * Gets treenode pending delete validation
     * @param topLevelId
     * @param queryParams
     * @returns treenode pending delete validation
     */
    public getTreenodePendingDeleteValidation(
        topLevelId: string,
        queryParams: WorkspaceValidationPropertiesQuery
    ): Observable<PendingValidation[]> {
        return this.httpInstance.get<PendingValidation[]>(
            `topLevel/${topLevelId}/delete`,
            this.httpInstance.baseOptions(),
            queryParams
        );
    }

    /**
     * Gets treenode change request validation
     * @param topLevelId
     * @param queryParams
     * @returns treenode change request validation
     */
    public getTreenodeChangeRequestValidation(
        topLevelId: string,
        queryParams: WorkspaceValidationPropertiesQuery
    ): Observable<{ [id: string]: ChangeRequestPendingValidation[] }> {
        return this.httpInstance.get<{ [id: string]: ChangeRequestPendingValidation[] }>(
            `validations/${topLevelId}/changerequests`,
            this.httpInstance.baseOptions(),
            queryParams
        );
    }

    /**
     * Posts validation
     * @param data
     * @returns validation
     */
    public postValidation(data: PendingValidationsModel[]): Observable<PendingValidationsModel[]> {
        return this.httpInstance.post<PendingValidationsModel[]>(`validations`, data);
    }

    /**
     * Gets document by id
     * @param changerequestId
     * @returns document by id
     */
    public getDocumentById(changerequestId: string): Observable<any> {
        const url = `changerequests/${changerequestId}/excel`;
        return this.httpInstance.get<HttpResponse<Blob>>(url, this.httpInstance.blobOptions());
    }
}
