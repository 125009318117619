import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringjoin'
})
export class StringjoinPipe implements PipeTransform {

  transform(content: string[]): string {
    if (content) {
      return content.join(', ');
    }
    return '';
  }

}
