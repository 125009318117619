import { Injectable } from '@angular/core';

// Models
import { Config } from '../../../admin/shared/models/config';
import { Unit } from '../../models/property';

// Rxjs
import { Observable } from 'rxjs';

// Services
import { HttpExtendedInstanceService } from 'src/app/shared/services';
import { ShowKPIComment, ShowKPIThresholds } from '../../models';

@Injectable({
    providedIn: 'root',
})
export class ConfigService {
    /**
     * Creates an instance of config service.
     * @param httpInstance
     */
    constructor(private httpInstance: HttpExtendedInstanceService) {}

    getConfigs(): Observable<Config[]> {
        return this.httpInstance.get<Config[]>('configs');
    }

    /**
     * Gets units
     * @returns units
     */
    public getUnits(): Observable<Unit[]> {
        return this.httpInstance.get<Unit[]>('units');
    }

    /**
     * Gets show kpicomment
     * @returns show kpicomment
     */
    public getShowKPIComment(): Observable<ShowKPIComment> {
        return this.httpInstance.get<ShowKPIComment>('configs/ShowKPIComment');
    }

    /**
     * Shows kpithresholds
     * @returns kpithresholds
     */
    public getShowKPIThresholds(): Observable<ShowKPIThresholds> {
        return this.httpInstance.get<ShowKPIThresholds>('configs/ShowKPIThresholds');
    }

    updateConfig(configKey: string, value: string): Observable<Config> {
        return this.httpInstance.put<Config>(`configs/${configKey}`, { value });
    }

    /**
     * Gets configs
     * @returns configs
     */
    public useChangeRequest(): Observable<Config> {
        return this.httpInstance.get<Config>('configs/UseChangeRequest');
    }
}
