export enum Rights {
  UserAndGroup = "80000000-0000-0000-0000-000000000001",
  ScorecardAndEffect = "80000000-0000-0000-0000-000000000002",
  PropertyAndSet = "80000000-0000-0000-0000-000000000003",
  CheckistAndDeliverable = "80000000-0000-0000-0000-000000000004",
  ExchangeRate = "80000000-0000-0000-0000-000000000005",
  Import = "80000000-0000-0000-0000-000000000006",
  Export = "80000000-0000-0000-0000-000000000007",
  Archive = "80000000-0000-0000-0000-000000000008",
  DeletedNode = "80000000-0000-0000-0000-000000000009",
  MaturityAndStrategy = "80000000-0000-0000-0000-000000000010",
  SendMail = "80000000-0000-0000-0000-000000000011",
  GroupMailAndTemplate = "80000000-0000-0000-0000-000000000012",
  Message = "80000000-0000-0000-0000-000000000013",
  Structure = "80000000-0000-0000-0000-000000000014",
  FrozenPeriod = "80000000-0000-0000-0000-000000000015",
  UpdatePeriod = "80000000-0000-0000-0000-000000000016",
  TopLevelMailTemplate = "80000000-0000-0000-0000-000000000017",
  TopLevelScorecardInfo = "80000000-0000-0000-0000-000000000018",
  TopLevelCreation = "80000000-0000-0000-0000-000000000019",
  Config = "80000000-0000-0000-0000-000000000020",
  TopLevelCurrency = "80000000-0000-0000-0000-000000000021",
  SnapshotDates = "80000000-0000-0000-0000-000000000022",
  TreeNodeCurrency = "80000000-0000-0000-0000-000000000023",
  CreateKPI = "80000000-0000-0000-0000-000000000024",
  Template = "80000000-0000-0000-0000-000000000027",
}
