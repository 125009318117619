import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericImportParameter } from '../../models/import';
import { HttpResponse } from '@angular/common/http';
import { HttpExtendedInstanceService } from 'src/app/shared/services';

@Injectable({
    providedIn: 'root',
})
export class ImportService {
    constructor(public http: HttpExtendedInstanceService) {}

    getTemplate(structureId: string, importType: number): Observable<HttpResponse<Blob>> {
        return this.http.get<HttpResponse<Blob>>(
            `gpspImport/${importType}/structure/${structureId}`,
            this.http.blobOptions()
        );
    }

    import(
        importType: string,
        topLevelId: string,
        data: FormData,
        importParameter: GenericImportParameter
    ): Observable<any> {
        data.append('parameter', JSON.stringify(importParameter));
        const options = this.http.blobOptions();
        options.headers = options.headers.delete('Content-Type');
        return this.http.post(
            `topLevel/${topLevelId}/gpspImport/${importType}`,
            data,
            options,
            importParameter
        );
    }
}
