import { Pipe, PipeTransform } from '@angular/core';
import { FinancialMetric } from '../models/enums';

@Pipe({
    name: 'metric'
})
export class MetricPipe implements PipeTransform {

    transform(metric: FinancialMetric): string {
        switch (metric) {
            case FinancialMetric.Kilo:
                return "k ";
            case FinancialMetric.Mega:
                return "M ";
            default:
                return "";
        }
    }

}
