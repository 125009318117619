import { Component, TemplateRef, OnInit } from '@angular/core';
import { forkJoin, Subject } from 'rxjs';
import { MessageService as PrimengMessageService } from 'primeng/api';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { Message as PrimeMessage } from 'primeng/api';
import { MessageService } from '../../../../../shared/services/repositories/message.service';
import { Message } from '../../../../../shared/models/message';
import { Top } from '../../../../../shared/models/top';
import { TopService } from '../../../../../shared/services/repositories/top.service';
import { ConfirmationDialogService } from '../../../../../shared/components/utils/confirmation/ConfirmationDialog.service';
import { BaseComponent } from '../../../../shared/components/base/base.component';
import { fadeInOut } from 'src/app/shared/animations/fade';
import { _ } from '../../../../../shared/services/translation.service';
import { Router } from '@angular/router';
import { WorkflowService } from '../../../../shared/services/workflow.service';
import { TranslateService } from '@ngx-translate/core';
import { MessageType } from 'src/app/shared/models/enums';
import { PrimeTools } from 'src/app/shared/tools/primeTools';

@Component({
    selector: 'app-messages',
    templateUrl: './messages.component.html',
    styleUrls: ['./messages.component.scss'],
    animations: [fadeInOut],
})
export class MessagesComponent extends BaseComponent implements OnInit {
    modalRef: BsModalRef;
    public dtTrigger: Subject<any> = new Subject();

    public isDialogOpen = false;
    public testMessages: PrimeMessage[];

    public MessageType = MessageType;
    public messages: Message[] = [];
    public tops: Top[] = [];
    public selectedMessage: Message;

    constructor(
        private messageService: MessageService,
        private topService: TopService,
        private pMessageService: PrimengMessageService,
        private modalService: BsModalService,
        private confirmationService: ConfirmationDialogService,
        public router: Router,
        protected workflowService: WorkflowService,
        private translate: TranslateService
    ) {
        super(router, workflowService);
        this.dtOptions = {
            ordering: false,
            searching: false,
            lengthChange: false,
            order: [],
        };
        this.generateBreadCrumb();
    }

    ngOnInit() {
        this.getMessages();
    }

    private getMessages() {
        this.loading = true;
        forkJoin(this.messageService.getMessages(), this.topService.getTops()).subscribe((res) => {
            this.messages = res[0];
            this.tops = res[1];

            this.messages.forEach((msg) => {
                msg.topName = this.tops.find((top) => {
                    return top.id === msg.topLevelId;
                }).name;
            });

            this.refreshTable('#table', this.dtTrigger);
        });
    }

    public removeMessage(message: Message) {
        this.confirmationService
            .confirm(this.translate.instant(_('Do you want to delete this message?')))
            .subscribe((result) => {
                if (result) {
                    this.messageService.deleteMessage(message.id).subscribe(
                        () => {
                            this.pMessageService.add({
                                severity: 'success',
                                summary: this.translate.instant(_('Success!')),
                                detail: this.translate.instant(_('Message removed with success!')),
                            });
                        },
                        (error) => {
                            return console.log('Error: ', error);
                        },
                        () => {
                            return this.getMessages();
                        }
                    );
                }
            });
    }

    public testMsg(message: Message) {
        this.testMessages = [
            {
                severity: PrimeTools.convertMessageType(message.type),
                summary: this.MessageType[message.type],
                detail: message.text,
            },
        ];
        this.isDialogOpen = true;
    }

    private generateBreadCrumb() {
        this.dynamicBreadCrumb('Communication');
    }
}
