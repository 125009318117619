import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { MessageService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';

import { Property, AccessRight } from '../../../../../shared/models/property';
import { PropertyService } from '../../../../../shared/services/repositories/property.service';
import { BaseComponent } from '../../../../shared/components/base/base.component';
import { WorkflowService } from '../../../../shared/services/workflow.service';
import { AuthService } from '../../../../../shared/services/auth.service';
import { _ } from '../../../../../shared/services/translation.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-property-access-rights',
  templateUrl: './property-access-rights.component.html',
  styleUrls: ['./property-access-rights.component.scss']
})
export class PropertyAccessRightsComponent extends BaseComponent implements OnInit {

  private propertyId: string;
  public property: Property = new Property();
  public accessRights: AccessRight;

  constructor(private propertyService: PropertyService, private route: ActivatedRoute, private location: Location,
              private messageService: MessageService, public router: Router, public authService: AuthService, protected workflowService: WorkflowService, private translate: TranslateService) {
    super(router, workflowService);
    this.generateBreadCrumb();
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      const id = params.get('id');
      this.propertyId = id;
      this.getProperty(this.propertyId);
      this.getAccessRights(this.propertyId);
    });
  }

  private getProperty(id: string) {
    this.propertyService.getProperty(id).subscribe(res => {
      this.property = res;
    });
  }

  private getAccessRights(propertyId: string) {
    this.propertyService.getPropertyAccessRights(propertyId).subscribe(res => {
      this.accessRights = res;
    });
  }

  public save() {
    this.propertyService.updatePropertyAccessRights(this.propertyId, this.accessRights).subscribe(() => {
      this.messageService.add(
        {
          severity: 'success',
          summary: this.translate.instant(_('Success!')),
          detail: this.translate.instant(_('Access rights updated with success!'))
        }
      );
      this.router.navigate(['/admin/' + this.authService.selectedInstance.name + '/nodes/properties']);
    });
  }
  private generateBreadCrumb() {
    this.dynamicBreadCrumb('Node Configuration', 'Properties',
      [{ name: 'Access rights', link: '', isActive: false, subElements: [], permissions: null }]);
  }
  public goBack() {
    this.location.back();
  }

}
