import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { saveAs } from 'file-saver';
import { BaseComponent } from 'src/app/admin/shared/components/base/base.component';
import { WorkflowService } from 'src/app/admin/shared/services/workflow.service';
import { Top } from 'src/app/shared/models/top';
import { SourceAgreementService } from 'src/app/shared/services/repositories/source-agreement.service';
import { TopService } from 'src/app/shared/services/repositories/top.service';

@Component({
  selector: 'app-source-agreement',
  templateUrl: './source-agreement.component.html',
  styleUrls: ['./source-agreement.component.scss']
})
export class SourceAgreementComponent extends BaseComponent implements OnInit {

  public topLevels: Top[];
  public selectedTopLevel: Top = new Top();

  constructor(
    public router: Router,
    protected workflowService: WorkflowService,
    private topService: TopService,
    private sourceAgreementService: SourceAgreementService,
  ) {
    super(router, workflowService);
    this.dynamicBreadCrumb('Structure Management');
  }

  ngOnInit(): void {
    this.getTopLevels();
  }

  private getTopLevels() {
    this.loading = true;
    this.topService.getTops().subscribe((data) => {
      this.topLevels = data;
      if (this.topLevels.length === 1) {
        this.selectedTopLevel = this.topLevels[0];
      }
      this.loading = false;
    });
  }

  public getSourceAgreement() {
    this.sourceAgreementService
      .getSourceAgreement(this.selectedTopLevel.id)
      .subscribe((data) => {
        const filename = data.headers
          .get('Content-Disposition')
          .split(';')[1]
          .trim()
          .split('=')[1]
          .replace(/"/g, '');
        saveAs(data.body, filename);
      });
  }


}
