import { Injectable } from '@angular/core';
import {
    Router,
    ActivatedRoute,
    ActivatedRouteSnapshot,
    CanActivate,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';
import { AuthService } from '../../../shared/services/auth.service';

@Injectable()
export class AdminAuthGuard implements CanActivate {
    path: ActivatedRouteSnapshot[];

    constructor(
        private authService: AuthService,
        permissionsService: NgxPermissionsService,
        rolesService: NgxRolesService,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        return new Observable((observer) => {
            // NOTE user is already filled on authservice, because before hit this auth.guard the navigations passed in auth.guard
            if (this.authService.actualUser && this.authService.actualUser.isAdministrator) {
                // check if actual user is administrator
                observer.next(this.authService.actualUser.isAdministrator);
            } else {
                this.router.navigate([`error/401`]);
            }
        });
    }
}
