import { Component } from '@angular/core';
import { AppConfig } from '../../services/config.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  public version = AppConfig.settings.app.version;
  public appName = AppConfig.settings.app.name;
  constructor() { }

}
