import { TreeNodeStatus, FinancialMetric } from './enums';

export class Top {
  id: string;
  description: string;
  scorecardId: string;
  startOfProgressUpdatePeriod: Date;
  startOfFinancialUpdatePeriod: Date;
  nextStatusUpdateFirstDate: Date;
  nextScorecardUpdateFirstDate: Date;
  nextStatusUpdateSecondDate: Date;
  nextScorecardUpdateSecondDate: Date;
  nextStatusUpdateThirdDate: Date;
  nextScorecardUpdateThirdDate: Date;
  endOfProgressUpdatePeriod: Date;
  endOfFinancialUpdatePeriod: Date;
  scorecardStart: Date;
  scorecardEnd: Date;
  code: string;
  name: string;
  topLevelStructureId: string;
  status: TreeNodeStatus;
  scorecardCurrency: string;
  numberOfChildren: number;
  creationDate: Date;
  metric: FinancialMetric;
  greyZoneFinancialState: string;

  // helper
  instanceId: string;
}

export class TopCreateModel {
  name: string;
  description: string;
}

export class TopEditModel extends TopCreateModel {
  topLevelId: string;
}

export class TopEditScorecardModel {
  topLevelId: string;
  description: string;
  scorecardId: string;
  scorecardStart: Date;
  scorecardEnd: Date;
  end: string;
  start: string;
  metric: FinancialMetric;
}

export class EditTopLevelDates {
  topLevelId: string;
  name: string;
  startOfProgressUpdatePeriod: string;
  startOfFinancialUpdatePeriod: string;
  nextStatusUpdateFirstDate: string;
  nextScorecardUpdateFirstDate: string;
  nextStatusUpdateSecondDate: string;
  nextScorecardUpdateSecondDate: string;
  nextStatusUpdateThirdDate: string;
  nextScorecardUpdateThirdDate: string;
  endOfProgressUpdatePeriod: string;
  endOfFinancialUpdatePeriod: string;
}
