import { Injectable } from '@angular/core';

// Models
import { ProfileHistoryQuery } from '../../models';

@Injectable({
    providedIn: 'root',
})
export class ProfileHistoryFilterService {
    /**
     * Selected range of profile history filter service
     */
    public selectedRange: Array<Date>;

    /**
     * Determines whether equal is
     */
    public isEqual = (a, b) => {
        return JSON.stringify(a) === JSON.stringify(b);
    };

    /**
     * Gets api query params
     * @returns api query params
     */
    public getApiQueryParams(): ProfileHistoryQuery {
        let startDate = null;
        let endDate = null;

        if (this.selectedRange && this.selectedRange.length) {
            startDate = this.selectedRange[0] ? new Date(this.selectedRange[0]).toDateString() : '';
            endDate = this.selectedRange[1] ? new Date(this.selectedRange[1]).toDateString() : '';
        }

        return { startDate, endDate };
    }
}
