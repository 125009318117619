import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { adminRoutes } from '../../admin/instance-admin/routes/admin.routing';

// Components
import { LayoutComponent } from '../../admin/shared/components/layout/layout.component';
import { ErrorComponent } from '../../shared/components/error/error.component';
import { WorkspaceLayoutComponent } from 'src/app/mytrend/workspace/workspace-layout.component';

// Ngx
import { NgxPermissionsGuard } from 'ngx-permissions';

// Guard
import { AuthGuard } from '../../shared/guards/auth.guard';
import { WorkspaceAuthGuard } from 'src/app/shared/guards/workspaceauth.guard';

// Resolvers
import { WorkspaceLayoutResolver } from 'src/app/mytrend/workspace/resolvers';

export const coreRoutes: Routes = [
    {
        path: '',
        loadChildren: () => {
            return import('../../mytrend/home/home.module').then((m) => {
                return m.HomeModule;
            });
        },
    },
    {
        path: ':instance/treenode/:treenodeId',
        canActivate: [WorkspaceAuthGuard],
        resolve: { response: WorkspaceLayoutResolver },
        component: WorkspaceLayoutComponent,
        loadChildren: () => {
            return import('../../mytrend/workspace/workspace.module').then((m) => {
                return m.WorkspaceModule;
            });
        },
    },
    {
        path: 'profile',
        loadChildren: () => {
            return import('../../mytrend/profile/profile.module').then((m) => {
                return m.ProfileModule;
            });
        },
    },
    {
        path: 'error/:error',
        component: ErrorComponent,
    },
    {
        path: 'admin/global',
        component: LayoutComponent,
        loadChildren: () => {
            return import('../../admin/global-admin/global-admin.module').then((m) => {
                return m.GlobalAdminModule;
            });
        },
    },
    {
        path: 'admin/:instance',
        canActivate: [AuthGuard],
        canActivateChild: [NgxPermissionsGuard],
        component: LayoutComponent,
        children: adminRoutes,
    },
];

@NgModule({
    imports: [RouterModule.forChild(coreRoutes)],
    exports: [RouterModule],
})
export class CoreRoutingModule {}
