export class Maturity {
  id: string;
  name: string;
  displayName: string;
  phase: number;
  checklistId: string;
  adminValidationEnabled: boolean;
  scorecardUpdatesAllowed: boolean;
  propertiesUpdatesAllowed: boolean;  // Allowed or Admin
  childrenCreationAllowedByAdmins: boolean;
  childrenCreationAllowedByUsers: boolean;
  childrenCreationByUsersRequireValidation: boolean;
  deletionByAdminAllowed: boolean;
  deletionByUsersAllowed: boolean;
  deletionByUsersRequireValidation: boolean;
  deleteNodeImmediately: boolean;
  updateInitialPropertyWhileUpdatingCurrentProperty: boolean;
  consideredAsCompleted: boolean;
  hidden: boolean;
  scheduledPropertiesUpdatesAllowed: boolean;
  scheduledScorecardUpdatesAllowed: boolean;
  system: boolean;

  visibleFinancialStates: string[] = [];
  financialStateVisibilities: any;
  numberOfMaturityStrategies: number;

  public hasChecklist(): boolean {
    return this.checklistId != null;
  }
}

// maturity strategy

export class MaturityStrategyView {
  id: string;
  name: string;
  system: string;
  numberOfMaturities: number;
  numberOfTopLevelStructures: number;
}

export class MaturityStrategyMaturityView {
  maturityStrategyId: string;
  isDefault: boolean;
  system: boolean;
  order: number;
  id: string;
  name: boolean;
  displayName: boolean;
  adminValidationEnabled: boolean;
  scorecardUpdatesAllowed: boolean;
  propertiesUpdatesAllowed: boolean;
  childrenCreationAllowedByAdmins: boolean;
  childrenCreationAllowedByUsers: boolean;
  childrenCreationByUsersRequireValidation: boolean;
  deletionByAdminAllowed: boolean;
  deletionByUsersAllowed: boolean;
  deletionByUsersRequireValidation: boolean;
  deleteNodeImmediatly: boolean;
  updateInitialPropertyWhileUpdatingCurrentProperty: boolean;
  consideredAsCompleted: boolean;
  hidden: boolean;
  scheduledPropertiesUpdatesAllowed: boolean;
  scheduledScorecardUpdatesAllowed: boolean;
}

export class AddMaturityToMaturityStrategyModel {
  maturityStrategyName: string;
  maturityStrategyId: string;
  isDefault: boolean;
  order: number;
  maturityId: string;
  maturityName: string;
  maturitiesAdded: MaturityStrategyMaturityView[];
  maturitiesToAdd: Maturity[];
}

export class MaturityStrategyPathModel {
  id: string;
  maturityStrategyId: string;
  fromMaturityId: string;
  toMaturityId: string;
  adminOnly: boolean;
  validationRequired: boolean;
  conditions: string[];
}

export class MaturityStrategyPathView extends MaturityStrategyPathModel {
  toMaturityName: string;
  toMaturityDisplayName: string;
}

export class MaturityChangeCondition {
  id: string;
  name: string;
  description: string;

  // helper
  checked: boolean;
}

export class MaturityStrategyMaturityPathsModel {
  maturityStrategyId: string;
  fromMaturityId: string;
  fromMaturityName: string;
  toMaturityId: string;
  toMaturityName: string;
  validationRequired: boolean;
  adminOnly: boolean;

  maturitiesToAdd: MaturityStrategyMaturityView[];
  maturitiesAdded: MaturityStrategyPathView[];
  listOfConditions: MaturityChangeCondition[];
  conditions: string[];
}


