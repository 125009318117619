import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IJob, JobSearch, JobType } from '../../models/job';
import { PagingResult } from '../../models/pagingResult';
import { BaseImportReport } from '../../../admin/shared/models/BaseImportReport';
import { HttpExtendedInstanceService } from 'src/app/shared/services';

@Injectable({
    providedIn: 'root',
})
export class JobService {
    constructor(private http: HttpExtendedInstanceService) {}

    getJobs(jobSearch: JobSearch): Observable<PagingResult<IJob>> {
        return this.http.get<PagingResult<IJob>>('jobs', this.http.baseOptions(), jobSearch);
    }

    getJob(id: string): Observable<IJob> {
        return this.http.get(`jobs/${id}`);
    }

    getJobTypes(): Observable<JobType[]> {
        return this.http.get<JobType[]>('jobtypes');
    }

    getJobImportReport(jobId: string): Observable<BaseImportReport> {
        return this.http.get<BaseImportReport>(`jobs/${jobId}/ImportReport`);
    }
}
