import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpinnerComponent } from 'src/app/shared/components/spinner/spinner.component';
import { EscapeHtmlPipe } from 'src/app/shared/pipes/safe.pipe';

@NgModule({
    declarations: [SpinnerComponent, EscapeHtmlPipe],
    imports: [CommonModule],
    exports: [SpinnerComponent, EscapeHtmlPipe],
})
export class SharedAdminModule {}
