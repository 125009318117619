import { Component, OnInit, Input, TemplateRef } from '@angular/core';
import { UserGroupProperty, UpdatePropertyValue } from '../../../../../../shared/models/property';
import { TreeNode } from '../../../../../../shared/models/treenode';
import { UserService } from '../../../../../../shared/services/repositories/user.service';
import { Router } from '@angular/router';
import { AuthService } from '../../../../../../shared/services/auth.service';
import { MyTrendConfig } from 'src/app/shared/services/myTrendConfig.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BaseComponent } from '../../../../../shared/components/base/base.component';
import { WorkflowService } from '../../../../../shared/services/workflow.service';
import { PropertyService } from '../../../../../../shared/services/repositories/property.service';
import { _ } from '../../../../../../shared/services/translation.service';
import { MessageService } from 'primeng/api';
import { GroupService } from '../../../../../../shared/services/repositories/group.service';
import { TreenodeService } from '../../../../../../shared/services/repositories/treenode.service';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'src/app/shared/models';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: '[app-user-assignations]',
    templateUrl: './user-assignations.component.html',
    styleUrls: ['./user-assignations.component.scss'],
})
export class UserAssignationsComponent extends BaseComponent implements OnInit {
    @Input() user: User;
    loadingAssignations: boolean;

    assignations: UserGroupProperty[];
    selectedNode: TreeNodeSelect;
    treeNodes: TreeNodeSelect[];
    selectedProperty: UserGroupProperty;
    nodeProperties: UserGroupProperty[];

    removeModalRef: BsModalRef;
    removeAllAssignationsModalRef: BsModalRef;
    removeAssignation: UserGroupProperty;

    treeNodeDropDownConfig = {
        search: true,
        displayKey: 'label',
        height: '300px',
        placeholder: 'Select a TreeNode',
        searchPlaceholder: 'Select a TreeNode',
    };

    propertyDropDownConfig = {
        search: true,
        height: '300px',
        displayKey: 'propertyDisplayName',
        placeholder: 'Select a property',
        searchPlaceholder: 'Select a Property',
    };

    addPropertyMessage: string;
    addPropertyGroupManagement: boolean;
    addPropertyModalRef: BsModalRef;

    constructor(
        private userService: UserService,
        public router: Router,
        protected workflowService: WorkflowService,
        protected authService: AuthService,
        private modal: BsModalService,
        private propertyService: PropertyService,
        private messageService: MessageService,
        private groupService: GroupService,
        private treeNodeService: TreenodeService,
        private translate: TranslateService,
    ) {
        super(router, workflowService);
    }

    ngOnInit() {
        this.getTreeNodes();
        this.getAssignations();
    }

    getTreeNodes() {
        this.treeNodeService.getAllActiveTreeNodes().subscribe((result) => {
            this.treeNodes = result
                .sort((a, b) => {
                    return a.level - b.level;
                })
                .map((x) => {
                    const t = new TreeNodeSelect();
                    Object.assign(t, x);
                    t.label = `${x.structureName} > ${x.name} (${x.code})`;
                    return t;
                });
        });
    }

    onSelectNode() {
        this.selectedProperty = null;
        this.nodeProperties = null;
        if (!this.selectedNode) {
            return;
        }
        this.propertyService.getUserProperties(this.selectedNode.id).subscribe((data) => {
            this.nodeProperties = data
                .filter((x) => {
                    return (
                        this.assignations.find((y) => {
                            return y.propertyId === x.propertyId && y.treeNodeId === x.treeNodeId;
                        }) == null
                    );
                })
                .map((x) => {
                    const t = new UserGroupProperty();
                    Object.assign(t, x);
                    t.propertyDisplayName = `${x.propertyDisplayName}  [${x.propertyType} property]`;
                    return t;
                });
        });
    }

    getAssignations() {
        this.loadingAssignations = true;
        this.userService.getUserProperties(this.user.id).subscribe(
            (properties) => {
                this.assignations = properties;
            },
            (error) => {
                return console.log('Error: ', error);
            },
            () => {
                return (this.loadingAssignations = false);
            }
        );
    }

    goToNode(treeNodeId: string) {
        window.open(
            `${MyTrendConfig.myTrendSettings.myTrendUrl}/${this.authService.selectedInstance.name}/Instance#/treenode/${treeNodeId}/details`,
            '_blank'
        );
    }

    goToRecycleBin() {
        const baseUrl = window.location.href.replace(this.router.url, '');
        const url = this.router.serializeUrl(
            this.router.createUrlTree([
                `/${this.authService.selectedInstance.name}/nodes/deletenodes`,
            ])
        );
        window.open(baseUrl + url, '_blank');
    }

    goToGroupManagement() {
        const baseUrl = window.location.href.replace(this.router.url, '');
        const url = this.router.serializeUrl(
            this.router.createUrlTree([
                `/${this.authService.selectedInstance.name}/usersandgroups/groups`,
            ])
        );
        window.open(baseUrl + url, '_blank');
    }

    removeModal(modal: TemplateRef<any>, assignation: UserGroupProperty) {
        this.removeAssignation = assignation;
        this.removeModalRef = this.modal.show(modal, this.modalConfig);
    }

    removeUser() {
        if (this.removeAssignation.isGroup) {
            this.removeModalRef.hide();
            return;
        }
        if (!this.removeAssignation.isSystemGroup) {
            this.removeProperty();
        } else {
            this.removeGroup();
        }
    }

    removeProperty() {
        const update = new UpdatePropertyValue({
            value: null,
            comment: null,
            system: false,
            propertyId: this.removeAssignation.propertyId,
        });
        this.propertyService
            .updatePropertyValue(
                this.removeAssignation.propertyId,
                this.removeAssignation.treeNodeId,
                update
            )
            .subscribe(
                () => {
                    this.messageService.add({
                        severity: 'success',
                        summary: this.translate.instant(_('Success!')),
                        detail: this.translate.instant(_('User removed from node!')),
                    });
                },
                (error) => {
                    return console.log('Error: ', error);
                },
                () => {
                    this.getAssignations();
                    this.removeModalRef.hide();
                }
            );
    }

    removeGroup() {
        this.propertyService
            .getPropertyValue(this.removeAssignation.propertyId, this.removeAssignation.treeNodeId)
            .subscribe((value) => {
                this.groupService
                    .removeUserFromGroup(value.currentValue.id, this.user.id)
                    .subscribe(
                        () => {
                            this.messageService.add({
                                severity: 'success',
                                summary: this.translate.instant(_('Success!')),
                                detail: this.translate.instant(_('User removed from node!')),
                            });
                        },
                        (error) => {
                            return console.log('Error: ', error);
                        },
                        () => {
                            this.getAssignations();
                            this.removeModalRef.hide();
                        }
                    );
            });
    }

    removeAllModal(modal: TemplateRef<any>) {
        this.removeAllAssignationsModalRef = this.modal.show(modal, this.modalConfig);
    }

    removeUserFromAllNodesAssignations() {
        this.loadingAssignations = true;
        this.userService.removeUserFromAllNodesAssignations(this.user.id).subscribe(
            {
                next: () => {
                    this.messageService.add({
                        severity: 'success',
                        summary: this.translate.instant(_('Success!')),
                        detail: this.translate.instant(_('User removed from all assignations!')),
                    });
                },
                error: (error) => {
                    return console.log('Error: ', error);
                },
                complete: () => {
                    this.getAssignations();
                    this.removeAllAssignationsModalRef.hide();
                }
            }
        );
    }

    addUserToProperty(template: TemplateRef<any>) {
        this.propertyService
            .getPropertyValue(this.selectedProperty.propertyId, this.selectedNode.id)
            .subscribe((data) => {
                const propertyValue = data.currentValue;
                if (this.selectedProperty.propertyType === 'User' && !propertyValue.id) {
                    const value = new UpdatePropertyValue({
                        value: this.user.id,
                        propertyId: this.selectedProperty.propertyId,
                        system: false,
                    });
                    this.propertyService
                        .updatePropertyValue(
                            this.selectedProperty.propertyId,
                            this.selectedNode.id,
                            value
                        )
                        .subscribe(
                            () => {
                                this.messageService.add({
                                    severity: 'success',
                                    summary: this.translate.instant(_('Success!')),
                                    detail: this.translate.instant(_('User added to node!')),
                                });
                            },
                            (error) => {
                                return console.log('Error: ', error);
                            },
                            () => {
                                this.getAssignations();
                            }
                        );
                } else if (
                    this.selectedProperty.propertyType === 'User' &&
                    propertyValue.id &&
                    !this.selectedProperty.isSystemGroup
                ) {
                    this.addPropertyGroupManagement = false;
                    this.addPropertyMessage = this.translate.instant(
                        _(`Impossible to add ${this.user.displayName} to property. A user is already assigned to this property,
          please navigate towards node to perform changes.`)
                    );
                    this.addPropertyModalRef = this.modal.show(template);
                } else if (this.selectedProperty.isSystemGroup) {
                    this.groupService.addUserToGroup(propertyValue.id, this.user.id).subscribe(
                        () => {
                            this.messageService.add({
                                severity: 'success',
                                summary: this.translate.instant(_('Success!')),
                                detail: this.translate.instant(_('User added to node!')),
                            });
                        },
                        (error) => {
                            return console.log('Error: ', error);
                        },
                        () => {
                            this.getAssignations();
                        }
                    );
                } else if (this.selectedProperty.propertyType === 'Group') {
                    this.addPropertyGroupManagement = true;
                    this.addPropertyMessage = this.translate.instant(
                        _(
                            `This is a group property. Go to node or to group management to assign the user on this property.`
                        )
                    );
                    this.addPropertyModalRef = this.modal.show(template);
                }
            });
    }
}
class TreeNodeSelect extends TreeNode {
    label: string;
}
