import { Component, OnInit, TemplateRef } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

import { fadeInOut } from 'src/app/shared/animations/fade';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MaturityStrategyView } from '../../../../../shared/models/maturity';
import { BaseComponent } from '../../../../shared/components/base/base.component';
import { ConfirmationDialogService } from '../../../../../shared/components/utils/confirmation/ConfirmationDialog.service';
import { MaturityService } from '../../../../../shared/services/repositories/maturity.service';
import { WorkflowService } from '../../../../shared/services/workflow.service';
import { _ } from '../../../../../shared/services/translation.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-maturity-strategies',
    templateUrl: './maturity-strategies.component.html',
    styleUrls: ['./maturity-strategies.component.scss'],
    animations: [fadeInOut],
})
export class MaturityStrategiesComponent extends BaseComponent implements OnInit {
    public addModalRef: BsModalRef;
    public editModalRef: BsModalRef;
    public dtTrigger: Subject<any> = new Subject();

    public maturityStrategies: MaturityStrategyView[];

    public newMaturityStraregy: MaturityStrategyView = new MaturityStrategyView();
    public edtMaturityStraregy: MaturityStrategyView = new MaturityStrategyView();

    constructor(
        private maturityService: MaturityService,
        private confirmationService: ConfirmationDialogService,
        private messageService: MessageService,
        private modalService: BsModalService,
        protected workflowService: WorkflowService,
        public router: Router,
        private translate: TranslateService
    ) {
        super(router, workflowService);
        // disable order in specific columns
        this.dtOptions.columnDefs = [
            { orderable: false, targets: 2 },
            { orderable: false, targets: 3 },
        ];
        this.generateBreadCrumb();
    }

    ngOnInit(): void {
        this.dtOptions.order = [[0, 'asc']];
        this.getMaturityStrategies();
    }

    getMaturityStrategies() {
        this.loading = true;
        this.maturityService.getMaturityStraregies().subscribe((res) => {
            this.maturityStrategies = res;
            this.refreshTable('#table', this.dtTrigger);
        });
    }

    //#region remove
    removeMaturityStrategy(maturityStrategy: MaturityStrategyView) {
        this.confirmationService
            .confirm(
                this.translate.instant(_('Do you want to delete ')) +
                    maturityStrategy.name +
                    this.translate.instant(_(' Maturity Strategy?'))
            )
            .subscribe((result) => {
                if (result) {
                    this.maturityService.deleteMaturityStrategy(maturityStrategy.id).subscribe(
                        () => {
                            this.messageService.add({
                                severity: 'success',
                                summary: this.translate.instant(_('Success!')),
                                detail: this.translate.instant(
                                    _('Maturity Strategy removed with success!')
                                ),
                            });
                        },
                        (error) => {
                            return console.log('Error: ', error);
                        },
                        () => {
                            return this.getMaturityStrategies();
                        }
                    );
                }
            });
    }

    //#region add
    addMaturityStrategyModal(modal: TemplateRef<any>) {
        this.newMaturityStraregy = new MaturityStrategyView();
        this.addModalRef = this.modalService.show(modal, this.modalConfig);
    }

    addMaturityStrategy() {
        // null or white spaces
        if (
            this.newMaturityStraregy.name !== null &&
            this.newMaturityStraregy.name.match(/^ *$/) === null
        ) {
            this.maturityService.setMaturityStraregy(this.newMaturityStraregy.name).subscribe(
                () => {
                    this.messageService.add({
                        severity: 'success',
                        summary: this.translate.instant(_('Success!')),
                        detail: this.translate.instant(_('Maturity Strategy added with success!')),
                    });
                },
                (error) => {
                    return console.log('Error: ', error);
                },
                () => {
                    return this.getMaturityStrategies();
                }
            );
            this.addModalRef.hide();
        }
    }

    //#region edit
    editMaturityStrategyModal(maturityStrategy: MaturityStrategyView, modal: TemplateRef<any>) {
        this.edtMaturityStraregy = new MaturityStrategyView();
        this.edtMaturityStraregy.id = maturityStrategy.id;
        this.edtMaturityStraregy.name = maturityStrategy.name;
        this.editModalRef = this.modalService.show(modal, this.modalConfig);
    }

    editMaturityStrategy() {
        // null or white spaces
        if (
            this.edtMaturityStraregy.name !== null &&
            this.edtMaturityStraregy.name.match(/^ *$/) === null
        ) {
            this.maturityService
                .updateMaturityStraregy(this.edtMaturityStraregy.id, this.edtMaturityStraregy.name)
                .subscribe(
                    () => {
                        this.messageService.add({
                            severity: 'success',
                            summary: this.translate.instant(_('Success!')),
                            detail: this.translate.instant(
                                _('Maturity Strategy updated with success!')
                            ),
                        });
                    },
                    (error) => {
                        return console.log('Error: ', error);
                    },
                    () => {
                        return this.getMaturityStrategies();
                    }
                );
            this.editModalRef.hide();
        }
    }

    private generateBreadCrumb() {
        this.dynamicBreadCrumb('Maturity');
    }
}
