import { Component } from '@angular/core';

@Component({
    selector: 'app-ventilations',
    templateUrl: './ventilations.component.html',
    styleUrls: ['./ventilations.component.scss'],
})
export class VentilationsComponent {
    constructor() {}
}
