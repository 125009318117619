export class BreadcrumElement {
  name: string;
  link: string;
  isActive: boolean;
  subElements: BreadcrumElement[];
  permissions: string[];
}


export class LayoutRootElement {
  title: string;
  description: string;
  modulesElements: FlowElement[];
  type: string;
  link: string;
  permissions: string[];
}

export class FlowElement {
  title: string;
  icon: string;
  description: string;
  link: string;
  disabled: boolean;
  type: string;
  permissions: string[];
  tag: string[];
}

