import { AuthenticatedUser } from './user';


export enum UpdateTypes {
    Progress = 0,
    Scorecard = 1
}

//#region Scorecard
export enum EffectOperator {
    Operation = 0,
    Effect = 1,
}

//#region jobs
export enum JobStatus {
    Waiting = 0,
    Failed = 1,
    Success = 2,
    Running = 3,
    Cancelled = 4,
}

// #region Top-exports
export enum ExportType {
    Simple,
    Duplication,
}

//#region Messages
export enum MessageType {
    Error = 0,
    Warning = 1,
    Info = 2,
}

//#region Nodes
export enum TreeNodeStatus {
    Ok = 0,
    Deleted = 1,
    PendingDeletion = 2,
    PendingCreation = 3,
    Draft = 4,
}

export enum FinancialMetric {
    None = 0,
    Kilo = 1,
    Mega = 2,
}

//#region property
export enum TypeProperty {
    String = 0,
    Text = 1,
    Number = 2,
    Date = 3,
    List = 4,
    User = 5,
    Group = 6,
    Boolean = 7,
    ImageList = 8,
    StatCompleted = 9,
}

export enum UpdateBehavior {
    NoValidation = 0,
    ValidationRequired = 1,
    AdminOnly = 2,
}


export enum VisibilityProperty {
    All = 0,
    AdminOnly = 1,
    Hidden = 2,
}

export enum ApprovalStatus {
    None = 0,
    OnHold = 1,
    WaitingForApproval = 2,
}

//#region Email
export enum EmailScheduleType {
    FirstStatusUpdate = 0,
    SecondStatusUpdate = 1,
    ThirdStatusUpdate = 2,
    FirstScorecardUpdate = 3,
    SecondScorecardUpdate = 4,
    ThirdScorecardUpdate = 5,
}

export enum GroupMailType {
    Progress = 0,
    Financials = 1,
}

export enum Frequency {
    Monthly = 0,
    Quarterly = 1,
}

//#region Import
export enum ImportType {
    Properties = 0,
    Financials = 1,
    Flags = 2,
    Kpis = 3,
    ExchangeRate = 4,
    Groups = 5,
    Users = 6,
    ChangeRequests = 7,
}

// Configuration
export enum ConfigType {
    String = 0,
    Integer = 1,
    Boolean = 2,
    Array = 3,
}

// scorecard

export enum ScorecardType {
    Basic = 0,
    Aggregation = 1,
    Correction = 2
}

export enum ScorecardAggregation {
    Full = 0,
    Partial = 1,
    None = 2,
}

export enum ExcelExtension {
    Xlsx = 0,
    Xls = 1,
    Xltx = 2,
    Xlsm = 3,
    Xlsb = 4
}

export enum SortType {
    Ascending = 0,
    Descending = 1,
}

export class AdminRight {
    id: string;
    name: string;
    displayName: string;
    description: string;
    permission: string;

    public static get USER_AND_GROUP(): string { return '80000000-0000-0000-0000-000000000001'; }
    public static get SCORECARD_AND_EFFECT(): string { return '80000000-0000-0000-0000-000000000002'; }
    public static get PROPERTY_AND_SET(): string { return '80000000-0000-0000-0000-000000000003'; }
    public static get CHECKLIST_AND_DELIVERABLE(): string { return '80000000-0000-0000-0000-000000000004'; }
    public static get EXCHANGE_RATE(): string { return '80000000-0000-0000-0000-000000000005'; }
    public static get IMPORT(): string { return '80000000-0000-0000-0000-000000000006'; }
    public static get EXPORT(): string { return '80000000-0000-0000-0000-000000000007'; }
    public static get ARCHIVE(): string { return '80000000-0000-0000-0000-000000000008'; }
    public static get DELETED_NODE(): string { return '80000000-0000-0000-0000-000000000009'; }
    public static get MATURITY_AND_STRATEGY(): string { return '80000000-0000-0000-0000-000000000010'; }
    public static get SEND_MAIL(): string { return '80000000-0000-0000-0000-000000000011'; }
    public static get GROUP_MAIL_AND_TEMPLATE(): string { return '80000000-0000-0000-0000-000000000012'; }
    public static get MESSAGE(): string { return '80000000-0000-0000-0000-000000000013'; }
    public static get STRUCTURE(): string { return '80000000-0000-0000-0000-000000000014'; }
    public static get FROZEN_PERIOD(): string { return '80000000-0000-0000-0000-000000000015'; }
    public static get UPPDATE_PERIOD(): string { return '80000000-0000-0000-0000-000000000016'; }
    public static get TOP_LEVEL_MAIL_TEMPLATE(): string { return '80000000-0000-0000-0000-000000000017'; }
    public static get TOP_LEVEL_SCORECARD_INFO(): string { return '80000000-0000-0000-0000-000000000018'; }
    public static get TOP_LEVEL_CREATION(): string { return '80000000-0000-0000-0000-000000000019'; }
    public static get CONFIG(): string { return '80000000-0000-0000-0000-000000000020'; }
    public static get TOP_LEVEL_CURRENCY(): string { return '80000000-0000-0000-0000-000000000021'; }
    public static get TREENODE_CURRENCY(): string { return '80000000-0000-0000-0000-000000000023'; }
    public static get CREATE_KPI(): string { return '80000000-0000-0000-0000-000000000024'; }

    public static hasRight(user: AuthenticatedUser, adminRight: string): boolean {
        if (user.isAdministrator) {
            return true;
        }
        var result = user.adminRights.some(rightId => {
            return rightId === adminRight;
        });
        return result;
    }
}
