import { Component, OnInit } from '@angular/core';
import { saveAs } from 'file-saver';
import { BaseComponent } from '../../../../shared/components/base/base.component';
import { Top } from '../../../../../shared/models/top';
import { Router } from '@angular/router';
import { WorkflowService } from '../../../../shared/services/workflow.service';
import { TopService } from '../../../../../shared/services/repositories/top.service';
import { ImportService } from '../../../../../shared/services/repositories/import.service';
import { TopLevelStructure } from '../../../../../shared/models/topLevelStructure';
import {
    Import,
    GenericImportParameter,
    ImportImportType,
} from '../../../../../shared/models/import';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { fadeInOut } from 'src/app/shared/animations/fade';
import { Constants } from '../../../../../shared/models/constants';
import { MessageService } from 'primeng/api';
import { ImportType } from 'src/app/shared/models/enums';
import { _ } from '../../../../../shared/services/translation.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
    selector: 'app-imports',
    templateUrl: './imports.component.html',
    styleUrls: ['./imports.component.scss'],
    animations: [fadeInOut],
})
export class ImportsComponent extends BaseComponent implements OnInit {
    import = new Import();

    topLevels: Top[];
    selectedTopLevel: Top;
    templateSelectedTopLevel: Top;

    templateTopLevelStructures: TopLevelStructure[];
    topLevelStructures: TopLevelStructure[];

    selectedTopLevelStructure: TopLevelStructure;
    templateSelectedTopLevelStructure: TopLevelStructure;

    imports: ImportImportType[];
    templateSelectedImportType: any;
    selectedImportType: any;

    files: FormData;
    fileNames: string[] = [];

    importFileType = '';

    importParameter: GenericImportParameter;

    constructor(
        public router: Router,
        protected workflowService: WorkflowService,
        private topService: TopService,
        private importService: ImportService,
        private translate: TranslateService,
        private messageService: MessageService
    ) {
        super(router, workflowService);
        this.dynamicBreadCrumb('Node Configuration');
    }

    ngOnInit() {
        this.imports = this.import.genericImports();

        this.loading = true;
        this.files = new FormData();
        this.importParameter = new GenericImportParameter();
        this.topService.getTops().subscribe(
            (data) => {
                this.topLevels = data;
                this.onTopSelect(this.topLevels[0].id);
                this.onTemplateTopSelect(this.topLevels[0].id);
            },
            (err) => {
                return console.log(err, err.Message);
            }
        );
    }

    onTemplateTopSelect(id: string) {
        this.templateSelectedTopLevel = this.topLevels.find((x) => {
            return x.id === id;
        });
        this.topService.getLevelsForTop(this.templateSelectedTopLevel.id).subscribe((data) => {
            this.templateTopLevelStructures = data;
            this.templateSelectedImportType = -1;
            this.loading = false;
        });
    }

    onTopSelect(id: string) {
        this.selectedTopLevel = this.topLevels.find((x) => {
            return x.id === id;
        });
        this.topService.getLevelsForTop(this.selectedTopLevel.id).subscribe((data) => {
            this.topLevelStructures = data;
            this.selectedImportType = -1;
            this.loading = false;
        });
    }

    displayForStructure(topLevelStructure: TopLevelStructure, type: ImportType) {
        if (!topLevelStructure) {
            return false;
        }
        switch (type) {
            case ImportType.Financials:
                return topLevelStructure.scorecardFrequency != null;
            case ImportType.Kpis:
                return topLevelStructure.hasKpi;
            case ImportType.ChangeRequests:
                return topLevelStructure.scorecardFrequency != null;
            default:
                return true;
        }
    }

    public getImportTemplate() {
        this.importService
            .getTemplate(this.templateSelectedTopLevelStructure.id, this.templateSelectedImportType)
            .subscribe((data) => {
                const filename = data.headers
                    .get('Content-Disposition')
                    .split(';')[1]
                    .trim()
                    .split('=')[1]
                    .replace(/"/g, '');
                saveAs(data.body, filename);
            });
    }

    onFileDrop(files: NgxFileDropEntry[]) {
        files.forEach((file) => {
            if (file.fileEntry.isFile) {
                switch (this.selectedImportType) {
                    case ImportType.Flags:
                        this.importFileType = 'Flags';
                        break;
                    case ImportType.Properties:
                        this.importFileType = 'Treenodes';
                        break;
                    case ImportType.Financials:
                        this.importFileType = 'Financials';
                        break;
                    case ImportType.Kpis:
                        this.importFileType = 'Kpis';
                        break;
                    case ImportType.ChangeRequests:
                        this.importFileType = 'ChangeRequests';
                        break;
                }

                const fileEntry = file.fileEntry as FileSystemFileEntry;
                fileEntry.file((uploadFile: File) => {
                    this.files.append(
                        file.fileEntry.name,
                        uploadFile,
                        this.importFileType + '.' + file.fileEntry.name.split('.').pop()
                    );
                    this.fileNames.push(file.fileEntry.name);
                });
            }
        });
    }

    removeFile(fileName: string, index: number) {
        this.files.delete(fileName);
        this.fileNames.splice(index, 1);
    }

    uploadFiles() {
        this.importService
            .import(
                Constants.IMPORT_GENERIC,
                this.selectedTopLevel.id,
                this.files,
                this.importParameter
            )
            .subscribe(
                () => {},
                (err) => {
                    return console.log(err, err.Message);
                },
                () => {
                    this.messageService.add({
                        severity: 'success',
                        summary: this.translate.instant(_('Success!')),
                        detail: this.translate.instant(_('Import job created')),
                    });
                    this.fileNames = [];
                    this.files = new FormData();
                }
            );
    }
}
