import { Component, OnInit, TemplateRef } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import {
    Top,
    TopCreateModel,
    TopEditModel,
    TopEditScorecardModel,
} from '../../../../../shared/models/top';
import { _ } from '../../../../../shared/services/translation.service';
import { WorkflowService } from '../../../../shared/services/workflow.service';

import { fadeInOut } from 'src/app/shared/animations/fade';
import { ConfirmationDialogService } from '../../../../../shared/components/utils/confirmation/ConfirmationDialog.service';
import { ScorecardService } from '../../../../../shared/services/repositories/scorecard.service';
import { IScorecardView } from '../../../../../shared/models/scorecard';
import { BaseComponent } from '../../../../shared/components/base/base.component';
import { TopService } from '../../../../../shared/services/repositories/top.service';
import { TranslateService } from '@ngx-translate/core';
import { FinancialMetric } from 'src/app/shared/models/enums';

@Component({
    selector: 'app-tops',
    templateUrl: './tops.component.html',
    styleUrls: ['./tops.component.scss'],
    animations: [fadeInOut],
})
export class TopsComponent extends BaseComponent implements OnInit {
    public tops: Top[];
    public dtTrigger: Subject<any> = new Subject();

    public topEditScorecardModel: TopEditScorecardModel;
    public topCreateModel: TopCreateModel = new TopCreateModel();
    public topEditModel: TopEditModel = new TopEditModel();
    public FinancialMetric = FinancialMetric;

    public addModalRef: BsModalRef;
    public editModalRef: BsModalRef;
    public editScorecardModalRef: BsModalRef;

    public scorecards: IScorecardView[];

    constructor(
        private topService: TopService,
        public router: Router,
        private confirmationService: ConfirmationDialogService,
        private modalService: BsModalService,
        public workflowService: WorkflowService,
        private messageService: MessageService,
        private scorecardService: ScorecardService,
        private translate: TranslateService
    ) {
        super(router, workflowService);
        // disable order in specific columns
        this.dtOptions.columnDefs = [
            { orderable: false, targets: 4 },
            { orderable: false, targets: 5 },
            { orderable: false, targets: 6 },
        ];
        this.dynamicBreadCrumb('Structure Management');
    }

    ngOnInit() {
        this.dtOptions.order = [[0, 'asc']];
        this.getTops();
        this.scorecardService.getScorecardsView().subscribe((data) => {
            this.scorecards = data;
        });
    }

    getTops() {
        this.loading = true;
        this.topService.getTops().subscribe((res) => {
            this.tops = res;
            this.refreshTable('#table', this.dtTrigger);
        });
    }

    addTopModal(modal: TemplateRef<any>) {
        this.addModalRef = this.modalService.show(modal, this.modalConfig);
    }

    addNewTop() {
        this.topService.addTop(this.topCreateModel).subscribe(
            () => {
                return this.messageService.add({
                    severity: 'success',
                    summary: this.translate.instant(_('Succes!')),
                    detail: this.translate.instant(_('Toplevel structure updated with success!')),
                });
            },
            (error) => {
                return console.log('Error', error);
            },
            () => {
                return this.getTops();
            }
        );
        this.addModalRef.hide();
    }

    editTopModal(modal: TemplateRef<any>, top: Top) {
        this.topEditModel = new TopEditModel();
        this.topEditModel.topLevelId = top.id;
        this.topEditModel.name = top.name;
        this.topEditModel.description = top.description;
        this.editModalRef = this.modalService.show(modal, this.modalConfig);
    }

    editTop() {
        this.topService.editTop(this.topEditModel.topLevelId, this.topEditModel).subscribe(
            () => {
                return this.messageService.add({
                    severity: 'success',
                    summary: this.translate.instant(_('Succes!')),
                    detail: this.translate.instant(_('Toplevel updated with success!')),
                });
            },
            (error) => {
                return console.log('Error', error);
            },
            () => {
                return this.getTops();
            }
        );
        this.editModalRef.hide();
    }

    removeTopLevel(level: Top) {
        this.confirmationService
            .confirm(
                this.translate.instant(_('Do you want to delete ')) +
                    level.name +
                    this.translate.instant(_(' top level?'))
            )
            .subscribe((result) => {
                if (result) {
                    this.topService.removeTop(level).subscribe(() => {
                        this.messageService.add({
                            severity: 'success',
                            summary: this.translate.instant(_('Succes!')),
                            detail: this.translate.instant(_('Top level removed with success!')),
                        });
                        this.getTops();
                    });
                }
            });
    }

    getMetricKeys() {
        const keys = Object.keys(this.FinancialMetric);
        return keys.slice(0, keys.length / 2);
    }

    editTopScorecardModal(modal: TemplateRef<any>, top: Top) {
        this.editScorecardModalRef = this.modalService.show(modal, this.modalConfig);
        this.topEditScorecardModel = new TopEditScorecardModel();
        this.topEditScorecardModel.topLevelId = top.id;
        this.topEditScorecardModel.scorecardId = top.scorecardId;
        this.topEditScorecardModel.scorecardStart = new Date(top.scorecardStart);
        this.topEditScorecardModel.scorecardEnd = new Date(top.scorecardEnd);
        this.topEditScorecardModel.metric = top.metric;
    }

    editScorecardTop() {
        const model = new TopEditScorecardModel();
        Object.assign(model, this.topEditScorecardModel);
        model.start = this.topEditScorecardModel.scorecardStart.toCustomString();
        model.end = this.topEditScorecardModel.scorecardEnd.toCustomString();
        this.topService.updateTopLevelScorecard(model).subscribe(
            (data) => {},
            (err) => {
                return console.log(err, err.Message);
            },
            () => {
                this.editScorecardModalRef.hide();
                this.messageService.add({
                    severity: 'success',
                    summary: this.translate.instant(_('Succes!')),
                    detail: this.translate.instant(_('Top updated!')),
                });
                this.ngOnInit();
            }
        );
    }
}
