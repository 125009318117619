import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService as PrimengMessageService } from 'primeng/api';
import { Location } from '@angular/common';
import { Message as PrimeMessage } from 'primeng/api';

import { MessageService } from '../../../../../../shared/services/repositories/message.service';
import { Message } from '../../../../../../shared/models/message';
import { TopService } from '../../../../../../shared/services/repositories/top.service';
import { Top } from '../../../../../../shared/models/top';
import { BaseComponent } from '../../../../../shared/components/base/base.component';
import { _ } from '../../../../../../shared/services/translation.service';
import { WorkflowService } from '../../../../../shared/services/workflow.service';
import { AuthService } from '../../../../../../shared/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { MessageType } from 'src/app/shared/models/enums';
import { PrimeTools } from 'src/app/shared/tools/primeTools';

@Component({
    selector: 'app-message',
    templateUrl: './message.component.html',
    styleUrls: ['./message.component.scss'],
})
export class MessageComponent extends BaseComponent implements OnInit {
    public isDialogOpen = false;
    public testMessages: PrimeMessage[];

    public messageTypesKeys;
    public MessageType = MessageType;

    public tops: Top[] = [];
    public editMode = false;
    public messageId: string;
    public message: Message = new Message();
    public rangeDateValue: Date[] = [];

    constructor(
        private messageService: MessageService,
        private topService: TopService,
        private route: ActivatedRoute,
        private pMessageService: PrimengMessageService,
        public router: Router,
        public authService: AuthService,
        private location: Location,
        protected workflowService: WorkflowService,
        private translate: TranslateService
    ) {
        super(router, workflowService);
        this.generateBreadCrumb();
        this.messageTypesKeys = Object.keys(this.MessageType);
        this.messageTypesKeys = this.messageTypesKeys.slice(0, this.messageTypesKeys.length / 2);
    }

    ngOnInit() {
        this.route.paramMap.subscribe((params) => {
            const id = params.get('id');
            if (id !== 'new') {
                this.editMode = true;
                this.messageId = id;
                this.getMessage(params.get('id'));
            } else {
                this.message = new Message();
            }
            this.getTops();
        });
    }

    getMessage(id: string) {
        this.messageService.getMessage(id).subscribe((res) => {
            this.message = res;
            this.rangeDateValue = [new Date(this.message.fromDate), new Date(this.message.endDate)];
        });
    }

    getTops() {
        this.topService.getTops().subscribe((res) => {
            return (this.tops = res);
        });
    }

    save() {
        if (
            !this.message.text ||
            !this.rangeDateValue ||
            !this.message.type ||
            !this.message.topLevelId
        ) {
            alert(this.translate.instant(_('Please fill all mandatory fields')));
            return;
        }
        this.message.fromDate = this.rangeDateValue[0];
        this.message.endDate = this.rangeDateValue[1];

        if (this.editMode) {
            this.messageService.updateMessage(this.messageId, this.message).subscribe(() => {
                this.pMessageService.add({
                    severity: 'success',
                    summary: this.translate.instant(_('Success!')),
                    detail: this.translate.instant(_('Message updated with success!')),
                });
                this.router.navigate([
                    '/admin/' + this.authService.selectedInstance.name + '/communication/messages',
                ]);
            });
        } else {
            this.messageService.setMessage(this.message).subscribe(() => {
                this.pMessageService.add({
                    severity: 'success',
                    summary: this.translate.instant(_('Success!')),
                    detail: this.translate.instant(_('Message created with success!')),
                });
                this.router.navigate([
                    '/admin/' + this.authService.selectedInstance.name + '/communication/messages',
                ]);
            });
        }
    }

    public testMsg(message: Message) {
        this.testMessages = [
            {
                severity: PrimeTools.convertMessageType(message.type),
                summary: this.MessageType[message.type],
                detail: message.text,
            },
        ];
        this.isDialogOpen = true;
    }

    public goBack() {
        this.location.back();
    }

    private generateBreadCrumb() {
        this.dynamicBreadCrumb('Communication', 'Messages', [
            {
                name: 'Message',
                link: '',
                isActive: false,
                subElements: [],
                permissions: null,
            },
        ]);
    }
}
