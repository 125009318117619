import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { forkJoin } from 'rxjs';
import { Location } from '@angular/common';
import * as __ from 'underscore';

import { EffectService } from '../../../../../shared/services/repositories/effect.service';
import { Effect, EffectViewModel } from '../../../../../shared/models/effect';
import { BaseComponent } from '../../../../shared/components/base/base.component';
import { WorkflowService } from '../../../../shared/services/workflow.service';
import { AuthService } from '../../../../../shared/services/auth.service';
import { _ } from '../../../../../shared/services/translation.service';
import { TranslateService } from '@ngx-translate/core';
import { TagEffect } from 'src/app/admin/shared/models/TagEffect';
import { TagEffectsService } from 'src/app/admin/global-admin/services/repositories/tageffects.service';

@Component({
    selector: 'app-effect',
    templateUrl: './effect.component.html',
    styleUrls: ['./effect.component.scss'],
})
export class EffectComponent extends BaseComponent implements OnInit {
    private effectId: string;
    public effect: EffectViewModel = new EffectViewModel();
    public editMode = false;

    public calculations: { value: string; order: number }[] = [];
    public originalIsAutoCalculated: boolean;
    public restrictions: string[];
    public allEffects: Effect[];
    public aggregations: string[] = [];
    private tagEffects: TagEffect[] = [];

    availableTags: string[];

    constructor(
        private effectService: EffectService,
        private tagEffectsService: TagEffectsService,
        private route: ActivatedRoute,
        private messageService: MessageService,
        public router: Router,
        public authService: AuthService,
        private location: Location,
        protected workflowService: WorkflowService,
        private translate: TranslateService
    ) {
        super(router, workflowService);
    }

    ngOnInit() {
        this.route.paramMap.subscribe((params) => {
            const id = params.get('id');
            if (id !== 'new') {
                this.editMode = true;
                this.effectId = id;
                this.getEffect(this.effectId);
            } else {
                this.effect = new EffectViewModel();
                this.effect.levelAggregationFlag = true;
                this.generateBreadCrumb();
                this.getAllValues();
            }
        });

    }

    public save() {
        const effect = new Effect();
        Object.assign(effect, this.effect);

        effect.calculations = [];
        this.calculations.forEach((element) => {
            // remove '-' in guid's
            const calculation =
                element.value.length < 2 ? element.value : element.value.replace(/-/g, '');
            effect.calculations.push(calculation);
        });
        effect.calculations.splice(-1, 1);

        // tag effects
        effect.tagEffectNames = this.tagEffects.filter(x => { return x.isSelected === true }).map(x => { return x.name });

        if (this.editMode) {
            this.effectService.updateEffect(this.effectId, effect).subscribe(() => {
                this.messageService.add({
                    severity: 'success',
                    summary: this.translate.instant(_('Success!')),
                    detail: this.translate.instant(_('Effect updated with success!')),
                });
                this.router.navigate([
                    '/admin/' + this.authService.selectedInstance.name + '/scorecards/effects',
                ]);
            });
        } else {
            this.effectService.setEffect(effect).subscribe(() => {
                this.messageService.add({
                    severity: 'success',
                    summary: this.translate.instant(_('Success!')),
                    detail: this.translate.instant(_('Effect created with success!')),
                });
                this.router.navigate([
                    '/admin/' + this.authService.selectedInstance.name + '/scorecards/effects',
                ]);
            });
        }
    }

    private getEffect(id: string) {
        this.effectService.getEffect(id).subscribe((res) => {
            this.effect = res;
            this.originalIsAutoCalculated = this.effect.autoCalculated;
            this.getAllValues();
            this.generateBreadCrumb();
        });
    }

    private getAllValues() {
        forkJoin(
            [this.effectService.getAllEffects(),
            this.effectService.getCumulativeEffctsKeys(),
            this.effectService.getRestrictionEffctsKeys(),
            this.tagEffectsService.getTagEffects()]
        ).subscribe((res) => {
            this.aggregations = res[1];
            this.restrictions = res[2];
            this.tagEffects = res[3];
            // populate multi select dropdown
            this.tagEffects.forEach((x) => {
                x.isSelected = this.effect.tagEffectNames?.some(y => { return x.name === y });
            });

            if (this.editMode) {
                this.allEffects = res[0].filter((x) => {
                    return x.id !== this.effectId;
                });
            } else {
                this.allEffects = res[0];
                this.effect.aggregation = this.aggregations[0]; // used to get arround IE bug null dropdown selection
            }

            // prepare calculation
            if (this.effect && this.effect.calculations) {
                this.effect.calculations.forEach((x) => {
                    this.calculations.push({ value: x.value, order: x.order });
                });
            }
            this.calculations.push({ value: '', order: this.effect.calculations.length });
            this.calculations = __.sortBy(this.calculations, 'order');
        });
    }

    public onCalculationChange(calculation: any) {
        if (calculation.value === '') {
            this.calculations.splice(
                calculation.order,
                this.calculations.length - calculation.order
            );
            const nextOrder = this.calculations.length;
            this.calculations.push({ value: '', order: nextOrder });
        } else if (calculation.order + 1 === this.calculations.length) {
            this.calculations.push({ value: '', order: this.calculations.length });
        }
    }

    public goBack() {
        this.location.back();
    }

    private generateBreadCrumb() {
        this.dynamicBreadCrumb('Scorecard', 'Effects', [
            { name: 'Effect', link: '', isActive: false, subElements: [], permissions: null },
        ]);
    }
}
