import { Component, OnInit, TemplateRef } from '@angular/core';
import { Subject } from 'rxjs';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { Property, PropertyListValue } from '../../../../../shared/models/property';
import { PropertyService } from '../../../../../shared/services/repositories/property.service';
import { BaseComponent } from '../../../../shared/components/base/base.component';
import { fadeInOut } from 'src/app/shared/animations/fade';
import { WorkflowService } from '../../../../shared/services/workflow.service';
import { AuthService } from '../../../../../shared/services/auth.service';
import { TypeProperty } from 'src/app/shared/models/enums';
import { environment } from 'src/environments/environment';
import { _ } from '../../../../../shared/services/translation.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-property-list-values',
    templateUrl: './property-list-values.component.html',
    styleUrls: ['./property-list-values.component.scss'],
    animations: [fadeInOut],
})
export class PropertyListValuesComponent extends BaseComponent implements OnInit {
    public addModalRef: BsModalRef;
    public editModalRef: BsModalRef;

    private propertyId: string;
    public property: Property = new Property();
    public listValues: PropertyListValue[];

    public TypeProperty = TypeProperty;

    public listValue = new PropertyListValue();
    public imageUrl: string;

    public dtTrigger: Subject<any> = new Subject();

    constructor(
        private propertyService: PropertyService,
        private route: ActivatedRoute,
        private location: Location,
        private messageService: MessageService,
        public router: Router,
        public authService: AuthService,
        private modalService: BsModalService,
        protected workflowService: WorkflowService,
        private translate: TranslateService
    ) {
        super(router, workflowService);

        this.imageUrl = environment.proxyUrl + '/' + this.authService.selectedInstance.name;
    }

    ngOnInit() {
        this.route.paramMap.subscribe((params) => {
            this.propertyId = params.get('id');
            this.getProperty(this.propertyId);
            this.getListValues(this.propertyId);
        });
    }

    private getProperty(id: string) {
        this.propertyService.getProperty(id).subscribe((res) => {
            this.property = res;
            this.generateBreadCrumb();
        });
    }

    private getListValues(propertyId: string) {
        this.propertyService.getPropertyListvalues(propertyId).subscribe((res) => {
            this.listValues = res;
            this.refreshTable('#table', this.dtTrigger);
        });
    }

    //#region add
    addPropertyListValueModal(modal: TemplateRef<any>) {
        this.listValue = new PropertyListValue();
        this.addModalRef = this.modalService.show(modal, this.modalConfig);
    }

    addPropertyListValue() {
        this.listValue.propertyId = this.propertyId;

        this.propertyService.setPropertyListValue(this.propertyId, this.listValue).subscribe(
            () => {
                this.messageService.add({
                    severity: 'success',
                    summary: this.translate.instant(_('Success!')),
                    detail: this.translate.instant(_('Property values added with success!')),
                });
            },
            (error) => {
                return console.log('Error: ', error);
            },
            () => {
                return this.getListValues(this.propertyId);
            }
        );
        this.addModalRef.hide();
    }

    //#region edit
    editPropertyListValueModal(listValue: PropertyListValue, modal: TemplateRef<any>) {
        this.listValue = listValue;
        this.editModalRef = this.modalService.show(modal, this.modalConfig);
    }

    editPropertyListValue() {
        // null or white spaces
        this.listValue.propertyId = this.propertyId;

        this.propertyService.updatePropertyListValue(this.propertyId, this.listValue).subscribe(
            () => {
                this.messageService.add({
                    severity: 'success',
                    summary: this.translate.instant(_('Success!')),
                    detail: this.translate.instant(_('Property values updated with success!')),
                });
            },
            (error) => {
                return console.log('Error: ', error);
            },
            () => {
                return this.getListValues(this.propertyId);
            }
        );
        this.editModalRef.hide();
    }

    delete(propertyValue: PropertyListValue) {
        this.propertyService
            .deletePropertyListValue(this.propertyId, propertyValue.id)
            .subscribe(() => {
                this.messageService.add({
                    severity: 'success',
                    summary: this.translate.instant(_('Success!')),
                    detail: this.translate.instant(_('Property value removed with success!')),
                });
                this.getListValues(this.propertyId);
            });
    }

    public goBack() {
        this.location.back();
    }

    //#region image
    changeListener($event): void {
        this.readThis($event.target);
    }

    readThis(inputValue: any): void {
        const file: File = inputValue.files[0];

        if (file.size > 10000000) {
            this.messageService.add({
                severity: 'warn',
                summary: this.translate.instant(_('Warning!')),
                detail: this.translate.instant(_('File size must be under 10MB.')),
            });
            return null;
        }

        const myReader: FileReader = new FileReader();
        myReader.onloadend = (e: any) => {
            this.listValue.data = myReader.result;
            this.listValue.contentFileName = file.name;
            this.listValue.contentType = file.type;
            this.listValue.content = (myReader.result as string).split(',')[1];
        };
        myReader.readAsDataURL(file);
    }
    private generateBreadCrumb() {
        this.dynamicBreadCrumb('Node Configuration', 'Properties', [
            {
                name: `${this.property.displayName} - Manage values`,
                link: '',
                isActive: false,
                subElements: [],
                permissions: null,
            },
        ]);
    }
}
