import { Unit } from './../../../../../shared/models/property';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { Subject, forkJoin } from 'rxjs';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import * as __ from 'underscore';

import { _ } from '../../../../../shared/services/translation.service';
import { BaseComponent } from '../../../../shared/components/base/base.component';
import { PropertyService } from '../../../../../shared/services/repositories/property.service';
import { fadeInOut } from 'src/app/shared/animations/fade';
import { Property, Section } from '../../../../../shared/models/property';
import { ConfirmationDialogService } from '../../../../../shared/components/utils/confirmation/ConfirmationDialog.service';
import { WorkflowService } from '../../../../shared/services/workflow.service';
import { AuthService } from '../../../../../shared/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { TypeProperty, VisibilityProperty, UpdateBehavior } from 'src/app/shared/models/enums';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-properties',
    templateUrl: './properties.component.html',
    styleUrls: ['./properties.component.scss'],
    animations: [fadeInOut],
})
export class PropertiesComponent extends BaseComponent implements OnInit {
    public modalRef: BsModalRef;

    public dtTrigger: Subject<any> = new Subject();
    dropdownSettings = {};

    public properties: Property[] = [];
    public sections: Section[] = [];
    public selectedSections: Section[] = [];
    public sec: Section;

    public property: Property;
    public editMode = false;
    public propertyTypesKeys;
    public propertyVisibilitiesKeys;
    public propertyUpdateBehaviorKeys;

    public types = [];

    public TypeProperty = TypeProperty;
    public VisibilityProperty = VisibilityProperty;
    public UpdateBehavior = UpdateBehavior;

    public units: Unit[] = [];
    public unitKeys: string[] = [];
    public unitSymbols: object;

    constructor(
        private propertyService: PropertyService,
        private confirmationService: ConfirmationDialogService,
        private messageService: MessageService,
        public router: Router,
        protected workflowService: WorkflowService,
        private authService: AuthService,
        private modalService: BsModalService,
        private translate: TranslateService
    ) {
        super(router, workflowService);
        this.generateBreadCrumb();

        this.dtOptions = {
            ordering: false,
            searching: true,
            lengthChange: false,
            paging: false,
            order: [],
        };

        this.dropdownSettings = {
            singleSelection: false,
            text: this.translate.instant(_('Select Sections')),
            selectAllText: this.translate.instant(_('Select All')),
            unSelectAllText: this.translate.instant(_('UnSelect All')),
            enableSearchFilter: true,
            classes: 'myclass multiselect-admin',
            labelKey: 'name',
            // badgeShowLimit: 4
        };

        this.convertToKeys();
    }

    ngOnInit() {
        this.getProperties();
    }

    getProperties() {
        this.loading = true;
        forkJoin(
            this.propertyService.getProperties(),
            this.propertyService.getSections()
        ).subscribe((res) => {
            this.properties = res[0];
            this.properties = __.sortBy(this.properties, 'name');
            this.sections = res[1];
            this.selectedSections = this.selectedSections.concat(this.sections);
            this.refreshTable('#table', this.dtTrigger);
        });
    }

    delete(property: Property) {
        this.confirmationService
            .confirm(
                this.translate.instant(_('Do you want delete proprety ')) +
                `${property.displayName}?`
            )
            .subscribe((result) => {
                if (result) {
                    this.propertyService.deleteProperty(property.id).subscribe(
                        () => {
                            this.messageService.add({
                                severity: 'success',
                                summary: this.translate.instant(_('Success!')),
                                detail: this.translate.instant(_('Property removed with success!')),
                            });
                        },
                        (error) => {
                            return console.log('Error: ', error);
                        },
                        () => {
                            return this.getProperties();
                        }
                    );
                }
            });
    }

    addPropertyModal(modal: TemplateRef<any>) {
        this.property = new Property();
        this.editMode = false;
        this.modalRef = this.modalService.show(modal, this.modalConfig);
    }

    editPropertyModal(property: Property, modal: TemplateRef<any>) {
        this.property = new Property();
        this.property.id = property.id;
        this.property.defaultValue = property.defaultValue;
        this.property.description = property.description;
        this.property.displayName = property.displayName;
        this.property.mandatory = property.mandatory;
        this.property.name = property.name;
        this.property.phases = property.phases;
        this.property.sectionId = property.sectionId;
        this.property.system = property.system;
        this.property.type = property.type;
        this.property.updateBehavior = property.updateBehavior;
        this.property.visibility = property.visibility;
        this.editMode = true;
        this.modalRef = this.modalService.show(modal, this.modalConfig);
    }

    public saveProperty(propertyType: TypeProperty = null) {
        if (this.editMode) {
            this.propertyService.updateProperty(this.property.id, this.property).subscribe(
                (res) => {
                    this.messageService.add({
                        severity: 'success',
                        summary: this.translate.instant(_('Success!')),
                        detail: this.translate.instant(_('Property updated with success!')),
                    });
                    if (
                        propertyType == TypeProperty.List ||
                        propertyType == TypeProperty.ImageList
                    ) {
                        this.router.navigate([
                            `/admin/${this.authService.selectedInstance.name}/nodes/properties/${res.id}/listvalues`,
                        ]);
                    } else if (
                        propertyType == TypeProperty.User ||
                        propertyType == TypeProperty.Group
                    ) {
                        this.router.navigate([
                            `/admin/${this.authService.selectedInstance.name}/nodes/properties/${res.id}/accessrights`,
                        ]);
                    }
                },
                (error) => {
                    return console.log('Error: ', error);
                },
                () => {
                    return this.getProperties();
                }
            );
        } else {
            this.propertyService.setProperty(this.property).subscribe(
                (res) => {
                    this.messageService.add({
                        severity: 'success',
                        summary: this.translate.instant(_('Success!')),
                        detail: this.translate.instant(_('Property created with success!')),
                    });
                    if (
                        propertyType == TypeProperty.List ||
                        propertyType == TypeProperty.ImageList
                    ) {
                        this.router.navigate([
                            `/admin/${this.authService.selectedInstance.name}/nodes/properties/${res.id}/listvalues`,
                        ]);
                    } else if (
                        propertyType == TypeProperty.User ||
                        propertyType == TypeProperty.Group
                    ) {
                        this.router.navigate([
                            `/admin/${this.authService.selectedInstance.name}/nodes/properties/${res.id}/accessrights`,
                        ]);
                    }
                },
                (error) => {
                    return console.log('Error: ', error);
                },
                () => {
                    this.selectedSections = [];
                    this.getProperties();
                }
            );
        }
        this.modalRef.hide();
    }

    convertToKeys() {
        this.propertyTypesKeys = Object.keys(this.TypeProperty);
        this.propertyTypesKeys = this.propertyTypesKeys.slice(0, this.propertyTypesKeys.length / 2);

        // remove the StatCompleted from creation possibilities
        this.propertyTypesKeys = this.propertyTypesKeys.filter(x => { return x != TypeProperty.StatCompleted });

        this.propertyVisibilitiesKeys = Object.keys(this.VisibilityProperty);
        this.propertyVisibilitiesKeys = this.propertyVisibilitiesKeys.slice(
            0,
            this.propertyVisibilitiesKeys.length / 2
        );

        this.propertyUpdateBehaviorKeys = Object.keys(this.UpdateBehavior);
        this.propertyUpdateBehaviorKeys = this.propertyUpdateBehaviorKeys.slice(
            0,
            this.propertyUpdateBehaviorKeys.length / 2
        );
    }

    updateFormControlValidation(formControls: FormControl[]) {
        formControls.forEach((formControl: FormControl) => {
            return formControl.updateValueAndValidity();
        });
    }

    private generateBreadCrumb() {
        this.dynamicBreadCrumb('Node Configuration');
    }
}
